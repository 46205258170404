<template>
    <div id="bipo">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GENERALITES SUR LE TROUBLE BIPOLAIRE CHEZ L'ADOLESCENT ET L'USAGE DES THYMOREGULATEURS">
                    <h2 class="title is-4">GENERALITES SUR LE TROUBLE BIPOLAIRE CHEZ L'ADOLESCENT ET L'USAGE DES THYMOREGULATEURS</h2>
                    <p class="soustitre2">Diagnostic</p>
                    <ul>
                        <li>Le diagnostic de trouble bipolaire (TB) de type 1 se pose face à un épisode maniaque, même s’il s’agit du 1er épisode, même en l’absence d’antécédent d’épisode dépressif.</li>
                        <li>La présentation de l’épisode maniaque chez l’adolescent est globalement similaire à celle de l’adulte.</li>
                        <li>Le diagnostic d’épisode maniaque/TB est parfois rendu compliqué en cas :
                            <ul>
                                <li>De symptômes mixtes, d’évolution plus progressives et de passages à l’acte répétés qui orientent à tort vers des aménagements limites/narcissiques de la personnalité</li>
                                <li>Des symptômes délirants florides qui font évoquer à tort un épisode psychotique aigu ou une entrée dans la schizophrénie</li>
                                <li>De comorbidités psychiatriques avec un chevauchement de certains symptômes (trouble déficit de l’attention, abus de substance)</li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Prévalence</p>
                    <ul>
                        <li>La prévalence du TB de type 1 et de type 2 est de 1,8% chez les adolescents.</li>
                        <li>Les autres formes de trouble bipolaire (type non spécifié) sont controversées et ne sont pas utilisées chez l’adolescent.</li>
                    </ul>
                    <p class="soustitre2">Histoire naturelle</p>
                    <ul>
                        <li>Le TB est un trouble dont le premier épisode survient classiquement à l’adolescence et chez l’adulte jeune.</li>
                        <li>Dans 70% des TB de type 1 le premier épisode thymique est un épisode dépressif qui survient en moyenne vers l’âge de 11 ans.</li>
                        <li>Les caractéristiques plus spécifiques d’un trouble dépressif bipolaire chez l’enfant et l’adolescent sont : 1) des caractéristiques psychotiques, 2) un ralentissement psychomoteur marqué, 3) des idées suicidaires, 4) un virage maniaque ou hypomaniaque iatrogène, 5) des antécédents familiaux de TB de type 1. Aucun de ces signes n’est spécifique.</li>
                        <li>Le premier épisode maniaque survient en moyenne vers l’âge de 15 ans.</li>
                        <li>La survenue d’un épisode maniaque est exceptionnelle chez l’enfant prépubère. Toutefois, de nombreux troubles psychiatriques et développementaux de l’enfance peuvent se manifester par des symptômes d’allures maniaques. Certains de ces jeunes répondent aux critères du Trouble Disruptif avec Dysrégulation Emotionnelle. Ils ne font pas partie des TB.</li>
                        <li>Le principal risque à court, moyen et long terme du TB à l’adolescence est le décès par suicide.</li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AUTORISATIONS DE MISE SUR LE MARCHE">
               <h2 class="title is-4">INDICATIONS ET AUTORISATIONS DE MISE SUR LE MARCHE</h2>
                    <p class="soustitre1">QUEL PATIENT TRAITER PAR UN THYMOREGULATEUR DANS LE CADRE D'UN TROUBLE BIPOLAIRE ?</p>
                    <ul>
                        <li>Le traitement de l’épisode maniaque repose sur le traitement thymorégulateur.</li>
                        <li>Le traitement doit permettre de traiter l’épisode actuel et de prévenir une rechute du trouble de l’humeur.</li>
                        <li>En cas d’épisode dépressif fortement évocateur d’un TB mais sans antécédent d’épisode maniaque ou hypomaniaque, le traitement est celui de <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 6 }}">l’épisode dépressif majeur.</router-link>
                            <ul>
                                <li>Il faut questionner régulièrement et attentivement le patient et sa famille sur des antécédents personnels de symptômes hypomaniaques ou maniaques qui seront déterminants dans la prise en charge médicamenteuse.</li>
                                <li>En cas de prescription d’antidépresseurs de la famille des Inhibiteur Spécifique de la Recapture de la Sérotonine (ISRS) il faut être très vigilant au risque de virage de l’humeur et de levée d’inhibition.</li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Enfant prépubère</p>
                    <ul>
                        <li>La survenue d’un épisode maniaque est exceptionnelle chez l’enfant prépubère.</li>
                        <li>Des symptômes d’allure maniaques (irritabilité, labilité émotionnelle) souvent d’évolution chronique sont en revanche fréquemment retrouvés dans différents troubles psychiatriques et développementaux. Ces symptômes ne prédisent pas l’évolution vers un TB à l’âge adulte.</li>
                        <li>Certains de ces jeunes répondent aux critères du Trouble Disruptif avec Dysrégulation Emotionnelle.</li>
                        <li>Les thymorégulateurs n’ont pas montré leur efficacité sur ces symptômes chroniques de dysrégulation des émotions.</li>
                    </ul>
                    <p class="soustitre2">Jeune avec trouble du neurodéveloppement</p>
                    <ul>
                        <li>Le diagnostic de TB peut être difficile compte tenu du chevauchement de certains symptômes (par exemple distractibilité et agitation chez les jeunes avec un trouble déficit de l’attention).</li>
                        <li>Les jeunes qui présentent un trouble du développement intellectuel ou un trouble du spectre de l’autisme peuvent aussi présenter un TB.</li>
                        <li>L’épisode maniaque implique un caractère épisodique, c’est-à-dire une franche rupture avec le fonctionnement antérieur.</li>
                        <li>Les jeunes avec troubles du développement présentent globalement une moins bonne tolérance aux psychotropes.</li>
                    </ul>
                    <p class="soustitre2">Symptômes maniaques au décours de la prise en charge d’un médicament ou d’un toxique</p>
                    <ul>
                        <li>Les traitements les plus concernés sont :
                            <ul>
                                <li>Les antidépresseurs : ISRS</li>
                                <li>Les psychostimulants : méthylphénidate, autres</li>
                                <li>Les corticoïdes</li>
                            </ul>
                        </li>
                        <li>La consommation de certains toxiques peut déclencher un épisode maniaque, en particulier la cocaïne. Par ailleurs, les patients avec un TB sont aussi plus à risque de développer d’authentiques troubles liés à l’usage de substances au cours de leur vie.</li>
                    </ul>
                    <p class="soustitre1">QUELLES SONT LES MOLECULES AYANT L'AUTORISATION DE MISE SUR LE MARCHE EN FRANCE ?</p>
                    <p>Trois familles de traitements existent : les antipsychotiques de seconde génération (AP2G), les sels de lithium et les anticonvulsivants.</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th colspan="2">
                                        Produits
                                    </th>
                                    <th rowspan="2">
                                        Indications en France
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <p>DCI</p>
                                    </th>
                                    <th>
                                        <p>Classe</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Aripiprazole&nbsp;(Abilify<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque ≥ 13 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sels de lithium (Teralithe 250<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>LIT</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque, prévention de la rechute ≥16 ans<br>Utilisation déconseillée chez l’enfant (RCP et notice)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Acide valpro&iuml;que (Depakote<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AC</p>
                                    </td>
                                    <td>
                                        <p>Pas d’AMM avant 18 ans
<br>Ne doit pas être utilisé chez les enfants et adolescents âgés de moins de 18 ans dans le traitement de l’épisode maniaque (RCP et notice)
<br>Chez l’adulte : Traitement de l’épisode maniaque, prévention rechute pour ceux ayant répondu positivement (pas en 1ère intention si femme en âge de procréer)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Carbamazépine (Tégrétol<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AC</p>
                                    </td>
                                    <td>
                                        <p>AMM pour le traitement des épilepsies partielles et généralisées chez l’enfant<br>
                                            Chez l’adulte : Episode maniaque, prévention de la rechute</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">AP2G= antipsychotique de seconde génération, LIT= sel de lithium, AC= anticonvulsivants</td>
                            </tfoot>
                        </table>
                    </div>
                    <div><br>
                        <p>Le tableau suivant présente les autorisations de mise sur le marché dans d'autres pays.&nbsp;</p>
                    </div>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        DCI
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Etats-Unis
                                    </th>
                                    <th>
                                        Angleterre
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Aripiprazole&nbsp;(Abilify<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque 10-17 ans</p>
                                    </td>
                                    <td>
                                        <p>Non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Rispéridone (Risperdal<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque 10-17 ans</p>
                                    </td>
                                    <td>
                                        <p>Non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Quetiapine (Xeroquel<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque 10-17 ans</p>
                                    </td>
                                    <td>
                                        <p>Non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Olanzapine (Zyprexa<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque 13-17 ans</p>
                                    </td>
                                    <td>
                                        <p>Non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sels de lithium (Teralithe250 <sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>LIT</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Acide valpro&iuml;que (Depakote<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AC</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Carbamazépine (Tégétrol<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>AC</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                    <td>
                                        <p>Traitement épisode maniaque &ge;12 ans</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="4">AMM en Angleterre (NICE Guideline) aux Etats-Unis (FDA Approval)</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES ATTENDUS">
                    <h2 class="title is-4">BENEFICES CLINIQUES ATTENDUS</h2>
                    <p class="soustitre1">QUELLE EST LA REPONSE ATTENDUE AU TRAITEMENT THYMOREGULATEUR DANS LE TRAITEMENT DU TROUBLE BIPOLAIRE DE L'ADOLESCENT&nbsp;?</p>
                    <ul>
                        <li>La place des thymorégulateurs dans la prise en charge du TB de type 1 chez l’adolescent est essentielle.</li>
                        <li>Les sels de lithium sont les traitements qui ont le plus fait la preuve de leur efficacité.</li>
                        <li>Le taux de réponse des sels de lithium pour le traitement de l’épisode maniaque est de 40-50% de réponse en monothérapie.</li>
                        <li>Les sels de lithium sont les seuls traitements qui ont montré leur efficacité sur la réduction du risque suicidaire chez l’adulte.</li>
                        <li>Chez l’adulte, le lithium est le traitement thymorégulateur le plus efficace sur l’épisode aigu et dans la prévention des rechutes chez l’adulte.</li>
                    </ul>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES POSSIBLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES POSSIBLES</h2>
                    <p>Les effets indésirables dépendent de la molécule prescrite&nbsp;:</p>

                    <div class="select my-4">
                        <select @change="onChangeEI($event)">
                            <option>Choisir une molécule...</option>
                            <option value="1">Sels de lithium (Téralithe LI 250, Téralithe LP 400)</option>
                            <option value="2">Valproate de sodium (Depakote)</option>
                            <option value="3">Carbamazépine (Tégrétol)</option>
                            <option value="4">Lamotrigine (Lamictal)</option>
                            <option value="5">Antipsychotique de seconde génération</option>
                        </select>
                    </div>
                    <div v-if="affiche_molecule_ei == 1">
                        <p class="soustitre2">Sels de lithium (Téralithe LI 250, Téralithe LP 400)</p>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Polyurie
                                            <br>Polydipsie
                                            <br>Tremblements
                                            <br>Prise de poids
                                            <br>Nausée Diarrhée
                                            <br>Hypothyroïdie
                                            <br>Léthargie
                                            <br>Sédation
                                            <br>Survenue ou aggravation d’acné ou de psoriasis
                                            <br>Bradycardie
                                            <br>Perte de cheveux
                                            <br>Hyperleucocytose
                                            <br>Changement sur l’ECG (aplatissement de l’onde T)
                                        </td>
                                        <td>
                                            <u>Signe intoxication (en cas de concentration> 1.5 mEq/l) :</u>
                                            <ul>
                                                <li>Neurologique : syndrome cérébelleux (tremblements, dysarthrie, perte équilibre, trouble de l’élocution), puis confusion, myocolonie, hypertonie pyramidale</li>
                                                <li>Digestif : diarrhée, vomissement</li>
                                                <li>Cardiaque : dysfonction du nœud sinusal, arythmie</li>
                                            </ul>
                                            <u>Atteinte cérébrale :</u>
                                            <ul>
                                                <li>Hypertension intracérébrale</li>
                                                <li>Mouvements anormaux, symptômes extrapyramidaux</li>
                                                <li>Nystagmus</li>
                                                <li>Crise d’épilepsie</li>
                                            </ul>
                                            <u>Autres :</u>
                                            <ul>
                                                <li>Hyperparathyroïdie</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule_ei == 2">
                        <p class="soustitre2">Valproate de sodium (Depakote)</p>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Neurologique, cognitif
                                            <ul>
                                                <li>Tremblements</li>
                                                <li>Léthargie</li>
                                                <li>Sédation, fatigue</li>
                                                <li>Ataxie</li>
                                                <li>Vertige</li>
                                            </ul>
                                            Gastro-intestinaux
                                            <ul>
                                                <li>Nausée, diarrhée</li>
                                                <li>Elévation des transaminases</li>
                                                <li>Insuffisance hépatique</li>
                                                <li>Pancréatite</li>
                                            </ul>
                                            Endocrino-métabolique
                                            <ul>
                                                <li>Prise de poids</li>
                                                <li>Hyperamnoniémie</li>
                                                <li>Elévation du taux de testostérone</li>
                                                <li>Syndrome des ovaires polykystiques (hirsutisme)</li>
                                            </ul>
                                            Autres
                                            <ul>
                                                <li>Alopécie</li>
                                                <li>Rash</li>
                                            </ul>
                                        </td>
                                        <td>
                                            Thrombocytopénie
                                            <br>Myelotoxicité
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                            <br>Réactions cutanées sévère
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule_ei == 3">
                        <p class="soustitre2">Carbamazépine&nbsp;(Tégrétol)</p>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Neurologique, cognitif
                                            <ul>
                                                <li>Maladresse, vertige</li>
                                                <li>Nystagmus</li>
                                                <li>Sédation</li>
                                                <li>Vision floue, diplopie, photosensibilité</li>
                                                <li>Léthargie</li>
                                                <li>Ataxie</li>
                                                <li>Confusion</li>
                                            </ul>
                                            Gastro-intestinaux
                                            <ul>
                                                <li>Nausée, vomissement</li>
                                            </ul>
                                            Autres
                                            <ul>
                                                <li>Rash</li>
                                                <li>Leucopénie</li>
                                                <li>Hyponatrémie</li>
                                            </ul>
                                        </td>
                                        <td>
                                            Réactions cutanées sévères
                                            <br>Agranulocytose
                                            <br>Anémie aplasique
                                            <br>Bloc auriculoventriculaire
                                            <br>Hépatite
                                            <br>Dysfonction rénale
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule_ei == 4">
                        <p class="soustitre2">Lamotrigine (Lamictal)</p>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Vertige
                                            <br>Ataxie
                                            <br>Céphalées
                                            <br>Tremblements
                                            <br>Vision floue, diplopie,
                                            <br>Rash
                                            <br>Nausées, vomissements
                                            <br>Ataxie
                                            <br>Léthargie
                                            <br>Confusion
                                        </td>
                                        <td>
                                            Réactions cutanées sévères (Stevens-Johnson ou syndrome de Lyell)
                                            <br>Anémie
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule_ei == 5">
                        Le détail des effets indésirables des traitements AP2G prescrits à visée thymorégulatrice, comme l’aripiprazole (Abilify®) et la rispéridone (Risperdal®), <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 5, start: 4 }}">peut être retrouvé ici.</router-link>
                    </div>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT D'ENVISAGER DE PRESCRIRE UN THYMOREGULATEUR">
                    <h2 class="title is-4">CONSEILS AVANT D'ENVISAGER DE PRESCRIRE UN THYMOREGULATEUR</h2>
                    <p class="soustitre1">RECUEIL DE L'ADHESION DU JEUNE ET SA FAMILLE</p>
                    <p class="soustitre2">Adhésion du jeune</p>
                    <ul>
                        <li>La prise d’un médicament au quotidien n’est pas un acte anodin pour un jeune.</li>
                        <li>Il faut expliquer en fonction de l’âge l’utilité du traitement.</li>
                        <li>Il est important de rappeler que les parents sont également d’accord et partie prenante.</li>
                        <li>Il conviendra de discuter de ces questions avec le jeune.</li>
                    </ul>
                    <p class="soustitre2">Adhésion des parents</p>
                    <ul>
                        <li>Il peut exister des réticences, parfois non rationnelles, parfois liées à l’expérience personnelle ou familiale au traitement.</li>
                        <li>Enfin, les parents ont désormais accès à bon nombre d’information sur internet, il faut donc répondre par avance aux questions concernant l’efficacité attendue, la tolérance, les possibles effets indésirables.</li>
                    </ul>
                    <p class="soustitre1">EVALUATION CLINIQUE</p>
                    <ul>
                        <li>La mise en place d’un traitement thymorégulateur (qui mettra plusieurs jours ou semaines à être efficace) est rarement une urgence.
                        <li>Dans le cadre d’un épisode maniaque il est courant de mettre en place un traitement symptomatique pour l’agitation dans l’attente du choix et de l’instauration d’un traitement thymorégulateur une fois l’anamnèse complétée.
                        <li>L’instauration du thymorégulateur nécessite de faire une évaluation clinique détaillée sur une période suffisamment longue qui comprend au minimum :
                            <ul>
                                <li>L’histoire naturelle des symptômes</li>
                                <li>Les comorbidités psychiatriques et somatiques</li>
                                <li>Les antécédents familiaux de troubles de l’humeur</li>
                                <li>L’existence de symptômes psychotiques associés</li>
                                <li>L’environnement familial et le contexte social et scolaire</li>
                                <li>L’existence de stress psychosociaux y compris violence physique ou sexuelle.</li>
                            </ul>
                        </li>
                        <li>Une fiche <a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l’instauration d’un thymorégulateur</a> est disponible pour le clinicien pour résumer ces points.</li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION">
                    <h2 class="title is-4">INSTAURATION</h2>
                    <p class="soustitre1">QUEL TRAITEMENT POUR LE TROUBLE BIPOLAIRE DOIT ETRE PRIVILEGIE ?</p>
                    <ul>
                        <li>Excepté pour les sels de lithium, l’efficacité des molécules thymorégulatrices est globalement comparable.</li>
                        <li>Plusieurs questions se posent pour choisir le traitement thymorégulateur adapté.
                            <ul>
                                <li>Quels sont les effets indésirables à éviter ?</li>
                                <li>Existe-t-il des antécédents personnels de réponse positive à un traitement médicamenteux ?</li>
                                <li>Existe-t-il des antécédents familiaux de réponse positive à un traitement médicamenteux ?</li>
                                <li>Existe-t-il des symptômes psychotiques associés ?</li>
                            </ul>
                        </li>
                        <li>Nous proposons l’algorithme suivant sur la base d’une revue de la littérature des recommandations des sociétés savantes américaines (Child and Adolescent Bipolar Foundation 2005, American Academy of Child and Adolescent Psychiatry 2007), anglaise (National Institute For Health and Care Excellence 2006, et australienne (The Mood Disorders Clinical Practice Guideline 2015) :
                        </li>
                    </ul>
                    <img class="illustration object-fill h-480 w-full" alt="algo" src="/pathologie/bipolaire/algo4.png">
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT DU TROUBLE BIPOLAIRE ?</p>
                    <ul>
                        <li>La mise en place d’un traitement thymorégulateur (qui mettra plusieurs jours ou semaines à être efficace) n’est jamais une urgence.</li>
                        <li>Dans le cadre d’un épisode maniaque il est courant de mettre en place un traitement symptomatique pour l’agitation dans l’attente du choix et de l’instauration d’un traitement thymorégulateur une fois l’anamnèse complétée.</li>
                        <li>Dans tous les cas il faut réaliser un bilan biologique à la recherche de comorbidités somatiques et liées aux possibles conduites de mises en danger (bilan IST, βHCG, toxiques urinaires).</li>
                        <li>La molécule doit être instaurée selon les doses préconisées et augmenter progressivement par palier en recherchant la dose minimale efficace (voir tableau suivant). Les paliers varient de 2 à 7 jours selon les molécules.</li>
                        <li>Dans l’attente de l’efficacité de la molécule on peut utiliser :
                            <ul>
                                <li>Traitement symptomatique pour l’agitation</li>
                                <li>Traitement hypnotique</li>
                                <li>Intérêt de traitement adjuvant avec un effet anti-maniaque : chlorpromazine (Largactil®), clonazépam (Rivotril®)</li>
                            </ul>
                        </li>
                        <li>Les traitements par clozapine ou par électro-convulsivothérapie sont réservés à la prise en charge des épisodes maniaques résistants à plusieurs lignes de traitements bien conduits.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        Dose de début
                                    </th>
                                    <th>
                                        Posologie
                                    </th>
                                    <th>
                                        Palier
                                    </th>
                                    <th>
                                        Dose efficace
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Aripiprazole&nbsp;(Abilify<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>2,5-5 mg/j</p>
                                    </td>
                                    <td>
                                        <p>1 prise le soir</p>
                                    </td>
                                    <td>
                                        <p>2,5-5 mg/j</p>
                                    </td>
                                    <td>
                                        <p>30 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Rispéridone (Risperdal<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>0,5-1 mg/j</p>
                                    </td>
                                    <td>
                                        <p>1 prise le soir</p>
                                    </td>
                                    <td>
                                        <p>0,5-1 mg/j</p>
                                    </td>
                                    <td>
                                        <p>6 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Quetiapine (Xéroquel<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>25 mg/j</p>
                                    </td>
                                    <td>
                                        <p>1 prise le soi</p>
                                    </td>
                                    <td>
                                        <p>50 mg/j (J2), 100mg (J3)</p>
                                    </td>
                                    <td>
                                        <p>600 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Olanzapine (Zyprexa<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>2,5-5 mg/j</p>
                                    </td>
                                    <td>
                                        <p>1 prise le soir</p>
                                    </td>
                                    <td>
                                        <p>2,5-5 mg/j</p>
                                    </td>
                                    <td>
                                        <p>20 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sels de lithium (Teralithe250 <sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>125-250 mg/j</p>
                                    </td>
                                    <td>
                                        <p>En 2 prises /j</p>
                                    </td>
                                    <td>
                                        <p>+ 125-250 mg/j tous les 5 jours</p>
                                    </td>
                                    <td>
                                        <p>Selon titration [0,5-0,8 mEq/l]</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Acide valpro&iuml;que (Depakote<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>10-15 mg/kg/j</p>
                                    </td>
                                    <td>
                                        <p>En 2-3 prises /j</p>
                                    </td>
                                    <td>
                                        <p>+ 250-500 mg/j tous les 3-5 jours</p>
                                    </td>
                                    <td>
                                        <p>Selon titration [40-100 &mu;g/ml]</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Carbamazépine (Tégrétol<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>200 mg/j</p>
                                    </td>
                                    <td>
                                        <p>En 2 prises /j</p>
                                    </td>
                                    <td>
                                        <p>+ 200 mg/j tous les 2-7 jours</p>
                                    </td>
                                    <td>
                                        <p>Selon titration |4-12 &mu;g/ml]</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre1">MODE DE PRESCRIPTION SELON LA MOLECULE</p>
                    <div class="select my-4">
                        <select @change="onChange($event)">
                            <option>Choisir une molécule...</option>
                            <option value="1">Sels de lithium (Téralithe LI 250, Téralithe LP 400)</option>
                            <option value="2">Valproate de sodium (Depakote)</option>
                            <option value="3">Carbamazépine (Tégrétol)</option>
                            <option value="4">Lamotrigine (Lamictal)</option>
                        </select>
                    </div>
                    <div v-if="affiche_molecule == 1">
                        <p class="soustitre2">Sels de lithium (Téralithe LI 250, Téralithe LP 400)</p>
                        <p class="py-4"><b>Instauration de la dose</b></p>
                        <ul>
                            <li>Le traitement doit être débuté à 125 ou 250 mg/j.</li>
                            <li>Le traitement est augmenté par palier de 125 ou 250 mg/j selon le dosage plasmatique.</li>
                            <li>Le dosage de la concentration plasmatique de lithium se fait au moins 5 jours après chaque augmentation de dose jusqu’à l’obtention d’une lithémie efficace stable sur 3 dosages successifs.</li>
                            <li>Le dosage se fait par la suite tous les 3 mois pendant 6 mois puis tous les 6 mois.</li>
                            <li>La lithémie cible lors de prélèvement le matin à jeun 12h après la dernière prise est de 0,5-0,8 mEq/l pour la forme LI (c’est la concentration minimale plasmatique efficace) et de 0,8-1,2 mEq/ pour la forme LP (c’est la concentration intermédiaire).</li>
                        </ul>
                        <p class="py-4"><b>Bilan pré thérapeutique</b></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>NFS, ionogramme sanguin</li>
                            <li>TSH, T4L</li>
                            <li>Urée, créatinémie, bandelette urinaire + analyse urine 24h (avec mesure de la protéinurie, créatinurie, calcul de la clairance) à faire si protéinurie + à la BU, polyurie marquée, ou modification de la créatinémie</li>
                            <li>Calcium, Albumine</li>
                            <li>βHCG chez la femme en âge de procréer</li>
                            <li>ECG</li>
                        </ul>
                        <p class="py-4"><b>Bilan de suivi à faire 1) chaque changement de dose, 2) tous les 6 mois, 3) si effets indésirables suspectés, 4) si survenue d’un épisode dépressif</b></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>NFS, ionogramme sanguin</li>
                            <li>Urée, créatinémie, bandelette urinaire + analyse urine 24h (avec mesure de la protéinurie, créatinurie, calcul de la clairance) à faire si protéinurie + à la BU, polyurie marquée, ou modification de la créatinémie</li>
                            <li>Calcium, Albumine</li>
                            <li>TSH, T4L</li>
                            <li>ECG</li>
                        </ul>
                        <p class="py-4"><strong>Précautions d'emploi</strong></p>
                        <ul>
                            <li>La RCP et la notice d’utilisation stipulent que l’utilisation de sels de lithium est déconseillée chez l’enfant.</li>
                            <li>En cas de diminution des apport hydriques (jeun...) ou de pertes abondantes (diarrhée, vomissements…) les doses de lithium doivent être réduites ou suspendues temporairement jusqu’à la reprise d’un apport hydrique régulier. Il faut maintenir une hydratation adéquate lors d’exercice physique ou en cas de fortes chaleurs.</li>
                            <li>Il faut éviter les changements majeurs dans les apports en sel, en caféine et en eau qui modifient l’élimination rénale du lithium.</li>
                            <li>Il faut informer le jeune et sa famille sur les risques liés à la prise conjointe de médicaments/toxiques qui risquent :
                                <ul>
                                    <li>D’augmenter la lithémie : AINS, diurétique, IEC, ARA2, alcool, marijuana</li>
                                    <li>De diminuer la lithémie : corticoïde</li>
                                </ul>
                            </li>
                            <li>Le lithium augmente le risque d’effets indésirables rares mais graves d’autres psychotropes :
                                <ul>
                                    <li>Comme les effets indésirables extra-pyramidaux et le syndrome malin chez les adolescents traités par antipsychotiques</li>
                                    <li>Comme le syndrome sérotoninergique sous ISRS</li>
                                </ul>
                            </li>
                            <li>Le dosage de la lithémie intra-érythrocytaire permet d’avoir une estimation indirecte de la lithémie intra-neuronale. La lithémie intra-érythrocytaire doit être dans la fourchette 0,2-0,4 mEq/L. Son dosage et le calcul du rapport lithémie érythrocytaire/sérique peuvent être intéressants en cas d’effets indésirables malgré une lithémie faible.</li>
                        </ul>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Polyurie
                                            <br>Polydipsie
                                            <br>Tremblements
                                            <br>Prise de poids
                                            <br>Nausée Diarrhée
                                            <br>Hypothyroïdie
                                            <br>Léthargie
                                            <br>Sédation
                                            <br>Survenue ou aggravation d’acné ou de psoriasis
                                            <br>Bradycardie
                                            <br>Perte de cheveux
                                            <br>Hyperleucocytose
                                            <br>Changement sur l’ECG (aplatissement de l’onde T)
                                        </td>
                                        <td>
                                            <u>Signe intoxication (en cas de concentration> 1.5 mEq/l) :</u>
                                            <ul>
                                                <li>Neurologique : syndrome cérébelleux (tremblements, dysarthrie, perte équilibre, trouble de l’élocution), puis confusion, myocolonie, hypertonie pyramidale</li>
                                                <li>Digestif : diarrhée, vomissement</li>
                                                <li>Cardiaque : dysfonction du nœud sinusal, arythmie</li>
                                            </ul>
                                            <u>Atteinte cérébrale :</u>
                                            <ul>
                                                <li>Hypertension intracérébrale</li>
                                                <li>Mouvements anormaux, symptômes extrapyramidaux</li>
                                                <li>Nystagmus</li>
                                                <li>Crise d’épilepsie</li>
                                            </ul>
                                            <u>Autres :</u>
                                            <ul>
                                                <li>Hyperparathyroïdie</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule == 2">
                        <p class="soustitre2">Valproate de sodium (Depakote)</p>
                        <p class="py-4"><b>Instauration de la dose</b></p>
                        <ul>
                            <li>Le traitement doit être débuté à 10-15mg/kg/j en deux prises par jour.
                            <li>Le traitement est augmenté par palier selon le dosage plasmatique.</li>
                            <li>La dose efficace habituelle se situe autour de 20 mg/kg/j mais peut être variable selon les patients.</li>
                            <li>Le dosage de la concentration plasmatique de valproate de sodium se fait au moins 3-5 jours après chaque augmentation de dose jusqu’à l’obtention d’une valproatémie entre 50-125 μg/ml.</li>
                        </ul>
                        <p class="py-4"><b>Bilan pré thérapeutique</b></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>Cycles menstruels. Devant tout signe de Syndrome des Ovaires Polykystiques (SOPK) : évaluation du cycle menstruel et consultation gynécologique</li>
                            <li>NFS (dont plaquettes)</li>
                            <li>ASAT, ALAT [risque d’insuffisance hépatique surtout les 6 premiers mois de traitement]</li>
                            <li>Lipase [Répéter le dosage en cas de suspicion de pancréatite] </li>
                            <li>βHCG chez la femme en âge de procréer</li>
                        </ul>
                        <p class="py-4"><b>Bilan de suivi à faire 1) à chaque changement de dose à S2, S4, M2, M4, 2) tous les 6 mois si dose stable, 3) si effets indésirables suspectés, 4) si survenue d’un épisode dépressif</b></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>Cycles menstruels. Devant tous signe de SOPK : évaluation du cycle menstruel et consultation gynécologique</li>
                            <li>NFS (dont plaquettes)</li>
                            <li>ASAT, ALAT [risque d’insuffisance hépatique surtout les 6 premiers mois de traitement]</li>
                        </ul>
                        <p class="py-4"><strong>Précautions d'emploi</strong></p>
                        <ul>
                            <li>La RCP et la notice d’utilisation stipulent que l’acide valproïque ne doit pas être utilisé chez les enfants et adolescents âgés de moins de 18 ans dans le traitement de l’épisode maniaque.</li>
                            <li>Les effets indésirables du valproate de sodium disparaissent le plus souvent avec l’adaptation de la dose.</li>
                            <li>Le traitement par valproate de sodium augmente la concentration de la carbamazépine.</li>
                            <li>Le valproate est non-indiqué chez les femmes en âge de procréer compte tenu du risque tératogène.</li>
                            <li>Le valproate doit être utilisé uniquement en cas de CI ou d’intolérance aux autres thymorégulateurs. L’instauration d’un traitement par valproate chez la femme en âge de procréer doit rester exceptionnel et nécessite une information écrite sur le risque tératogène avec un consentement signé. La mise en place d’une contraception efficace est indispensable.</li>
                        </ul>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Neurologique, cognitif
                                            <ul>
                                                <li>Tremblements</li>
                                                <li>Léthargie</li>
                                                <li>Sédation, fatigue</li>
                                                <li>Ataxie</li>
                                                <li>Vertige</li>
                                            </ul>
                                            Gastro-intestinaux
                                            <ul>
                                                <li>Nausée, diarrhée</li>
                                                <li>Elévation des transaminases</li>
                                                <li>Insuffisance hépatique</li>
                                                <li>Pancréatite</li>
                                            </ul>
                                            Endocrino-métabolique
                                            <ul>
                                                <li>Prise de poids</li>
                                                <li>Hyperamnoniémie</li>
                                                <li>Elévation du taux de testostérone</li>
                                                <li>Syndrome des ovaires polykystiques (hirsutisme)</li>
                                            </ul>
                                            Autres
                                            <ul>
                                                <li>Alopécie</li>
                                                <li>Rash</li>
                                            </ul>
                                        </td>
                                        <td>
                                            Thrombocytopénie
                                            <br>Myelotoxicité
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                            <br>Réactions cutanées sévère
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule == 3">
                        <p class="soustitre2">Carbamazépine&nbsp;(Tégrétol)</p>
                        <p class="py-4"><strong>Instauration de la dose</strong></p>
                        <ul>
                            <li>Le traitement par carbamazépine doit être débuté à 200mg/j en deux prises (100 mg matin, 100 mg soir).</li>
                            <li>Le traitement est augmenté par palier de 200 mg/j tous les 2-7 jours.</li>
                            <li>Le dosage de la concentration plasmatique de carbamazépine se fait au moins 1 à 2 semaines après chaque augmentation de dose jusqu’à l’obtention d’une concentration entre 4-12 μg/ml.</li>
                        </ul>
                        <p class="py-4"><strong>Bilan pré thérapeutique</strong></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>NFS (dont plaquettes), ionogramme sanguin</li>
                            <li>ASAT, ALAT</li>
                            <li>βHCG chez la femme en âge de procréer</li>
                        </ul>
                        <p class="py-4"><strong>Bilan de suivi à faire 1) à S1, S3, S4 après chaque changement de dose, 2) tous les 3-4 mois si dose stable, 3) si effets indésirables suspectés, 4) si survenue d’un épisode dépressif.</strong></p>
                        <ul>
                            <li>Poids, Taille, IMC</li>
                            <li>NFS (dont plaquettes) à faire en cas de fièvre inexpliquée, de maux de gorge, de lymphadénopathie ou de fatigue sévère</li>
                            <li>ASAT, ALAT</li>
                            <li>Ionogramme sanguin</li>
                            <li>βHCG chez la femme en âge de procréer</li>
                        </ul>
                        <p class="py-4"><strong>Précautions d'emploi</strong></p>
                        <ul>
                            <li>La carbamazépine est un inducteur enzymatique qui influence le métabolique d’autres médicaments par une action sur les cytochromes P450 1A2 et 3A4 :
                                <ul>
                                    <li>↓ concentration aripiprazole, quétiapine, olanzapine, zolpidem, sertraline, lamotrigine</li>
                                    <li>↓ concentration des contraceptifs oraux</li>
                                    <li>↓ concentration de la carbamazépine (à terme il induit son propre métabolisme et son efficacité diminue spontanément)</li>
                                    <li>Chez la femme en âge de procréer une contraception efficace est indispensable (attention ↓efficacité de la contraception orale)</li>
                                </ul>
                            </li>
                        </ul>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Neurologique, cognitif
                                            <ul>
                                                <li>Maladresse, vertige</li>
                                                <li>Nystagmus</li>
                                                <li>Sédation</li>
                                                <li>Vision floue, diplopie, photosensibilité</li>
                                                <li>Léthargie</li>
                                                <li>Ataxie</li>
                                                <li>Confusion</li>
                                            </ul>
                                            Gastro-intestinaux
                                            <ul>
                                                <li>Nausée, vomissement</li>
                                            </ul>
                                            Autres
                                            <ul>
                                                <li>Rash</li>
                                                <li>Leucopénie</li>
                                                <li>Hyponatrémie</li>
                                            </ul>
                                        </td>
                                        <td>
                                            Réactions cutanées sévères
                                            <br>Agranulocytose
                                            <br>Anémie aplasique
                                            <br>Bloc auriculoventriculaire
                                            <br>Hépatite
                                            <br>Dysfonction rénale
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="affiche_molecule == 4">
                        <p class="soustitre2">Lamotrigine (Lamictal)</p>
                        <p class="py-4"><strong>Instauration de la dose</strong></p>
                        <ul>
                            <li>Le traitement doit être débuté à 25 mg/j chez les ≥ 12 ans.</li>
                            <li>Le traitement est augmenté par palier de 25 mg/j toutes les 2 semaines administrés 2 fois par jour jusqu’à 100 mg/j (titration lente sur ≈ 6-8 semaines).</li>
                        </ul>
                        <p class="py-4"><strong>Bilan pré thérapeutique</strong>
                            <ul>
                                <li>NFS (dont plaquettes)</li>
                                <li>ASAT, ALAT</li>
                                <li>βHCG chez la femme en âge de procréer</li>
                            </ul>
                        </p>
                        <p class="py-4"><strong>Bilan de suivi à faire 1) tous les 3-6 mois, 2) si effets indésirables suspectés, 3) si survenue d’un épisode dépressif</strong></p>
                        <ul>
                            <li>NFS (dont plaquettes)</li>
                            <li>ASAT, ALAT</li>
                            <li>βHCG chez la femme en âge de procréer</li>
                        </ul>
                        <p class="py-4"><strong>Précautions d'emploi</strong></p>
                        <ul>
                            <li>Des informations claires doivent être données pour surveiller le risque de rash cutané ou au niveau des muqueuses : éviter l’exposition au soleil, ne pas changer de savon, shampoing, ou de lessive lors de l’introduction du traitement.</li>
                            <li>Contacter un médecin en urgence si un rash cutané apparait. Le traitement doit être suspendu immédiatement en cas d’éruption cutané. </li>
                            <li>En cas de traitement associé par valproate la dose doit être réduite de moitié pour éviter les effets indésirables.</li>
                        </ul>
                        <p class="py-4"><strong>Effets indésirables</strong></p>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Effets indésirables fréquents
                                        </th>
                                        <th>
                                            Effets indésirables rares et graves
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Vertige
                                            <br>Ataxie
                                            <br>Céphalées
                                            <br>Tremblements
                                            <br>Vision floue, diplopie,
                                            <br>Rash
                                            <br>Nausées, vomissements
                                            <br>Ataxie
                                            <br>Léthargie
                                            <br>Confusion
                                        </td>
                                        <td>
                                            Réactions cutanées sévères (Stevens-Johnson ou syndrome de Lyell)
                                            <br>Anémie
                                            <br>Syndrome d’hypersensibilité aux anticonvulsifs
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>Le suivi nécessite des consultations régulières en milieu hospitalier.</li>
                        <li>Des grilles de suivis sont proposées pour observer <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>l’évolution clinique et la tolérance des thymorégulateurs.</a></li>
                        <li>L’objectif est d’obtenir la rémission complète.</li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE A 6-8 SEMAINES</p>
                    <ul>
                        <li>Il faut continuer le traitement thymorégulateur après la résolution des symptômes avec une surveillance régulière.</li>
                        <li>Le traitement thymorégulateur qui a fonctionné pour le traitement de l’épisode aigu doit être maintenu au moins 2 ans avant d’envisager une diminution prudente.</li>
                    </ul>
                    <p class="soustitre1">SI PAS D'AMELIORATION CLINIQUE SIGNIFICATIVE OBSERVEE A 6-8 SEMAINES</p>
                    <ul>
                        <li>Il faut s’assurer de l’observance du traitement.</li>
                        <li>On peut augmenter le traitement thymorégulateur jusqu’à la dose maximale tolérée, en respectant les paliers.</li>
                        <li>Si le traitement reste inefficace malgré l’augmentation à dose maximale, il faut considérer un changement de traitement.<br><router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 1, start: 6 }}">L’algorithme suivant</router-link> a été proposé pour le choix des traitement médicamenteux de seconde et troisième ligne des épisodes maniaques de l’adolescent.</li>
                        <li>En cas d’épisode maniaque sévère résistant on discutera la potentialisation avec un autre traitement pharmacologique.</li>
                        <li>Il faut recherche d’éventuelles comorbidités psychiatriques, en particulier un abus de substance.</li>
                        <li>Il faut rechercher des stimulations excessives de l’environnement (sollicitations de l’entourage, contexte d’agitation d’autres patients dans l’unité d’hospitalisation).</li>
                    </ul>
                    <p class="soustitre1">PRISE EN CHARGE DES COMORBIDITEES</p>
                    <ul>
                        <li>La prise en charge de l’épisode maniaque est la priorité.</li>
                        <li>La prise en charge de comorbidités tel qu’un trouble anxieux ou un abus de substance ne peut se faire qu’une fois l’humeur stabilisée.</li>
                    </ul>
                    <p class="soustitre1">EN CAS DE DEPRESSION BIPOLAIRE OU DE VIRAGE DEPRESSIF SOUS THYMOREGULATEUR</p>
                    <ul>
                        <li>On peut discuter l’ajout ou le changement de thymorégulateur pour les sels de lithium.</li>
                        <li>L’introduction d’ISRS n’est pas formellement contre-indiquée mais nécessite une couverture thymorégulatrice et une bonne surveillance du risque de virage de l’humeur et de levée d’inhibition.</li>
                        <li>Le traitement par ISRS doit être prescrit à faible dose, en augmentation progressivement les doses et sur une durée la plus courte possible.</li>
                        <li>Dans la littérature on mentionne l’intérêt de molécules comme la quétiapine (Xéroquel®), la lamotrigine (Lamictal®) ou encore le bupropion (Zyban®). Ces traitements sont hors AMM chez l’adolescent. Leurs bénéfices n’ont pas été formellement démontrés chez l’adolescent.</li>
                    </ul>
                    <p class="soustitre1">TRAITEMENT PROPHYLAXIQUE</p>
                    <ul>
                        <li>Privilégier la prescription d’un traitement thymorégulateur seul pour favoriser tolérance et observance (sels de lithium en 1ère intention).</li>
                        <li>Le traitement doit être maintenu au moins 2 ans avant d’envisager une diminution prudente.</li>
                    </ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>

                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l'instauration d'un thymorégulateur</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Fiche de suivi de l'efficacité clinique et de la tolérance</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique du trouble bipolaire</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[4])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur le traitement médicamenteux du trouble bipolaire pour enseignement</a></li>
                    </ul>

                    <p class="soustitre2">Association de patients et liens utiles</p>
                    <ul>
                        <li>ARGOS 2001 : <a href="http://www.argos2001.fr" target="_blank">www.argos2001.fr</a></li>
                        <li>Bipolarité France : <a href="http://www.bipolaritefrance.com" target="_blank">https://www.bipolaritefrance.com/</a></li>
                    </ul>

                    <p class="soustitre2">Liste des recommandations utilisées pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Recommandations
                                    </th>
                                    <th>
                                        Année
                                    </th>
                                    <th>
                                        Traitements approuvés en 1<sup>ere</sup> intention
                                    </th>
                                    <th>
                                        Traitements approuvés en 2<sup>nd</sup> intention
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CABF (US)</p>
                                    </td>
                                    <td>
                                        <p>2005</p>
                                    </td>
                                    <td>
                                        <p>LITHIUM/VALPROATE/CARBAMAZEPINE<br />
                                            AP2G (olanzapine, quetiapine, risperidone)</p>
                                    </td>
                                    <td>
                                        <p>Autre classe</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>NICE (UK)</p>
                                    </td>
                                    <td>
                                        <p>2006</p>
                                    </td>
                                    <td>
                                        <p>AP2G</p>
                                    </td>
                                    <td>
                                        <p>LITHIUM +/&minus; VALPROATE</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>AACAP (US)</p>
                                    </td>
                                    <td>
                                        <p>2007</p>
                                    </td>
                                    <td>
                                        <p>LITHIUM/VALPROATE +/&minus; AP2G</p>
                                    </td>
                                    <td>
                                        <p>LAMOTRIGINE/AP2G (olanzapine)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Mood Disorders CPG (Australie)</p>
                                    </td>
                                    <td>
                                        <p>2015</p>
                                    </td>
                                    <td>
                                        <p>AP2G (olanzapine, ziprasidone, risperidone, aripiprazole), quetiapine +/- VALPROATE</p>
                                    </td>
                                    <td>
                                        <p>LITHIUM +/&minus; VALPROATE</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="4">Note : Les travaux récents sur la tératogénicité du valproate réalisés ces derniers années ne sont pas inclus dans ces recommandations.<br>
                                    AACAP= American Academy for Child and Adolescent Psychiatry, CABF= Child and Adolescent Bipolar Foundation, NICE= National Institute for Health and Care Excellence.</td>
                            </tfoot>
                        </table>
                    </div>


                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleBipo',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            //showpart: 1,
            rep: 'bipolaire',
            affiche_molecule_ei: 0,
            affiche_molecule: 0,
            maintitle: 'Trouble bipolaire',
            dl_files: {},
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ],
        }
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {
        onChange(event) {
            this.affiche_molecule = event.target.value
        },
        onChangeEI(event) {
            this.affiche_molecule_ei = event.target.value
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>