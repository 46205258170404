import { render, staticRenderFns } from "./Trouble.vue?vue&type=template&id=4064445f&scoped=true&"
import script from "./Trouble.vue?vue&type=script&lang=js&"
export * from "./Trouble.vue?vue&type=script&lang=js&"
import style0 from "./Trouble.vue?vue&type=style&index=0&id=4064445f&scoped=true&lang=css&"
import style1 from "./Trouble.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4064445f",
  null
  
)

export default component.exports