<template>
    <div id="psychotique">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <ul>
                        <li>Les antipsychotiques de première génération (AP1G) et les antipsychotiques de seconde génération (AP2G) ont montré une certaine efficacité chez l’enfant et l’adolescent dans la prise en charge :
                            <ul>
                                <li>Des schizophrénies à début précoce et autres troubles psychotiques</li>
                                <li>Des troubles bipolaires (page dédiée <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 1, start: 1 }}">disponible ici</router-link>)</li>
                                <li>Des troubles du comportement chez les patients avec un trouble du spectre autistique et/ou une déficience intellectuelle</li>
                                <li>Des tics et syndrome de Gilles de la Tourette</li>
                                <li>De l’état d’agitation le plus souvent dans des contextes d’urgences</li>
                            </ul>
                        </li>
                        <li>Comme de nombreux psychotropes, les prescriptions d’antipsychotique chez l’enfant et l’adolescent sont majoritairement hors AMM (1, 2).</li>
                        <li>En France la prescription des antipsychotiques chez les jeunes de moins de 20 ans est relativement stable (3) avec une diminution des prescriptions des AP1G et une augmentation des prescriptions d’AP2G. La fréquence des prescriptions des antipsychotiques en 2014 en France chez les jeunes entre 0-19 ans était de 3,8 pour 1 000 sujets (4).</li>
                        <li>Des données récentes suggèrent que les enfants et les adolescents seraient plus sensibles que les adultes à certains effets indésirables, en particulier les effets endocrinologiques et métaboliques (5).</li>
                        <li>L’évaluation de la balance bénéfice-risque est essentielle à la décision de prescription d’un antipsychotique. L’acceptation par l’enfant et sa famille du traitement est un élément majeur de son efficacité</li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    Le tableau suivant résume les AMM des AP1G en France chez l’enfant et l’adolescent.
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        DCI
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Indication thérapeutique et posologies en France
                                    </th>
                                </tr>
                                <tr>
                                    <td>Chlorpromazine (Largactil®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement avec agitation et agressivité (1 à 5 mg/kg/jour) chez les enfants de plus de 6 ans</td>
                                </tr>
                                <tr>
                                    <td>
                                        Cyamémazine (Tercian®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement avec agitation et agressivité (1 à 4 mg/kg/jour) chez les enfants de plus de 3 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lévomépromazine (Nozinan®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement avec agitation et agressivité (0,5 à 2 mg/kg/jour) chez les enfants de plus de 3 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Loxapine (Loxapac®)</td>
                                    <td>AP1G*</td>
                                    <td>États psychotiques aigus et états psychotiques chroniques (schizophrénies et délires chroniques non schizophréniques) à la posologie de 75 à 200 mg/jour) à partir de 15 ans
                                        <br>États d’agitation, d’agressivité, et anxiétés associées à des troubles psychotiques ou à certains troubles de la personnalité : entre 50 et 300 mg/jour, répartis en 2 ou 3 injections (Solution injectable) à partir de 15 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Halopéridol (Haldol®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement (agitation, automutilations, stéréotypies) notamment dans le cadre des syndromes autistiques (0,02 à 0,2 mg/kg/jour) à partir de 3 ans
                                        <br>Chorées (mouvements anormaux) et maladie des tics de Gilles de la Tourette (0,02 à 0,2 mg/kg/jour) à partir de 3 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Sulpiride (Dogmatil®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement (agitation, automutilations, stéréotypies) notamment dans le cadre des syndromes autistiques (5 à 10 mg/kg) à partir de 6 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tiapride (Tiapridal®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement avec agitation et agressivité (100 à 150mg/j) à partir de 3 ans (gouttes)/ 6 ans (comprimés)
                                        <br>Chorées et maladie des tics de Gilles de la Tourette (3 à 6 mg/kg/jour) à partir de 3 ans (gouttes)/ 6 ans (comprimés)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Pimozide (Orap®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement (agitation, automutilations, stéréotypies) notamment dans le cadre des syndromes autistiques (0,02 à 0,2 mg/kg) à partir de 6 ans
                                        <br>Chorées et maladie des tics de Gilles de la Tourette (0,02 à 0,2 mg/kg) à partir de 6 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Propériciazine (Neuleptil®)</td>
                                    <td>AP1G</td>
                                    <td>Troubles graves du comportement avec agitation et agressivité (0,1 à 0,5 mg/kg/jour) chez les enfants de plus de 3 ans
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">Note : mise à jour en Septembre 2020<br>* Bien que la loxapine soit classée dans la catégorie des antipsychotiques conventionnels, elle est parfois classée parmi les AP2G compte tenu de sa structure chimique proche de celle la clozapine</td>
                            </tfoot>
                        </table>
                    </div>
                    Le tableau suivant résume les AMM des AP2G en France chez l’enfant et l’adolescent.
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        DCI
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Indication thérapeutique et posologies en France
                                    </th>
                                </tr>
                                <tr>
                                    <td>Rispéridone (Risperdal®)</td>
                                    <td>AP2G</td>
                                    <td>Troubles des conduites chez les enfants à partir de 5 ans et les adolescents avec un fonctionnement intellectuel inférieur à la moyenne ou un retard mental pour une durée de 6 semaines maximale (1)
                                        <br>&lt;50kg : entre 0,25mg et 0,75mg une fois/jour.
                                        <br>≥50kg : entre 0,5mg et 1,5mg une fois/jour.
                                        <br><span class="bluetext">USA/ FDA : Traitement épisode maniaque ou mixte chez les jeunes entre 10 et 17 ans ; Traitement de la schizophrénie chez les jeunes entre 13-17 ans ; irritabilité et agression chez les enfants autistes entre 5 et 16 ans</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        Aripiprazole (Abilify®)</td>
                                    <td>AP2G</td>
                                    <td>Schizophrénie à partir de l’âge de 15 ans
                                        <br>Trouble bipolaire : épisodes maniaques modérés à sévères des TBI jusqu’à 12 semaines à partie de l’âge de 13 ans
                                        <br><span class="bluetext">USA/ FDA : Traitement épisode maniaque ou mixte chez les jeunes entre 10 et 17 ans ; Traitement de la schizophrénie chez les jeunes entre 13-17 ans ; irritabilité et agression chez les enfants autistes entre 6 et 17 ans</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Olanzapine (Zyprexa®)</td>
                                    <td>AP2G</td>
                                    <td>Pas d’AMM avant 18 ans
                                        <br><span class="bluetext">USA/ FDA : Traitement épisode maniaque ou mixte chez les jeunes entre 10 et 17 ans ; Traitement de la schizophrénie chez les jeunes entre 13-17 ans</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Quetiapine (Xéroquel®)</td>
                                    <td>AP2G</td>
                                    <td>Pas d’AMM avant 18 ans
                                        <br><span class="bluetext">USA/ FDA : Traitement épisode maniaque ou mixte chez les jeunes entre 10 et 17 ans ; Traitement de la schizophrénie chez les jeunes entre 13-17 ans</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Amisulpride (Solian®)</td>
                                    <td>AP2G</td>
                                    <td>Schizophrénie : pas de recommandation avant l’âge de 18 ans et contre-indiqué chez les enfants de moins de 15 ans
                                        <br><span class="bluetext">USA/FDA : pas d’autorisation pour la schizophrénie</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Clozapine (Leponex®)</td>
                                    <td>AP2G</td>
                                    <td>Schizophrénie résistante aux traitements (200 à 450 mg/jour en prises fractionnées) à partir de 16 ans
                                        <br><span class="bluetext">USA/FDA : Traitement des schizophrénie résistantes ; réduction des comportements suicidaires à répétition dans le trouble schizophrénique et schizoaffectif</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">Note : mise à jour en Septembre 2020. En bleu, les autorisations de la Federal Drug Administration, c’est-à-dire l’agence de régulation des médicaments aux Etats-Unis.
                                    <br>(1) Le reste de l’AMM stipule que « [ce traitement] doit faire partie intégrante d'un programme de traitement plus large, incluant des mesures psychosociales et éducatives. Il est recommandé que la rispéridone soit prescrite par un spécialiste en neurologie de l'enfant et en psychiatrie de l'enfant et de l'adolescent ou un médecin très familier du traitement du trouble des conduites de l'enfant et de l'adolescent ».</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES">
                    <h2 class="title is-4">BENEFICES CLINIQUES</h2>

                        <p class="soustitre1">SCHIZOPHRENIE A DEBUT PRECOCE ET AUTRES TROUBLES PSYCHOTIQUES</p>
                        <button v-on:click="isHidden_benef1 = !isHidden_benef1">Voir le détail</button>
                    <div v-if="!isHidden_benef1">
                        <ul>
                            <li>Tous les antipsychotiques ont montré une efficacité supérieure au placebo sur la sévérité des symptômes psychotiques Ces symptômes sont généralement évalués à l’aide des échelles Clinical Global Impression (CGI), Positive And Negative Syndrome Scale (PANSS) ou Brief Psychiatric Rating Scale (BPRS).</li>
                            <li>La réponse moyenne aux antipsychotiques est de 72% pour les AP1G et de 56% pour les AP2G. La taille d’effet est en moyenne de 0,42 (0,42 sur les symptômes positifs et 0,32 sur les symptômes négatifs) (6)</li>
                            <li>Les études comparant les différents antipsychotiques entre eux ne retrouvent pas de différences significatives en termes d’efficacité, excepté pour la clozapine qui a montré une efficacité plus importante par rapport à l’halopéridol et l’olanzapine.</li>
                            <li>Compte tenu de leur meilleur profil de tolérance, les AP2G sont considérés comme le traitement de 1er ligne de la schizophrénie à début précoce (7). Ces traitements doivent être prescrits en association avec des interventions psychothérapeutiques adaptées (8). Les traitements les plus fréquemment prescrit sont : la rispéridone, l’aripiprazole, et la quétiapine.</li>
                            <li>L’olanzapine ne doit pas être utilisée en 1ere intention en raison de ses effets indésirables métaboliques (7).</li>
                            <li>La clozapine n’est pas un traitement de 1er intention compte tenu du risque d’agranulocytose. Son utilisation est réservée dans les cas de schizophrénie résistante (c’est-à-dire d’échec de l’utilisation de 2 antipsychotiques prescrits à bonnes doses de façon séquentielle pour une durée minimale de 6-8 semaines) ou de symptômes extra-pyramidaux sévères en cas d’utilisation d’autres traitements (7, 9, 10).</li>
                        </ul>
                    </div>

                        <p class="soustitre1">TROUBLE BIPOLAIRE A L’ADOLESCENCE</p>
                        <button v-on:click="isHidden_benef2 = !isHidden_benef2">Voir le détail</button>
                    <div v-if="!isHidden_benef2">
                        <ul>
                            <li>La rispéridone, la quétiapine, l’aripiprazole, l’olanzapine ont montré leur efficacité par rapport au placebo dans la prise en charge des épisodes maniaques et mixtes chez l’adolescent (page dédiée <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 1, start: 3 }}">disponible ici</router-link>).</li>
                        </ul>
                    </div>

                        <p class="soustitre1">TROUBLE DU COMPORTEMENT CHEZ UN JEUNE AVEC UN TROUBLE AUTISTIQUE</p>
                        <button v-on:click="isHidden_benef3 = !isHidden_benef3">Voir le détail</button>
                    <div v-if="!isHidden_benef3">
                        <ul>
                            <li>Aucun traitement médicamenteux n’est efficace sur les symptômes autistiques en tant que tels.</li>
                            <li>Chez les enfants avec troubles autistiques, les antipsychotiques ont montré leur efficacité pour réduire la sévérité de symptômes fréquemment associés comme l’irritabilité, l’agression, les crises de rage et la labilité émotionnelle (11).</li>
                            <li>La rispéridone est le traitement qui a le plus montré son efficacité dans cette indication. Les deux autres molécules qui ont montré un bénéfice sont l’aripiprazole et l’olanzapine.</li>
                            <li>L’utilisation de traitements médicamenteux pour contrôler les symptômes associés les plus problématiques chez les enfants ou adolescents avec troubles autistiques ne peut d’envisager qu’après :
                                <ul>
                                    <li>S’être assuré que le patient reçoit des interventions éducatives et comportementales adaptées incluant la prise en charge de difficultés développementales associées (rééducation pour le langage ou la motricité…).</li>
                                    <li>Avoir éliminer un trouble associé psychiatrique (ex : trouble de l’humeur, trouble attentionnel), somatique (ex : douleur dentaire, déficit sensoriel, constipation) ou iatrogène nécessitant une prise en charge spécifique.
                                    </li>
                                </ul>
                            </li>
                            <li>L’AMM de la rispéridone stipule que ce traitement doit être limité à six semaines.</li>
                            <li>Le trouble des conduites sans troubles neurodéveloppementaux associés n’est pas une indication de traitement par antipsychotique (12).</li>
                        </ul>
                    </div>

                        <p class="soustitre1">TROUBLE DU COMPORTEMENT CHEZ UN JEUNE AVEC UN TROUBLE DU DEVELOPPEMENT INTELLECTUEL</p>
                        <button v-on:click="isHidden_benef4 = !isHidden_benef4">Voir le détail</button>
                    <div v-if="!isHidden_benef4">
                        <ul>
                            <li>La rispéridone est le traitement qui a le plus montré son efficacité dans cette indication.</li>
                            <li>Les autres molécules qui montrent un bénéfice sont l’aripiprazole, l’olanzapine et l’halopéridol.</li>
                            <li>Ces traitements ont un effet sur l’irritabilité et les comportements problématiques en général. Les taille d’effet sont : pour le risperdal SMD=-0,98 et pour l’aripiprazole SMD=-0,64.</li>
                            <li>Les effets indésirables sont plus importants dans cette population.</li>
                        </ul>
                    </div>

                        <p class="soustitre1">TIC ET SYNDROME GILLES DE LA TOURETTE</p>
                        <button v-on:click="isHidden_benef5 = !isHidden_benef5">Voir le détail</button>
                    <div v-if="!isHidden_benef5">
                        <ul>
                            <li>Dans cette indication, les traitements les plus efficaces sont dans l’ordre décroissant : la rispéridone à une posologie entre 1,0 et 2,5 mg/j, la pimozide (Orap®) puis l’halopéridol.</li>
                        </ul>
                    </div>

                        <p class="soustitre1">AUTRES TROUBLES PSYCHIATRIQUES</p>
                        <button v-on:click="isHidden_benef6 = !isHidden_benef6">Voir le détail</button>
                    <div v-if="!isHidden_benef6">
                        <ul>
                            <li>Dans la littérature, on retrouve d’autres indications possibles des antipsychotiques dans la prise en charge de troubles psychiatriques, comme l’anorexie mentale, les troubles obsessionnels-compulsifs résistants, ou encore les troubles dépressifs résistants</li>
                            <li>Ces indications marginales qui relèvent de l’hyper-spécialité sont mentionnées dans les fiches correspondantes.</li>
                        </ul>
                    </div>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES</h2>
                    <ul>
                        <li>Globalement les antipsychotiques sont moins bien tolérés chez les enfants et les adolescents que chez les adultes (7-10, 13). Le profil d’effets indésirables varie selon la molécule.</li>
                        <li>Le tableau suivant décrit les principaux effets indésirables des antipsychotiques selon la molécule.</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Haldol</th>
                                        <th>Clozapine</th>
                                        <th>Risp&eacute;ridone</th>
                                        <th>Aripiprazole</th>
                                        <th>Olanzapine</th>
                                        <th>Qu&eacute;tiapine</th>
                                    </tr>
                                    <tr>
                                        <td height="1" rowspan="6">
                                            <p class="lien_ei" v-on:click.prevent='sw("toggle_neuro")'>Neurologique</p>
                                        </td>
                                        <td>
                                            <p>S&eacute;dation</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Parkinsonisme</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dyskin&eacute;sie tardive</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dyskin&eacute;sie &agrave; l&rsquo;arr&ecirc;t</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Akathisie</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Crise comitiale</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">
                                            <p class="lien_ei" v-on:click.prevent='sw("toggle_cardiaque")'>Cardiaque</p>
                                        </td>
                                        <td>
                                            <p>Allongement QT</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Tachycardie/hypotension</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3">
                                            <p class="lien_ei" v-on:click.prevent='sw("toggle_endoc")'>Endocrinologique-m&eacute;tabolique</p>
                                        </td>
                                        <td>
                                            <p>Prise de poids</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Syndrome m&eacute;tabolique</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>++</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Hyperprolactin&eacute;mie</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">
                                            <p class="lien_ei" v-on:click.prevent='sw("toggle_autre")'>Autres effets ind&eacute;sirables</p>
                                        </td>
                                        <td>
                                            <p>Anticholinergique</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0</p>
                                        </td>
                                        <td>
                                            <p>+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Neutrop&eacute;nie</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>+++</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                        <td>
                                            <p>0/+</p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="8"><big>Cliquez sur le type d’effet indésirable dans la première colonne pour en afficher des conseils de prise en charge</big></td>
                                </tfoot>
                            </table>
                        </div>
                    </ul>
                    <span id="detail_ei"></span>
                    <div v-show="eis.toggle_neuro">
                        <p class="soustitre1">EFFET NEUROLOGIQUE</p>
                        <p class="soustitre2">Sédation</p>
                        <ul>
                            <li>Un effet de sédation et de somnolence est fréquent chez les jeunes traités par antipsychotiques : 4,5 fois supérieur chez les enfants et 1,9 fois supérieur chez les adolescents par rapport aux adultes.</li>
                            <li>Cet effet serait moins important pour l’aripiprazole mais plus important pour la rispéridone, l’olanzapine et la clozapine (14).</li>
                            <li>Cet effet indésirable est parfois spontanément résolutif du fait d’une tolérance acquise.</li>
                        </ul>
                        <p class="soustitre2">Effets extra-pyramidaux</p>
                        <ul>
                            <li>Les enfants et les adolescents sont plus sensibles aux effets indésirables extra-pyramidaux des antipsychotiques que les adultes (14) avec en moyenne 4,5 fois plus de dyskinésie chez les enfants et une fréquence comparable entre les adolescents et les adultes.</li>
                            <li>Ces effets indésirables sont plus importants pour les AP1G comparés au AP2G.</li>
                            <li>Ces effets indésirables ne sont pas toujours dose-dépendant.</li>
                            <li>La quétiapine et la clozapine sont les molécules qui entrainent le moins d’effets extra-pyramidaux.</li>
                        </ul>
                        Le tableau suivant décrit les principaux effets indésirables extra-pyramidaux et leurs prises en charge selon les recommandations canadiennes CAMESA (13)
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                            Signes neurologiques
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Traitement pharmacologique : toujours trouver la dose minimale efficace et envisager de changer d’AP
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Parkinsonisme
                                        </td>
                                        <td>
                                            Tremblement, lenteur, symptômes unilatéraux
                                        </td>
                                        <td>
                                            Anticholinergique, propranolol, clonazepam, amantadine ou mirtazapine
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Dyskinésies
                                        </td>
                                        <td>
                                            Mouvements anormaux, face et bouche répétitifs
                                        </td>
                                        <td>
                                            Clonazepam ou tetrabénazine, voire lévétiracétam
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Akathisie
                                        </td>
                                        <td>
                                            Besoin de bouger sans arrêt (plus fréquent avec l’aripiprazole)
                                        </td>
                                        <td>
                                            Tardive : peu de ttt efficace, tetrabénazine, réserpine ou lorazépam
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Dystonie
                                        </td>
                                        <td>
                                            Posture anormale du corps totale ou partielle
                                        </td>
                                        <td>
                                            Tardive : Anticholinergique et si inefficace envisager toxine botulique voire stimulation cérébrale profonde
                                            <br>Aigue : Diphénhydramine ou anticholinergique ou diazépam IV
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Et aussi, dysarthrie, dysphonie, hypersialorrhée, crise oculogyre ou bradykinésie</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="soustitre2">Crise comitiale</p>
                        <ul>
                            <li>Un abaissement du seuil épileptogène est noté sous clozapine.</li>
                            <li>Le bilan pré thérapeutique de la clozapine nécessite un EEG.</li>
                        </ul>
                    </div>
                    <div v-show="eis.toggle_cardiaque">
                        <p class="soustitre1">EFFET CARDIAQUE</p>
                        <p class="soustitre2">Allongement du QT</p>
                        <ul>
                            <li>La plupart des antipsychotiques sont associés à un allongement du QT.</li>
                            <li>Ce risque est associé à un risque de torsade de pointe, de troubles du rythme cardiaque et de mort subite.</li>
                            <li>Le risque d’allongement du QT dépend de la molécule par ordre croissant:
                                <ul>
                                    <li>AP1G : chlorpromazine (+) > halopéridol (0/+) > loxapine (0)</li>
                                    <li>AP2G : quétiapine (++) > clozapine (+), rispéridone (+) > amisulpride (0/+), olanzapine (0/+), aripiprazole (0)
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p class="soustitre2">Hypotension orthostatique - tachycardie</p>
                        <ul>
                            <li>Les antipsychotiques peuvent être associés à de la tachycardie ou de l’hypotension orthostatique.</li>
                            <li>Ce risque est particulièrement élevé pour les traitements de la famille des phénothiazines (comme la chlorpromazine) qui présente une action anti-alpha 2 adrénergiques.</li>
                            <li>Le traitement par clozapine est associé à un risque de tachycardie, d’hypotension orthostatique et plus rarement de myocardite.</li>
                        </ul>
                    </div>
                    <div v-show="eis.toggle_endoc">
                        <p class="soustitre1">EFFET ENDOCRINOLOGIQUE-METABOLIQUE</p>
                        <p class="soustitre2">Prise de poids</p>
                        <i>Mécanismes</i>
                        <ul>
                            <li>La prise de poids chez les enfants et adolescents traités par antipsychotique est liée à une baisse de l’activité physique habituelle, une diminution de la sensation de satiété et à une augmentation de l’appétit et de la consommation alimentaire.</li>
                            <li>Cette prise de poids est 4,3 fois supérieure chez les enfants et 3,2 fois supérieure chez les adolescents que chez les adultes (15). Elle est aussi plus importante chez les patients autistes.</li>
                            <li>Une prise de poids importante peut favoriser le développement d’une dyslipidémie, d’un diabète de type 2, d’une hypertension, d’une apnée du sommeil et d’un syndrome des ovaires polykystiques chez la fille.</li>
                        </ul>
                        <i>Selon la molécule</i>
                        <ul>
                            <li>La prise de poids dépend de la molécule, par ordre croissant : Olanzapine (+++) > Clozapine (+++) > Quetiapine (++) > Rispéridone (+) > Halopéridol (+) > Aripiprazole (0/+) (9, 15).</li>
                            <li>Dans les études conduites chez les enfants et les adolescents la prise de poids moyenne est de 3,45 kg sous olanzapine, 1,76 kg sous risperdal, 1,43 kg sous quétiapine, 0,79 sous aripiprazole (5). Ces données sont issues de population ayant participé à une étude clinique et ne reflète pas nécessairement l’ensemble des enfants et adolescents qui vont être traités par antipsychotique. Dans l’étude observationnelle ETAPE réalisée en France chez des jeunes âgés en moyenne de 12 ans qui recevaient un traitement antipsychotique pour la 1er fois l’augmentation moyenne sous traitement était de 5,9 kg à 12 mois (16).</li>
                        </ul>
                        <i>Prévention</i>
                        <ul>
                            <li>Des mesures de prévention de la prise de poids sous antipsychotiques inspirées du Programme National Nutrition Santé (activité physique et éducation nutritionnelle) peuvent être proposées.</li>
                            <li>Il n’existe pas de recommandation en faveur de l’utilisation de la metformine pour réduire la prise de poids liée aux AP2G chez l’enfant et l’adolescent.</li>
                        </ul>
                        <p class="soustitre2">Syndrome métabolique</p>
                        <ul>
                            <li>Le syndrome métabolique est défini par l’association de 3 critères sur 5 concernant le taux de triglycérides, d’HDL cholestérol, la glycémie à jeun ainsi que le tour de taille et la tension artérielle systolique.</li>
                            <li>Ce syndrome est lié à une résistance à l’insuline secondaire à la prise de poids.</li>
                            <li>Le développement d’un diabète de type 2 est plus élevé en cas de prise de poids rapide, d’obésité et d’antécédents familiaux de diabète et de dyslipidémie (15).</li>
                        </ul>
                        <p class="soustitre2">Hyperprolactinémie</p>
                        <ul>
                            <li>Mécanisme : L’hyperprolactinémie induite par les antipsychotiques est liée à l’action positive sur les réseaux dopaminergiques et en particulier sur le système tubéro-infundibulaire qui régule la sécrétion hypophysaire de la prolactine.</li>
                            <li>Conséquences : Cette augmentation est souvent asymptomatique mais peut néanmoins occasionner une diminution de la libido et de l’hirsutisme, une aménorrhée-galactorrhée chez la fille et une gynécomastie et des troubles de l’érection chez le garçon. Les conséquences à long-terme de cette hyperprolactinémie sont mal connues.</li>
                            <li>Facteurs de risques :
                                <ul>
                                    <li>Cet effet indésirable concerne surtout la rispéridone (+++) >> amisulpride (+) > olanzapine (+). Chez les enfants et adolescents traités par rispéridone on observe une augmentation de 80% du taux de prolactine à huit semaines.</li>
                                    <li>Cet effet indésirable est dose-dépendant.</li>
                                    <li>Une augmentation de la prolactine dans les semaines qui suivent le début de traitement peut être spontanément résolutive.</li>
                                    <li>L’aripiprazole peut être associé à une hypo-prolactinémie.</li>
                                </ul>
                            </li>
                            <li>Prise en charge : En cas d’impossibilité de changer d’antipsychotique, l’adjonction de 10 mg/jour d’aripiprazole permet parfois de corriger l’hyperprolactinémie induite par la rispéridone ou l’amisulpride.</li>
                        </ul>
                    </div>
                    <div v-show="eis.toggle_autre">
                        <p class="soustitre1">AUTRES EFFETS INDESIRABLES</p>
                        <p class="soustitre2">Neutropénie</p>
                        <ul>
                            <li>La fréquence d’une perturbation hématologique dans l’année qui suit la mise en place de clozapine chez l’enfant et l’adolescent est de 16%.</li>
                            <li>Une surveillance hématologique régulière est indiquée.</li>
                        </ul>
                        <p class="soustitre2">Cytolyse hépatique</p>
                        <ul>
                            <li>Peu fréquent, cet effet indésirable peut être lié à la prise de poids rapide et à une stéatose hépatique.</li>
                        </ul>
                        <p class="soustitre2">Atteintes ophtalmologique et cutané</p>
                        <ul>
                            <li>Il existe un risque de photosensibilisation sous chlorpromazine. Il existe un possible risque de cataracte sous quétiapine.</li>
                        </ul>
                        <p class="soustitre2">Syndrome malin des neuroleptiques</p>
                        <ul>
                            <li>Mécanismes : C’est un effet indésirable rare mais grave des antipsychotiques. Il peut exister aussi bien pour les AP1G que les AP2G (17).</li>
                            <li>Conséquences : Les signes cliniques et paraclinique du syndrome malin sont :
                                <ul>
                                    <li>Trouble de la conscience : confusion, mutisme, coma</li>
                                    <li>Rigidité extra-pyramidale (9)</li>
                                    <li>Signes de dysautonomie : hyperthermie, sueurs profuses, tachycardie, changement de tension artérielle</li>
                                    <li>Perturbation du bilan biologique : élévation de la créatine phosphokinase (CPK), cytolyse hépatique et une hyperleucocytose.
                                    </li>
                                </ul>
                            </li>
                            <li>Facteurs de risques :
                                <ul>
                                    <li>Cet effet indésirable existe pour tous les antipsychotiques.</li>
                                    <li>La durée moyenne de survenue après l’introduction de traitement est de 4,4 jours pour les AP1G (min 0, max 59 jours) et de 8.7 jours pour les AP2G (min 0, max 56 jours) (17).</li>
                                    <li>Dans le cas de la prise d’AP2G le tableau clinique peut-être incomplet, même si l’élévation des CPK est systématiquement rapportée (17).</li>
                                </ul>
                            </li>
                            <li>Prise en charge :
                                <ul>
                                    <li>Urgence médicale qui nécessite l’arrêt de l’antipsychotique et une prise en charge adaptée.</li>
                                    <li>La réintroduction d’un antipsychotique d’une nouvelle classe chez un enfant ou adolescent qui a déjà présenté un syndrome malin des neuroleptiques ne peut se faire que dans des conditions d’extrême surveillance (9).</li>
                                </ul>
                            </li>
                        </ul>
                        <p class="soustitre2">Apparition ou aggravation de symptômes catatonique</p>
                        <ul>
                            <li>Le syndrome catatonique est caractérisé par au moins 2 symptômes parmi les suivants : immobilité motrice, activité motrice excessive, négativisme/mutisme, particularité des mouvements volontaires, écholalie/échopraxie, refus de s’alimenter.</li>
                            <li>L’échelle Pediatric Catatonia Rating Scale peut aider au diagnostic.</li>
                            <li>Il s’agit d’une urgence médicale qui nécessite un traitement symptomatique adaptée et de revoir la prescription d’antipsychotique.</li>
                        </ul>
                    </div>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS AVANT LA PRESCRIPTION</h2>
                    <p class="soustitre1">RECUEIL DE L'ADHESION DU JEUNE ET SA FAMILLE</p>
                    <p class="soustitre2">Adhésion du jeune</p>
                    <ul>
                        <li>La prise d’un médicament au quotidien n’est pas un acte anodin pour un enfant.</li>
                        <li>Il faut expliquer en fonction de l’âge l’utilité du traitement, ici « il va te permettre de te diminuer les pensées et voix qui te gênent ».</li>
                        <li>Il est important de rappeler que les parents sont également d’accord et partie prenante.</li>
                        <li>Il conviendra de discuter de ces questions avec l’enfant.</li>
                        <li>On pourra télécharger l’une des deux fiches d’information du jeune sur les antipsychotiques <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>disponibles ici</a>.</li>
                    </ul>
                    <p class="soustitre2">Adhésion des parents</p>
                    <ul>
                        <li>Il peut exister des réticences, parfois non rationnelles, parfois liées à l’expérience personnelle ou familiale au traitement.</li>
                        <li>Enfin, les parents ont désormais accès à bon nombre d’informations sur internet, il faut donc répondre par avance aux questions concernant l’efficacité attendue, la tolérance, les possibles effets indésirables.</li>
                        <li>Les parents doivent être informés que la prescription est effectuée hors-AMM le cas échéant.</li>
                    </ul>
                    <p class="soustitre1">EVALUATION CLINIQUE</p>
                    <ul>
                        <li>Selon les recommandations de la société américaine de psychiatrie de l’enfant et de l’adolescent (9) ce temps d’évaluation doit comporter au moins:
                            <ul>
                                <li>Antécédents médicaux individuels et chez les parents : diabète, obésité, maladie cardiovasculaire et cardiaque, épilepsie, hypercholestérolémie, dyslipidémie</li>
                                <li>Autres traitements médicamenteux reçus par le jeune</li>
                                <li>Antécédents médicaux familiaux : mort soudaine ou inexpliquée, anomalie cardiaque, état de santé des membres de la fratrie</li>
                                <li>Examen physique comprenant au moins Poids, Taille, IMC (Indice de masse Corporel), Tour de Taille (ombilic), Tension Artérielle</li>
                                <li>Examen neurologique à la recherche de mouvements anormaux par exemple utilisation de l’AIMS (Abnormal Involuntary Movement Scale) ou le NRS (Neurological Rating Scale)</li>
                            </ul>
                        </li>
                        <li>Les examens paracliniques recommandés sont :
                            <ul>
                                <li>Examen paraclinique : Glycémie à jeun, HDL cholestérol, LDL cholestérol, Triglycérides</li>
                                <li>ECG en cas d’antécédents familiaux mort soudaine ou inexpliquée, anomalie cardiaque ou antécédents personnels de syncope, palpitations ou d’anomalies cardiovasculaires (signe d’alerte sont FC > 130/min, intervalle PR > 200ms, QRS > 120ms, QTc > 460 ms)</li>
                                <li>EEG en cas de prescription de clozapine</li>
                                <li>Examen ophtalmologique en cas de prescription de quétiapine</li>
                            </ul>
                        </li>
                        <li>Une <a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>fiche de suivi de l’efficacité et de la tolérance des antipsychotiques</a> est disponible pour vous accompagner dans l’initiation et la surveillance du traitement.</li>
                        <li>Le tableau suivant résumé les principaux paramètres à surveiller lors de l’introduction et la surveillance d’antipsychotique : selon les recommandations HAS issues de la population adulte (en jaune) (18) et selon les recommandations CAMESA adaptées aux enfants et adolescents (13).</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            Début
                                        </th>
                                        <th>
                                            1 mois
                                        </th>
                                        <th>
                                            2 mois
                                        </th>
                                        <th>
                                            3 mois
                                        </th>
                                        <th>
                                            6 mois
                                        </th>
                                        <th>
                                            9 mois
                                        </th>
                                        <th>
                                            1 an
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Taille, Poids et IMC
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tour de Taille (ombilic)
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tension Artérielle
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Examen Neurologique
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Glycémie à jeun (≤6,1 mmol/l)
                                            <br>Insuline à jeun (≤100 μmol/l)
                                            <br>Cholesterol total (≤5,2 mmol/l)
                                            <br>HDL (≥1,05 mmol/l)
                                            <br>LDL (≤3,35 mmol/l)
                                            <br>Triglyceride (≤1,5 mmol/l)
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X/5 ans
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            ASAT / ALAT
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Prolactine (AP1G et rispéridone)
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            25OHD3
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="8">Clozapine : Le bilan prétéhrapeutique implique une NFS, plaquette, EEG. L’EEG peut être refait une fois la dose optimale de clozapine obtenue et ainsi pourra être comparé à un nouvel EEG demandé en cas de changement brutal et anormal de comportement (9). La surveillance des paramètres hématologiques est nécessaire tout au long du traitement.
                                        <br>Quetiapine : compte tenu du risque possible de cataracte dans les études précliniques un bilan ophtalmologique de base et régulier est recommandé tout au long de la prise du traitement.</td>
                                </tfoot>
                            </table>
                        </div>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <p class="soustitre1">QUEL TRAITEMENT DOIT-ETRE PRIVILEGIE ?</p>
                    Le choix de la molécule doit tenir compte :
                    <ul>
                        <li>Trouble psychiatriques et neurodéveloppement comorbides. Les molécules à privilégier pour chaque trouble sont décrites plus haut (section <router-link :to="{ name: 'trouble', params: { id: 5, start: 3 }}">3. Bénéfices)</router-link>
                        </li>
                        <li>Profil d’effets indésirables</li>
                        <li>Préférence du jeune et de sa famille
                        </li>
                    </ul>
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT ANTIPSYCHOTIQUE ?</p>
                    <p class="soustitre2">Initiation</p>
                    <ul>
                        <li>Initiation à faible dose avec augmentation progressive par palier sur 2 à 3 semaines en recherchant la dose minimale efficace pour minimiser le risque d’effet indésirable (règle du “start low and go slow” des anglo-saxons) (9).</li>
                        <li>Il n’y a pas d’intérêt à utiliser des fortes doses d’antipsychotique d’emblée (8).</li>
                        <li>En phase aiguë, il est possible d’utiliser des traitements associés en « si besoin » en respectant les doses maximales autorisées (8).</li>
                    </ul>
                    <p class="soustitre2">Augmentation progressive</p>
                    <ul>
                        <li>La dose cible varie selon le symptôme cible et entre les individus.</li>
                        <li>Les doses cibles pour le traitement de la schizophrénie et de l’épisode maniaque sont plus importantes que pour le traitement des tics et des troubles du comportement chez les jeunes avec autisme et/ou déficience intellectuelle.</li>
                        <li>L’effet thérapeutique des antipsychotiques commence habituellement au bout d’1-2 semaine.</li>
                        <li>Si réponse insuffisante au bout de 3 semaines : augmentation de la dose et optimisation du reste de la prise en charge</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            Dose de début
                                        </th>
                                        <th>
                                            Posologie
                                        </th>
                                        <th>
                                            Pallier
                                        </th>
                                        <th>
                                            Dose cible initiale
                                        </th>
                                        <th>
                                            Dose maximale
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Rispéridone (Risperdal®)*
                                            <br>Troubles psychotiques
                                        </td>
                                        <td>
                                            1 mg
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            1-2 mg/j
                                        </td>
                                        <td>
                                            4-6 mg/j
                                        </td>
                                        <td>
                                            16 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Rispéridone (Risperdal®)*
                                            <br>Comportement DI/TSA
                                        </td>
                                        <td>
                                            &lt;50kg 0,25 mg/j
                                            >50kg 0,5 mg/j
                                            (1mg = 1ml)
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            &lt;50kg 0,25 mg/j
                                            <br>>50kg 0,5 mg/j
                                        </td>
                                        <td>
                                            &lt;50kg 0,5 mg/j
                                            <br>>50kg 1 mg/j
                                        </td>
                                        <td>
                                            &lt;50kg 0,75 mg/j
                                            <br>>50kg 1,5 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Aripiprazole (Abilify®)*
                                        </td>
                                        <td>
                                            2,5-5 mg/j
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            2,5-5 mg/j
                                        </td>
                                        <td>
                                            15-20 mg/j
                                        </td>
                                        <td>
                                            30 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Olanzapine (Zyprexa®)*
                                        </td>
                                        <td>
                                            2,5-5 mg/j
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            2,5-5 mg/j
                                        </td>
                                        <td>
                                            5-10 mg/j
                                        </td>
                                        <td>
                                            20 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Quetiapine (Xéroquel®)
                                        </td>
                                        <td>
                                            25 mg/j
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            50 mg/j (J2), 100mg (J3)
                                        </td>
                                        <td>
                                            300-400 mg/j
                                        </td>
                                        <td>
                                            750 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Amisulpride (Solian®)
                                        </td>
                                        <td>
                                            50-100 mg/j
                                        </td>
                                        <td>
                                            1 prise le soir
                                        </td>
                                        <td>
                                            100mg/j
                                        </td>
                                        <td>
                                            300-400 mg/j
                                        </td>
                                        <td>
                                            1200 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Halopéridol (Haldol®)
                                        </td>
                                        <td>
                                            2-5 gouttes/ j
                                            <br>(1 mg = 10 gouttes ; 1 mL = 20 gouttes)
                                        </td>
                                        <td>
                                            1 – 2 prises par jour
                                        </td>
                                        <td>
                                            1 mg
                                        </td>
                                        <td>
                                            0,02 à 0,2 mg/kg/jour
                                        </td>
                                        <td>
                                            30 mg/j
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Clozapine (Leponex®)
                                        </td>
                                        <td>
                                            12,5 mg
                                        </td>
                                        <td>
                                            2 prises par jour
                                        </td>
                                        <td>
                                            25 mg tous les 2-3 jours
                                        </td>
                                        <td>
                                            250 mg /j
                                            <br>Clozapinémie : 350–500 μg/L
                                        </td>
                                        <td>
                                            900 mg/j
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="6">* Ces molécules existent en voie IM</td>
                                </tfoot>
                            </table>
                        </div>
                    </ul>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>Surveillance de l’efficacité : symptômes cibles, retentissement fonctionnel</li>
                        <li>Surveillance de la tolérance : effets indésirables réévalués régulièrement par un spécialiste</li>
                        <li>La fréquence des consultations de suivi dépend du trouble psychiatrique considéré</li>
                        <li>Une <a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>fiche de suivi de l’efficacité et de la tolérance des antipsychotiques</a> est disponible pour vous accompagner dans l’initiation et la surveillance du traitement.</li>
                        <li>Le tableau ci-dessous résume les principaux paramètres à surveiller lors de la surveillance d’antipsychotique : selon les recommandations HAS issues de la population adulte (en jaune) (18) et selon les recommandations CAMESA adaptées aux enfants et adolescents (13).</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            Début
                                        </th>
                                        <th>
                                            1 mois
                                        </th>
                                        <th>
                                            2 mois
                                        </th>
                                        <th>
                                            3 mois
                                        </th>
                                        <th>
                                            6 mois
                                        </th>
                                        <th>
                                            9 mois
                                        </th>
                                        <th>
                                            1 an
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Taille, Poids et IMC
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tour de Taille (ombilic)
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tension Artérielle
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Examen Neurologique
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Glycémie à jeun (≤6.1 mmol/l)
                                            <br>Insuline à jeun (≤100 μmol/l)
                                            <br>Cholesterol total (≤5.2 mmol/l)
                                            <br>HDL (≥1.05 mmol/l)
                                            <br>LDL (≤3.35 mmol/l)
                                            <br>Triglyceride (≤1.5 mmol/l)
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td class="has-background-warning">
                                            X/5 ans
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            ASAT / ALAT
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Prolactine (AP1G et rispéridone)
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            25OHD3
                                        </td>
                                        <td>
                                            X
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            X
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="8">Clozapine : Le bilan prétéhrapeutique implique une NFS, plaquette, EEG. L’EEG peut être refait une fois la dose optimale de clozapine obtenue et ainsi pourra être comparé à un nouvel EEG demandé en cas de changement brutal et anormal de comportement (9). La surveillance des paramètres hématologiques est nécessaire tout au long du traitement.
                                        <br>Quetiapine : compte tenu du risque possible de cataracte dans les études précliniques un bilan ophtalmologique de base et régulier est recommandé tout au long de la prise de quétiapine.</td>
                                </tfoot>
                            </table>
                        </div>
                    </ul>
                    <p class="soustitre1">QUELS ANTIPSYCHOTIQUES PEUVENT ETRE DOSES ET QUAND LES DOSER ?</p>
                    <ul>
                        <li>Le dosage de la concentration plasmatique des antipsychotiques est utile pour :
                            <ul>
                                <li>Documenter l’observance</li>
                                <li>En cas de suspicion d’effet toxique</li>
                                <li>En cas de non réponse clinique ou de réponse partielle malgré la prescription d’une dose maximale</li>
                            </ul>
                        </li>
                        <li>Condition pratique : Avant tout dosage il faut s’assurer que l’antipsychotique soit prescrit sur une durée suffisamment longue (au moins 4-5 demi-vie) et du moment où le dosage doit être fait (le plus souvent 12h après la dernière prise). Si l’objectif est de documenter un effet toxique le prélèvement doit se faire au moment où sont présent les signes cliniques de toxicité.</li>
                        <li>Le tableau suivant résume les principales concentrations plasmatiques selon l’antipsychotique (inspiré de (19, 20))</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <th>Molécules
                                        </th>
                                        <th>
                                            Dosage cible chez l’adulte
                                        </th>
                                        <th>
                                            Dosage cible en population pédiatrique
                                        </th>
                                        <th>
                                            Durée pour obtenir une dose stable
                                        </th>
                                        <th>
                                            Interactions cytochrome
                                        </th>
                                        <th>
                                            Recommandé en pratique clinique
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Risp&eacute;ridone</strong></p>
                                        </td>
                                        <td>
                                            <p>20-60 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>2-3 jours</p>
                                        </td>
                                        <td>
                                            <p>CYP3A4</p>
                                        </td>
                                        <td>
                                            <p>Non</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Aripiprazole</strong></p>
                                        </td>
                                        <td>
                                            <p>150-210 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>15-16 jours</p>
                                        </td>
                                        <td>
                                            <p>CYP2D6, CYP3A4</p>
                                        </td>
                                        <td>
                                            <p>Non</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Olanzapine</strong></p>
                                        </td>
                                        <td>
                                            <p>20-40 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>1 semaine</p>
                                        </td>
                                        <td>
                                            <p>CYP1A2, CYP2D6,</p>
                                        </td>
                                        <td>
                                            <p>Non</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Quetiapine</strong></p>
                                        </td>
                                        <td>
                                            <p>50-100 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>2-3 jours</p>
                                        </td>
                                        <td>
                                            <p>CYP3A4</p>
                                        </td>
                                        <td>
                                            <p>Non</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Clozapine</strong></p>
                                        </td>
                                        <td>
                                            <p>350-500 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>2-3 jours</p>
                                        </td>
                                        <td>
                                            <p>CYP1A2, CYP2D6, CYP3A4</p>
                                        </td>
                                        <td>
                                            <p>Oui</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Amisulpride</strong></p>
                                        </td>
                                        <td>
                                            <p>200-320 &micro;g/L</p>
                                        </td>
                                        <td>
                                            <p>?</p>
                                        </td>
                                        <td>
                                            <p>3 jours</p>
                                        </td>
                                        <td>
                                            <p>Aucune interaction avec CYP</p>
                                        </td>
                                        <td>
                                            <p>Non</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <li>L’utilisation de concentration plasmatique cible présente des limites : certains patients répondent pour des concentrations plus faibles qu’indiquées, les limites ne sont pas toujours connues pour les enfants et adolescents, les normes varient de façon importante selon les laboratoires.</li>
                        <li>La surveillance de la dose plasmatique est particulièrement indiquée pour la clozapine et l’olanzapine. Pour ces deux molécules ils existent une grande variabilité dans le métabolisme. En pratique la concentration plasmatique peut varier de 1 à 10 pour une même posologie.</li>
                        <li>En plus de la dose du médicament, les autres facteurs qui influencent la concentration plasmatique sont :
                            <ul>
                                <li>Le sexe : métabolisme de l’olanzapine et de clozapine plus important chez les garçons</li>
                                <li>L’âge</li>
                                <li>L’IMC</li>
                                <li>Variations interindividuelles qui définissent des métaboliseurs lents, rapides et ultra-rapides</li>
                                <li>Les interactions médicamenteuses avec des substances qui influencent l’activité des cytochromes impliqués dans le métabolisme des antipsychotiques (<a :href="dl_files_external.cytochromes" target="_blank">voir ici</a>).</li>
                                <li>La consommation de tabac : le métabolisme de l’olanzapine et de clozapine est plus important chez les fumeurs</li>
                                <li>La consommation de café et le thé qui peuvent induire des précipités insolubles et inactifs d’antipsychotiques.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE MAIS EFFETS INDESIRABLES</p>
                    <ul>
                        <li>La plupart des effets indésirables sont dose-dépendants.</li>
                        <li>En cas de survenue d’effet indésirable il faut diminuer légèrement la dose et examiner la réponse.</li>
                        <li>Si l’effet indésirable diminue il est possible de refaire une tentative d’augmentation progressivement de la dose plus tard.</li>
                        <li>Si l’effet indésirable persiste il faut évaluer la balance bénéfice-risque (voir la page dédiée à la <router-link :to="{ name: 'trouble', params: { id: 5, start: 4 }}">prise en charge des effets indésirables des antipsychotiques)</router-link>
                        </li>
                        <li>La survenue d’effets indésirables sévères et dangereux (ex : syndrome malin des neuroleptiques) impose un arrêt immédiat de l’antipsychotique.
                        </li>
                    </ul>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE ET QUE LE TRAITEMENT EST BIEN TOLERE</p>
                    <p class="soustitre2">Durée de traitement</p>
                    <ul>
                        <li>La durée de traitement par antipsychotique dépend de l’indication.</li>
                        <li>Dans la prise en charge de troubles du comportement chez les patients avec un trouble du spectre autistiques et/ou un trouble du développement intellectuelle la prescription est limitée à six semaines.</li>
                        <li>Dans la prise en charge d’un épisode psychotique aigu le traitement doit être donné pour au moins 2 ans, en cas de rémission incomplète le traitement est prescrit à plus long-terme (21, 22).</li>
                        <li>Dans la prise en charge d’une schizophrénie à début précoce il faut éviter le traitement indéfini par antipsychotique et évoquer une réévaluation régulière, au moins annuelle (8, 9).</li>
                    </ul>
                    <p class="soustitre2">Arrêt de l’antipsychotique</p>
                    <ul>
                        <li>L’arrêt doit toujours se faire progressivement sur au moins 3 à 6 mois afin d’éviter une recrudescence de symptômes et la survenue de dyskinésies à l’arrêt.</li>
                        <li>Dans le cadre du traitement de symptômes psychotique, le risque de rechute est maximal dans les deux années qui suivent l’arrêt du traitement (10).</li>
                        <li>Après un épisode psychotique aigu la surveillance clinique des symptômes de rechute doit se faire au moins 2 ans après l’arrêt du traitement (10).</li>
                    </ul>
                    <p class="soustitre1">SI PAS D’AMELIORATION CLINIQUE SIGNIFICATIVE</p>
                    <ul>
                        <li>Avant de conclure à l’inefficacité d’un antipsychotiques, le traitement doit être prescrit à bonne dose pendant au moins 6-8 semaines (7, 9). Pour la clozapine une amélioration de la réponse clinique peut être observée après 6 à 8 mois de traitement (10).</li>
                        <li>En cas d’absence d’amélioration, le moyen mnémotechnique suivant a été proposé pour évaluer de façon systématique les différents facteurs possibles <b>OPACITE</b> : <b>O</b>bservance, <b>P</b>sychothérapie, <b>A</b>ssociation avec autres pathologies, <b>C</b>oncentration, <b>I</b>dentifier facteurs environnementaux, <b>T</b>itration, <b>E</b>ssai d’une autre molécule.</li>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><strong>Observance</strong></p>
                                        </td>
                                        <td>
                                            <p>La premi&egrave;re cause de non efficacit&eacute; d&rsquo;un traitement et de ne pas le prendre (toujours y penser avant d&rsquo;envisager un changement de traitement)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Psychoth&eacute;rapie</strong></p>
                                        </td>
                                        <td>
                                            <p>Le traitement m&eacute;dicamenteux, bien qu&rsquo;essentiel pour la prise en charge de ces troubles n&rsquo;est pas le seul &eacute;l&eacute;ment de la prise en charge.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Association avec un autre trouble psychiatrique, toxique, organique</strong></p>
                                        </td>
                                        <td>
                                            C&rsquo;est un aspect essentiel dans la cadre d&rsquo;une schizophr&eacute;nie &agrave; d&eacute;but pr&eacute;coce.
                                            On peut retrouver des sympt&ocirc;mes psychotiques dans d&rsquo;autres cadres que les schizophr&eacute;nies, des prises de toxiques (cannabis), des prises m&eacute;dicamenteuses (cortico&iuml;des), des maladies organiques (enc&eacute;phalopathie auto-immune) ou encore des troubles de l&rsquo;humeur (d&eacute;pression, troubles bipolaires) et des troubles de la personnalit&eacute; (limite).
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Concentration</strong></p>
                                        </td>
                                        <td>
                                            <p>Quand cela est possible, doser le m&eacute;dicament, rechercher interf&eacute;rence avec le m&eacute;tabolisme</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Identifier les facteurs environnementaux de maintien des sympt&ocirc;mes psychiatriques</strong></p>
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Titration</strong></p>
                                        </td>
                                        <td>
                                            Augmenter progressivement la dose de l&rsquo;antipsychotique en respectant les paliers jusqu&rsquo;&agrave; la dose maximum tol&eacute;r&eacute;e si n&eacute;cessaire
                                            <br>
                                            Le tableau plus haut pr&eacute;sente les paliers et la dose maximale pour les enfants
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>Essai d&rsquo;une autre mol&eacute;cule&nbsp;: changement ou ajout</strong></p>
                                        </td>
                                        <td>
                                            <p>Changement de mol&eacute;cule</p>
                                            En l&rsquo;absence d&rsquo;efficacit&eacute; de l&rsquo;antipsychotique prescrit &agrave; bonne dose pendant au moins 6 semaines, la prescription d&rsquo;un autre antipsychotique doit &ecirc;tre envisag&eacute; (7, 9).
                                            Site internet pour changement de mol&eacute;cule (<a href="http://cmpsy-switch.com/Le-Switch/">http://cmpsy-switch.com/Le-Switch/</a>)
                                            <br><br>
                                            <p>Ajout d&rsquo;une mol&eacute;cule</p>
                                            <p>Il existe peu de donn&eacute;es sur l&rsquo;innocuit&eacute; et l&rsquo;efficacit&eacute; des bith&eacute;rapies d&rsquo;antipsychotiques.</p>
                                            <p>Les bith&eacute;rapies d&rsquo;antipsychotiques ne peut s&rsquo;envisager qu&rsquo;apr&egrave;s avoir essay&eacute; d&rsquo;autres antipsychotiques (&agrave; la fois AP2G et AP1G) et &eacute;ventuellement d&rsquo;autres mol&eacute;cules selon l&rsquo;indication (par exemple un thymor&eacute;gulateur dans le cadre d&rsquo;un trouble de l&rsquo;humeur) (9, 23).</p>
                                            <p>L&rsquo;absence d&rsquo;efficacit&eacute; d&rsquo;un antipsychotique &agrave; 1 ou 2 semaines n&rsquo;est pas une indication pour ajouter un second antipsychotique.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l’instauration d’un antipsychotique</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Fiche d’information pour le jeune et sa famille sur les antipsychotiques</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Fiche de suivi de l’efficacité clinique et de la tolérance</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[4])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé de l’usage des antipsychotiques chez l’enfant et l’adolescent</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur l’usage des antipsychotiques chez l’enfant et l’adolescent pour l’enseignement</a></li>
                        <li><a :href="dl_files_external.cytochromes" target="_blank">Médicaments métabolisés par le cytochrome p450</a></li>
                    </ul>
                    <p class="soustitre2">Association de patients et liens utiles :</p>
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/schizojeunes/">https://www.facebook.com/schizojeunes/</a></li>
                    </ul>
                    <p class="soustitre2 py-2">Liste des recommandations utilisées pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Pays
                                    </th>
                                    <th>
                                        Année
                                    </th>
                                    <th>
                                        Références
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Canada / CAMESA</p>
                                    </td>
                                    <td>
                                        <p>2011</p>
                                    </td>
                                    <td>
                                        <p>Ho et al. 2011</p>
                                        <p>Pringsheim et al. 2011a, 2011b</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>RU / NICE</p>
                                    </td>
                                    <td>
                                        <p>2013</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.nice.org.uk/guidance/cg155" target="_blank">Psychosis and schizophrenia in children and young people : recognition and management</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>RU / NICE</p>
                                    </td>
                                    <td>
                                        <p>2014</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.nice.org.uk/guidance/cg185" target="_blank">Bipolar disorder: the assessment and management of bipolar disorder in adults, children and young people in primary and secondary care.</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>USA / AACAP</p>
                                    </td>
                                    <td>
                                        <p>2013</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.aacap.org/App_Themes/AACAP/docs/practice_parameters/Schizophrenia_Sept13.pdf" target="_blank">Practice Parameter for the Assessment and Treatment of Children and Adolescents with Schizophrenia</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>USA / AACAP</p>
                                    </td>
                                    <td>
                                        <p>2009</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.jaacap.org/article/S0890-8567(09)60156-8/fulltext" target="_blank">Practice Parameter on the Use of Psychotropic Medication in Children and Adolescents</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>USA / AACAP</p>
                                    </td>
                                    <td>
                                        <p>2011</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.aacap.org/App_Themes/AACAP/docs/practice_parameters/Atypical_Antipsychotic_Medications_Web.pdf" target="_blank">Practice Parameter for the Use of Atypical Antipsychotic Medications in Children and Adolescent</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Australie / RANZCP</p>
                                    </td>
                                    <td>
                                        <p>2010</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.orygen.org.au/Training/Resources/Psychosis/Guidelines/Australian-Clinical-Guidelines-for-Early-Psychosis" target="_blank">Australian Clinical Guidelines for Early Psychosis, 2nd edition</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Australie / RANZCP</p>
                                    </td>
                                    <td>
                                        <p>2016</p>
                                    </td>
                                    <td>
                                        <p><a href="https://www.ranzcp.org/files/resources/college_statements/clinician/cpg/schizophrenia-disorders-cpg.aspx" target="_blank">Practice guidelines for the management of schizophrenia and related disorders</a></p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">Note: CAMESA: The Canadian Alliance for Monitoring Effectiveness and Safety of Antipsychotics in Children; AACAP: American Academy for Child and Adolescent Psychiatry; NICE: National Institute for Health and Care Excellence; RANZCP: Royal Australian and New Zealand College of Psychiatrists</td>
                            </tfoot>
                        </table>
                    </div>
                    <p class="soustitre2">Autres références :</p>
                    <ul class="is-size-6">
                        <li>1. Dinnissen M, Dietrich A, van der Molen JH, Verhallen AM, Buiteveld Y, Jongejan S, et al. Prescribing antipsychotics in child and adolescent psychiatry: guideline adherence. European Child & Adolescent Psychiatry. 2020.</li>
                        <li>2. Harrison JN, Cluxton-Keller F, Gross D. Antipsychotic medication prescribing trends in children and adolescents. J Pediatr Health Care. 2012;26(2):139-45.</li>
                        <li>3. Verdoux H, Pambrun E, Cortaredona S, Tournier M, Verger P. Antipsychotic prescribing in youths: a French community-based study from 2006 to 2013. Eur Child Adolesc Psychiatry. 2015;24(10):1181-91.</li>
                        <li>4. Hálfdánarson Ó, Zoëga H, Aagaard L, Bernardo M, Brandt L, Fusté AC, et al. International trends in antipsychotic use: A study in 16 countries, 2005-2014. Eur Neuropsychopharmacol. 2017;27(10):1064-76.</li>
                        <li>5. De Hert M, Dobbelaere M, Sheridan EM, Cohen D, Correll CU. Metabolic and endocrine adverse effects of second-generation antipsychotics in children and adolescents: A systematic review of randomized, placebo controlled trials and guidelines for clinical practice. European psychiatry : the journal of the Association of European Psychiatrists. 2011;26(3):144-58.</li>
                        <li>6. Stafford MR, Mayo-Wilson E, Loucas CE, James A, Hollis C, Birchwood M, et al. Efficacy and safety of pharmacological and psychological interventions for the treatment of psychosis and schizophrenia in children, adolescents and young adults: a systematic review and meta-analysis. PloS one. 2015;10(2):e0117166.</li>
                        <li>7. McClellan J, Stock S, Issues ACoQ. AACAP Practice Parameter for the Assessment and Treatment of Children and Adolescents With Schizophrenia. Journal of the American Academy of Child & Adolescent Psychiatry. 2013;52(9):976-90.</li>
                        <li>8. NICE. National Institute for Health and Clinical Excellence: Guidance. Psychosis and schizophrenia in children and young people: recognition and management. Leicester (UK): The British Psychological Society & The Royal College of Psychiatrists.; 2013.</li>
                        <li>9. Findling RD, Jensen PS, Rapoport JL, AACAP Committee on Quality Issues. AACAP Practice parameter for the use of atypical antipsychotic medications in children and adolescents. Washington, DC: American Academy of Child and Adolescent Psychiatry (AACAP); 2011.</li>
                        <li>10. Abidi S, Mian I, Garcia-Ortega I, Lecomte T, Raedler T, Jackson K, et al. Canadian Guidelines for the Pharmacological Treatment of Schizophrenia Spectrum and Other Psychotic Disorders in Children and Youth. Can J Psychiatry. 2017;62(9):635-47.</li>
                        <li>11. Morgan S, Taylor E. Antipsychotic drugs in children with autism. BMJ (Clinical research ed). 2007;334(7603):1069-70.</li>
                        <li>12. NICE. National Institute for Health and Care Excellence: Guidance. Conduct disorders in children – new guidance to help parents. Leicester (UK): The British Psychological Society & The Royal College of Psychiatrists.; 2006.</li>
                        <li>13. Pringsheim T, Panagiotopoulos C, Davidson J, Ho J. Des recommandations probantes pour surveiller l'innocuité des antipsychotiques de deuxième génération chez les enfants et les adolescents. Paediatrics & child health. 2012;17(Suppl B):12B-21B.</li>
                        <li>14. Correll CU. Antipsychotic use in children and adolescents: minimizing adverse effects to maximize outcomes. J Am Acad Child Adolesc Psychiatry. 2008;47(1):9-20.</li>
                        <li>15. Correll CU, Carlson HE. Endocrine and Metabolic Adverse Effects of Psychotropic Medications in Children and Adolescents. Journal of the American Academy of Child & Adolescent Psychiatry. 2006;45(7):771-91.</li>
                        <li>16. Menard ML, Thümmler S, Giannitelli M, Cruzel C, Bonnot O, Cohen D, et al. Incidence of adverse events in antipsychotic-naïve children and adolescents treated with antipsychotic drugs: Results of a multicenter naturalistic study (ETAPE). Eur Neuropsychopharmacol. 2019;29(12):1397-407.</li>
                        <li>17. Neuhut R, Lindenmayer J-P, Silva R. Neuroleptic malignant syndrome in children and adolescents on atypical antipsychotic medication: a review. Journal of child and adolescent psychopharmacology. 2009;19(4):415-22.</li>
                        <li>18. Agence Française de Sécurité Sanitaire des Produits de Santé Suivi cardio-métabolique des patients traités par antipsychotiques. 2010.</li>
                        <li>19. Taylor D, Paton C, Kapur S. Plasma level monitoring of psychotropics and anticonvulsants. In: Trust ONF, editor. The Maudsley Prescribing Guidelines 10th Edition: informa healthcare; 2009. p. 1-8.</li>
                        <li>20. Urichuk L, Prior TI, Dursun S, Baker G. Metabolism of atypical antipsychotics: involvement of cytochrome p450 enzymes and relevance for drug-drug interactions. Curr Drug Metab. 2008;9(5):410-8.</li>
                        <li>21. Jackson HJ, McGorry PD, Editors. 2009 p. The recognition and management of early psychosis: a preventive approach, 2nd Edition Cambridge University Press: Cambridge; 2009.</li>
                        <li>22. Early Psychosis Guidelines Writing Group. Australian Clinical Guidelines for Early Psychosis, 2nd edition: A Brief Summary for Practitioners. . Melbourne: Orygen Youth Health; 2010.</li>
                        <li>23. Canadian Institute for Health Research. CAMESA Guidelines: Information about the side effects of antipsychotic drugs in children 2018 [Available from: http://camesaguideline.org/about-the-guidelines.</li>
                    </ul>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroublePsychotique',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            window: {
                width: 0,
                height: 0,
                maxwidth: 900
            },
            dl_files: {},
            // tdah
            eis: {
                toggle_neuro: false,
                toggle_cardiaque: false,
                toggle_endoc: false,
                toggle_autre: false,
            },
            isHidden_benef1: true,
            isHidden_benef2: true,
            isHidden_benef3: true,
            isHidden_benef4: true,
            isHidden_benef5: true,
            isHidden_benef6: true,
            //showpart: 1,
            rep: 'psychotique',
            maintitle: 'Troubles psychotiques',
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {
        reveal(msg, event) {
            var new_elem = document.createElement("span");
            new_elem.style.cssText = 'color:blue;';
            var new_content = document.createTextNode(msg);
            new_elem.appendChild(new_content);
            event.target.replaceWith(new_elem);
        },
        // switch eis
        sw: function(message) {
            const keys = Object.keys(this.eis)
            for (const key of keys) {
                this.eis[key] = (key == message);
            }
            this.scrollEi()
        },
        scrollEi() {
            var bc = document.querySelector("#detail_ei")
            if (bc) {
                bc.scrollIntoView({ behavior: 'smooth' })
            }
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bluetext {
    color: blue;
}

.lien_ei {
    color: royalblue;
    text-decoration: underline;
}

.lien_ei:hover {
    color: blue;
    text-decoration: none;
    cursor: pointer;
}
</style>