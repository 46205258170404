<template>
    <div id="depression">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Définitions</p>
                    <ul>
                        <li>Parmi les troubles psychiatriques associés à une humeur dépressive il faut différencier :
                            <ul class="roman">
                                <li>Les troubles de l’adaptation avec humeur dépressive, c’est-à-dire les états dépressifs réactionnels et transitoires observés généralement dans des contextes d’évènements de vie difficiles (maladie somatique, deuil).</li>
                                <li>L’Episode Dépressif Majeur qui se caractérise par la persistance pendant au moins 2 semaines d’une humeur dépressive ou éventuellement irritable présente pratiquement toute la journée presque tous les jours, d’une diminution marquée de l’intérêt pour toutes les activités et de symptômes associés comme des pensées négatives, une perte d’énergie, des difficultés à se concentrer, une perte d’appétit ou des troubles du sommeil.</li>
                                <li>Les autres troubles dépressifs associés à une humeur négative chronique (trouble dépressif persistant, trouble disruptif avec dysrégulation émotionnelle). Dans ces situations il peut être difficile de documenter une rupture de fonctionnement. Les antidépresseurs n’ont pas été évalués dans ce cadre.</li>
                                <li>Les autres troubles psychiatriques qui peuvent être marqués par une humeur dépressive (trouble bipolaire, entrée dans la schizophrénie, trouble du spectre autistique non-spécifié et trouble complexe du développement).</li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Prévalence</p>
                    <ul>
                        <li>A l’âge de 16 ans, 12% des filles et 7% des garçons ont déjà eu au cours de leur vie un trouble dépressif.</li>
                        <li>Entre 1% à 2% des enfants prépubères et 5% des adolescents présentent un Episode Dépressif Majeur.</li>
                        <li>Un Episode Dépressif Majeur dure entre 7 à 9 mois en moyenne quand aucune prise en charge n’est proposée.</li>
                        <li>Les complications principales sont liées au retentissement fonctionnel (scolaire, relationnel, familial) et au risque suicidaire.</li>
                    </ul>
                    <p class="soustitre2">Prescription</p>
                    <ul>
                        <li>La prescription d’antidépresseur en France concerne 22,5 à 28,5 % des prescriptions de psychotropes réalisées chez l’enfant et l’adolescent.</li>
                        <li>La prévalence de la prescription d’antidépresseur chez l’enfant et l’adolescent est comprise entre 0,4 et 0,8%. </li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    <p class="soustitre1">QUEL PATIENT TRAITER PAR UN ANTIDéPRESSEUR&nbsp;DANS LE CADRE D'UN SYNDROME DéPRESSIF&nbsp;?</p>
                    <ul>
                        <li>L’utilisation d’un traitement antidépresseur chez l’enfant ou l’adolescent est réservée au cas suivant :
                            <ul>
                                <li>Adolescent présentant un Episode Dépressif Majeur d’intensité sévère</li>
                                <li>Adolescent présentant un Episode Dépressif Majeur d’intensité modérée avec comorbidité psychiatrique</li>
                                <li>Dans tous le cas les antidépresseurs ne peuvent être utilisés qu’après échec d’une approche psychothérapeutique bien conduite</li>
                            </ul>
                        </li>
                        <li>Par ailleurs, les antidépresseurs peuvent aussi être indiqués chez les jeunes qui présentent d’autres troubles psychiatriques, comme le <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 7, start: 1 }}">trouble anxieux</router-link>, les troubles liés au stress et au trauma et les <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 3, start: 1 }}">troubles obsessionnels compulsifs</router-link>.</li>
                        <li>Les antidépresseurs n’ont pas montré leur efficacité dans la prise en charge des autres formes de troubles dépressifs chroniques : trouble dépressif persistant, trouble disruptif dépressif avec dysrégulation émotionnelle.</li>
                        <li>Les antidépresseurs n’ont pas montré leur efficacité dans la prise en charge de l’Episode Dépressif Majeur chez l’enfant prépubère. Leur utilisation doit donc être réservée à l’adolescent.</li>
                    </ul>
                    <p class="soustitre2">Population particulière&nbsp;:</p>
                    <img width="800" alt="toubles" usemap="#dep-map-troubles" src="/pathologie/depression/trbles_dep.png">
                    <map name="dep-map-troubles">
                        <area shape="rect" coords="11, 212, 215, 288" href="#" alt="prépubère" v-on:click.prevent='sw("toggle_prepub")' />
                        <area shape="rect" coords="275, 212, 500, 288" href="#" alt="neurodéveloppement" v-on:click.prevent='sw("toggle_neurodev")' />
                        <area shape="rect" coords="547, 212, 774, 288" href="#" alt="somatique" v-on:click.prevent='sw("toggle_somatique")' />
                    </map>
                    <div id="map_detail">
                        <div v-bind:class="[window.width>900 ? 'block' : 'is-hidden']"><i>cliquez sur une bulle ci-dessus pour voir le détail</i></div>
                        <div class="bloc_toggle" v-show="troubles.toggle_prepub">
                            <p class="soustitre2">Enfant prépubère</p>
                            <ul>
                                <li><b>Repérage :</b>
                                    <ul>
                                        <li>Irritabilité et comportements agressifs réactifs/explosifs peuvent être au premier plan et masquer les symptômes thymiques.</li>
                                        <li>Dans les troubles dépressifs avec humeur négative chronique, le diagnostic différentiel avec d’autre forme d’instabilité psychomotrice peut-être délicat.</li>
                                    </ul>
                                </li>
                                <li><b>Prise en charge :</b>
                                    <ul>
                                        <li>L’association d’un Episode Dépressif Majeur avec d’autres troubles psychiatriques est la règle (par exemple : trouble anxieux, trouble déficit avec attention avec ou sans hyperactivité, trouble des apprentissages spécifiques, trouble de la communication).</li>
                                        <li>Il faut traiter en priorité les troubles psychiatriques associés.</li>
                                        <li>Les antidépresseurs sont inefficaces ou d’efficacité très modérée à cet âge.</li>
                                        <li>La contribution des facteurs environnementaux et psychosociaux est majeure à cet âge. L’intervention sur ces facteurs nécessite une prise en charge multidisciplinaire.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_neurodev">
                            <p class="soustitre2">Jeune avec trouble du neurodéveloppement</p>
                            <ul>
                                <li><b>Repérage :</b>
                                    <ul>
                                        <li>La perte d’intérêt pour l’environnement peut se traduire par une majoration des comportements stéréotypés et une restriction des investissements autour d’intérêts restreints.</li>
                                        <li>Le sentiment de tristesse et les troubles de la réciprocité sociale peuvent se traduire par des biais d’attribution avec une méfiance ou un vécu d’hostilité, qui orientent à tort vers des symptômes psychotiques.</li>
                                    </ul>
                                </li>
                                <li><b>Prise en charge :</b>
                                    <ul>
                                        <li>Il n’existe aucune contre-indication à l’instauration d’un traitement antidépresseur chez ces patients.</li>
                                        <li>Il existe globalement une plus grande sensibilité aux effets indésirables chez ces patients.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_somatique">
                            <p class="soustitre2">Jeune avec maladie somatique associée</p>
                            <ul>
                                <li><b>Repérage :</b>
                                    <ul>
                                        <li>L’existence d’une maladie somatique, à fortiori chronique, est un facteur de risque de dépression chez l’enfant et l’adolescent.</li>
                                        <li>Certains symptômes de l’Episode Dépressif Majeur (par exemple les difficultés de sommeil et de l’appétit) peuvent être communs à l’Episode Dépressif Majeur et à la maladie organique.</li>
                                        <li>Les problèmes d’observance médicamenteuse sont fréquents à l’adolescence. Ces conduites peuvent avoir des sens très différents, mais il faut garder en tête que dans certaines situations il s’agit de la manifestation d’une souffrance dépressive. Ces situations nécessitent de rechercher l’existence d’un syndrome dépressif.</li>
                                    </ul>
                                </li>
                                <li><b>Prise en charge :</b>
                                    <ul>
                                        <li>La prescription d’ISRS, en particulier la fluoxétine, doit rendre attentif au risque d’interaction médicamenteuse, lié à <router-link :to="{ name: 'trouble', params: { id: 4, start: 8 }}">la prescription de traitements avec effets indésirables comme les psychotropes</router-link> ou de <a :href="dl_files_external.cytochromes" target="_blank">traitements modifiants le métabolisme du cytochrome p450.</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p class="soustitre1">QUELLES SONT LES MOLéCULES AYANT L'AUTORISATION DE MISE SUR LE MARCHé EN FRANCE ?</p>
                    <ul>
                        <li>
                            Seuls les Inhibiteurs Spécifiques de la Recapture de la Sérotonine (ISRS) sont utilisés chez l’enfant et l’adolescent.
                        </li>
                        <li>
                            L’utilisation des antidépresseurs tricycliques n’est plus recommandée en 1<sup>er</sup> intention compte tenu des effets indésirables, en particulier sur le plan cardiologique. Son utilisation relève de la prise en charge spécialisée.
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Nom du traitement
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Indication
                                    </th>
                                    <th>
                                        Age
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="4" class="has-text-centered">
                                        <b>MEDICAMENTS AYANT L’AMM EN FRANCE</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fluoxétine (Prozac<sup>®</sup>)
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        Episode Dépressif Majeur modéré à sévère
                                    </td>
                                    <td>
                                        ≥ 8 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Sertraline (Zoloft<sup>®</sup>)
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        Trouble Obsessionnel-Compulsif
                                    </td>
                                    <td>
                                        ≥ 6 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fluvoxamine (Floxyfral<sup>®</sup>)
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        Trouble Obsessionnel-Compulsif
                                    </td>
                                    <td>
                                        ≥ 8 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Clomipramine (Anafranil<sup>®</sup>)
                                    </td>
                                    <td>
                                        A3C
                                    </td>
                                    <td>
                                        Trouble Obsessionnel-Compulsif
                                    </td>
                                    <td>
                                        ≥ 10 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Imipramine (Tofranil<sup>®</sup>)
                                    </td>
                                    <td>
                                        A3C
                                    </td>
                                    <td>
                                        Enurésie
                                    </td>
                                    <td>
                                        ≥ 6 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Amitryptiline (Laroxyl<sup>®</sup>)
                                    </td>
                                    <td>
                                        A3C
                                    </td>
                                    <td>
                                        Enurésie
                                    </td>
                                    <td>
                                        ≥ 6 ans
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4">
                                        Molécules formellement contre-indiquées selon les recommandations françaises AFSSAPS 2005 :
                                        <br>&lt; 15 ans : paroxétine (Deroxat®), citalopram (Seropram®), milnacipran (Ixel®), miansérine (Athymil®)
                                        <br>&lt; 18 ans : venlafaxine (Effexor®)
                                        <br>ISRS : Inhibiteur Spécifique de la Recapture de la Sérotonine, A3C : Antidépresseur Tricyclique
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <p>Le tableau suivant présente des traitements antidépresseurs ayant l’autorisation de commercialisation à l’étranger. </p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Nom du traitement
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Indication
                                    </th>
                                    <th>
                                        Age
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="4" class="has-text-centered">
                                        <b>TRAITEMENTS AYANT DES AUTORISATIONS DE COMMERCIALISATION A L&rsquo;ETRANGER</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dulox&eacute;tine (Cymbalta<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        IRSNa
                                    </td>
                                    <td>
                                        FDA&nbsp;: Troubles anxieux g&eacute;n&eacute;ralis&eacute;
                                    </td>
                                    <td>
                                        &ge; 7 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Escitalopram (Seroplex<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        FDA&nbsp;: Episode D&eacute;pressif Majeur mod&eacute;r&eacute; &agrave; s&eacute;v&egrave;re
                                    </td>
                                    <td>
                                        &ge; 6 ans
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Olanzapine et Fluox&eacute;tine
                                    </td>
                                    <td>
                                        Association AP2G + ISRS
                                    </td>
                                    <td>
                                        FDA&nbsp;: D&eacute;pression bipolaire
                                    </td>
                                    <td>
                                        &ge; 10 ans
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4">
                                        Molécules formellement contre-indiquées selon les recommandations à l’étranger :
                                        <br>NICE (Royaume-Unis) : &lt; 18 ans : paroxétine (Deroxat®), venlafaxine (Effexor®), agomélatine (Valdoxan®), traitements de la famille des A3C, traitement de la famille des IMAO, millepertuis
                                        <br>FDA (Etats-Unis) : &lt; 18 ans : paroxétine (Deroxat®), venlafaxine (Effexor®), levomilnacipran
                                        <br>IRSNa : Inhibiteur de la Recapture de la Sérotonine et Noradrénergique, FDA : Food & Drug Administration (Agence de Régulation du Médicament aux Etats-Unis), AP2G : Antipsychotique de Seconde Génération, NICE : National Institute for Health and Care Excellence, A3C : Antidépresseur Tricyclique, IMAO : IMAO : inhibiteur de monoamine oxydase.
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES">
                    <h2 class="title is-4">BENEFICES CLINIQUES</h2>
                    <p class="soustitre1">QUELLE EST LA REPONSE ATTENDUE AU TRAITEMENT ANTIDEPRESSEUR DANS LE TRAITEMENT DE LA DEPRESSION DE L'ENFANT ET L'ADOLESCENT?</p>
                    <ul>
                        <li>
                            L’effet des antidépresseurs dans l’Episode Dépressif Majeur de l’adolescent est relativement modeste.
                        </li>
                        <li>
                            On considère qu’il faut traiter en moyenne 10 jeunes avec un Episode Dépressif Majeur pour avoir un patient en rémission complète (disparation complète des symptômes*).
                        </li>
                        <li>
                            Dans les essais cliniques sur le traitement de l’Episode Dépressif Majeur chez l’enfant et l’adolescent, le pourcentage de réponse aux ISRS est de 47-69% contre 33-57% pour le placebo.
                        </li>
                        <li>
                            La fluoxétine est le traitement qui a le plus montré son efficacité en population pédiatrique.
                        </li>
                    </ul>
                    <br>
                    <p>* La rémission complète est définie par un score à la Child Depression Rating Scale (CDRS) &lt; 29, un score à l’échelle de dépression de Montgomery et Asberg (MADRS) &lt; 12, un score à l’échelle de dépression d’Hamilton (HAM-D) &lt; 7
                    </p>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES</h2>
                    <ul>
                        <li>
                            Globalement les antid&eacute;presseurs ISRS sont bien tol&eacute;r&eacute;s. Les effets ind&eacute;sirables concernent en moyenne entre 1 jeune trait&eacute; sur 4 et 1 jeune trait&eacute; sur 10.
                        </li>
                        <li>
                            La seule contre-indication absolue des ISRS est l&rsquo;association avec IMAO (inhibiteur de monoamine oxydase).
                        </li>
                        <li>
                            Il existe un risque d&rsquo;interaction de la fluox&eacute;tine avec les m&eacute;dicaments m&eacute;tabolis&eacute;s par le cytochrome p450 (fiche <a :href="dl_files_external.cytochromes" target="_blank">traitements modifiants le m&eacute;tabolisme du cytochrome p450</a>).
                        </li>
                        <li>
                            Les effets ind&eacute;sirables sont surtout pr&eacute;sents &agrave; l&rsquo;introduction du traitement, lors du changement de dose (&uarr; ou &darr;) ou au moment du sevrage.
                        </li>
                        <li>
                            Le tableau suivant pr&eacute;sente les principaux effets ind&eacute;sirables des antid&eacute;presseurs ISRS.
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>Type</p>
                                    </th>
                                    <th>
                                        <p>Description</p>
                                    </th>
                                    <th>
                                        <p>Fréquence</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="lien_pec" v-on:click.prevent='sw2("toggle_gastro")'>Troubles gastro-intestinaux</p>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Nausées
                                            </li>
                                            <li>
                                                Vomissement
                                            </li>
                                            <li>
                                                Douleur abdominale
                                            </li>
                                            <li>
                                                Hyporexie
                                            </li>
                                            <li>
                                                Changement de poids
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        ++++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="lien_pec" v-on:click.prevent='sw2("toggle_vege")'>Sympt&ocirc;mes neurovégétatifs</p>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Hypersudation
                                            </li>
                                            <li>
                                                Rash cutané
                                            </li>
                                            <li>
                                                Bouffées de chaleur
                                            </li>
                                            <li>
                                                Trouble sexuel
                                            </li>
                                            <li>
                                                Douleur
                                            </li>
                                            <li>
                                                Tremblement
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        +++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="lien_pec" v-on:click.prevent='sw2("toggle_mania")'>Syndrome d'activation comportementale et virage maniaque</p>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Insomnie
                                            </li>
                                            <li>
                                                Hyperactivité
                                            </li>
                                            <li>
                                                Akathisie
                                            </li>
                                            <li>
                                                Irritabilité
                                            </li>
                                            <li>
                                                Labilité émotionnelle
                                            </li>
                                            <li>
                                                Agitation
                                            </li>
                                            <li>
                                                Agression
                                            </li>
                                            <li>
                                                Virage maniaque ou hypomaniaque
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        ++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="lien_pec" v-on:click.prevent='sw2("toggle_suic")'>Conduites suicidaires</p>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Idées suicidaires
                                            </li>
                                            <li>
                                                Comportement auto-agressif
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        ++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Autres
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Tics
                                            </li>
                                            <li>
                                                Epistaxis
                                            </li>
                                            <li>
                                                Syndrome sérotoninergique
                                            </li>
                                            <li>
                                                Hyponatrémie
                                            </li>
                                            <li>
                                                Abaissement du seuil épileptogène
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        +
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot><tr><td colspan="3">Cliquez sur le type d’effet indésirable pour avoir des conseils sur sa prise en charge</td></tr></tfoot>
                        </table>
                    </div>
                    <span id="detail_pec"></span>
                    <div v-show="pec.toggle_gastro">
                    <p class="soustitre2">Prise en charge des effets indésirables gastro-intestinaux</p>
                    <ul>
                        <li>
                            Ces effets ind&eacute;sirables sont li&eacute;s &agrave; l&rsquo;augmentation de la s&eacute;rotonine au niveau des cellules enterochromaffines dans le syst&egrave;me nerveux ent&eacute;rique qui contr&ocirc;le la motilit&eacute; digestive.
                        </li>
                        <li>
                            On peut prescrire un traitement symptomatique par antispasmodique. Ces effets disparaissent g&eacute;n&eacute;ralement avec le maintien de la dose.
                        </li>
                    </ul>
                </div>
                <div v-show="pec.toggle_vege">
                    <p class="soustitre2">Prise en charge des effets indésirables neurovégétatifs</p>
                    <ul>
                        <li>
                            Ces effets ind&eacute;sirables peuvent n&eacute;cessiter un traitement symptomatique.
                        </li>
                        <li>
                            Ces effets disparaissent g&eacute;n&eacute;ralement avec le maintien de la dose.
                        </li>
                    </ul>
                </div>
                <div v-show="pec.toggle_mania">
                    <p class="soustitre2">Prise en charge d'un syndrome d'activation comportemental ou virage de l'humeur</p>
                    <ul>
                        <li>
                            Dans la litt&eacute;rature ces effets ind&eacute;sirables peuvent survenir de quelques jours &agrave; 8 semaines suivant l&rsquo;introduction des ISRS. Le risque est maximal &agrave; 21 jours de prise d&rsquo;ISRS.
                        </li>
                        <li>
                            Il existe des facteurs de risque &agrave; rechercher&nbsp;:
                            <ul>
                                <li>des ant&eacute;c&eacute;dents personnels ou familiaux de trouble bipolaire</li>
                                <li>des ant&eacute;c&eacute;dents personnels de tentatives de suicide</li>
                                <li>un pr&eacute;c&eacute;dent traitement par antid&eacute;presseur</li>
                                <li>des ant&eacute;c&eacute;dents familiaux de troubles psychiatriques non-thymiques</li>
                                <li>un jeune &acirc;ge (2 fois plus fr&eacute;quents chez l&rsquo;enfant que chez l&rsquo;adolescent)</li>
                            </ul>
                        </li>
                        <li>
                            Ces situations n&eacute;cessitent de demander un avis sp&eacute;cialis&eacute; pour discuter la diminution ou l&rsquo;arr&ecirc;t de l&rsquo;antid&eacute;presseur.
                        </li>
                        <li>
                            Trois cas de figure existent&nbsp;:
                            <ul>
                                <li>
                                    Soit il s&rsquo;agit d&rsquo;un effet ind&eacute;sirable simple, ces sympt&ocirc;mes disparaissent &agrave; la diminution de la dose.
                                </li>
                                <li>
                                    Soit il s&rsquo;agit d&rsquo;un virage maniaque ou mixte, ces sympt&ocirc;mes disparaissent &agrave; l&rsquo;arr&ecirc;t du médicament. Il n&rsquo;existe pas d&rsquo;indication &agrave; mettre en place un traitement thymor&eacute;gulateur. Il s&rsquo;agit d&rsquo;un facteur de risque de d&eacute;velopper un trouble bipolaire, la balance b&eacute;n&eacute;fice-risque de r&eacute;introduire un nouvel antid&eacute;presseur doit &ecirc;tre &eacute;valu&eacute;e.
                                </li>
                                <li>
                                    Si les sympt&ocirc;mes ne disparaissent pas &agrave; l&rsquo;arr&ecirc;t du m&eacute;dicament, il s&rsquo;agit d&rsquo;un &eacute;pisode maniaque pharmaco-induit qui n&eacute;cessite la mise en place d&rsquo;un traitement sp&eacute;cifique thymor&eacute;gulateur.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="pec.toggle_suic">
                    <p class="soustitre2">Prise en charge des conduites suicidaires sous antidépresseur</p>
                    <ul>
                        <li>
                            Le risque de majoration des id&eacute;es suicidaires sous antid&eacute;presseur chez les adolescents est faible mais bien &eacute;tabli.
                        </li>
                        <li>
                            Dans les essais cliniques&nbsp;:
                            <ul>
                                <li>
                                    Le risque de majoration des id&eacute;es ou conduites suicidaires concerne 1 jeune trait&eacute; par ISRS sur 100.
                                </li>
                                <li>
                                    On retrouve en moyenne 2 fois plus de conduites suicidaires dans le groupe trait&eacute; sous ISRS (4%) par rapport au groupe t&eacute;moin (2%).
                                </li>
                                <li>
                                    Aucun d&eacute;c&egrave;s par suicide n&rsquo;a &eacute;t&eacute; rapport&eacute; dans ces &eacute;tudes.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Ce risque est maximum dans les 3 semaines qui suivent l&rsquo;introduction de l&rsquo;antid&eacute;presseur. La r&eacute;duction du risque suicidaire sous ISRS apparait g&eacute;n&eacute;ralement apr&egrave;s un mois de traitement.
                        </li>
                        <li>
                            Ce risque &eacute;tait plus important
                            <ul>
                                <li>
                                    pour les jeunes trait&eacute;s pour d&eacute;pression par rapport &agrave; ceux recevant un ISRS pour un trouble anxieux ou un TOC
                                </li>
                                <li>
                                    pour les mol&eacute;cules n&rsquo;ayant pas l&rsquo;AMM (en particulier parox&eacute;tine et venlafaxine).
                                </li>
                            </ul>
                        </li>
                        <li>
                            Prise en charge&nbsp;: selon la situation il faut discuter diminution ou arr&ecirc;t de l&rsquo;ISRS. Dans tous les cas la surveillance clinique &agrave; l&rsquo;instauration de tout antid&eacute;presseur doit &ecirc;tre tr&egrave;s r&eacute;guli&egrave;re les 3 semaines qui suivent l&rsquo;instauration.
                        </li>
                    </ul>
                </div>
                    <p>Voici les principaux effets indésirables des Antidépresseurs tricycliques (A3C) :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Fr&eacute;quence
                                    </th>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td rowspan="3">
                                        Troubles cardio-vasculaire
                                    </td>
                                    <td>
                                        Hypotension orthostatique
                                    </td>
                                    <td>
                                        +++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tachycardie, palpitation
                                    </td>
                                    <td>
                                        ++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Trouble de la conduction ou du rythme, cardiomyopathie
                                    </td>
                                    <td>
                                        -
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td rowspan="3">
                                        Sympt&ocirc;mes anticholinergiques
                                    </td>
                                    <td>
                                        Bouche s&egrave;che, constipation, vision floue, trouble de l&rsquo;accommodation, trouble de la miction
                                    </td>
                                    <td>
                                        +++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Bouff&eacute;es de chaleur, sueur, mydriase, hypotension, tachycardie
                                    </td>
                                    <td>
                                        ++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Glaucome, r&eacute;tention urinaire
                                    </td>
                                    <td>
                                        -
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td rowspan="3">
                                        Sympt&ocirc;mes neurologiques
                                    </td>
                                    <td>
                                        Vertige, tremblements, myoclonie, asth&eacute;nie, somnolence
                                    </td>
                                    <td>
                                        +++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        C&eacute;phal&eacute;es, paresth&eacute;sie, trouble de la m&eacute;moire
                                    </td>
                                    <td>
                                        ++
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Convulsions, ataxie, agitation, confusion
                                    </td>
                                    <td>
                                        +
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Sympt&ocirc;mes psychiatriques
                                    </td>
                                    <td>
                                        Id&eacute;es suicidaires<br>Virage maniaque
                                    </td>
                                    <td>
                                        +
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Autres
                                    </td>
                                    <td>
                                        Syndrome s&eacute;rotoninergique
                                        <br>Syndrome malin des neuroleptiques
                                        <br>El&eacute;vation asymptomatique des enzymes h&eacute;patiques
                                    </td>
                                    <td>
                                        +
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="3">Note : +++ très fréquent, ++fréquent, + peu fréquent, - rare</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS AVANT LA PRESCRIPTION</h2>
                    <p class="soustitre1">RECUEIL DE L'ADHéSION DU JEUNE ET SA FAMILLE</p>
                    <p class="soustitre2">Adhésion du jeune</p>
                    <ul>
                        <li>
                            La prise d&rsquo;un m&eacute;dicament au quotidien n&rsquo;est pas un acte anodin pour un enfant ou un adolescent.
                        </li>
                        <li>
                            Il faut expliquer en fonction de l&rsquo;&acirc;ge l&rsquo;utilit&eacute; du traitement, ici &laquo;&nbsp;il va te permettre de te sentir mieux, de retrouver ton moral habituel &raquo;.</li>
                        <li>
                            Il est important de rappeler que les parents sont &eacute;galement d&rsquo;accord et partie prenante.</li>
                        <li>
                            Il conviendra de discuter de ces questions avec le jeune.</li>
                    </ul>
                    <p class="soustitre2">Adhésion des parents</p>
                    <ul>
                        <li>
                            Il peut exister des r&eacute;ticences, parfois non rationnelles, parfois li&eacute;es &agrave; l&rsquo;exp&eacute;rience personnelle ou familiale au traitement.
                        </li>
                        <li>
                            Enfin, les parents ont d&eacute;sormais acc&egrave;s &agrave; bon nombre d&rsquo;information sur internet, il faut donc r&eacute;pondre par avance aux questions concernant l&rsquo;efficacit&eacute; attendue, la tol&eacute;rance, les possibles effets ind&eacute;sirables.
                        </li>
                        <li>
                            On pourra t&eacute;l&eacute;charger la <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>fiche d&rsquo;information pour les parents sur les antid&eacute;presseurs.</a>
                        </li>
                    </ul>
                    <p class="soustitre1">EVALUATION CLINIQUE</p>
                    <ul>
                        <li>
                            L&rsquo;instauration de l&rsquo;antid&eacute;presseur n&eacute;cessite de faire une &eacute;valuation clinique d&eacute;taill&eacute;e sur p&eacute;riode suffisamment longue&nbsp;qui comprend au minimum&nbsp;:
                            <ul>
                                <li>
                                    l&rsquo;histoire naturelle des sympt&ocirc;mes
                                </li>
                                <li>
                                    les comorbidit&eacute;s psychiatriques et &eacute;ventuellement somatique
                                </li>
                                <li>
                                    la recherche des ant&eacute;c&eacute;dents de bipolarit&eacute;
                                </li>
                                <li>
                                    la recherche de sympt&ocirc;mes psychotiques
                                </li>
                                <li>
                                    Evaluation de l&rsquo;environnement familial, du contexte social et scolaire. Il faut rechercher l&rsquo;existence de stress psychosociaux y compris violence physique ou sexuelle
                                </li>
                            </ul>
                        </li>
                        <li>
                            Soins psychoth&eacute;rapeutiques re&ccedil;us
                            <ul>
                                <li>
                                    La psychoth&eacute;rapie est toujours en 1<sup>er</sup> intention.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Une fiche <a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l&rsquo;instauration d&rsquo;un antid&eacute;presseur</a> est disponible pour le clinicien pour r&eacute;sumer ces points.
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <p class="soustitre1">QUELLE MOLECULE ANTIDEPRESSIVE DOIT-ETRE PRIVILEGIEE ?</p>
                    <ul>
                        <li>
                            Il est recommand&eacute; de choisir la mol&eacute;cule selon les donn&eacute;es scientifiques actuelles
                        </li>
                        <li>
                            La fluox&eacute;tine (Prozac<sup>&reg;</sup>) est l&rsquo;ISRS utilis&eacute; en 1<sup>er</sup> intention pour les Episodes D&eacute;pressif Majeur de l&rsquo;adolescent.
                        </li>
                        <li>
                            La sertraline (Zoloft<sup>&reg;</sup>) est particuli&egrave;rement utile quand il existe l&rsquo;association avec un trouble anxieux ou un Trouble Obsessionnel Compulsif.
                        </li>
                        <li>
                            Les traitements Inhibiteurs de la Recapture de la S&eacute;rotonine et Noradr&eacute;nergique ne sont pas recommand&eacute; en France chez l&rsquo;enfant et l&rsquo;adolescent. La Venlafaxine (Effexor<sup>&reg;</sup>) est associ&eacute;e &agrave; un risque plus &eacute;lev&eacute; de majoration des conduites suicidaires sous traitement compar&eacute; aux autres ISRS.
                        </li>
                    </ul>
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT ANTIDEPRESSEUR ?</p>
                    <ul>
                        <li>Bilan biologique pré thérapeutique :
                            <ul>
                                <li>Non indispensable avant l’instauration de la molécule (si nécessaire bilan hépatique et ionogramme).</li>
                                <li>Souvent utile dans la prise en globale de l’enfant ou adolescents avec un syndrome dépressif pour éliminer des troubles somatiques associés à une plus grande fatigue (carence en fer, hypothyroïdie)</li>
                            </ul>
                        </li>
                        <li>La molécule doit être instaurée à faible dose et augmentée progressivement par palier en recherchant la dose minimale efficace.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>DCI</p>
                                    </th>
                                    <th>
                                        <p>Début</p>
                                    </th>
                                    <th>
                                        <p>Pallier</p>
                                    </th>
                                    <th>
                                        <p>Dose efficace habituellement</p>
                                    </th>
                                    <th>
                                        <p>Dose maximale</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Fluoxétine&nbsp;(Prozac<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>5-10 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>20 mg/j</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sertraline (Zoloft<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>25 mg/j</p>
                                    </td>
                                    <td>
                                        <p>12,5-25 mg</p>
                                    </td>
                                    <td>
                                        <p>50 mg/j</p>
                                    </td>
                                    <td>
                                        <p>200 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Fluvoxamine (Floxyfral<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>25 mg/j</p>
                                    </td>
                                    <td>
                                        <p>25-50 mg</p>
                                    </td>
                                    <td>
                                        <p>50-100 mg/j</p>
                                    </td>
                                    <td>
                                        <p>200 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Clomipramine (Anafranil<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>25 mg/j</p>
                                    </td>
                                    <td>
                                        <p>25-50 mg</p>
                                    </td>
                                    <td>
                                        <p>75-150 mg/j</p>
                                    </td>
                                    <td>
                                        <p>250 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Imipramine (Tofranil<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>10 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>10 -30 mg/j</p>
                                    </td>
                                    <td>
                                        <p>50 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Amitryptiline (Laroxyl<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p>10 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>10 -30 mg/j</p>
                                    </td>
                                    <td>
                                        <p>50 mg/j</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>
                            Le suivi n&eacute;cessite des consultations r&eacute;guli&egrave;res.
                        </li>
                        <li>
                            Par exemple, la Soci&eacute;t&eacute; Am&eacute;ricaine de P&eacute;diatrie (GLAD-PC) recommande de faire une &eacute;valuation clinique 1 fois/sem. pendant 4 semaines, puis 1 fois/2 sem. pendant 4 semaines, puis &agrave; 12 semaines.
                        </li>
                        <li>
                            Entre ces consultations, certains pr&eacute;conisent des &eacute;valuations t&eacute;l&eacute;phoniques [3]
                        </li>
                        <li>
                            Des grilles de suivi sont propos&eacute;es pour observer <a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>l&rsquo;&eacute;volution clinique et la tol&eacute;rance des ISRS</a>.
                        </li>
                        <li>
                            L&rsquo;objectif est d&rsquo;obtenir la r&eacute;mission compl&egrave;te.
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE A 6-8 SEMAINES</p>
                    <ul>
                        <li>
                            Le traitement doit &ecirc;tre maintenu au moins 6 &agrave; 12 mois.
                        </li>
                        <li>
                            Le suivi se prolonge 6 &agrave; 12 mois (24 mois si r&eacute;currence) apr&egrave;s la r&eacute;solution des sympt&ocirc;mes et l&rsquo;arr&ecirc;t du traitement.
                        </li>
                        <li>
                            Le risque de rechute est maximum entre 8 et 12 semaines apr&egrave;s l&rsquo;arr&ecirc;t du traitement.
                        </li>
                    </ul>
                    <p class="soustitre1">SI PAS D'AMELIORATION CLINIQUE SIGNIFICATIVE OBSERVEE A 6-8 SEMAINES</p>
                    <p>En cas d’absence d’amélioration, l’approche systématique (OPACITE) suivante peut vous guider. </p>
                    <div class="table-container">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>O</strong>bservance</p>
                                    </td>
                                    <td>
                                        <p>La premi&egrave;re cause de non efficacit&eacute; d&rsquo;un traitement et de ne pas le prendre (toujours y penser avant d&rsquo;envisager un changement de traitement)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>P</strong>sychoth&eacute;rapie</p>
                                    </td>
                                    <td>
                                        <p>Le traitement m&eacute;dicamenteux, bien qu&rsquo;essentiel pour la prise en charge de ces troubles n&rsquo;est pas le seul &eacute;l&eacute;ment de la prise en charge.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>A</strong>ssociation avec un autre trouble psychiatrique, toxique, organique</p>
                                    </td>
                                    <td>
                                        <p>Rechercher des comorbidit&eacute;s psychiatriques (abus de substance, sympt&ocirc;mes hypomaniaques) ou des facteurs environnementaux qui contribueraient &agrave; p&eacute;renniser les sympt&ocirc;mes d&eacute;pressifs.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>(C</strong>oncentration)</p>
                                    </td>
                                    <td>
                                        <p>Le dosage plasmatique des ISRS ne se fait pas en pratique.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>I</strong>dentifier les facteurs environnementaux de maintien des sympt&ocirc;mes psychiatriques</p>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>T</strong>itration</p>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                On peut proposer d&rsquo;augmenter la dose de l&rsquo;ISRS progressivement, en respectant les paliers, jusqu&rsquo;&agrave; la dose maximum tol&eacute;r&eacute;e.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>E</strong>ssai d&rsquo;une autre mol&eacute;cule&nbsp;: changement ou ajout</p>
                                    </td>
                                    <td>
                                        <p>Changement de mol&eacute;cule</p>
                                        <ul>
                                            <li>
                                                Si le traitement reste inefficace malgr&eacute; l&rsquo;augmentation jusqu&rsquo;&agrave; la dose maximale, il faut consid&eacute;rer un changement de traitement.
                                            </li>
                                        </ul>
                                        <p>Ajout d&rsquo;une mol&eacute;cule</p>
                                        <ul>
                                            <li>
                                                En cas de d&eacute;pression s&eacute;v&egrave;re r&eacute;sistante on discutera la potentialisation avec un autre traitement pharmacologique ou physique.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre1">ARRET DU TRAITEMENT</p>
                    <ul>
                        <li>En cas d’efficacité 6 à 12 mois après la rémission des symptômes.</li>
                        <li>Il existe un risque de rebond à l’arrêt pour tous les ISRS sauf la fluoxétine, dont la demi-vie est plus longue (4-6 jours).</li>
                    </ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l'instauration d'un antidépresseur</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Fiche d’information famille sur les antidépresseurs</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Fiche de suivi de l'efficacité clinique et de la tolérance</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[4])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique de la dépression</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur le traitement médicamenteux de la dépression pour enseignement</a></li>
                        <li><a :href="dl_files_external.cytochromes" target="_blank">Médicaments métabolisés par le cytochrome p450</a></li>
                    </ul>
                    <p class="soustitre2"> Associations de patients et liens utiles</p>
                    <ul>
                        <li>L’Association France Dépression (enfant, adolescent, adultes). <a target="_blank" href="http://www.france-depression.org">http://www.france-depression.org</a></li>
                        <li>Fil Santé Jeunes. 0800 235 236 (appel anonyme et gratuit depuis un poste fixe) : écoute, information et orientation des jeunes dans les domaines de la santé physique, psychologique et sociale. Ouvert 7j/7 de 8h à minuit.
                            <a target="_blank" href="http://www.filsantejeunes.com">http://www.filsantejeunes.com</a></li>
                        <li>Phare Enfants-Parents. 0 810 810 987 (prix d’un appel local depuis un poste fixe) : écoute des parents et des enfants en difficulté, du lundi au vendredi de 9h30 à 18h.
                            <a target="_blank" href="http://www.phare.org">http://www.phare.org</a></li>
                    </ul>
                    <p class="soustitre2">Liste des recommandations et articles utilisés pour la revue</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Pays
                                    </th>
                                    <th>
                                        Ann&eacute;es
                                    </th>
                                    <th>
                                        R&eacute;f&eacute;rences
                                    </th>
                                </tr>
                                <tr>
                                    <td rowspan="2">
                                        France
                                    </td>
                                    <td>
                                        2014
                                    </td>
                                    <td>
                                        HAS&nbsp;: recommandations de bonnes pratiques&nbsp;: Manifestations d&eacute;pressives
                                        &agrave; l&rsquo;adolescence : rep&eacute;rage, diagnostic et prise en charge en soins de premier recours
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2005
                                    </td>
                                    <td>
                                        AFFSAPS&nbsp;: Antid&eacute;presseurs chez l&rsquo;enfant et l&rsquo;adolescent
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="3">
                                        Royaume-Uni
                                    </td>
                                    <td>
                                        2017
                                    </td>
                                    <td>
                                        Mise &agrave; jour de NICE guideline CG28
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2014
                                    </td>
                                    <td>
                                        NHS: Guidance on the Use of Antidepressants in Children and Adolescents
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2005
                                    </td>
                                    <td>
                                        NICE guideline CG28 Depression in children and young people: identification and management (2005)
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2">
                                        Etat-Unis
                                    </td>
                                    <td>
                                        2018
                                    </td>
                                    <td>
                                        American Academy of Pediatrics - GLAD PC guidelines
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        1998
                                    </td>
                                    <td>
                                        American Academy of Child and Adolescent Psychiatry - Practice Parameters for the Assessment and Treatment of Children and Adolescents With Depressive Disorders
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2">
                                        Australie
                                    </td>
                                    <td>
                                        2016
                                    </td>
                                    <td>
                                        Western Australian Psychotropic Drugs Committee: Antidepressant Therapy in Children and Adolescents
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2015
                                    </td>
                                    <td>
                                        Royal Australian and New Zealand College of Psychiatrists &ndash; Clinical practice guidelines for mood disorders (section for child and adolescents p131, Recommandations Box 13)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleDepression',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            //showpart: 1,
            window: {
                width: 0,
                height: 0,
                maxwidth: 900
            },
            rep: 'depression',
            maintitle: 'Dépression',
            dl_files: {},
            troubles: {
                toggle_prepub: false,
                toggle_neurodev: false,
                toggle_somatique: false,
            },
            pec: {
                toggle_gastro: false,
                toggle_vege: false,
                toggle_mania: false,
                toggle_suic: false,
            },
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            const keys = Object.keys(this.troubles)
            for (const key of keys) {
                this.troubles[key] = this.troubles[key] || this.window.width < this.window.maxwidth;
            }
        },
        // switch troubles
        sw: function(message) {
            const keys = Object.keys(this.troubles)
            if (this.window.width > this.window.maxwidth) {
                for (const key of keys) {
                    this.troubles[key] = (key == message);
                }
            }
        },
        sw2: function(message) {
            const keys2 = Object.keys(this.pec)
            for (const key2 of keys2) {
                this.pec[key2] = (key2 == message);
            }
            this.scrollEi()
        },
        scrollEi() {
            var bc = document.querySelector("#detail_pec")
            if (bc) {
                bc.scrollIntoView({ behavior: 'smooth' })
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lien_pec {
    color: royalblue;
    text-decoration: underline;
}

.lien_pec:hover {
    color: blue;
    text-decoration: none;
    cursor: pointer;
}
</style>