<template>
  <div class="columns is-mobile is-centered my-6 ">
    <div class="column is-half-tablet has-background-light is-dark mb-6 box-shadow">
        <div v-if="error" class="error">
            <div class="notification is-danger">
                Une erreur est survenue, mail non envoyé.
            </div>
        </div>
        <div v-if="success" class="success">
            <div class="notification is-info">
                OK! Mail envoyé.
            </div>
        </div>
        <div v-if="loading" class="success">
            <div class="notification">
                envoi en cours...
            </div>
        </div>
        <div v-if="!error && !success && !loading" class="content px-6 py-6 ">
            <form class="contact-form" @submit.prevent="sendEmail">
                <h3 class="title"><i class="fas fa-comments"></i> Contactez-nous</h3>
                <p>Vous avez des questions? Nous avons les réponses!</p>
                <div class="field">
                    <label class="label">Votre adresse email</label>
                    <div class="control has-icons-left">
                        <input name="user_email" class="input" type="email" placeholder="votre email" required>
                        <span class="icon is-small is-left">
                            <i class="fas fa-envelope"></i>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Nom Prénom</label>
                    <div class="control">
                        <input id="sujet" name="user_name" class="input" type="text" placeholder="votre nom et prenom" minlength="5" required>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Message</label>
                    <div class="control">
                        <textarea name="message" class="textarea" placeholder="" minlength="5" required></textarea>
                    </div>
                </div>
                <input type="submit" class="button is-primary" value="Envoyer">
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
    data: function() {
        return {
          error: false,
          success: false,
          loading: false,
        }
    },
    name: 'ContactUs',
    methods: {
        sendEmail: function(e) {
            this.loading = true
            emailjs.sendForm('service_hlbytpj', 'template_etbu5vn', e.target, 'user_nPvUde5EG1OoNDrpW2mu7')
                .then(() => {
                  this.loading = false
                  this.success = true
                    //console.log('SUCCESS!', result.status, result.text);
                }, () => {
                  this.loading = false
                  this.error = true
                    //console.log('FAILED...', error);
                });
        }
    }
}
</script>

<style scoped>
.box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>