<template>
    <div id="tic">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Définition</p>
                    <ul>
                        <li>
                            Un tic se d&eacute;finit comme un mouvement moteur ou une vocalisation soudain(e), rapide, r&eacute;current(e), non rythmique. Les tics sont brefs mais r&eacute;p&eacute;titifs sans p&eacute;riodicit&eacute; et apparaissent sous forme de &laquo;&nbsp;crises&nbsp;&raquo; ou de s&eacute;ries de mouvement.
                        </li>
                        <li>
                            On distingue
                            <ul>
                                <li>
                                    Les tics moteurs ou vocaux
                                </li>
                                <li>
                                    Les tics moteurs simples ou complexes
                                </li>
                            </ul>
                        </li>
                        <li>
                            Vers l&rsquo;&acirc;ge de 10 ans, les enfants parviennent &agrave; d&eacute;crire une sensation pr&eacute;monitoire plus ou moins localis&eacute; (&agrave; type de tension interne, de chatouillement, ou de picotement dans la zone musculaire consid&eacute;r&eacute;e) soulag&eacute;e par la r&eacute;alisation du tic.
                        </li>
                        <li>
                            Il existe un contr&ocirc;le automatique des tics par exemple dans les moments de concentration. Les grands enfants et les adolescents peuvent aussi diff&eacute;rer la r&eacute;alisation de tics quelques secondes, minutes ou heures au prix d&rsquo;un effort mental important. La fatigue qui suit une p&eacute;riode de suppression prolong&eacute;e peut faciliter l&rsquo;&eacute;mergence des tics (typiquement au retour de l&rsquo;&eacute;cole).
                        </li>
                        <li>
                            Les tics peuvent diminuer dans les moments de distraction, lors de t&acirc;ches n&eacute;cessitant une forte concentration, ou sous l&rsquo;effet de substance (comme l&rsquo;alcool ou de cannabis). Toutefois, ils peuvent &ecirc;tre major&eacute;s par le stress ou les &eacute;motions (positives ou n&eacute;gatives). La nature fluctuante des tics peut donner l&rsquo;impression erron&eacute;e qu&rsquo;il s&rsquo;agit de mouvements intentionnels.
                        </li>
                        <li>
                            Certains tics complexes peuvent &ecirc;tre masqu&eacute;s par les adolescents pour donner l&rsquo;impression d&rsquo;un mouvement volontaire.
                        </li>
                        <li>
                            Il existe fr&eacute;quemment des sympt&ocirc;mes associ&eacute;s &agrave; type de crise de rage, comportements auto-agressif, des comportements sociaux inappropri&eacute;s, des comportements obsessionnels-compulsifs.
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Termes
                                    </th>
                                    <th>
                                        Définition/Exemples
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="2" class="has-text-centered subth">
                                        <strong>Tics moteurs</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tics moteurs simples
                                    </td>
                                    <td>
                                        Clignement des yeux, roulement des yeux, grimaces,
                                        hochement de tête, contraction des épaules, du torse, du
                                        pelvis
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tics moteurs complexes
                                    </td>
                                    <td>
                                        S’agenouiller par terre ou sauter
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Copropraxie
                                    </td>
                                    <td>
                                        Tic moteur complexe consistant à manifester des conduites
                                        obscènes
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Echopraxie
                                    </td>
                                    <td>
                                        Tic moteur complexes consistant à imiter des gestes perçus
                                        chez autrui
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="has-text-centered subth">
                                        <strong>Tics vocaux (ou phoniques)</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tic vocaux simples
                                    </td>
                                    <td>
                                        Légère toux, raclement de gorge, râle, reniflement,
                                        grognement, cri, bruits d’animaux
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tic vocaux complexes
                                    </td>
                                    <td>
                                        Syllabes, des mots ou phrases
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Palilalie
                                    </td>
                                    <td>
                                        Tic vocal complexe consistant à répéter des mots prononcés
                                        par soi
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Echolalie
                                    </td>
                                    <td>
                                        Tic vocal complexe consistant à répéter des mots prononcés
                                        par d’autres personnes
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Coprolalie
                                    </td>
                                    <td>
                                        Tic vocal complexe consistant à répéter des mots à
                                        caractère obscène
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre2">Définition</p>
                    <ul>
                        <li>
                            On distingue plusieurs diagnostics
                            <ul>
                                <li>
                                    les tics provisoires (d’une durée &lt; 1 an)
                                </li>
                                <li>
                                    les tics chroniques (un ou plusieurs tics moteur ou vocaux d’une durée ≥ 1 an mais sans qu’il y ait une association de tics moteurs et vocaux
                                </li>
                                <li>
                                    le syndrome de Gilles de la Tourette (SGT) en cas d’association de tics moteurs et d’au moins un tic vocal présent ou passé (les tics moteur et vocaux ne sont pas nécessairement présents simultanément), d’une durée ≥ 1 an, avec un début avant l’âge de 18 ans. Ces patients peuvent aussi présenter fréquemment des crises de rage, des comportements d’automutilation, des troubles spécifiques des apprentissages et des troubles du spectre autistiques (RACGP 2021)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Prévalence et histoire naturelle</p>
                    <ul>
                        <li>
                            Tics dans l’enfance
                            <ul>
                                <li>
                                    Près de 20% des enfants présentent des tics moteurs à un
                                    moment au cours de leur développement. Il s’agit la plupart
                                    du temps de forme transitoire (&lt; 12 mois) avec une
                                    rémission spontanée
                                </li>
                            </ul>
                        </li>
                        <li>
                            Tics chroniques et SGT
                            <ul>
                                <li>
                                    La prévalence des tics chroniques est de 3-4% et celle du
                                    SGT est de 1% des enfants d’âge scolaire (Roessner 2011,
                                    AAN 2019, RACGP 2021)
                                </li>
                                <li>
                                    Les garçons sont 4 fois plus concernés que les filles
                                </li>
                                <li>
                                    Le pic d’apparition des tics est vers 6-7 ans.
                                </li>
                                <li>
                                    Le premier symptôme est généralement un tic moteur simple
                                    de la face (clignement des yeux, grimaces), au cours du
                                    temps il peut s’étendre aux épaules, aux extrémités et aux
                                    torses. Les tics vocaux surviennent en moyenne 2 à 4 ans
                                    après l’apparition des tics moteurs.
                                </li>
                            </ul>
                        </li>
                        <li>
                            L’évolution des tics chroniques est fluctuante, de façon
                            irrégulière, sur une période allant de 6 à 12 semaines sans
                            nécessairement de facteur déclenchant.
                            <ul>
                                <li>
                                    Le pic de sévérité des tics est observé vers 10-12 ans.
                                </li>
                                <li>
                                    La fin de l’adolescente est associée à une amélioration des
                                    tics (AAN 2019).
                                </li>
                                <li>
                                    La rémission est fréquente à l’âge adulte : 1/3 des enfants
                                    avec des tics ne présentent plus de tics à l’âge adulte,
                                    1/3 présentent des symptômes non cliniquement significatifs
                                    (Roessner 2011).
                                </li>
                            </ul>
                        </li>
                        <li>
                            Les facteurs associés à une persistance des tics à l’âge adulte
                            sont
                            <ul>
                                <li>
                                    Une histoire familiale de tic
                                </li>
                                <li>
                                    La présence de tics vocaux ou complexes
                                </li>
                                <li>
                                    Des symptômes obsessionnels compulsifs
                                </li>
                                <li>
                                    Des comportements auto- ou hétéroagressifs
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2" id="diagdiff">Diagnostics différentiels</p>
                    Le tableau suivant décrit les principaux diagnostics différentiels des tics.
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Mouvements
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Principaux troubles
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Tics</strong>
                                    </td>
                                    <td>
                                        Mouvement moteur ou vocalisation soudain(e), rapide,
                                        récurrent(e), non rythmique *
                                    </td>
                                    <td>
                                        Tics provisoires
                                        <br />
                                        Tics chronique
                                        <br />
                                        Syndrome Gilles de la Tourette
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="subth has-text-centered">
                                        <strong>SYMPTOMES NEUROLOGIQUES</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Dystonie</strong>
                                    </td>
                                    <td>
                                        Contraction musculaire répétées, intermittentes ou
                                        persistantes, produisant une torsion, des mouvements
                                        anormaux, une posture anormale
                                    </td>
                                    <td>
                                        Dystonie généralisée à début précoce (gène DYT1), maladie
                                        de Wilson, dystonie myoclonique, torticolis spasmodique,
                                        symptômes extra-pyramidaux lié à un traitement antagoniste
                                        dopaminergique
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Dyskinésie</strong>
                                    </td>
                                    <td>
                                        Mouvements involontaires, lent et stéréotypés
                                    </td>
                                    <td>
                                        Symptômes extra-pyramidaux iatrogène lié aux
                                        antipsychotiques
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Chorée</strong>
                                    </td>
                                    <td>
                                        Mouvements involontaire, abrupt, non répétitif, impliquant
                                        des groupes de muscles proximaux et distaux, se rapprochant
                                        d’un mouvement volontaire
                                    </td>
                                    <td>
                                        Chorée de Sydenham, chorée de Huntington
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Myoclonie</strong>
                                    </td>
                                    <td>
                                        Secousse musculaire involontaire qui peut toucher une seule
                                        partie du corps, ou une partie du corps
                                    </td>
                                    <td>
                                        Hoquet, myoclonie d’endormissement, épilepsie myoclonique
                                        juvénile, syndrome de Lennox-Gastaud, encéphalopathie
                                        mitochondriale, maladie métabolique
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Hémiballisme</strong>
                                    </td>
                                    <td>
                                        Mouvements de large amplitude, d’un côté du corps,
                                        affectant souvent la partie proximale ou le tronc
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Athétose</strong>
                                    </td>
                                    <td>
                                        Mouvement de balancement, souvent des extrémités
                                        (main/bras), lent et sinueux
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Akathisie</strong>
                                    </td>
                                    <td>
                                        Sensations déplaisant d’impatience interne, souvent
                                        associés à des mouvements ponctuels pour faire cesser cette
                                        sensation
                                    </td>
                                    <td>
                                        symptômes extra-pyramidaux lié à un traitement antagoniste
                                        dopaminergique
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="subth has-text-centered">
                                        <strong>SYMPTOMES PSYCHIATRIQUES</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Stéréotypie</strong>
                                    </td>
                                    <td>
                                        Mouvements (ou discours) répétées, stéréotypés, rythmés,
                                        sans variation dans le temps
                                    </td>
                                    <td>
                                        Autisme, Trouble du développement intellectuel
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Compulsions</strong>
                                    </td>
                                    <td>
                                        Acte moteur ou mental, répétées, destinées à neutraliser ou
                                        à diminuer de l’anxiété ou un sentiment de détresse ou à
                                        empêcher un évènement ou une situation redoutée
                                    </td>
                                    <td>
                                        TOC, anorexie mentale
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>
                                            Symptômes neurologiques fonctionnels/ conversif
                                        </strong>
                                    </td>
                                    <td>
                                        Présentation fluctuante, suggestibilité, non-cohérente
                                    </td>
                                    <td>
                                        Trouble des symptômes somatiques
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Mouvements volontaires</strong>
                                    </td>
                                    <td>
                                        Comportements qui peuvent être impulsifs ou être dus à
                                        l’ennui (jouer avec son stylo, faire du bruits)
                                    </td>
                                    <td>
                                        TDAH, TOP, trouble de l’intégration sensorielle
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">* Dans les tics chronique et SGT les mouvements moteurs sont typiquement : d’évolution fluctuante et irrégulière, n’interfère pas avec les mouvements volontaires, sont précédés d’une sensation prémonitoire d’urgence, entrainent un sentiment de soulagement après leur réalisation, peuvent être partiellement contrôlés chez les plus grand, sont diminués (sans disparaitre) au cours du sommeil </td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(2)" title="EVALUATION INITIALE">
                    <h2 class="title is-4">EVALUATION INITIALE</h2>
                    <ul>
                        <li>Elle a pour objectif
                        </li>
                    </ul>
                    <ul>
                        <ul>
                            <li>De confirmer le diagnostic
                            </li>
                            <li>D’écarter les diagnostics différentiels
                            </li>
                            <li>De rechercher des comorbidités psychiatriques et neurodéveloppementales (en particulier TDAH, TOC et troubles anxieux)
                            </li>
                            <li>D’évaluer la sévérité clinique et le retentissement des tics
                            </li>
                        </ul>
                    </ul>
                    <ul>
                        <li>Cette évaluation doit comprendre
                            <ul>
                                <li>Les antécédents familiaux de tics, de stéréotypies et autres mouvements anormaux
                                </li>
                                <li>L’histoire développementale
                                </li>
                                <li>La sévérité des tics, si besoin évaluer avec une échelle clinique/entretien semi-structuré
                                    <ul>
                                        <li>Yale Global Tic Severity Scale (YGTSS) (disponible <a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>ici</a>)
                                        </li>
                                        <li>Tourette’s Syndrome Severity Scale (TSSS)
                                        </li>
                                    </ul>
                                </li>
                                <li>Les symptômes psychiatriques associés
                                </li>
                                <li>Le retentissement fonctionnel (familial, scolaire, relationnel)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>Les explorations complémentaires à demander sont
                            <ul>
                                <li>une consultation neurologique à titre systématique
                                </li>
                                <li>Les bilans complémentaires (EEG, IRM, bilan sanguins, électrophysiologie) se discutent au cas par cas en collaboration avec le neuropédiatre (PNDS 2016)
                                </li>
                                <li>Les bilans à visée pré thérapeutique
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Yale Global Tic Severity Scale (YGTSS)</p>
                    <ul>
                        <li>Entretien semi-structuré utilisée pour évaluer la sévérité des tics</li>
                        <li>Evaluation du nombre, de la fréquence, de l’intensité, de la complexité et de l’interférence des tics moteurs et phoniques séparément.</li>
                        <li>Le score énumère 46 symptômes : 12 tics moteurs simples, 19 tics moteurs complexes, 7 tics vocaux simples et 8 tics vocaux complexes.</li>
                        <li>Le score global va de 0 à 100 ; 50 points attribués aux tics proprement dits, et 50 points attribués au retentissement socio-professionnel et familial</li>
                        <li>Le score permet d’avoir une estimation de la sévérité des tics<ul>
                                <li>Forme légère : score YGTSS total ≥ 9 et ≤19</li>
                                <li>Forme modérée : score YGTSS total ≥ 20 et ≤ 35</li>
                                <li>Forme sévère : score YGTSS total > 35</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(3)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    <p class="soustitre1">QUELLES SONT LES MOLECULES QUI ONT MONTRE LEUR EFFICACITE ?</p>
                    <ul>
                        <li>
                            Les traitements sont symptomatiques et non curatifs.</li>
                        <li>Les buts fixés doivent être réalistes, avec en général une diminution des tics de 30 à 50% (Roessner 2011, PNDS 2016).</li>
                        <li>Le tableau suivant résume les molécules selon le niveau de preuve de leur efficacité sur les tics et SGT.
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>Médicament</th>
                                    <th>Niveau de preuve</th>
                                    <th>AMM EN France POUR LES TIC/SGT</th>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">
                                        <p>ANTIPSYCHOTIQUE DE 1<sup>er</sup> GENERATION</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>HALDOL ® (Halopéridol)</td>
                                    <td>A</td>
                                    <td>OUI<br>
                                        <em>Chorées (mouvements anormaux) et maladie des tics de Gilles de la Tourette (0,02 à 0,2 mg/kg/jour) à partir de 3 ans</em></td>
                                </tr>
                                <tr>
                                    <td>ORAP ® (Pimozide)</td>
                                    <td>A</td>
                                    <td>OUI<br>
                                        <em>Chorées (mouvements anormaux) et maladie des tics de Gilles de la Tourette (0,02 à 0,2 mg/kg/jour) à partir de 6 ans</em></td>
                                </tr>
                                <tr>
                                    <td>TIAPRIDAL ® (Tiapride)</td>
                                    <td>B</td>
                                    <td>OUI
                                        Chorées et maladie des tics de Gilles de la Tourette (3 à 6 mg/kg/jour) à partir de 3 ans (gouttes)/ 6 ans (comprimés)</td>
                                </tr>
                                <tr>
                                    <td>DOGMATIL (Sulpiride)</td>
                                    <td>B</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">ANTIPSYCHOTIQUE DE 2<sup>nd</sup> GENERATION</td>
                                </tr>
                                <tr>
                                    <td>RISPERDAL ® (Rispéridone)</td>
                                    <td>A</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td>ABILIFY ® (Aripiprazole)</td>
                                    <td>A</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td>ZYPREXA ® (Olanzapine)</td>
                                    <td>C</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td>XEROQUEL ® (Quétiapine)</td>
                                    <td>C</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">AGONISTE ALPHA-2 ADRENERGIQUE</td>
                                </tr>
                                <tr>
                                    <td>CATAPRESSAN ® (Clonidine)</td>
                                    <td>A</td>
                                    <td>NON</td>
                                </tr>
                                <tr>
                                    <td>INTUNIV ® (Guanfacine)</td>
                                    <td>A</td>
                                    <td>NON (disponible uniquement sur ATU)</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">Note : Gradation des niveaux de preuves : A : plus de 2 essais contrôlés randomisés, niveau B : 1 essai contrôlé randomisé, C essai ouvert, série de cas</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(4)" title="CHOIX DU TRAITEMENT">
                    <h2 class="title is-4">CHOIX DU TRAITEMENT</h2>
                    <ul>Plusieurs questions se posent pour décider du traitement le plus adapté :
                        <li>Depuis combien de temps évoluent les tics ?</li>
                        <li>Quel est le retentissement des tics chroniques ?</li>
                        <li>Existe-t-il un trouble psychiatrique associé ?</li>
                        <li>Si oui, quelle est la priorité thérapeutique ?</li>
                    </ul>
                    <p class="soustitre2">Etape 1. Depuis combien de temps évoluent les tics?</p>
                    <ul>
                        <li>Le plus souvent les tics chez l’enfant ont une rémission spontanée</li>
                        <li>Il n’y a pas d’argument pour penser qu’un traitement précoce serait plus efficace ou préviendrait l’histoire naturelle des troubles (Pringsheim 2012)</li>
                        <li>Si la durée des tics est &lt; 6 mois, proposer<ul>
                                <li>Information et psychoéducation</li>
                                <li>Abstention thérapeutique et surveillance</li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Etape 2. Quel est le retentissement des tics chroniques au quotidien ? Existe-t-il un trouble psychiatrique associé ? Si oui, quelle est la priorité thérapeutique ?</p>
                    <ul>
                        <li>L’approche dépend de la sévérité des tics chroniques.</li>
                        <li>Les traitements médicamenteux des tics chroniques sont indiqués 1) si les tics ont une sévérité modérée à sévère, 2) s’il existe un retentissement sévère des tics sur la qualité de vie, 3) s’il existe des comorbidités psychiatriques pour lequel le traitement des tics serait efficace (par exemple des agonistes alpha-2 adrénergique en cas de TDAH associé)</li>
                        <li>En cas de comorbidités psychiatriques, il faut déterminer quel trouble psychiatrique traité en priorité selon leur sévérité respective (Roessner 2011, JAACAP 2013, RACGP 2021). Ainsi le fait de prendre en charge un TDAH associé peut réduire la fréquence et la sévérité en tics compte tenu de l’amélioration des capacités d’inhibition et donc suppression des tics.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Forme légère
                                    </th>
                                    <th>
                                        Forme modérée sans comorbidités psychiatriques au 1er plan
                                    </th>
                                    <th>
                                        Forme modérée avec comorbidités psychiatriques au 1er plan
                                    </th>
                                    <th>
                                        Forme sévère
                                    </th>
                                </tr>
                                <tr>
                                    <td class="has-text-centered">Psychoéducation uniquement
                                        <br>+/-<br>
                                        Intervention comportementales
                                        (selon le souhait du patient)</td>
                                    <td class="has-text-centered">Psychoéducation
                                        <br>+<br>
                                        Interventions comportementales
                                        <br>+/-<br>
                                        Traitement médicamenteux</td>
                                    <td class="has-text-centered">Psychoéducation
                                        <br>+<br>
                                        Traitement du trouble associé dans un premier temps</td>
                                    <td class="has-text-centered">Psychoéducation
                                        <br>+<br>
                                        Traitement médicamenteux
                                        <br>+/-<br>
                                        Interventions comportementales
                                        <br>+/-<br>
                                        Traitement d’exception
                                        (cas complexes/résistants)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre2">Etape 3. Le choix de la molécule ?</p>
                    <ul>
                        <li>Les antipsychotiques :
                            <ul>
                                <li>AP1G :
                                    <ul>
                                        <li>Traitement historique des tics mais actuellement moins utilisés compte tenu du risque d’effets indésirables
                                        </li>
                                        <li>Il s’agit de l’halopéridol (HALDOL®), le tiapride (TIAPRIDAL®), le pimozide (ORAP®) et le sulpiride (DOGMATIL®)
                                        </li>
                                        <li>Ils restent encore largement utilisés (par exemple le Tiapride est utilisé en 1er intention en Allemagne chez l’enfant et l’adolescent).
                                        </li>
                                        <li>Ils sont prescrits à des dose plus faibles
                                        </li>
                                    </ul>
                                </li>
                                <li>AP2G
                                    <ul>
                                        <li>Actuellement d’avantage utilisé
                                        </li>
                                        <li>Rispéridone (RISPERDAL®), Aripiprazole (ABILIFY®) (a montré son efficacité contre placebo)
                                        </li>
                                        <li>L’aripiprazole à faible dose est recommandée en 1<sup>er</sup> intention en France (PNDS 2016) ainsi que dans les dernières recommandations Européennes (Müller‑Vahl et al. 2021)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Les agonistes alpha-2 adrénergique (A2A)
                            <ul>
                                <li>Utilisés historiquement dans l’hypertension, parfois dans les troubles vaso-moteurs associé à la ménopause.</li>
                                <li>Utilisés en 1er intention pour les tics chroniques chez l’enfant et l’adolescent au Canada (Pringsheim 2012) en Australie et en Nouvelle-Zélande</li>
                                <li>Ils sont particulièrement intéressants<ul>
                                        <li>En cas d’association avec TDAH (voir <router-link :to="{ name: 'trouble', params: { id: 2, start: 8 }}">ici</router-link>) ou de trouble du sommeil</li>
                                        <li>S’il existe des réserves à l’utilisation d’antipsychotiques, comme un patient en surpoids</li>
                                    </ul>
                                </li>
                                <li><u>Bilan pré thérapeutique :</u> tension artérielle, ECG indispensable</li>
                                <li><u>Instauration</u> progressive pour éviter le risque d’hypotension</li>
                                <li><u>Surveillance :</u> des principaux effets indésirables (somnolence, bouche sèche, hypotension, sensation vertigineuse, troubles gastro-intestinaux)</li>
                                <li><u>Arrêt :</u> progressif pour éviter un effet rebond sur les tics et la tension artérielle.</li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre1">TICS CHRONIQUES ASSOCIES A D’AUTRES TROUBLES PSYCHIATRIQUES</p>
                    <ul>
                        <li>Les comorbidités psychiatriques sont très fréquentes (65% pour les tics moteur chronique et 85% des SGT)
                        </li>
                        <li>Les troubles les plus fréquemment associés sont : le TDAH, les TOC, les troubles anxieux, les troubles des apprentissages spécifiques et les troubles du spectre autistique (Cravedi 2017).
                        </li>
                        <li>Les études qui analysent les classes de patients avec tics et des troubles psychiatriques et neurodéveloppementaux associés retrouvent trois grandes catégories (Cravedi 2018) :
                            <ul>
                                <li>Patients avec de nombreux troubles neurodéveloppementaux complexes : TDAH et/ou des trouble des apprentissages spécifiques et/ou TSA et/ou trouble du développement intellectuel
                                </li>
                                <li>Patients sans troubles neurodéveloppementaux
                                </li>
                                <li>Patients avec des difficultés attentionnelles parfois un profil de haut-potentiel intellectuel, des difficultés de graphisme en lien avec les tics
                                </li>
                            </ul>
                        </li>
                        <li>Les symptômes de TDAH généralement précédent les tics, alors que les symptômes de TOC apparaissent à la fin de l’enfance, début de l’adolescence
                        </li>
                        <li>En cas de comorbidités psychiatriques, il faut déterminer quel trouble psychiatrique traité en priorité selon leur sévérité respective (Roessner 2011, JAACAP 2013, RACGP 2021).
                        </li>
                    </ul>
                    <br>
                    <p><b>En cas de TDAH</b></p>
                    <ul>
                        <li>Près de 30-50% des enfants ou adolescents avec un SGT ont un TDAH associé
                        </li>
                        <li>L’existence d’un TDAH est associée à un retentissement fonctionnel plus sévère. Il existe aussi généralement des symptômes de dysrégulation émotionnelle (intolérance à la frustration, crises de colère/rage, comportements agressifs) ainsi que des difficultés d’apprentissage
                        </li>
                        <li>Les propositions thérapeutiques sont les mêmes que pour les enfants avec un TDAH sans tic (<router-link :to="{ name: 'trouble', params: { id: 2, start: 8 }}">voir fiche ici</router-link>)
                            <ul>
                                <li>Le méthylphénidate (MPH) n’est pas contre-indiqué, il reste le traitement de 1<sup>ère</sup> intention en cas de TDAH associé (Roessner 2011).
                                </li>
                                <li>Il peut exister un risque d’aggravation des tics sous méthylphénidate.
                                </li>
                                <li>Compte tenu de l’évolution des tics par salves, il faut s’assurer que l’effet du MPH sur les tics soit évalué sur une période suffisamment longue.
                                </li>
                                <li>L’ajout de faible dose d’AP2G (rispéridone, aripiprazole) au MPH peut réduire les tics.
                                </li>
                                <li>Les alternatives thérapeutiques sont :
                                    <ul>
                                        <li>La clonidine (<router-link :to="{ name: 'trouble', params: { id: 2, start: 8 }}">voir fiche ici</router-link>) (Pringsheim 2012)
                                        </li>
                                        <li>L’atomoxétine (<router-link :to="{ name: 'trouble', params: { id: 2, start: 8 }}">voir fiche ici</router-link>)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Le pronostic des deux troubles peut être différent. Par exemple, un TDAH peut s’améliorer à l’adolescence, alors que le SGT persiste et nécessite de maintenir un traitement spécifique.
                        </li>
                    </ul>
                    <br>
                    <p><b>En cas de Trouble Obsessionnel Compulsif</b></p>
                    <ul>
                        <li>Près de 10-50% des enfants ou adolescents avec un SGT ont des TOC associés</li>
                        <li>La distinction entre compulsion et tics peut parfois être difficile (par exemple avec des tics de tapement) (voir <router-link :to="{ name: 'trouble', params: { id: 8, start: 1 },  hash:'#diagdiff'}">diagnostics différentiels</router-link>)</li>
                        <li>Les analyses des résultats des essais cliniques montrent que la présence de tics est associée à une moins bonne réponse au traitement antidépresseur par Inhibiteur Spécifique de la Recapture de la Sérotonine (ISRS) mais n’affecte pas la réponse aux psychothérapies sur les TOC</li>
                        <li>L’aripiprazole permettrait d’augmenter l’efficacité des ISRS chez les patients qui présentent un trouble anxieux, un TOC, ou un EDM. C’est donc un traitement à privilégier dans ces circonstances (Eddy 2011).</li>
                    </ul>
                    <br>
                    <p><b>En cas de trouble anxieux ou dépressif</b></p>
                    <ul>
                        <li>L’existence d’un trouble dépressif est plus fréquente chez les adolescents qui présentent des tics chroniques et corrélés à la sévérité des tics</li>
                        <li>Il faut traiter en priorité le trouble le plus gênant.</li>
                    </ul>
                    <br>
                    <p><b>En cas de crises de rage ou de comportements agressifs sévères associés</b></p>
                    <ul>
                        <li>Les AP2G à faible dose sont à privilégier (rispéridone, aripiprazole).</li>
                    </ul>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS AVANT LA PRESCRIPTION</h2>
                    <p class="soustitre1">PSYCHOEDUCATION</p>
                    <ul>
                        <li>Sa place dans la prise en charge des tics chroniques est essentielle (Pringsheim 2012, RCGPG 2021)</li>
                        <li>La psychoéducation concerne : i) la nature des tics, ii) leur histoire naturelle, iii) les troubles fréquemment associés, et iv) les options thérapeutiques</li>
                        <li>On peut orienter la famille vers des outils de psychoéducation spécifique (AAN 2019)</li>
                        <li>L’intérêt de transmettre des informations aux milieux scolaires est de lutter contre la stigmatisation et le rejet des autres jeunes (AACAP 2013)</li>
                        <li>Dans les formes légères de tics chroniques seule la psychoéducation est indiquée (Pringsheim 2012)</li>
                    </ul>
                    <p class="soustitre1">PLACE DES PSYCHOTHERAPIES DANS LES TICS CHRONIQUES</p>
                    <ul>
                        <li>Il s’agit principalement d’intervention comportementale</li>
                        <li>Elles sont indiquées s’il existe un retentissement des tics, si les tics ont une sévérité modérée, ou s’il existe des comorbidités psychiatriques</li>
                        <li>Elle se fonde sur le modèle d’apprentissage. L’objectif est de prévenir le renforcement négatif entre la réalisation des tics et la sensation prémonitoire d’urgence</li>
                        <li>On distingue<ul>
                                <li>Les techniques dites d’inversion d’habitude</li>
                                <li>Les thérapies d’exposition et de prévention de la réponse</li>
                                <li>Autres approches : relaxation, intervention centrée sur les circonstances aggravantes</li>
                            </ul>
                        <li>Ces interventions sont possibles dès l’âge de 10 ans compte tenu de la motivation et du degré d’introspection nécessaire (PNDS 2016)</li>
                        <li>La place des autres types de psychothérapies se discute selon l’existence de comorbidités psychiatriques (par exemple anxieuse), la priorité thérapeutique (tic ou comorbidités), la préférence du patient et de la famille et les ressources locales</li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <ul>
                        <li>Le bilan pré thérapeutique inclut selon la molécule :<ul>
                                <li>AP1G/AP2G : Poids, Taille, TT, TA, bilan hépatique, glycémie, lipidique +/- prolactinémie, ionogramme sanguin (pour traitement de la famille des benzamides comme sulpiride et tiapride) (<router-link :to="{ name: 'trouble', params: { id: 5, start: 7 }}">bilan complet ici</router-link>)</li>
                                <li>A2A : TA, ECG (<router-link :to="{ name: 'trouble', params: { id: 5, start: 7 }}">bilan complet ici</router-link>)</li>
                            </ul>
                        </li>
                        <li>Le traitement devrait être instauré progressivement en contrôlant l’efficacité et la tolérance à intervalles régulières.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Médicament
                                    </th>
                                    <th>
                                        Dose initiale
                                    </th>
                                    <th>
                                        Fourchettes thérapeutiques
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">
                                        <strong>ANTIPSYCHOTIQUE DE 1<sup>ere</sup> GENERATION</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>HALDOL ® (Halopéridol)</p>
                                    </td>
                                    <td>
                                        <p>0,25 -0,5 mg</p>
                                    </td>
                                    <td>
                                        <p>0,25-3 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>ORAL ® (Pimozide)</p>
                                    </td>
                                    <td>
                                        <p>0,25-0,5 mg</p>
                                    </td>
                                    <td>
                                        <p>1-4 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>TIAPRIDAL ® (Tiapride)</p>
                                    </td>
                                    <td>
                                        <p>50-100 mg (2 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>2-10 mg/kg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>DOGMATIL (Sulpiride)</p>
                                    </td>
                                    <td>
                                        <p>50-100 mg (2 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>2-10 mg/kg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">
                                        <p>ANTIPSYCHOTIQUE DE 2<sup>nd</sup> GENERATION</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>RISPERDAL ® (Rispéridone)</p>
                                    </td>
                                    <td>
                                        <p>0,25 mg</p>
                                    </td>
                                    <td>
                                        <p>0,25-3 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>ABILIFY ® (Aripiprazole) <sup>1</sup></p>
                                    </td>
                                    <td>
                                        <p>1,0 mg</p>
                                    </td>
                                    <td>
                                        <p>2,0-10 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>ZYPREXA ® (Olanzapine)</p>
                                    </td>
                                    <td>
                                        <p>2,5–5 mg</p>
                                    </td>
                                    <td>
                                        <p>2,5-10 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>XEROQUEL ® (Quétiapine)</p>
                                    </td>
                                    <td>
                                        <p>100–150 mg</p>
                                    </td>
                                    <td>
                                        <p>100-400 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="has-text-centered subth">
                                        <p>AGONISTE ALPHA-2 ADRENERGIQUE</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CATAPRESSAN ® (Clonidine) <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>0,05 mg</p>
                                    </td>
                                    <td>
                                        <p>0,1 – 0,3 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>INTUNIV ® (Guanfacine)</p>
                                    </td>
                                    <td>
                                        <p>0,5-1,0 mg</p>
                                    </td>
                                    <td>
                                        <p>1 - 3 mg/j</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">1 Traitement disponible en solution buvable pour la prescription de dose inférieur à 5 mg.<br>
                                    2 Aux Etats-Unis et au Canada, des patches de 1-2 mg de clonidine à libération prolongée à changer toutes les semaines sont aussi disponibles.</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>La surveillance concerne :
                            <ul>
                                <li>l’efficacité : sur une période d’observation suffisamment prolongée dans le temps, compte tenu du caractère fluctuant des tics.
                                </li>
                                <li>La tolérance
                                    <ul>
                                        <li>Les effets indésirables des antipsychotique de 1<sup>er</sup> génération et de 2<sup>nd</sup> génération (détaillés <router-link :to="{ name: 'trouble', params: { id: 5, start: 4 }}">ici</router-link>) sont rares car les doses observés sont beaucoup plus faible que pour les autres indications
                                        </li>
                                        <li>Effets indésirables des agonistes alpha-2 adrénergique (détaillés <router-link :to="{ name: 'trouble', params: { id: 2, start: 4 }}">ici</router-link>)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>La surveillance doit se faire sur une période d’au moins 3 mois (PNDS 2016). Compte tenu de l’évolution par salve sur 6-12 semaines, un traitement peut être jugé à tort efficace ou inefficace s’il est introduit respectivement lors d’une période de rémission ou lors d’une période de recrudescence.
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE</p>
                    <ul>
                        <li>Une fois la dose minimale efficace atteinte, la prise doit être régulière pendant au moins un an.
                        </li>
                        <li>Une fenêtre thérapeutique annuel (généralement pendant les vacances d’été) permet de réévaluer la nécessité ou non de poursuivre le traitement (PNDS 2016)
                        </li>
                        <li>Chez les patients cliniquement stables, il est recommandé de diminuer le traitement à la fin de l’adolescence, compte tenu du taux de rémission spontanée à cet âge.
                        </li>
                    </ul>
                    <p class="soustitre1">SI PAS D’AMELIORATION CLINIQUE</p>
                    <ul>
                        <li>Les traitements d’exception sont à réserver aux cas complexes et résistants.</li>
                        <li>La balance bénéfice-risque de ces traitements est à discuter en équipe multidisciplinaire en particulier avec les centres ressources Gilles de la Tourette.
                            <ul>
                                <li>tetrabénazine (XENAZINE ®) 12,5 à 50 mg/j, traitement dépleteur en monoamines
                                </li>
                                <li>topiramate (EPITOMAX ®) 1-9 mg/kg/j ou 25-150 mg/j dans les formes résistantes. Principaux effets indésirables : possible difficultés cognitives ou au niveau du langage, somnolence, perte de poids et risque de lithiase rénale
                                </li>
                                <li>injection de toxine botulique : chez grands adolescents et adultes, en cas de tics moteurs simples, ou tics vocaux. Les effets durent 12-16 semaines et les principaux effets secondaires sont la faiblesse musculaire au niveau du site d’injection et l’hypophonie
                                </li>
                                <li>stimulation cérébrale profonde : peu de données chez l’enfant
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique des tics chroniques/SGT</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur le traitement médicamenteux des tics chroniques/SGT pour enseignement</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Echelle YGTSS</a></li>
                    </ul>
                    <p class="soustitre2">Association de patients et liens utiles :</p>
                    <ul>
                        <li>AFSGT : <a target="_blank" href="http://www.france-tourette.org">http://www.france-tourette.org</a></li>
                        <li>Société européene : <a target="_blank" href="https://www.essts.org">https://www.essts.org</a></li>
                    </ul>
                    <p class="py-2 soustitre2">Liste des recommandations et articles utilisés pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th>Pays</th>
                                    <th>Recommandations</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>France</td>
                                    <td>Protocole National de Diagnostic et de Soins (PNDS) Syndrome Gilles de la Tourette (2016)</td>
                                </tr>
                                <tr>
                                    <td>Union-Européenne</td>
                                    <td>European clinical guidelines for Tourette syndrome and other tic
                                        disorders :<br>
                                        Part I: assessment – Cath et al. (2011)
                                        Part II: pharmacological treatment – Roessner et al. (2011)<br>
                                        Part III: behavioural and psychosocial interventions – Verdellen et al. (2011)<br>
                                        Part IV: Deep brain stimulation – Müller-Vahl (2011)
                                        <br><br>
                                        European clinical guidelines for Tourette Syndrome and other tic disorders: patients’ perspectives on research and treatment :<br>
                                        Part I: patients’ perspectives on research and treatment - Anderson et al. (2021)<br>
                                        Part II: psychological intervention – Andrén et al. (2021)<br>
                                        Part III: summary statement – Müller‑Vahl et al. (2021)</td>
                                </tr>
                                <tr>
                                    <td>Etats-Unis</td>
                                    <td>Prinsheim et al. 2019 - American Academy of Neurology (AAN)
                                        Murphy et al. 2013 - American Academy of Child and Adolescent Psychiatry (AACAP)</td>
                                </tr>
                                <tr>
                                    <td>Australie/ Nouvelle-Zélande</td>
                                    <td>Eapen &amp; Usherwood (2021) - Royal Australian College of General Practitioners (RACGP)</td>
                                </tr>
                                <tr>
                                    <td>Canada</td>
                                    <td>Prinsheim et al. (2012) - Canadian Guidelines for the Evidence-Based Treatment of Tic Disorders: Pharmacotherapy</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="has-text-centered has-background-info has-text-white"><b>AUTRES REFERENCES</b></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <ul>
                                            <li> Eddy CM, Rickards HE, Cavanna AE. Treatment strategies for tics in Tourette syndrome. Ther Adv Neurolo Disord 2011;4(1):25–45. doi: 10.1177/1756285610390261.
                                            <li>Cravedi E, Deniau E, Giannitelli M, Pellerin H, Czernecki V, Priou T, Xavier J, Consoli A, Hartmann A, Cohen D. Disentangling Tourette syndrome heterogeneity through hierarchical ascendant clustering. Developmental Medicine &amp; Child Neurology 2018. doi: 10.1111/dmcn.13913Cit</li>
                                            <li>Cravedi E, Deniau E, Giannitelli M, Xavier J, Hartmann A, Cohen D. Tourette syndrome and other neurodevelopmental disorders: a comprehensive review 2017. Child Adolesc Psychiatry Ment Health (2017) 11:59 doi: 10.1186/s13034-017-0196-x</li>
                                            <li>Sallee F, Kohegyi E, Zhao J, McQuade R, Cox K, Sanchez R, van Beek A, Nyilas M, Carson W, Kurlan R. Randomized, Double-Blind, Placebo-Controlled Trial Demonstrates the Efficacy and Safety of Oral Aripiprazole for the Treatment of Tourette's Disorder in Children and Adolescents. J Child Adolesc Psychopharmacol. 2017 Nov;27(9):771-781. doi: 10.1089/cap.2016.0026. Epub 2017 Jul 7. PMID: 28686474; PMCID: PMC5689110.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleTIC',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            dl_files: {},
            //showpart: 1,
            rep: 'tic',
            maintitle: 'TIC',
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Eval. initiale', 'icon': 'fas fa-lightbulb' },
                { 'id': 3, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 4, 'title': 'Choix du trt', 'icon': 'fas fa-balance-scale' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {},
    destroyed() {},
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>