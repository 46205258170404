<template>
    <div>
       <Navbar />
        <section class="hero is-normal has-background-white is-bold">
            <div class="hero-body" align="center">
                <div class="container py-1">
                    <figure id="logo" class="image is-128x128"><img v-on:click="goHome" src="../assets/logoSFPEADA.svg" alt="logo"></figure>
                </div>
                <div class="container infosite">Site d'aide à la prescription pharmacologique
                    <router-link :to="{ name: 'about'}"><i class="fas fa-info-circle about"></i></router-link>
                </div>
            </div>
        </section>
        <span v-if="$route.name != 'home'" id="scroll_here"></span>
        <router-view></router-view>
        <template>
            <div class="">
                <cookie-law theme="royal" buttonText="OK" message="Ce site utilise des cookies afin d'améliorer votre expérience utilisateur"></cookie-law>
            </div>
        </template>
        <footer class="footer has-background-info has-text-white has-text-centered">
            <div class="container">
                Site de pharmacologie de la <a target="_blank" href="https://sfpeada.fr">SFPEADA</a>
                <br><router-link :to="{ name: 'about'}">Qui sommes nous?</router-link> | <router-link :to="{ name: 'mentions'}">Mentions légales</router-link>
            </div>
        </footer>
    </div>
</template>
<script>
import CookieLaw from 'vue-cookie-law';
import Navbar from './Navbar.vue'

export default {
    data: function() {
        return {
        }
    },
    name: 'Main',
    components: {
        CookieLaw,
        Navbar
    },
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'SFPEADA',
      // all titles will be injected into this template
      titleTemplate: '%s | Site de pharmacologie'
    },
    methods: {
        goHome: function() {
            if (this.$route.path != '/') {
                this.$router.push({ name: 'home'})
            }
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logo:hover{
    cursor: pointer;
}
footer a {
    color: #fafafa;
    text-decoration: underline;
}
footer a:hover {
    color: #eee;
}
footer{
    padding-bottom: 50px;
}
.infosite{
    padding-top: 40px;
    color: #f3511a;
    width: 220px;
}
.about{
    color:#f3511a;
}
</style>