<template>
    <div id="trouble" class="has-background-light">
        <section>
            <div class="container">
                <br><br>
                <nav class="breadcrumb is-medium" aria-label="breadcrumbs" id="breadcrumb">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'home'}">Pathologies</router-link>
                        </li>
                        <li class="is-active is-capitalized"><a href="#" aria-current="page">{{titlepage}}</a></li>
                    </ul>
                </nav>
                <div class="trouble_wrapper" id="trouble_wrapper">
                    <div v-if="id == 1">
                        <TroubleBipo :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 2">
                        <TroubleADHD :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 3">
                        <TroubleTOC :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 4">
                        <TroubleDepression :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 5">
                        <TroublePsychotique :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 6">
                        <TroubleSommeil :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 7">
                        <TroubleAnxieux :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 8">
                        <TroubleTIC :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else-if="id == 9">
                        <TroubleAgitation :id-chapter="id" :start-at="getStartAt()" :titlepage="titlepage" @update="onChildUpdate" />
                    </div>
                    <div v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import TroubleBipo from '../components/Troubles/Bipo.vue'
import TroubleADHD from '../components/Troubles/ADHD.vue'
import TroubleTOC  from '../components/Troubles/TOC.vue'
import TroubleDepression  from '../components/Troubles/Depression.vue'
import TroublePsychotique  from '../components/Troubles/Psychotique.vue'
import TroubleSommeil  from '../components/Troubles/Sommeil.vue'
import TroubleAnxieux  from '../components/Troubles/Anxieux.vue'
import TroubleTIC  from '../components/Troubles/TIC.vue'
import TroubleAgitation  from '../components/Troubles/Agitation.vue'
import NotFound    from './NotFound.vue'

export default {
    props: ['id', 'start'],
    name: 'Trouble',
    data: function() {
        return {
            titlepage: '-',
        }
    },
    components: {
        TroubleBipo,
        TroubleADHD,
        TroubleTOC,
        TroubleDepression,
        TroublePsychotique,
        TroubleSommeil,
        TroubleAnxieux,
        TroubleTIC,
        TroubleAgitation,
        NotFound
    },
    methods: {
        onChildUpdate(newValue) {
            this.titlepage = newValue
        },
        getStartAt() {
            return this.start ?? 1
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.trouble_wrapper{
    font-size: 13pt;
    line-height: 160%;
    padding-left: 8px;
    padding-right: 8px;
}
#trouble {
    min-height: 500px;
    padding-bottom: 100px;
}

.breadcrumb {
    padding-left: 10px;
}
</style>

<style>
.docs_utiles{
    list-style-type: square;
    list-style-position: inside;
    list-style-image: none;
}

#paginate{
    padding-top: 50px;
    font-size: 24pt;
    color: royalblue;
    display: none;
}
#paginate i:hover{
    cursor: pointer;
}

h2.title{
    text-transform: uppercase;
}

.bipogrid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(5, 140px);
    grid-template-rows: repeat(2, 140px);
    grid-column-gap: 10px;
    grid-row-gap: 8px;
}

.bipogrid div {
    background: white;
    min-height: 50px;
    border: 1px solid #dddddd;
}

.bipogrid h2 {
    padding-top: 6px;
}

.bipogrid i {
    font-size: 40pt;
    color: royalblue;
}

.bipogrid div:hover,
.currbox {
    background: royalblue !important;
    color: #fafafa !important;
    cursor: pointer;
}

.bipogrid div:hover i,
.currbox i {
    color: #fafafa !important;
}

.bipogrid div:hover .subtitle,
.currbox .subtitle {
    color: #fafafa !important;
}


.is-horizontal-center {
    justify-content: center;
}

.content_trouble {
    margin-top: 50px;
    /* margin-bottom: 50px;*/
}

.content_trouble h2.title {
    padding-top: 10px;
    padding-bottom: 10px;
}


/* contenu des sections */
.soustitre1 {
    font-weight: bold;
    color: royalblue;
    margin-top: 28px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 18px;
}

.soustitre1::before {
    content: "🔹 ";
}

.soustitre2 {
    font-weight: bold;
    color: royalblue;
    margin-top: 15px;
}

.sectiontitle {
    text-align: left;
}

.sectiontitle::before {
    counter-increment: section;
    content: "#"counter(section) " ";
}


table {
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table td.subth {
    background: #ccc;
    font-weight: bold;
}

table:not(.rowsp) tr:nth-child(even) {
    background-color: #f2f2f2;
}

table:not(.rowsp) tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ED8936;
    color: white;
}

.tablegend {
    font-size: smaller;
}

.sep {
    height: 200px;
}

.but {
    border-left: 10px solid #ccc;
    padding-left: 10px;
}

.nom_patho {
    font-weight: bold;
    text-align: left;
    background-color: cyan;
    border: 1px solid royalblue;
    font-size: 24px;
    padding: 10px;
}

img.illustration {
    margin-top: 20px;
    margin-bottom: 20px;
}

ul {
  list-style: square inside !important;
  margin-left:20px !important;
}

ol {
  margin-left:20px !important;
}

ul ul{
  list-style: circle inside !important;
  margin-left:20px;
}

tfoot{
    font-size: 9pt;
}

ul.roman li{
   list-style-type:lower-roman !important;
}

@media only screen and (max-width: 600px) {
    .bipogrid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: repeat(3, 100px);
        grid-column-gap: 10px;
        grid-row-gap: 8px;
    }

    .bipogrid i {
        font-size: 30pt;
        color: royalblue;
    }

    .subtitle {
        font-size: 13px;
        padding-top: 5px;
    }
}
</style>