<template>
    <div id="anxiete">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>

<ul>
<li>
Les troubles anxieux sont les troubles psychiatriques les plus fr&eacute;quents chez l&rsquo;enfant et l&rsquo;adolescent. Ils touchent pr&egrave;s de 6% des enfants et adolescents (1).
</li>
<li>
Parmi les troubles anxieux d&eacute;butant dans l&rsquo;enfance on distingue&nbsp;:
<ul>
<li>
Le trouble anxieux de s&eacute;paration
</li>
<li>
Le mutisme s&eacute;lectif
</li>
<li>
Les phobies sp&eacute;cifiques
</li>
<li>
L&rsquo;agoraphobie
</li>
<li>
L&rsquo;anxi&eacute;t&eacute; sociale
</li>
<li>
Le trouble anxieux g&eacute;n&eacute;ralis&eacute;
</li>
<li>
Le trouble panique
</li>
</ul>
</li>
<li>
Les complications principales des troubles anxieux sont li&eacute;es au retentissement fonctionnel (scolaire, relationnel, familial) et &agrave; la persistance à l’âge adulte qui est associée à un risque plus élevé de trouble dépressif, d’abus de substance, et de conduites suicidaires (2).
</li>
<li>
Les troubles anxieux doivent &ecirc;tre diff&eacute;renci&eacute;s des manifestations anxieuses li&eacute;es &agrave; un autre trouble psychiatrique, comme le trouble d&eacute;pressif, le trouble du spectre de l&rsquo;autisme ou les troubles du comportement alimentaire.
</li>
<li>
L&rsquo;objectif d&rsquo;un traitement prolong&eacute; est d&rsquo;&eacute;viter la rechute et de permettre au jeune de multiplier les exp&eacute;riences de nouvelles situations habituellement stressantes sans provoquer d&rsquo;anxi&eacute;t&eacute; (comme la rentr&eacute;e scolaire).
</li>
</ul>

                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    <p class="soustitre1">CHOIX DU TRAITEMENT</p>
<ul>
    <li>Les psychothérapies sont toujours le traitement de 1ère intention à proposer aux enfants et adolescents avec un trouble anxieux.</li>
    <li>En cas de trouble anxieux sévère et quand les approches psychothérapeutiques ont échoué ou ne peuvent pas être menées, les Inhibiteur Spécifique de la Recapture de la Sérotonine (ISRS) sont les traitements à utiliser en premier intention chez l’enfant et l’adolescent.</li>
    <li>Il existe d’autres classes de médicaments utilisés pour traiter ponctuellement les manifestations anxieuses chez l’enfant et l’adolescent, comme les antihistaminiques.</li>
</ul>

    <p class="soustitre1">QUELLES SONT LES MOLECULES AYANT L’AUTORISATION DE MISE SUR LE MARCHE EN FRANCE ET A L’ETRANGER ?</p>
<ul>
    <li>Les médicaments utilisés pour traiter les troubles anxieux chez l’enfant et l’adolescent sont très largement utilisés hors-AMM.</li>
    <li>En France, le seul traitement ayant une AMM spécifique est l’hydroxyzine (Atarax®).</li>
    <li>L’hydroxyzine est indiquée dans les manifestations mineures de l’anxiété, à partir de 3 ans pour la forme en sirop, et à partir de 6 ans pour la forme en comprimé. Les autres indications d’AMM de l’hydroxyzine sont la prémédication avant une anesthésie générale, le traitement symptomatique de l’urticaire, et les insomnies d’endormissement liées à un état d’hyper-éveil (vigilance accrue liée à des manifestations anxieuses au coucher) après échec des mesures comportementales seules (3).</li>
    <li>Aux Etats-Unis, la seule molécule ayant reçu l’autorisation des agences de régulation du médicaments (Food and Drug Administration) est la duloxétine pour les enfants à partir de l’âge de 7 ans ayant un trouble anxieux généralisé (4).</li>
</ul>

                </div>
                <div v-show="showPart(3)" title="PLACE DES ISRS">
                    <h2 class="title is-4">PLACE DES ISRS</h2>
                    <p class="soustitre1">QUEL EST LE BENEFICE DES ISRS DANS LE TROUBLE ANXIEUX PEDIATRIQUE CHRONIQUE ?</p>
                    <ul>
<li>
L&rsquo;effet des antid&eacute;presseurs type ISRS dans la prise en charge des troubles anxieux p&eacute;diatriques chroniques est significatif.
<ul>
<li>
Le pourcentage de r&eacute;ponse aux ISRS est de 34-62% contre 28% pour le placebo.
</li>
<li>
Le nombre de sujet &agrave; traiter pour un patient en r&eacute;mission compl&egrave;te (c&rsquo;est-&agrave;-dire une disparation compl&egrave;te des sympt&ocirc;mes) est de 3 (5).
</li>
</ul>
</li>
<li>
Il n&rsquo;existe pas de consensus sur le fait que certaines mol&eacute;cules ISRS soient plus efficaces que d&rsquo;autres selon le trouble anxieux.
<ul>
<li>
La sertraline (Zoloft<sup>&reg;</sup>) est l&rsquo;ISRS le plus utilis&eacute; dans les troubles anxieux avec une efficacit&eacute; &eacute;valu&eacute;e dans le trouble anxieux g&eacute;n&eacute;ralis&eacute; et les troubles anxieux mixtes.
</li>
<li>
Les autres mol&eacute;cules bien &eacute;valu&eacute;es sont la fluox&eacute;tine (Prozac&reg;) et fluvoxamine (Floxyfral&reg;). Attention, une attention particuli&egrave;re est &agrave; porter &agrave; la fluvoxamine, car celle-ci est un puissant inhibiteur enzymatique des cytochromes (CYP), qui peut donc modifier le m&eacute;tabolisme et les dosages plasmatiques d&rsquo;autres traitements psychotropes ou autres (<a :href="dl_files_external.cytochromes" target="_blank">liste des interactions li&eacute; au cytochrome</a>).
</li>
</ul>
</li>
</ul>

<p class="soustitre1">QUELS SONT LES FACTEURS ASSOCIES A UNE MEILLEURE REPONSE CLINIQUE ?</p>
<ul>
<li>
Les facteurs associ&eacute;s &agrave; un meilleure r&eacute;ponse clinique au ISRS chez les jeunes avec un troubles anxieux sont (6, 7) :
<ul>
<li>
&ecirc;tre un gar&ccedil;on
</li>
<li>
un &acirc;ge moins &eacute;lev&eacute;
</li>
<li>
ne pas appartenir &agrave; une minorit&eacute; ethnique
</li>
<li>
un niveau plus bas de s&eacute;v&eacute;rit&eacute; des sympt&ocirc;mes anxieux
</li>
<li>
un niveau plus bas de retentissement familial
</li>
<li>
l&rsquo;absence de trouble d&eacute;pressif associ&eacute; ou de trouble des conduites comorbide
</li>
<li>
l&rsquo;absence d&rsquo;apparent&eacute; du premier degr&eacute; avec des troubles anxieux
</li>
</ul>
</li>
<li>
Les &eacute;tudes conduites par des investigateurs publiques comme le <em>Child/Adolescent Anxiety Multimodal Study</em> (CAMS) ou le <em>Research Unit on Pediatric Psychopharmacology</em> (RUPP) qui incluent des sujets plus proches des patients trait&eacute;s habituellement montrent que les ISRS sont aussi efficaces chez les sujets qui pr&eacute;sentent des comorbidités dépressives ou de troubles externalisées, en particulier un trouble du d&eacute;ficit de l&rsquo;attention avec ou sans hyperactivit&eacute; (8, 9).
</li>
<li>
L&rsquo;essai contr&ocirc;l&eacute; randomis&eacute; CAMS a montr&eacute; que le traitement combin&eacute; associant un traitement par sertraline et une TCC &eacute;tait sup&eacute;rieur par rapport aux groupes recevant une TCC ou un traitement par ISRS seul (8).
</li>
</ul>
<p class="soustitre1">COMMENT INSTAURER CE TRAITEMENT ?</p>

<ul>
<li>
Vous pouvez trouver dans <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 1 }}">la page sur la prise en charge du troubles d&eacute;pressifs</router-link> quelques conseils d&eacute;taill&eacute;s sur la prise en charge avant l&lsquo;instauration d&rsquo;un ISRS chez l&rsquo;enfant et l&rsquo;adolescent, ainsi que sur l&rsquo;introduction,la surveillance, et les principaux effets ind&eacute;sirablesdes ISRS.
</li>
<li>
<em>Exemple de prescription de sertraline (Zoloft</em><sup><em>&reg;</em></sup><em>) dans le cadre d&rsquo;un trouble anxieux p&eacute;diatrique</em>
<ul>
<li>
Initier &agrave; 12,5 ou 25 mg/j pour sept jours
</li>
<li>
Augmenter &agrave; 12,5 mg/j (enfant) ou 25-50 mg/j (adolescent) la semaine suivante
</li>
<li>
La r&eacute;ponse clinique peut &ecirc;tre observ&eacute;e d&egrave;s la premi&egrave;re semaine, mais plus g&eacute;n&eacute;ralement entre la 2&egrave;me et 4&egrave;me semaine.
</li>
<li>
En cas d&rsquo;inefficacit&eacute; de la r&eacute;ponse clinique &agrave; 6 &agrave; 8 semaines le traitement peut &ecirc;tre augment&eacute; par pallier de 12,5 ou 25-50 mg/j, jusqu&rsquo;&agrave; un maximum de 200 mg/j.
</li>
<li>
Une majoration du b&eacute;n&eacute;fice clinique peut &ecirc;tre observ&eacute;e jusqu&rsquo;&agrave; 12-16 semaines apr&egrave;s l&rsquo;initiation du traitement (11).
</li>
<li>
Le suivi n&eacute;cessite des consultations r&eacute;guli&egrave;res. Il a &eacute;t&eacute; recommand&eacute; de faire une &eacute;valuation clinique 1 fois par semaine pendant 4 semaines, puis 1 fois toutes les 2 semaines pendant 4 semaines, puis &agrave; 12 semaines.
</li>
<li>
Il est recommand&eacute; de faire un essai d&rsquo;arr&ecirc;t du m&eacute;dicament chez les patients qui pr&eacute;sente une am&eacute;lioration des sympt&ocirc;mes anxieux depuis au moins 12 mois, par exemple lors de p&eacute;riodes moins stressantes comme les vacances.
</li>
<li>
En cas de rechute à l’arrêt, le traitement doit être reconduit (10). Le risque de rechute est maximum entre 8 et 12 semaines après l’arrêt du traitement.
</li>
<li>
Les ISRS doivent &ecirc;tre arr&ecirc;t&eacute;s progressivement. Pour la sertraline, il est recommand&eacute; de diminuer les doses progressivement de 25 &agrave; 50mg par semaine.
</li>
</ul>
</li>
</ul>
<p class="soustitre1">SI PAS D’AMELIORATION CLINIQUE SIGNIFICATIVE OBSERVEE A 6-8 SEMAINES</p>
<ul>
        <li>Avant de conclure à l’inefficacité d’un ISRS, le traitement doit être prescrit à bonne dose pendant au moins 6-8 semaines</li>
    <li>En cas d’absence d’amélioration cliniquement significative, le moyen mnémotechnique OPACITE peut vous guider dans l’analyse des différents facteurs possiblement impliqués.</li>
</ul>

<div class="table-container">
    <table class="table-auto">
        <table>
<tbody>
<tr>
<td>
<strong>Observance</strong>
</td>
<td>
La premi&egrave;re cause de non efficacit&eacute; d&rsquo;un traitement et de ne pas le prendre (toujours y penser avant d&rsquo;envisager un changement de traitement)
</td>
</tr>
<tr>
<td>
<strong>Psychoth&eacute;rapie</strong>
</td>
<td>
R&eacute;examiner et soutenir le travail psychoth&eacute;rapeutique
Les traitements cognitivo-comportementale sont les approches les mieux &eacute;valu&eacute;s dans la litt&eacute;rature.
</td>
</tr>
<tr>
<td>
<strong>Association avec un autre trouble psychiatrique, toxique, organique</strong>
</td>
<td>
Recherche un trouble comorbides associ&eacute;s non pris en charge&nbsp;:
<br><u>Chez l&rsquo;enfant&nbsp;:</u> penser aux troubles du neurod&eacute;veloppement&nbsp;pass&eacute;s inaper&ccedil;us comme le TDAH, trouble de la communication, troubles des acquisitions et de la coordination, trouble des apprentissages sp&eacute;cifiques, efficience limite peuvent 1) &ecirc;tre associ&eacute;s &agrave; des difficult&eacute;s d&rsquo;apprentissage source d&rsquo;anxi&eacute;t&eacute; (+/- exprim&eacute;s) 2) &ecirc;tre associ&eacute;s &agrave; une moins bonne r&eacute;ponse de certaines approches psychoth&eacute;rapeutiques en cas de difficult&eacute;s de communication, penser aux alternatives (int&eacute;r&ecirc;t de privil&eacute;gier des m&eacute;diations corporelles, artistique ou sc&eacute;niques)
<br><u>Chez l&rsquo;adolescent</u>&nbsp;: penser &agrave; la prise en charge d&rsquo;un trouble du sommeil avec syndrome de retard de phase et aux troubles li&eacute;s &agrave; l&rsquo;usage de substance
</td>
</tr>
<tr>
<td>
<strong>Concentration</strong>
</td>
<td>
Quand cela est possible, doser le m&eacute;dicament, &eacute;voquer la possibilit&eacute; que le patient soit un m&eacute;taboliseur rapide/lent. Dans le cas de concentration basse ou nulle, il est possible de demander une analyse génétique des cytochromes au service de pharmacotoxicologie.
</td>
</tr>
<tr>
<td>
<strong>Identifier les facteurs environnementaux de maintien des sympt&ocirc;mes anxieux</strong>
</td>
<td>
R&eacute;&eacute;valuer certains aspects de psycho&eacute;ducation des troubles anxieux, en particulier recherche de contres-attitudes familiales quand les manifestations d&rsquo;anxi&eacute;t&eacute; se fond sur des modalit&eacute;s r&eacute;gressives ou contr&ocirc;lante pour l&rsquo;entourage.
</td>
</tr>
<tr>
<td>
<strong>Titration</strong>
</td>
<td>
Augmenter progressivement la dose de l&rsquo;ISRS en respectant les paliers jusqu’à la dose maximale tolérée si nécessaire (voir <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 6 }}">tableau des doses maximales d’ISRS</router-link>).
</td>
</tr>
<tr>
<td>
<strong>Essai d&rsquo;une autre mol&eacute;cule</strong>
</td>
<td>
<u>Changement de mol&eacute;cule</u>&nbsp;: En cas d&rsquo;&eacute;chec d&rsquo;un essai d&rsquo;un ISRS prescrit pendant 6-8 semaines, on prescrit un autre ISRS.
<br><u>Ajout d&rsquo;une mol&eacute;cule&nbsp;:</u> Aucune &eacute;tude n&rsquo;a montr&eacute; le b&eacute;n&eacute;fice d&rsquo;ajouter &agrave; un traitement par ISRS, un second ISRS, un antipsychotique atypique, ou un A3C dans la prise en charge d&rsquo;un trouble anxieux.
</td>
</tr>
</tbody>
</table>
    </table>
</div>


                </div>
                <div v-show="showPart(4)" title="PLACE DES ANTIHISTAMINIQUES">
                    <h2 class="title is-4">PLACE DES ANTIHISTAMINIQUES</h2>
<ul>
<li>
L&rsquo;hydroxyzine (Atarax&reg;) est indiqu&eacute; dans le traitement des insomnies d&rsquo;endormissement li&eacute;es &agrave; un &eacute;tat d&rsquo;hyper-&eacute;veil (vigilance accrue li&eacute;e &agrave; des manifestations anxieuses au coucher), apr&egrave;s &eacute;chec des mesures comportementales seules. La dur&eacute;e de traitement doit &ecirc;tre la plus courte possible.
</li>
<li>
Vous pouvez trouver dans <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 6, start: 1 }}">la page sur la prise en charge du trouble du sommeil</router-link> quelques conseils d&eacute;taill&eacute;s sur la prise en charge avant l&lsquo;instauration de l&rsquo;hydroxyzine (Atarax<sup>&reg;)</sup> chez l&rsquo;enfant et l&rsquo;adolescent, ainsi que sur l&rsquo;introduction, la surveillance, et les principaux effets ind&eacute;sirablesde ce traitement.
</li>
<li>
<em>Exemple de prescription de de l&rsquo;hydroxyzine (Atarax</em><sup><em>&reg;</em></sup><em>) dans le cadre d&rsquo;un trouble anxieux p&eacute;diatrique</em>
<ul>
<li>
Un ECG avec mesure du QTc doit &ecirc;tre r&eacute;alis&eacute; avant l&rsquo;instauration
</li>
<li>
Commencer par un comprim&eacute; de 25 mg ou 10-15 ml/j en sirop 2 mg/ml pour les enfants entre 6 et 10 ans
</li>
<li>
Attendre sept jours
</li>
<li>
Augmentation possible par pallier de 25 mg, dose maximale de 2 mg/kg chez l'enfant &le; 40 kg et de 100 mg chez l'enfant &gt; 40 kg
</li>
<li>
Traitement efficace entre 15 et 30 min, en cas de traitement en si besoin avec prises multiples d&eacute;lai d&rsquo;1h entre les prises
</li>
<li>
Evaluer le jeune 1 fois par semaine initialement puis espacer les consultations en fonction de l&rsquo;am&eacute;lioration clinique.
</li>
</ul>
</li>
</ul>

                </div>
                <div v-show="showPart(5)" title="PLACE DES BENZODIZEPINES">
                    <h2 class="title is-4">PLACE DES BENZODIAZEPINES</h2>
<p class="soustitre1">QUELS SONT LES INDICATIONS DES BENZODIAZEPINES DANS LE TROUBLE ANXIEUX PEDIATRIQUE ?</p>
<ul>
<li>
Les benzodiaz&eacute;pines ne sont pas recommand&eacute;es en 1<sup>er</sup> intention dans la prise en charge des troubles anxieux chez l’enfant et l’adolescent.
</li>
<li>
L&rsquo;usage des benzodiaz&eacute;pines doivent &ecirc;tre restreints &agrave; des situations exceptionnelles et ponctuelles :
<ul>
<li>
En cas de manifestations anxieuses s&eacute;v&egrave;res avec contre-indication d&rsquo;autres mol&eacute;cules (par exemple chez des patients avec une anorexie mentale et un risque cardiovasculaire contre-indiquant l&rsquo;usage de l&rsquo;hydroxyzine ou de neuroleptique)
</li>
<li>
Comme traitement de 1<sup>&egrave;re </sup>ligne des &eacute;pisodes catatoniques
</li>
<li>
Comme traitement de 2<sup>nd</sup> ligne dans le traitement d&rsquo;&eacute;pisodes maniaques r&eacute;sistants dans l&rsquo;attente de l&rsquo;efficacit&eacute; des thymor&eacute;gulateurs
</li>
<li>
Dans des indications&nbsp;neurologiques : &eacute;pilepsie, crises convulsives du nourrisson, mouvements anormaux dont l&rsquo;akathisie
</li>
</ul>
</li>
<li>
Le rapport b&eacute;n&eacute;fice-risque sera &eacute;valu&eacute; et la durée de traitement maintenu la plus brève possible (2-4 semaines au maximum). Leur utilisation prolong&eacute;e est contre-indiqu&eacute;e chez l&rsquo;enfant et l&rsquo;adolescent compte tenu du risque d&rsquo;accoutumance et d&rsquo;effets ind&eacute;sirables plus fr&eacute;quent &agrave; cet &acirc;ge.
</li>
</ul>
<p class="soustitre1">COMMENT INSTAURER CE TRAITEMENT ?</p>
<p>Le tableau suivant résume les principales caractéristiques des benzodiazépines ainsi que les AMM chez l’enfant</p>

<div class="table-container">
    <table class="table-auto">

<tbody>
<tr>
<th>
Mol&eacute;cule
</th>
<th>
Indications/AMM
</th>
<th>
PK
</th>
<th>
Posologie
</th>
<th>
Gal&eacute;nique
</th>
</tr>
<tr>
<td colspan="5" class="has-text-centered">
<strong>Benzodiaz&eacute;pine &agrave; demi vie longue (&ge; 20 heures)</strong>
</td>
</tr>
<tr>
<td>
Clorazepate dipotassique (Tranx&egrave;ne&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action&nbsp;: rapide
&frac12; vie&nbsp;: 30-150h
M&eacute;tabolite actif <sup>1</sup>
</td>
<td>
0,5 mg/kg/jour en 2 &agrave; 3 prises
</td>
<td>
G&eacute;lule de 5, 10 et 20 mg
Forme IM, IVL&nbsp;: 20mg/2 ml
</td>
</tr>
<tr>
<td>
Praz&eacute;pam (Lysanxia&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action&nbsp;: rapide
&frac12; vie&nbsp;: 30-150h
M&eacute;tabolite actif
</td>
<td>
&frac12; posologie adulte : de 5 &agrave; 15 mg/kg/jour
</td>
<td>
Cp de 10 et 40 mg
Gouttes &agrave; 0,5 mg
</td>
</tr>
<tr>
<td>
Bromazepam (Lexomil&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action&nbsp;:&nbsp;?
&frac12; vie&nbsp;: 20h
Pas de m&eacute;tabolite actif
</td>
<td>
&frac12; posologie adulte - 3 mg/jour
</td>
<td>
Cp de 6 mg quadri-s&eacute;cable
</td>
</tr>
<tr>
<td>
Diaz&eacute;pam (Valium&reg;)
</td>
<td>
AMM d&egrave;s la naissance&nbsp;: traitement et pr&eacute;vention des convulsion f&eacute;brile, &eacute;tat de mal &eacute;pileptique
</td>
<td>
D&eacute;lai d&rsquo;action&nbsp;: rapide
&frac12; vie&nbsp;: 40h
M&eacute;tabolite actif
</td>
<td>
0,12-0.8 mg/kg/jr en 2 &agrave; 3 prises
</td>
<td>
Solution buvable&nbsp;1% (3 gouttes=1mg)
Cp&nbsp;de 2, 5 et 10 mg
Forme IM, IVL&nbsp;:&nbsp;10mg/2ml
</td>
</tr>
<tr>
<td  colspan="5" class="has-text-centered">
<strong>Benzodiaz&eacute;pine &agrave; demi vie courte (&lt; 20 heures)</strong>
</td>
</tr>
<tr>
<td>
Alprazolam (Xanax&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action : interm&eacute;diaire
&frac12; vie&nbsp;: 10-20h
Pas de m&eacute;tabolite actif
</td>
<td>
&frac12; posologie adulte moyenne
0,5 mg/jour
</td>
<td>
Cp de 0,25 mg et 0,50 mg
</td>
</tr>
<tr>
<td>
Loraz&eacute;pam (Temesta&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action : interm&eacute;diaire
&frac12; vie&nbsp;: 10-20h
Pas de m&eacute;tabolite actif
</td>
<td>
&frac12; posologie adulte - 1-2 mg/jour <sup>2</sup>
</td>
<td>
Cp de 1 mg et 2,5 mg (s&eacute;cable)
</td>
</tr>
<tr>
<td>
Oxazepam (Seresta&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action : lent
&frac12; vie&nbsp;: 8h
Pas de m&eacute;tabolite actif
</td>
<td>
&frac12; posologie adulte &ndash; 10 - 30 mg/jour
</td>
<td>
Cp de 10 mg et 50 mg (s&eacute;cable)
</td>
</tr>
<tr>
<td>
Clonazepam (Rivotril&reg;)
</td>
<td>
Pas d&rsquo;AMM chez l&rsquo;enfant
</td>
<td>
D&eacute;lai d&rsquo;action : interm&eacute;diaire
&frac12; vie&nbsp;: 22-33h
</td>
<td>
0,5 mg/kg/j en 2 prises
</td>
<td>
Cp de 2 mg quadris&eacute;cable
Solution buvable&nbsp;0,25% (1 goutte = 0,1 mg)
Forme IV lente&nbsp;: 1 mg= 1 ml
</td>
</tr>
</tbody>
<tfoot>
    <tr>
        <td colspan="5">
            Note :
Données présentés sur la base des travaux (11, 12) et RCP des produits.
<br>1 En cas de métabolite actif il peut exister un risque de surdosage lié à l’accumulation du médicament ou de ses métabolites lors de prises répétées
<br>2 Dose plus importante dans le cadre de la prise en charge d’un état catatonique.
Midazolam, intra-nasal
        </td>
    </tr>
</tfoot>
    </table>
</div>

<p>Le tableau suivant résume les principales contre-indications et effets indésirables des benzodiazépines chez l’enfant et l’adolescent</p>
<div class="table-container">
    <table class="table-container">

<tbody>
<tr>
<td colspan="2">
<strong>Contre-indications</strong>
</td>
</tr>
<tr>
<td colspan="2">
<ul>
<li>
Insuffisance respiratoire grave
</li>
<li>
Myasth&eacute;nie
</li>
<li>
Syndrome d&rsquo;apn&eacute;e du sommeil
</li>
<li>
Insuffisance h&eacute;patique
</li>
<li>
Ne pas utiliser en cas de risque d&rsquo;addiction
</li>
</ul>
</td>
</tr>
<tr>
<td>
<strong>Effets ind&eacute;sirables</strong>
</td>
<td>
<strong>Remarque</strong>
</td>
</tr>
<tr>
<td>
S&eacute;dation, somnolence, vertige, ataxie
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
Confusion, d&eacute;sorientation, amn&eacute;sie ant&eacute;rograde, alt&egrave;re les performances intellectuelles
</td>
<td>
Effet sur les performances scolaires, majore le risque d&rsquo;accidents et de traumatismes physiques
</td>
</tr>
<tr>
<td>
Tol&eacute;rance, abus, risque de d&eacute;pendance
</td>
<td>
Risque plus important en cas de posologie &eacute;lev&eacute;e et de dur&eacute;es prolong&eacute;e (13). Les autres facteurs de risque sont&nbsp;: un trouble lié à l’usage de substance associé, recevoir un autre traitement psychotrope, recevoir un traitement par opioïde (14)
</td>
</tr>
<tr>
<td>
Effets paradoxaux&nbsp;: d&eacute;sinhibitions comportementales, perte de contr&ocirc;le, majoration de l&rsquo;anxi&eacute;t&eacute;, comportements agressifs, r&eacute;action de rages, cauchemars, hallucinations
</td>
<td>
Plus fr&eacute;quent chez l&rsquo;enfant, en particulier chez les enfants avec des troubles du neurod&eacute;veloppement
</td>
</tr>
<tr>
<td>
Effet rebond &agrave; l&rsquo;arr&ecirc;t
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
D&eacute;pression respiratoire
</td>
<td>
Rare en cas de prise per os.
Possible dans contextes d&rsquo;intoxication et prise IV (l&rsquo;antidote est la flumaz&eacute;nile)
</td>
</tr>
</tbody>

    </table>
</div>

                </div>
                <div v-show="showPart(6)" title="PLACE DES AUTRES MOLECULES A EFFET ANXIOLYTIQUE ">
                    <h2 class="title is-4">PLACE DES AUTRES MOLECULES A EFFET ANXIOLYTIQUE </h2>
<p class="soustitre1">INHIBITEUR DE LA RECPATURE DE LA SEROTONINE ET NORADRENERGIQUE (IRSNA)</p>
<button v-on:click="isHidden_place1 = !isHidden_place1">Voir le détail</button>
                    <div v-if="!isHidden_place1">
<ul>
        <li>Aux Etats-Unis les traitements par Inhibiteurs de la Recapture de la Sérotonine et Noradrénergique (IRSNA), en particulier la duloxétine (Cymbalta®) sont utilisés comme traitement de 2nd intention des troubles anxieux chez l’enfant et l’adolescent.</li>
    <li>Ils sont recommandés par l’Académie Américaine de Psychiatrie de l’Enfant et de l’Adolescent (AACAP) en cas d’échec d’un traitement par ISRS bien conduit pendant 6-8 semaines, après une titration à dose maximale de l’ISRS et l’optimisation de la psychothérapie (6, 7, 15).</li>
    <li>Toutefois, en Europe et aux Royaume-Unis, les IRSNA ne sont pas recommandés dans la prise en charge des troubles anxieux chez l’enfant et l’adolescent et quasiment pas utilisés en pratique clinique.</li>
    <li>Ces recommandations s’appuient sur le fait que la venlafaxine (Effexor®), un ISRNA, a montré un risque accru de conduite suicidaire comparé aux ISRS dans les essais cliniques conduits chez l’enfant et l’adolescent. La venlafaxine (Effexor®) est formellement contre-indiqué avant l’âge de 18 ans dans le traitement de l’épisode dépressif majeur caractérisé (16).</li>
</ul>
</div>
<p class="soustitre1">ANTIDEPRESSEUR TRICYCLIQUES (A3C)</p>
<button v-on:click="isHidden_place2 = !isHidden_place2">Voir le détail</button>
                    <div v-if="!isHidden_place2">
<ul>
        <li>Les antidépresseurs tricycliques sont utilisés historiquement dans la prise en charge de l’énurésie et manifestations anxieuses de l’enfant.</li>
    <li>Les antidépresseurs tricycliques ne sont plus recommandés chez l’enfant et l’adolescent compte tenu de leur effet cardiotoxique.</li>
    <li>Son utilisation relève de la prise en charge spécialisée. Vous pouvez trouver dans <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 1 }}">la page sur la prise en charge du trouble dépressif</router-link> quelques conseils détaillés sur la l’introduction, la surveillance, et les principaux effets indésirables des antidépresseurs tricycliques</li>
</ul>
</div>
<p class="soustitre1">ANTIPSYCHOTIQUES</p>
<button v-on:click="isHidden_place3 = !isHidden_place3">Voir le détail</button>
                    <div v-if="!isHidden_place3">
<ul>    <li>Les antipsychotiques sédatifs de la famille des phénothiazines sont fréquemment prescrits hors-AMM dans le traitement ponctuel des manifestations anxieuses de l’enfant et de l’adolescent.</li>
    <li>La chlorpromazine (Largactil®), cyamémazine (Tercian®), levomepromazine (Nozinan®), propericiazine (Neuleptil®) ont une AMM dans l’indication des troubles graves du comportement avec agitation et agressivité à partir de l’âge de 3 ans.</li>
    <li>Le tableau suivant résume les principales caractéristiques sur ces molécules. Vous pouvez trouver dans <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 5, start: 1 }}">la page consacrée à la prise en charge de troubles psychotiques</router-link> des conseils détaillés sur l’introduction, la surveillance, et les principaux effets indésirables des antipsychotiques.</li></ul>

<div class="table-container">
    <table class="table-auto">
<tbody>
<tr>
<th>
DCI
</th>
<th>
Posologies
</th>
<th>
Gal&eacute;nique
</th>
</tr>
<tr>
<td>
Chlorpromazine (Largactil<sup>&reg;</sup>)
</td>
<td>
1 &agrave; 5 mg/kg/j
</td>
<td>
3-6&nbsp;: solution buvable <sup>1</sup>
&ge; 6 ans&nbsp;: comprim&eacute;s
</td>
</tr>
<tr>
<td>
Cyam&eacute;mazine (Tercian<sup>&reg;</sup>)
</td>
<td>
1 &agrave; 4 mg/kg/j
</td>
<td>
3-6&nbsp;: solution buvable <sup>1</sup>
&ge; 6 ans&nbsp;: comprim&eacute;s
</td>
</tr>
<tr>
<td>
Levomepromazine (Nozinan<sup>&reg;</sup>)
</td>
<td>
0,5 &agrave; 2 mg/kg/jour
</td>
<td>
3-6&nbsp;: solution buvable <sup>1</sup>
&ge; 6 ans&nbsp;: comprim&eacute;s
</td>
</tr>
<tr>
<td>
Propericiazine (Neuleptil<sup>&reg;</sup>)
</td>
<td>
0,1 &agrave; 0,5 mg/kg/j
</td>
<td>
3-6&nbsp;: solution buvable <sup>2</sup>
&ge; 6 ans&nbsp;: comprim&eacute;s
</td>
</tr>
</tbody>
<tfoot>
    <tr><td colspan="3">Note :
<br>1) 1 mg = 1 ml
<br>2) Gouttes à 1% : 1 goutte = 0,25 mg Gouttes à 4% : 1 goutte = 1 mg
</td></tr>
</tfoot>
</table>
</div>
</div>
<p class="soustitre1">AGONISTE PARTIEL 5-HT<sub>1A</sub></p>
<button v-on:click="isHidden_place4 = !isHidden_place4">Voir le détail</button>
                    <div v-if="!isHidden_place4">
<ul>
        <li>La buspirone (Buspar®) est recommandée en 2ème intention dans la prise en charge des troubles anxieux chez l’enfant et l’adolescent par l’Académie Américaine de Psychiatrie de l’Enfant et de l’Adolescent (AACAP).</li>
    <li>Il existe des comprimés de 10mg (chez l’adulte, 10 à 40 mg/j). Chez l’enfant l’introduction se fait par palier de 5 mg, avec une augmentation maximale de 10 mg par semaine. La dose efficace se situe entre 15 et 20 mg/jour.</li>
    <li>Il n’existe à l’heure actuelle que très peu de donnée probante sur son efficacité et sa tolérance dans le trouble anxieux chez l’enfant et l’adolescent. Un risque de désinhibition et d’aggravation des comportements agressifs chez l’enfant.</li>
    <li>Ces traitements ne sont pas indiqués dans la prise en charge d’un trouble anxieux chez l’enfant et l’adolescent. Des études sont en cours pour évaluer le bénéfice de cette molécule chez des jeunes qui présentent des troubles graves du comportement et une déficience intellectuelle, en particulier comme alternative au traitement antipsychotique.</li>
</ul>
</div>
<p class="soustitre1">AGONISTE &alpha;<sub>2A</sub> ADRENERGIQUE</p>
<button v-on:click="isHidden_place5 = !isHidden_place5">Voir le détail</button>
                    <div v-if="!isHidden_place5">
<ul>
        <li>La clonidine (Catapressan®) et la guanfacine (Estulic®) sont des traitements agonistes alpha-2 adrénergique utilisés pour diminuer les symptômes d’hypervigilance, mais n’agissent pas spécifiquement sur l’anxiété.</li>
    <li>Il n’existe à l’heure actuelle que très peu de donnée probante sur son efficacité et sa tolérance dans le trouble anxieux chez l’enfant et l’adolescent.</li>
    <li>Ces traitements ne sont pas indiqués dans la prise en charge d’un trouble anxieux chez l’enfant et l’adolescent. Des études sont en cours pour évaluer le bénéfice de cette molécule chez des jeunes qui présentent des états de stress post-traumatique.</li>
</ul>
</div>
                </div>

                <div v-show="showPart(7)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Fiche de suivi de l’efficacité clinique et de la tolérance</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique du trouble anxieux pédiatrique</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur le traitement médicamenteux du trouble anxieux pédiatrique pour enseignement</a></li>
                    </ul>

                    <p class="py-2 soustitre2">Liste des recommandations utilisées pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">

                        <tbody>
<tr>
<th>
Pays
</th>
<th>
Soci&eacute;t&eacute;s savantes
</th>
<th>
Titre
</th>
</tr>
<tr>
<td>
Canada
</td>
<td>
ADAC 2014 (mis &agrave; jour en 2018)
</td>
<td>
Position statement on use of selective serotonin reuptake inhibitor medications for the treatment of child and adolescent mental illness (17)
<br><a href="https://cpnp.org/guideline/external/anxiety" target="_blank">https://cpnp.org/guideline/external/anxiety</a>
</td>
</tr>
<tr>
<td>
Royaume-Uni
</td>
<td>
NICE 2014
</td>
<td>
Quality standard on anxiety disorders
<br><a href="https://www.nice.org.uk/guidance/qs53" target="_blank">https://www.nice.org.uk/guidance/qs53</a>
</td>
</tr>
<tr>
<td>
USA
</td>
<td>
AACAP 2017
</td>
<td>
Practice Parameter for the Assessment and Treatment of Children and Adolescents With Anxiety Disorders (15)
<br><a href="https://www.jaacap.org/article/S0890-8567(09)61838-4/pdf" target="_blank">https://www.jaacap.org/article/S0890-8567(09)61838-4/pdf</a>
</td>
</tr>
<tr>
<td>&nbsp;</td>
<td>
AAP 2018
</td>
<td>
Guidelines for pediatric providers for recognizing and referring children with posttraumatic stress disorder (2018)
<br><a href="https://pedsinreview.aappublications.org/content/39/2/68" target="_blank">https://pedsinreview.aappublications.org/content/39/2/68</a>
</td>
</tr>
<tr>
<td>
Australie
</td>
<td>
CEH
</td>
<td><a href="https://www.ranzcp.org/files/resources/college_statements/clinician/cpg/ranzcp-anxiety-clinical-practice-guidelines.aspx" target="_blank">https://www.ranzcp.org/files/resources/college_statements/clinician/cpg/ranzcp-anxiety-clinical-practice-guidelines.aspx</a></td>
</tr>
</tbody>
<tfoot>
    <tr>
        <td colspan="3">AACAP: American Academy for Child and Adolescent Psychiatry, AAP: American Academy of Pediatrics, ADAC: Anxiety Disorders Association, CEH: Center for Emotional Health, NICE: National Institute for Health and Care Excellence</td>
    </tr>
</tfoot>
</table>
                    </div>
                    <p class="soustitre2">Autres références :</p>
                    <ul>
                        <li>1. Polanczyk GV, Salum GA, Sugaya LS, Caye A, Rohde LA. Annual research review: A meta-analysis of the worldwide prevalence of mental disorders in children and adolescents. J Child Psychol Psychiatry. 2015;56(3):345-65.</li>
<li>2. Woodward LJ, Fergusson DM. Life course outcomes of young people with anxiety disorders in adolescence. J Am Acad Child Adolesc Psychiatry. 2001;40(9):1086-93.</li>
<li>3. Haute Autorit&eacute; de Sant&eacute; H. Avis de la Commission de la Transparence : hydroxyzine (chlorhydrate). In: HAS-Direction de l'EValuation M&eacute;dicale EedSP, editor. 2017.</li>
<li>4. Strawn JR, Prakash A, Zhang Q, Pangallo BA, Stroud CE, Cai N, et al. A randomized, placebo-controlled study of duloxetine for the treatment of children and adolescents with generalized anxiety disorder. J Am Acad Child Adolesc Psychiatry. 2015;54(4):283-93.</li>
<li>5. Strawn JR, Welge JA, Wehry AM, Keeshin B, Rynn MA. Efficacy and tolerability of antidepressants in pediatric anxiety disorders: a systematic review and meta-analysis. Depression and anxiety. 2015;32(3):149-57.</li>
<li>6. Wehry AM, Beesdo-Baum K, Hennelly MM, Connolly SD, Strawn JR. Assessment and treatment of anxiety disorders in children and adolescents. Current psychiatry reports. 2015;17(7):52.</li>
<li>7. Hussain FS, Dobson ET, Strawn JR. Pharmacologic Treatment of Pediatric Anxiety Disorders. Curr Treat Options Psychiatry. 2016;3(2):151-60.</li>
<li>8. Walkup JT, Albano AM, Piacentini J, Birmaher B, Compton SN, Sherrill JT, et al. Cognitive behavioral therapy, sertraline, or a combination in childhood anxiety. The New England journal of medicine. 2008;359(26):2753-66.</li>
<li>9. RUPP group. Fluvoxamine for the treatment of anxiety disorders in children and adolescents. The Research Unit on Pediatric Psychopharmacology Anxiety Study Group. The New England journal of medicine. 2001;344(17):1279-85.</li>
<li>10. Rynn M, Puliafico A, Heleniak C, Rikhi P, Ghalib K, Vidair H. Advances in pharmacotherapy for pediatric anxiety disorders. Depression and anxiety. 2011;28(1):76-87.</li>
<li>11. Bonneau I, Omer P. R&egrave;gles de prescription des principaux psychotropes chez l&rsquo;enfant et l&rsquo;adolescent de moins de 18 ans. 2017 [file:///C:/Users/xavie/Downloads/Guide_traitements_psychotropes_enfants_V2.627.pdf].</li>
<li>12. Witek MW, Rojas V, Alonso C, Minami H, Silva RR. Review of benozdiazepine use in children and adolescents. Psychiatric Quarterly. 2005;76:283-96.</li>
<li>13. Patel DR, Feucht C, Brown K, Ramsay J. Pharmacological treatment of anxiety disorders in children and adolescents: a review for practitioners. Transl Pediatr. 2018;7(1):23-35.</li>
<li>14. Bushnell GA, Crystal S, Olfson M. Prescription Benzodiazepine Use in Privately Insured U.S. Children and Adolescents. American journal of preventive medicine. 2019;57(6):775-85.</li>
<li>15. Connolly SD, Bernstein GA. Practice Parameter for the Assessment and Treatment of Children and Adolescents With Anxiety Disorders. Journal of the American Academy of Child &amp; Adolescent Psychiatry. 2007;46(2):267-83.</li>
<li>16. Benarous X, Cravero C, Cohen D. Prescription d'antid&eacute;presseurs chez les enfants et les adolescents. EMC - Psychiatrie. 2018;15(3):1-11.</li>
<li>17. Korczak DJ. Use of selective serotonin reuptake inhibitor medications for the treatment of child and adolescent mental illness. Paediatrics &amp; child health. 2013;18(9):487-91.</li>
                    </ul>

                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleAnxieux',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            dl_files: {},
            //showpart: 1,
            rep: 'anxieux',
            maintitle: 'Troubles anxieux',
            isHidden_place1: true,
            isHidden_place2: true,
            isHidden_place3: true,
            isHidden_place4: true,
            isHidden_place5: true,
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'ISRS', 'icon': 'fas fa-capsules' },
                { 'id': 4, 'title': 'Anti-H1', 'icon': 'fas fa-capsules' },
                { 'id': 5, 'title': 'BZD', 'icon': 'fas fa-capsules' },
                { 'id': 6, 'title': 'Autres trt.', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {
    },
    destroyed() {
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>