<template>
    <div id="adhd">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Définition</p>
                    <ul>
                        <li>Le trouble déficit de l’attention (TDAH) est un trouble neurodéveloppemental lié à un défaut de maturation des capacités d’inhibition de l’enfant par rapport aux compétences attendues pour l’âge.</li>
                        <li>Il existe généralement une altération non seulement des capacités d’inhibition mais aussi d’autres fonctions exécutives associées.</li>
                    </ul>
                    <p class="soustitre2">Diagnostic</p>
                    <ul>
                        <li>Il existe trois groupes de sympt&ocirc;mes :
                            <ul>
                                <li>
                                    Inattention = Incapacité à se concentrer sur une t&acirc;che plus de quelques minutes, grande distractibilité
                                </li>
                                <li>
                                    Impulsivité = motrice, verbale et cognitive
                                </li>
                                <li>
                                    Hyperactivité = motrice ou hyperkinésie sous forme d'une agitation motrice non contr&ocirc;lée et incessante (surtout avant 12 ans)
                                </li>
                            </ul>
                        </li>
                        <li class="my-4">Par définition il existe un caractère durable, ancien, +/- permanent des symptômes associés à un retentissement fonctionnel significatif de ces symptômes :
                            <ul>
                                <li>
                                    Présents depuis &gt; 6 mois
                                </li>
                                <li>
                                    D'apparition précoce : &lt;7 ans (CIM-10) ou &lt;12ans (DSM-5)
                                </li>
                                <li>
                                    Présents dans toutes les situations (famille, école, extrascolaires)
                                </li>
                                <li>
                                    Entra&icirc;nant un décalage avec les enfants du m&ecirc;me &acirc;ge =&gt; souffrance subjective
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Prévalence</p>
                    <ul>
                        <li>La prévalence du TDAH dans une population d’âge scolaire est estimée à 3,5-5,6%.</li>
                        <li>Le TDAH touche 2-3 garçons pour 1 fille.</li>
                        <li>Chez l’adulte, la prévalence serait de 2.5%.</li>
                        <li>On estime que 10% des enfants avec TDAH sont traités par un médicament en France (contre 50-70% aux Etats-Unis).</li>
                    </ul>
                    <p class="soustitre2">Diagnostics différentiels</p>
                    <ul>
                        <li>Il peut être difficile de différencier le TDAH des autres causes d’instabilité psychomotrice de l’enfant.
                            <div class="table-container">
                                <table class="table-auto">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <p>L'instabilité psychomotrice</p>
                                            </th>
                                            <th>
                                                <p>Le TDAH</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Sympt&ocirc;me non spécifique présent dans de nombreuses situations cliniques</p>
                                            </td>
                                            <td>
                                                <p>Diagnostic psychiatrique (trouble hyperkinétique dans CIM-10)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Signe d'une contrainte développementale interne ou externe</p>
                                            </td>
                                            <td>
                                                <p>Trouble neurodéveloppemental</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Peut-&ecirc;tre aigue ou chronique</p>
                                            </td>
                                            <td>
                                                <p>Forcément chronique avec 1ers signes typiquement à l’âge de la marche, mais formes avec inattention prédominante souvent repérées plus tardivement</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            Le diagnostic repose donc une évaluation consciencieuse :
                            <ul>
                                <li>De l’histoire développementale au cours de l’anamnèse</li>
                                <li>De l’état clinique actuel</li>
                                <li>Du retentissement fonctionnel dans les différents domaines de la vie de l’enfant</li>
                                <li>Des résultats des éventuels évaluations complémentaires associées</li>
                            </ul>
                        </li>
                        <li>Le tableau suivant donne quelques exemples de pièges diagnostiques. Il faut garder en tête que les diagnostics différentiels du TDAH peuvent aussi être d’authentiques comorbidités.
                            <div class="table-container">
                                <table class="table-auto">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <p><strong>Inquiétudes depuis</strong></p>
                                            </th>
                                            <th>
                                                <p><strong>O&ugrave; ?</strong></p>
                                                <p><strong>A l'école</strong></p>
                                            </th>
                                            <th>
                                                <p><strong>O&ugrave; ?</strong></p>
                                                <p><strong>A la maison</strong></p>
                                            </th>
                                            <th>
                                                <p><strong>Amélioration pendant les vacances ?</strong></p>
                                            </th>
                                            <th>
                                                <p><strong>Bilan WISC-V</strong></p>
                                            </th>
                                            <th>
                                                <p><strong>DIAGNOSTIC ?</strong></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Age de marcher</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>IMT, IVT (code) &darr;&darr;</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('TDAH', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Age de marcher</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>IVS &darr;</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Trouble Acquisitions Coordinations', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Maternelle/ &acirc;ge scolaire</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>IVS &darr;</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Dyspraxie visuo-spatiale', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Age scolaire</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>ICV &darr;&darr;</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Trouble du langage oral', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Age scolaire</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>Pas d'anomalie ou variabilité</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Trouble du sommeil', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Périnatal/ contexte difficultés d'interaction</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>Pas d'anomalie</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Contexte de carence éducative', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Age scolaire</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Pas d'anomalie ou échec/inhibition</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Anxiété scolaire', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Dès l'entrée en collectivité</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>Pas spécifique (IVS parfois &darr;)</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Trouble du Spectre de l\'Autisme', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Maternelle &ndash; &acirc;ge scolaire</p>
                                            </td>
                                            <td>
                                                <p>Non</p>
                                            </td>
                                            <td>
                                                <p>Pas forcément</p>
                                            </td>
                                            <td>
                                                <p>Oui</p>
                                            </td>
                                            <td>
                                                <p>Retard homogène&lt; 70</p>
                                            </td>
                                            <td>
                                                <button @click="reveal('Trouble du Développement Intellectuel (retard léger)', $event)">voir réponse</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    <p class="soustitre1">QUEL PATIENT TRAITER PAR UN PSYCHOSTIMULANT DANS LE CADRE D'UN TDAH&nbsp;?</p>
                    <ul>
                        <li>Tous les jeunes avec TDAH ne nécessitent pas de traitement. L'utilisation d’un traitement psychostimulant chez un enfant de plus de 6 ans ou un adolescent avec TDAH est réservée aux cas suivants :
                            <ul>
                                <li>Patients avec symptômes et retentissement sévère du TDAH</li>
                                <li>Patients avec symptômes et retentissement modéré du TDAH si les interventions non médicamenteuses ne sont pas suffisamment efficaces ou sont refusées</li>
                            </ul>
                        </li>
                        <li class="py-4">Il s’agit toujours d’un traitement multimodal qui inclus une intervention psychologique et éducative. </li>
                    </ul>
                    <p class="soustitre2">Situations particulières liés aux comorbidités psychiatriques :&nbsp;</p>
                    <ul>
                        <li>Près de 50-90% des jeunes avec TDAH ont aussi au moins un autre trouble psychiatrique comorbide, et 50% ont au moins 2 troubles comorbides.</li>
                        <li>L’existence d’un trouble psychiatrique associé peut influencer les stratégies thérapeutiques.</li>
                    </ul>
                    <img width="800" alt="toubles" usemap="#tdah-map-troubles" src="/pathologie/tdah/troubles.png">
                    <map name="tdah-map-troubles">
                        <area shape="rect" coords="133, 102, 259, 150" href="#" alt="anxiete" v-on:click.prevent='sw("toggle_anx")' />
                        <area shape="rect" coords="110, 190, 240, 235" href="#" alt="depression" v-on:click.prevent='sw("toggle_dep")' />
                        <area shape="rect" coords="116, 278, 240, 323" href="#" alt="bipolaire" v-on:click.prevent='sw("toggle_bipo")' />
                        <area shape="rect" coords="255, 344, 364, 396" href="#" alt="tics" v-on:click.prevent='sw("toggle_tics")' />
                        <area shape="rect" coords="410, 349, 528, 399" href="#" alt="autisme" v-on:click.prevent='sw("toggle_aut")' />
                        <area shape="rect" coords="555, 300, 678, 352" href="#" alt="rm" v-on:click.prevent='sw("toggle_rm")' />
                        <area shape="rect" coords="570, 197, 689, 248" href="#" alt="substance" v-on:click.prevent='sw("toggle_subst")' />
                        <area shape="rect" coords="507, 93, 630, 146" href="#" alt="conduite" v-on:click.prevent='sw("toggle_cond")' />
                        <area shape="rect" coords="317, 58, 436, 108" href="#" alt="irritabilité" title="irritabilité" v-on:click.prevent='sw("toggle_irrit")' />
                    </map>
                    <div id="map_detail">
                        <div v-bind:class="[window.width>900 ? 'block' : 'is-hidden']"><i>cliquez sur une bulle ci-dessus pour avoir des conseils sur la prise en charge</i></div>
                        <div class="bloc_toggle" v-show="troubles.toggle_anx">
                            <p class="soustitre2">Jeune avec troubles anxieux</p>
                            <ul>
                                <li>Il convient de traiter le trouble psychiatrique le plus gênant en priorité.</li>
                                <li>Il est possible d’observer une majoration ou une diminution des symptômes anxieux sous psychostimulant.</li>
                                <li>Il faut commencer le psychostimulant à faible dose et augmenter très progressivement. </li>
                                <li>Si le méthylphénidate est mal toléré, on peut discuter la mise en place d’atomoxétine. Dans ce cas il faudra faire attention à l’effet inhibiteur de l’atomoxétine sur le cytochrome 2D6 qui joue un rôle dans le métabolisme de la fluoxétine et de la paroxétine (<a :href="dl_files_external.cytochromes" target="_blank">voir fiche sur le métabolisme des cytochromes).</a></li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_dep">
                            <p class="soustitre2">Jeunes avec troubles dépressifs</p>
                            <ul>
                                <li>En cas d’Episode Dépressif Majeur d’intensité léger ou de trouble dépressif persistant, il est recommandé de commencer par le traitement médicamenteux du TDAH.</li>
                                <li>En cas d’Episode Dépressif Majeur d’intensité modéré ou sévère, il est recommandé de d’abord traiter la dépression.</li>
                                <li>Dans tous les cas il faut être vigilant dans l’évaluation du risque suicidaire. Les psychostimulant peuvent mimer et/ou aggraver des symptômes thymiques comme une humeur dysphorique, des difficultés de sommeil et de l’appétit (<router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 5, start: 4 }}">voir effets indésirables des psychostimulants</router-link>).</li>
                                <li>Les traitements antidépresseurs par ISRS peuvent être associés au psychostimulant.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_bipo">
                            <p class="soustitre2">Jeune avec trouble bipolaire</p>
                            <ul>
                                <li>En cas de survenue d’un épisode maniaque chez un jeune avec un TDAH déjà traité par psychostimulant : mise en place d’un thymorégulateur et arrêt du psychostimulant. La remise en place du traitement par psychostimulant sera discutée une fois l’humeur stabilisée.</li>
                                <li>En cas de survenue de symptômes d’activation comportementale chez un jeune qui vient d’être traité par psychostimulant sans antécédent de trouble bipolaire connu : discuter la possibilité d’un virage de l’humeur, la conduite à tenir est comparable aux symptômes d’allure maniaque retrouvés sous traitement ISRS (voir fiche <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 4 }}">effets indésirables des antidépresseurs)</router-link>.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_irrit">
                            <p class="soustitre2">Jeune avec irritabilité chronique et comportement agressifs réactifs</p>
                            <ul>
                                <li>Le traitement par psychostimulant a montré son efficacité dans la réduction des comportements agressifs réactifs chez les jeunes avec un TDAH.</li>
                                <li>Certains de ces jeunes vont répondre aux critères du Trouble Disruptif avec Dysrégulation Emotionnelle, à savoir une humeur irritable de façon persistante associée à des crises de colères répétées (≥ 3 fois/s) disproportionnées par rapport à l’âge de l’enfant sur les 12 derniers mois.</li>
                                <li>Dans les formes sévères, certains auteurs recommandent l’utilisation d’antipsychotiques atypiques à faible dose.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_cond">
                            <p class="soustitre2">Jeune avec troubles de conduites</p>
                            <ul>
                                <li>L’existence de conduites agressives proactives (non-impulsives et non liées à un facteur déclenchant émotionnel) et de comportements transgressifs sévères isolés ne relèvent pas d’un traitement médicamenteux.</li>
                                <li>La mise en place d’interventions psychosociales sous formes de programmes parentaux structurés (programmes de renforcement des habiletés parentales, résistance non-violente, thérapie multi-systémique) est recommandé. Le traitement doit se focaliser sur le changement de comportement du jeune, en tant que stratégie relationnelle déviante s’inscrivant généralement dans une histoire de vie marquée par la négligence éducative, affective et des contre-attitudes des adultes.</li>
                                <li>Il s’agit parfois de situations d’urgence pour la sécurité de l’enfant sans être une urgence psychiatrique ou médicale en tant que telle.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_subst">
                            <p class="soustitre2">Jeune avec abus de substance</p>
                            <ul>
                                <li>Le traitement par psychostimulant n’induit pas d’abus de substance. Les jeunes avec TDAH traités par psychostimulant ont un risque réduit de développer un abus de substance à l’âge adulte comparé aux jeunes avec TDAH non traité par psychostimulant.</li>
                                <li>En cas de réelle comorbidité entre TDAH et trouble lié à l’usage de substance, il est recommandé de traiter en priorité le trouble lié à l’usage de substance. </li>
                                <li>S’il existe un risque un mésusage important du psychostimulant, on peut préférer l’atomoxétine au méthylphénidate. En tout cas, il faut éviter la forme à libération immédiate qui est la plus souvent détournée.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_tics">
                            <p class="soustitre2">Jeunes avec tics</p>
                            <ul>
                                <li>La présence de tics n’est pas une contre-indication au méthylphénidate.</li>
                                <li>Le traitement du TDAH ne cause pas des tics mais peut augmenter ou réduire des tics existants.</li>
                                <li>En cas de tic gênant sous psychostimulant malgré la diminution de la dose il y a trois options : changer pour une autre forme de méthylphénidate, certaines formes présentent un profil différent d’effets indésirables sans que l’on sache pourquoi (1ère intention), prescrire de l’atomoxétine à la place du méthylphénidate (2ème intention), ajouter à la dose de méthylphénidate un traitement par antipsychotique atypique à faible dose au coucher (par exemple Aripiprazole 2,5mg/j) (3ème intention).</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_aut">
                            <p class="soustitre2">Jeunes avec autisme</p>
                            <ul>
                                <li>Les effets des psychostimulants sont globalement moins importants chez les jeunes avec autisme et TDAH comparés aux autres jeunes avec un TDAH sans TSA.</li>
                                <li>Certains effets indésirables (ex : humeur dysphorique) sont plus fréquents chez les jeunes avec TSA et TDAH traités par psychostimulant. On peut en particulier observer une augmentation des comportements ritualisés et des stéréotypies.</li>
                                <li>La titration doit être faite à un rythme plus lent en suivant les mêmes propositions thérapeutiques.</li>
                            </ul>
                        </div>
                        <div class="bloc_toggle" v-show="troubles.toggle_rm">
                            <p class="soustitre2">Jeunes avec déficit intellectuel</p>
                            <ul>
                                <li>Les effets des psychostimulant sont globalement moins importants chez les jeunes avec Trouble du Développement Intellectuel (TDI) comparé aux autres jeunes avec un TDAH.</li>
                                <li>Certains effets indésirables (ex : humeur dysphorique) sont plus fréquents chez les jeunes avec TDI traités par psychostimulant.</li>
                                <li>La titration doit être faite à un rythme plus lent en suivant les mêmes propositions thérapeutiques.</li>
                            </ul>
                        </div>
                    </div>
                    <p class="soustitre1">QUELLES SONT LES MOLECULES AYANT L'AUTORISATION DE MISE SUR LE MARCHE EN FRANCE ?&nbsp;</p>
                    <p>Le méthylphénidate (MPH) et l’atomoxétine (ATX) ont l’AMM en France chez l’enfant et l’adolescent. La commercialisation de l’atomoxétine n'a pas lieu en France en raison d'une Amélioration du Service Médical Rendu (ASMR) jugée faible. L’atomoxétine est disponible en Autorisation Temporaire d’Utilisation.</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Dénomination Commune Internationale
                                    </th>
                                    <th>
                                        Composition
                                    </th>
                                    <th>
                                        Indication
                                    </th>
                                </tr>
                                <tr>
                                    <td class="subth" colspan="3">
                                        <p align="center"><strong>MEDICAMENTS AYANT L’AMM ET DISPONIBLES EN FRANCE</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>RITALINE LI &reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>MPH 100% LI/0% LP</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>RITALINE LP&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>MPH 0% LI/100% LP</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>QUASYM LP&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>MPH 30% LI/ 70% LP</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>CONCERTA LP&reg; et son générique (MPH Mylan)</strong></p>
                                    </td>
                                    <td>
                                        <p>MPH 22% LI/78% LP</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>MEDIKINET&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>MPH 50% LI/50% LP</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">MPH= Méthylphénidate, LI= Libération immédiate, LP= Libération prolongée</td>
                            </tfoot>
                        </table>
                    </div>
                    <p>Le tableau suivant présente les autres traitements mentionnés dans les recommandations internationales.</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Dénomination Commune Internationale
                                    </th>
                                    <th>
                                        Composition
                                    </th>
                                    <th>
                                        Indication
                                    </th>
                                </tr>
                                <tr>
                                    <td class="subth" colspan="3">
                                        <p align="center"><strong>TRAITEMENTS MENTIONNES DANS LES RECOMMANDATIONS INTERNATIONALES</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>STRATTERA&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>Atomoxétine</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>GUANFACINE&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>Agoniste &alpha;-2 adrénergique</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>CLONIDINE&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>Agoniste &alpha;-2 adrénergique</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>VIVANSE&reg;</strong></p>
                                    </td>
                                    <td>
                                        <p>Lisdexamphétamine</p>
                                    </td>
                                    <td>
                                        <p><strong>TDAH</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES">
                    <h2 class="title is-4">BENEFICES CLINIQUES</h2>
                    <ul>
                        <li>Les psychostimulants font partie des psychotropes pour lesquels l’effet du médicament est considéré comme le plus efficaces sur les symptômes cibles. La taille d’effet est de 1.0 pour le méthylphénidate, 0.7 pour l’atomoxétine, et 0.7 pour les agonistes alpha-2.
                        <li>Le nombre de sujets à traiter pour avoir un jeune en rémission complète est entre 2 et 5.</li>
                        <li>Les traitements médicamenteux du TDAH ont tous un effet suspensif, c’est-à-dire qu’ils sont efficaces uniquement sur la période durant laquelle ils sont pris.</li>
                        <li>Les facteurs associés à une bonne réponse au traitement sont :
                            <ul>
                                <li>L’absence de symptômes dépressifs chez les parents</li>
                                <li>Un TDAH de sévérité modérée</li>
                                <li>L’absence de trouble du développement intellectuel</li>
                                <li>L’absence de trouble anxieux associé</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES</h2>
                    <ul>
                        <li>La plupart des effets indésirables sont dose-dépendants.</li>
                        <li>Il n’y a pas d’effet secondaires dans 24% des cas.</li>
                        <li>Le tableau suivant décrit les principaux effets indésirables des psychostimulants et autres traitements du TDAH</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        MPH
                                    </th>
                                    <th>
                                        ATX
                                    </th>
                                    <th>
                                        A2A
                                    </th>
                                    <th>
                                        DEX
                                    </th>
                                </tr>
                                <tr>
                                    <td class="subth" colspan="5">
                                        <p align="left">Effets indésirables</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Insomnie d'endormissement</p>
                                    </td>
                                    <td>
                                        <p>+++ <sup>(1)</sup></p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Somnolence</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Diminution de l'appétit</p>
                                    </td>
                                    <td>
                                        <p>++ <sup>(1)</sup></p>
                                    </td>
                                    <td>
                                        <p>+++</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Perte de poids</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Ralentissement de la croissance</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Céphalées</p>
                                    </td>
                                    <td>
                                        <p>++ <sup>(2)</sup></p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Bouche sèche</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Nausée, vomissement</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>+++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Douleur abdominale</p>
                                    </td>
                                    <td>
                                        <p>++ <sup>(2)</sup></p>
                                    </td>
                                    <td>
                                        <p>+++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Constipation</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Dysphorie</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Rebond d'irritabilité</p>
                                    </td>
                                    <td>
                                        <p>+++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Elévation de la PA/ FC</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+ <sup>(3)</sup></p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Diminution de la PA/ FC</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>+ <sup>(4)</sup></p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Exacerbation de tics</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sensation vertigineuse</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Réactions cutanée</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>++</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Trouble sexuel</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>+</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="5">(1) Surtout forme LP, (2) surtout forme LI, (3) non sauf si arr&ecirc;t brutal, (4) hypotension orthostatique
                                    <br>&nbsp;+++ : &gt; 10% <br> ++ : 1-10% <br> + : 0.1-1% <br>- : 0.01-0.1% ou pas rapporté<br>PA= Pression Artérielle, FC= Fréquence Cardiaque</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS AVANT LA PRESCRIPTION</h2>
                    <p class="soustitre1">RECUEIL DE L'ADHESION DU JEUNE ET SA FAMILLE</p>
                    <p class="soustitre2">Adhésion du jeune</p>
                    <ul>
                        <li>La prise d’un médicament au quotidien n’est pas un acte anodin pour un enfant.</li>
                        <li>Il faut expliquer en fonction de l’âge l’utilité du traitement, ici « il va te permettre de te concentrer plus longtemps et d’être plus attentif ».</li>
                        <li>Il est important de rappeler que les parents sont également d’accord et partie prenante.</li>
                        <li>Il conviendra de discuter de ces questions avec l’enfant.</li>
                        <li>On pourra télécharger <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 2, start: 9 }}">l’une des deux fiches d’information du jeune et de famille</router-link> sur le TDAH.</li>
                    </ul>
                    <p class="soustitre2">Adhésion des parents</p>
                    <ul>
                        <li>Il peut exister des réticences, parfois non rationnelles, parfois liées à l’expérience personnelle ou familiale au traitement.</li>
                        <li>Enfin, les parents ont désormais accès à bon nombre d’informations sur internet, il faut donc répondre par avance aux questions concernant l’efficacité attendue, la tolérance, les possibles effets indésirables.</li>
                        <li>On pourra télécharger <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 2, start: 9 }}">l’une des deux fiches d’information du jeune et de famille</router-link> sur le traitement médicamenteux du TDAH.</li>
                    </ul>
                    <p class="soustitre1">EVALUATION CLINIQUE</p>
                    <ul>
                        <li>L’instauration de traitement pour le TDAH (psychostimulant ou autres) nécessite de faire une évaluation clinique détaillée sur période suffisamment longue qui comprend au minimum : l’histoire naturelle des symptômes, la comorbidité psychiatrique et éventuellement somatique, recherche des antécédents familiaux, le fonctionnement scolaire et l’existence éventuelle de troubles des apprentissages et d’évaluer l’environnement familial et le contexte social.
                        <li>Les facteurs à prendre en compte pour évaluer le retentissement et prendre la décision d’instaurer un traitement médicamenteux :
                            <ul>
                                <li>Résultats scolaires</li>
                                <li>Estime de soi et souffrance personnelle liée aux symptômes</li>
                                <li>Interactions sociales et relations</li>
                                <li>Trouble du comportement</li>
                                <li>Développement d’une comorbidité psychiatrique</li>
                            </ul>
                        <li>Une fiche <a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l’instauration d’un traitement pour le TDAH</a> est disponible pour le clinicien pour résumer ces points.</li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <p class="soustitre1">QUEL TRAITEMENT POUR LE TDAH DOIT-ETRE PRIVILEGIE ?</p>
                    <ul>
                        <li>Evaluation détaillée, en particulier tenir compte :
                            <ul>
                                <li>Troubles psychiatriques et neurodéveloppementaux comorbides (trouble anxieux, abus de substance, épilepsie, tics…)</li>
                                <li>Profil d’effets indésirables</li>
                                <li>Problèmes éventuels de compliance, d’administration de prises multiples</li>
                                <li>Risque de détournement ou d’abus</li>
                                <li>Préférence du jeune et de sa famille</li>
                            </ul>
                        </li>
                        <li>Le traitement de 1ère intention est le MPH en monothérapie.</li>
                        <li>Généralement une titration est faite avec la forme LI du MPH avec si nécessaire un relais vers la forme LP.</li>
                        <li>Le choix de la molécule peut se faire selon la ½ vie.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        DCI
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Mécanisme
                                    </th>
                                    <th>
                                        Dose initiale
                                    </th>
                                    <th>
                                        Titration
                                    </th>
                                    <th>
                                        Dose max
                                    </th>
                                    <th>
                                        Doses disponibles
                                    </th>
                                </tr>
                                <tr>
                                    <td class="subth" colspan="7">
                                        <p>Autorisation en France</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>RITALINE LI &reg;</p>
                                    </td>
                                    <td>
                                        <p>MPH</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 20-60 min
                                            <br>&nbsp;Efficace 1-4 h</p>
                                        <p>100% LI &ndash; 0% LP</p>
                                    </td>
                                    <td>
                                        <p>5 mg matin et 5 mg midi</p>
                                        <p>(0.3 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>x 2 doses initiales tous les 3-7 jours</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j (1.2 mg/kg/j)*</p>
                                    </td>
                                    <td>
                                        <p>5, 10, 20 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>RITALINE LP&reg;</p>
                                    </td>
                                    <td>
                                        <p>MPH</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 20-60 min
                                            <br>&nbsp;Efficace 6-8 h</p>
                                        <p>0% LI &ndash; 100% LP</p>
                                    </td>
                                    <td>
                                        <p>20 mg</p>
                                    </td>
                                    <td>
                                        <p>x 2 doses initiales tous les 3-7 jours</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j (1.2 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>20, 30, 40 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>QUASYM LP&reg;</p>
                                    </td>
                                    <td>
                                        <p>MPH</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 1-2 heure</p>
                                        <p>Efficace&nbsp;: 8-10h</p>
                                        <p>30% LI &ndash; 70% LP</p>
                                    </td>
                                    <td>
                                        <p>20 mg</p>
                                    </td>
                                    <td>
                                        <p>x 2 doses initiales tous les 3-7 jours</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j (1.2 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>10, 20, 30 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CONCERTA LP&reg;</p>
                                    </td>
                                    <td>
                                        <p>MPH</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 20-60 min</p>
                                        <p>Efficace&nbsp;: 10-12 h</p>
                                        <p>22% LI &ndash; 78% LP</p>
                                    </td>
                                    <td>
                                        <p>18 mg</p>
                                    </td>
                                    <td>
                                        <p>&uarr; 18 mg tous les 3-7 jours</p>
                                    </td>
                                    <td>
                                        <p>54 mg (&lt;13 ans)
                                            <br>&nbsp;72 mg (&ge;13 ans)</p>
                                    </td>
                                    <td>
                                        <p>18, 36, 54 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>MEDIKINET&reg;</p>
                                    </td>
                                    <td>
                                        <p>MPH</p>
                                        <p>
                                            <br>
                                        </p>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 20-60 min</p>
                                        <p>Efficace&nbsp;: 8-10 h</p>
                                        <p>50% LI &ndash; 50% LP</p>
                                    </td>
                                    <td>
                                        <p>5-10 mg</p>
                                    </td>
                                    <td>
                                        <p>x 2 doses initiales tous les 3-7 jours</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j (1.2 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>5, 10, 20, 30, 40 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="subth" colspan="7">
                                        <p>Autres traitements (hors AMM ou avec ATU)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>STRATTERA&reg;</p>
                                    </td>
                                    <td>
                                        <p>ATX</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 1-2 semaines</p>
                                        <p>Efficace 10-12h</p>
                                    </td>
                                    <td>
                                        <p>0.5 mg/kg/j matin pour 4 jours</p>
                                    </td>
                                    <td>
                                        <p>1 mg/kg/j pour 4 jours puis 1.2 mg/kg/j</p>
                                    </td>
                                    <td>
                                        <p>100 mg/j (1.4 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>10, 18, 25, 40, 60, 80, 100mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>GUANFACINE&reg;</p>
                                    </td>
                                    <td>
                                        <p>A2A</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 1-2 semaines</p>
                                        <p>Efficace 10-12h</p>
                                    </td>
                                    <td>
                                        <p>1 mg/j</p>
                                    </td>
                                    <td>
                                        <p>1 mg/j toutes les semaines</p>
                                    </td>
                                    <td>
                                        <p>4 mg/j (0.17 mg/kg/j)</p>
                                    </td>
                                    <td>
                                        <p>1, 2, 3, 4 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CLONIDINE&reg;</p>
                                    </td>
                                    <td>
                                        <p>A2A</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 1-2 semaines</p>
                                        <p>Efficace 10-12h</p>
                                    </td>
                                    <td>
                                        <p>0.1 mg/j</p>
                                    </td>
                                    <td>
                                        <p>0.1 mg/j toutes les semaines</p>
                                    </td>
                                    <td>
                                        <p>0.4 mg/j</p>
                                    </td>
                                    <td>
                                        <p>0.1, 0.2 mg</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>VIVANSE&reg;</p>
                                    </td>
                                    <td>
                                        <p>DEX</p>
                                    </td>
                                    <td>
                                        <p>Pic&nbsp;: 60 min</p>
                                        <p>Efficace 10-12h</p>
                                    </td>
                                    <td>
                                        <p>20 mg</p>
                                    </td>
                                    <td>
                                        <p>
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p>70 mg</p>
                                    </td>
                                    <td>
                                        <p>20, 30, 40, 50, 60, 70 mg</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="7">Note : * Les recommandations australiennes/anglaises suggèrent dose maximum 80 mg ou 2.1 mg/kg/j chez l’enfant et 100 mg chez l’adolescent. Considérer un passage de la forme LI à LP après 1-2 mois de traitement efficace pour une dose équivalente</td>
                            </tfoot>
                        </table>
                    </div>
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT DU TDAH ?</p>
                    <div class="notification is-warning">
                        <span class="icon">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span> 11/10/2021 : Les règles de prescription ont changé pour le methylphenidate, il est désormais possible de le prescrire, y compris le débuter, avec une prescription de ville par un spécialiste psychiatre ou neurologue ou pédiatre. Nous allons corriger notre site dès que possible. Pour l'instant seul <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>le guide de prescription</a> est à jour.</div>
                    <p class="soustitre2">Règles de prescription :</p>
                    <ul>
                        <li>La 1ère prescription se fait en milieu hospitalier par un spécialiste</li>
                        <li>Sur une ordonnance sécurisée</li>
                        <li>L’ordonnance doit être renouvelée tous les 28 jours par le médecin habituel (pas forcément hospitalier, médecin traitant, psychiatre référent)</li>
                    </ul>
                    <p class="soustitre2">Bilan pré thérapeutique&nbsp;:</p>
                    <ul>
                        <li>Antécédents médicaux (possible contre-indications)
                        <li>Traitements médicamenteux habituels</li>
                        <li>Poids et taille (comparés à la norme par rapport à l’âge et le sexe)</li>
                        <li>Préciser la PA, FC</li>
                        <li>Examen cardiovasculaire</li>
                        <li>ECG, si le traitement affecte l’intervalle QT (NICE 2018)</li>
                        <li>Consultation avec cardiologue (NICE 2018)</li>
                        <ul>
                            <li>Antécédent de chirurgie cardiaque ou cardiopathie congénitale</li>
                            <li>Antécédents familiaux de mort subite chez un apparenté du premier degré avant 40 ans évoquant une maladie cardiaque</li>
                            <li>Manque de souffle à l’effort comparé aux autres</li>
                            <li>Evanouissement à l’effort, ou quand il est effrayé ou atmosphère bruyante</li>
                            <li>Palpitation rapide et régulière qui débute et s’arrête brutalement</li>
                            <li>Douleur à la poitrine suggérant un problème cardiaque</li>
                            <li>Bruits anormaux à l’auscultation cardiaque</li>
                            <li>Anomalie TA> 95°percentile pour l’âge et le poids, orienté vers un spécialiste</li>
                        </ul>
                    </ul>
                    <p class="soustitre2">Contre-indication&nbsp;:</p>
                    <ul>
                        <li>Hyperthyroïdie</li>
                        <li>Phéochromocytome</li>
                        <li>Glaucome</li>
                        <li>Association avec les IMAO</li>
                        <li>Trouble cardiovasculaire</li>
                    </ul>
                    <p class="soustitre2">Titration :&nbsp;</p>
                    <ul>
                        <li>Il existe une variation importante dans la dose efficace de MPH selon les individus.</li>
                        <li>La dose efficace n’est pas forcément liée à l'âge, au poids ou à la sévérité des symptômes.</li>
                        <li>Il est recommandé de commencer selon la dose inscrite sur le tableau puis d’augmenter progressivement par palier jusqu’à ce que les objectifs du traitement aient été atteints ou que les effets secondaires dépassent les gains cliniques. Il est recommandé de viser 25-50% d’amélioration des symptômes au moins. </li>
                    </ul>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>Surveillance de l’efficacité : symptômes du TDAH, retentissement fonctionnel
                        <li>Surveillance de la tolérance : effets indésirables réévalués régulièrement par un spécialiste :
                            <ul>
                                <li>Mesure poids (tous les 3 mois &lt; 10 ans), taille (tous les 6 mois)</li>
                                <li>FC, PA chaque changement de dose et tous les 6 mois (reporter dans les courbes)</li>
                                <li>Pas de bilan biologique, ni d’ECG à faire systématique, uniquement si FC>120, PAS>95°percentile</li>
                            </ul>
                        </li>
                        <li>Les consultations de suivis sont prévues tous les mois jusqu’à l’obtention d’une réponse optimale.</li>
                        <li>Il est recommandé d’avoir des contacts téléphoniques avec les parents toutes les semaines dans le mois qui suit la mise en place du traitement (AAP 2011, NICE 2018).
                        <li>Une fois la réponse clinique obtenue le suivi peut s’espacer tous les 3 mois la 1ère année, puis tous les six mois.</li>
                        <li>La surveillance doit être plus fréquente en cas d’association à d’autres troubles du neurodéveloppement non stabilisés (ex : TSA, tics, trouble apprentissage, TDI), de trouble psychiatrique comorbide, ou de pathologie somatique associée.</li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE</p>
                    <ul>
                        <li>Le bénéfice du traitement doit être réévalué tous les ans.</li>
                        <li>Le TDAH est un trouble du neurodéveloppement impliquant la maturation cérébrale. Cela implique qu’un certain nombre d’enfants avec TDAH vont progressivement rattraper leur retard de maturation. On peut proposer de faire une fenêtre thérapeutique pendant les grandes vacances scolaires afin de voir si le traitement est toujours nécessaire.</li>
                        <li>Le traitement peut être maintenu durant le week-end et les vacances de moins d’un mois. Il peut exister une majoration des effets indésirables liée à l’arrêt brutal du traitement de façon répétée.</li>
                    </ul>
                    <p class="soustitre1">SI PAS D'AMELIORATION CLINIQUE</p>
                    <ul>
                        <li>Il faut s’assurer de l’observance du traitement.</li>
                        <li>Réexaminer et soutenir les stratégies d’optimisation de l’environnement : contrôle des stimuli, gestion des contingences. Réévaluer les indications et l’adéquation des thérapies de remédiation ou de psychothérapie.</li>
                        <li>Rechercher des comorbidités psychiatriques ou des facteurs environnementaux qui contribueraient à pérenniser les symptômes.</li>
                        <li>On peut proposer d’augmenter la dose de MPH progressivement, en respectant les paliers, jusqu’à la dose maximum tolérée.</li>
                        <li>Si le traitement reste inefficace malgré l’augmentation jusqu’à la dose maximale tolérée, il faut considérer un changement de traitement.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-autox">
                            <tbody>
                                <tr>
                                    <td style="background: #eee">
                                    </td>
                                    <td>
                                        <p>Niveau 1</p>
                                        <p><b>Monothérapie par MPH</b></p>
                                        <p>Titration avec MPH forme LI puis si nécessaire passage à la forme LP</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background: #bbb" width="70">
                                    </td>
                                    <td style="background: #fff">
                                        <p>Niveau 2&nbsp;: si pas efficace à 6 semaines et/ou effets indésirables intolérables</p>
                                        <p><strong>Changement pour un autre traitement par MPH</strong></p>
                                        <p>Au moins la moitié des enfants/ado dont les symptômes ne répondent pas à une première ligne de MPH répondent à une autre forme de MPH</p>
                                        <p><strong>Supplémenter un traitement MPH LP par dose ponctuelle de traitement par MPH LI</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background: #000">
                                    </td>
                                    <td>
                                        <p>Niveau 3&nbsp;: alternative ou adjuvant, hors AMM ou ATU. Si pas d'efficacité à 6 semaines ou mauvaise tolérance</p>
                                        <p><strong>Changement pour un traitement non psychostimulant&nbsp;: ATX</strong></p>
                                        <p>En 1<sup>er</sup> intention si abus de substance, à considérer en cas de tics ou trouble anxieux</p>
                                        <p><strong>Ajouter au traitement par MPH un traitement par A2A&nbsp;</strong>(CLONIDINE, GUANFACINE)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre1">ALTERNATIVES THERAPEUTIQUES</p>
                    <div class="select my-4">
                        <select @change="onChange($event)">
                            <option>Choisir un médicament...</option>
                            <option value="1">Atomoxétine (Strattera <sup>&reg;</sup>)</option>
                            <option value="2">Agoniste &alpha;-2 adrénergique (Guanfacine <sup>&reg;</sup>, Clonidine <sup>&reg;</sup>)</option>
                        </select>
                    </div>
                    <div v-if="affiche_molecule == 1">
                        <p class="soustitre2">Principe</p>
                        <ul>
                            <li>Inhibiteur de la recapture de la noradrénaline au niveau présynaptique.</li>
                            <li>Indication chez l’adulte dont les symptômes existent depuis l’enfance. Indication chez l’enfant en cas :
                                <ol>
                                    <li>de comorbidité du TDAH avec troubles anxieux sévères, tics, syndrome Gille de la Tourette, ou abus de substance autre que l’alcool qui rendent difficile de prescrire un traitement par psychostimulant</li>
                                    <li>d’apparition ou d’aggravation de symptôme thymique sous psychostimulant</li>
                                    <li>d’effets indésirables sévères nécessitant l’arrêt du psychostimulant : trouble du sommeil, de l’appétit ou sur la croissance.</li>
                                </ol>
                            </li>
                            <li>L’atomoxétine est disponible en France via une procédure d’ATU.</li>
                        </ul>
                        <p class="soustitre2">Instauration de la dose</p>
                        <ul>
                            <li>Débuter à 0.5 mg/kg/j pendant 7 jours avant d’être augmentés progressivement. Dose d’entretien à 1.2 mg/kg/j. Dose habituelle de traitement entre 0.5 à 2 mg/kg/j</li>
                            <li>Evaluer l’efficacité à 4 à 6 semaines. A la différence du MPH le délai d’efficacité est plus long.</li>
                            <li>Les jeunes métaboliseur lent du CYP2D6 (7% des Caucasiens) doivent recevoir une dose plus faible.</li>
                        </ul>
                        <p class="soustitre2">Bilan pré thérapeutique</p>
                        <ul>
                            <li>Idem que le MPH</li>
                            <li>Contre-indication : Glaucome à angle fermé, association avec les IMAO, trouble cardiovasculaire</li>
                        </ul>
                        <p class="soustitre2">Effets indésirables</p>
                        <ul>
                            <li>Somnolence initiale</li>
                            <li>Symptômes gastro-intestinaux (nausée, vomissement, douleur abdominale)</li>
                            <li>Diminution de l’appétit</li>
                            <li>Labilité émotionnelle</li>
                            <li>Augmentation des idées suicidaires</li>
                            <li>Risque rare mais grave d’hépatite</li>
                        </ul>
                    </div>
                    <div v-if="affiche_molecule == 2">
                        <p class="soustitre2">Principe</p>
                        <ul>
                            <li>Utilisé historiquement dans l’hypertension, parfois dans les troubles vaso-moteurs associés à la ménopause.</li>
                            <li>Peuvent être utilisés comme traitement adjuvant en associant avec le MPH ou l’atomoxétine.</li>
                        </ul>
                        <p class="soustitre2">Instauration de la dose</p>
                        <ul>
                            <li>L’instauration doit être progressive. A la différence du MPH il n’y a généralement pas d’efficacité avant 4-6 semaines.</li>
                            <li>L’arrêt devra se faire progressivement pour éviter un effet rebond en particulier sur la tension artérielle.</li>
                        </ul>
                        <p class="soustitre2">Bilan pré thérapeutique</p>
                        <ul>
                            <li>ECG pré thérapeutique indispensable.</li>
                        </ul>
                        <p class="soustitre2">Effets indésirables</p>
                        <ul>
                            <li>Somnolence</li>
                            <li>Bouche sèche</li>
                            <li>Hypotension</li>
                            <li>Troubles gastro-intestinaux</li>
                            <li>Sensation vertigineuse</li>
                        </ul>
                    </div>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l'instauration d'un traitement pharmacologique pour le TDAH</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Fiche d'information famille TDAH (livret Nantes)</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Fiche d’information sur le traitement médicamenteux dans le TDAH (livret Montpellier)</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[4])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique du TDAH</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>Présentation sur le traitement médicamenteux du TDAH pour enseignement</a></li>
                    </ul>
                    <p class="soustitre2">Association de patients et liens utiles :</p>
                    <ul>
                        <li><a target="_blank" href="http://www.caddra.ca">www.caddra.ca</a></li>
                        <li><a target="_blank" href="http://www.tdah-france.fr">www.tdah-france.fr/</a> (HyperSupers - TDAH France)</li>
                        <li><a target="_blank" href="http://www.attentiondeficit-info.com/">www.attentiondeficit-info.com/</a></li>
                    </ul>
                    <p class="py-2 soustitre2">Liste des recommandations utilisées pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Pays
                                    </th>
                                    <th>
                                        Recommandations les plus récentes
                                    </th>
                                    <th>
                                        Traitements approuvés
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>France</p>
                                    </td>
                                    <td>
                                        <p>HAS 2014</p>
                                    </td>
                                    <td>
                                        <p><span lang="en-US">MPH</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Grande-Bretagne</p>
                                    </td>
                                    <td>
                                        <p>NICE 2018 (TDAH)</p>
                                    </td>
                                    <td>
                                        <p>MPH, DEX<br><a target="_blank" href="https://www.nice.org.uk/guidance/ng87">https://www.nice.org.uk/guidance/ng87</a></p>
                                        <p><a href="https://www.has-sante.fr/upload/docs/application/pdf/2015-02/tdah_argumentaire.pdf" target="_blank">https://www.has-sante.fr/upload/docs/application/pdf/2015-02/tdah_argumentaire.pdf</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p></p>
                                    </td>
                                    <td>
                                        <p>NICE 2006 (troubles des conduites)</p>
                                    </td>
                                    <td>
                                        <p>Interventions pour les familles<br><a target="_blank" href="https://www.nice.org.uk/guidance/cg158">https://www.nice.org.uk/guidance/cg158</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>US</p>
                                    </td>
                                    <td>
                                        <p>AAP 2010 (complète recos de 2000)</p>
                                    </td>
                                    <td>
                                        <p>MPH, DEX, ATX, A2A</p>
                                        <p><a href="https://pediatrics.aappublications.org/content/144/4/e20192528">https://pediatrics.aappublications.org/content/144/4/e20192528</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Canada</p>
                                    </td>
                                    <td>
                                        <p>CADDRA 2011</p>
                                    </td>
                                    <td>
                                        <p>MPH, DEX, ATX, A2A</p>
                                        <p><a href="https://caddra.ca/pdfs/caddraGuidelines2011.pdf">https://caddra.ca/pdfs/caddraGuidelines2011.pdf</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Australie</p>
                                    </td>
                                    <td>
                                        <p>RACP 2009</p>
                                    </td>
                                    <td>
                                        <p>MPH, DEX</p>
                                        <p><a href="https://www.racp.edu.au/advocacy/division-faculty-and-chapter-priorities/paediatrics-and-child-health-division/australian-guidelines-on-adhd">https://www.racp.edu.au/advocacy/division-faculty-and-chapter-priorities/paediatrics-and-child-health-division/australian-guidelines-on-adhd</a></p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3">RACP: The Royal Australian College of Physicians, AAP: American Academy of Pediatry, NICE: National Institute for Health and Care Excellence, MPH: Methylphenidate, DEX: Dexamphetamine, ATX: Atomoxétine, A2A: Agoniste α-2 adrénergique</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleADHD',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            window: {
                width: 0,
                height: 0,
                maxwidth: 900
            },
            dl_files: {},
            affiche_molecule: 0,
            // tdah
            troubles: {
                toggle_anx: false,
                toggle_dep: false,
                toggle_bipo: false,
                toggle_tics: false,
                toggle_aut: false,
                toggle_rm: false,
                toggle_subst: false,
                toggle_cond: false,
                toggle_irrit: false
            },
            //showpart: 1,
            rep: 'tdah',
            maintitle: 'TDAH',
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {
        onChange(event) {
            this.affiche_molecule = event.target.value
        },
        reveal(msg, event) {
            var new_elem = document.createElement("span");
            new_elem.style.cssText = 'color:blue;';
            var new_content = document.createTextNode(msg);
            new_elem.appendChild(new_content);
            event.target.replaceWith(new_elem);
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            const keys = Object.keys(this.troubles)
            for (const key of keys) {
                this.troubles[key] = this.troubles[key] || this.window.width < this.window.maxwidth;
            }
        },
        // switch troubles TDAH
        sw: function(message) {
            const keys = Object.keys(this.troubles)
            if (this.window.width > this.window.maxwidth) {
                for (const key of keys) {
                    this.troubles[key] = (key == message);
                }
            }
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>