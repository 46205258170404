<template>
    <div id="notfound">
        <section>
        <h3 class="title has-text-centered is-4"> Page introuvable !</h3>
        <figure class="image">
            <img src="../assets/notfound.svg" width="360" height="180" alt="not found">
        </figure>
        </section>
    </div>
</template>
<script>
export default {
    name: 'NotFound',
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#notfound{
	min-height: 500px;
    padding-top: 50px;
}
.image {
    max-width: 360px;
     display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 33%;
}
</style>