<template>
    <div id="agitation">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Définition</p>
                    <ul>
                        <li>
                            L’agitation se définit comme un état de tension intérieur associé à une activité motrice excessive généralement improductive et stéréotypée en rupture avec le fonctionnement habituel de la personne.
                        </li>
                        <li>
                            L’agitation est un motif fréquent de consultations aux urgences pédiatriques.
                        </li>
                        <li>
                            Le caractère d’urgence de l’agitation est lié :
                            <ul>
                                <li>
                                    à des facteurs étiologiques qui vont parfois nécessiter une intervention qui ne peut être différée (ex : hypoglycémie, intoxication médicamenteuse)
                                </li>
                                <li>
                                    à ces complications pour le jeune et son entourage (ex : mise en danger accidentelle ou intentionnelle, fugue du service, comportements agressifs, éventuellement déshydratation).
                                </li>
                            </ul>
                        </li>
                        <li>
                            La prise en charge de l’agitation chez l’enfant et l’adolescent est toujours individualisée impliquant idéalement la collaboration entre des professionnels formés à l’urgence et à la pédiatrie (ex : psychiatre d’enfant et d’adolescent, médecin urgentistes, pédiatres spécialistes, infirmiers, éventuellement assistante sociale).
                        </li>
                    </ul>
                    <p class="soustitre2">Orientation diagnostique</p>
                    <ul>
                        <li>
                            L’évaluation diagnostique doit se faire en urgence, en parallèle de la prise en charge symptomatique de l’agitation (c’est-à-dire de la mise en place de stratégies comportementales de désescalade, parfois associé à un traitement médicamenteux et exceptionnellement à une contention physique).
                        </li>
                        <li>
                            L’examen clinique comprend la recherche des antécédents psychiatriques et somatiques, la liste des traitements antérieurs (en particulier ceux qui peuvent être ingérés de façon accidentel
                            ou intentionnel), les allergies éventuelles, l’histoire développementale, le contexte familial et social (dont les traumatismes éventuels), ainsi qu’un examen physique.
                        </li>
                        <li>
                            Les principaux facteurs contributifs à l’état d’agitation chez l’enfant et l’adolescent sont :
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Une maladie somatique (ex : douleur, syndrome confusionnel, intoxication)
                        </li>
                        <li>
                            Des symptômes psychiatriques (symptômes anxieux, psychotiques, maniaques, catatoniques)
                        </li>
                        <li>
                            Des traitements médicaments (traitement habituel récemment arrêté, intoxication, sevrage brutal)
                        </li>
                        <li>
                            Des difficultés cognitives et développementales
                        </li>
                        <li>
                            Des troubles du comportement perturbateur
                        </li>
                        <li>
                            Des traumatismes psychologiques
                        </li>
                        <li>
                            Un handicap physique et des particularités sensorielles
                        </li>
                        <li>
                            Des difficultés de communication
                        </li>
                    </ul>
                    <ul>
                        <li>
                            On peut classer ces facteurs contributifs en deux catégories:
                            <ul>
                                <li>
                                    Les facteurs étiologiques qui peuvent rendre compte à eux seuls comptes de l’agitation (ex : syndrome confusionnel ou catatonique)
                                </li>
                                <li>
                                    Les facteurs de vulnérabilité qui ne peuvent pas expliquer à eux seul l’agitation (ex : trouble du comportement perturbateur, trouble autistique) pour lesquels il faut identifier des facteurs précipitants associés (ex : douleur, symptômes anxieux)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Interventions médicamenteuses et non médicamenteuses</p>
                    <ul>
                        <li>
                            Identifier et traiter les facteurs contributifs généralement réduit voir fait disparaitre la nécessité de recourir à un traitement médicament pour l’agitation.
                        </li>
                        <li>
                            Parmi les interventions non médicamenteuses on distingue :
                            <ul>
                                <li>
                                    Les stratégies de prévention primaire de l’état d’agitation
                                </li>
                                <li>
                                    Les stratégies comportementales de désescalade face au jeune en état d’agitation ou à risque d’agitation
                                </li>
                                <li>
                                    Les mesures de contention physique
                                </li>
                            </ul>
                        </li>
                        <li>
                            Les traitements médicamenteux ne se résument pas au traitement symptomatique de l’agitation. Ils sont le plus souvent utilisés pour intervenir sur les facteurs précipitants de l’agitation comme l’anxiété ou la douleur physique.
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INTERVENTIONS NON MEDICAMENTEUSES">
                    <h2 class="title is-4">INTERVENTIONS NON MEDICAMENTEUSES</h2>
                    <ul>
                        <li>
                            Parmi les interventions non médicamenteuses on distingue :
                            <ul>
                                <li>
                                    Les mesures de prévention primaire de l’état d’agitation aux urgences pédiatriques
                                </li>
                                <li>
                                    Les stratégies comportementales de désescalade face au jeune en état d’agitation ou à risque d’agitation
                                </li>
                                <li>
                                    Les mesures de contention physique
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre1">QUELLES SONT LES MESURES DE PREVENTION PRIMAIRE DE L’ETAT D’AGITATION AUX URGENCES PEDIATRIQUES ?</p>
                    <ul>
                        <li>
                            Parmi les mesures de prévention de l’agitation chez l’enfant et l’adolescent on peut mentionner :
                            <ul>
                                <li>
                                    Disposer d’un lieu d’évaluation dédié, isolé des autres patients, dans un environnement calme, protégé, sans accès à des objets dangereux ou contigus
                                </li>
                                <li>
                                    Réduire les délais d’attente avant la consultation
                                </li>
                                <li>
                                    Encourager une communication claire à tous les niveaux de la prise en charge depuis l’accueil.
                                </li>
                                <li>
                                    S’assurer que la communication est bien adaptée au niveau de développement de l’enfant et si besoin utiliser des alternatives (ex : images, gestes adaptés)
                                </li>
                                <li>
                                    Evaluer et prendre en charge de façon active la douleur
                                    physique
                                </li>
                                <li>
                                    Evaluer le risque d’agitation et s’informer des stratégies d’apaisement habituellement utilisées par le jeune, sa famille et l’entourage (ex : éducateur du foyer, professionnel de l’IME)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre1">QUELLES SONT LES STRATEGIES COMPORTEMENTALES DE DESESCALADE FACE A L’ENFANT OU L’ADOLESCENT AGITE OU A RISQUE D’AGITATION ?</p>
                    <ul>
                        <li>
                            L’utilisation de stratégies comportementales de désescalade est toujours recommandée.
                        </li>
                        <li>
                            Ces mesures doivent être mises en place avant, pendant et après les investigations à visée étiologique et l’administration d’un médicament.
                        </li>
                        <li>
                            Elles ont pour objectif d’apaiser et de réduire le risque de survenue ou de complications de l’état d’agitation chez l’enfant ou l’adolescent.
                        </li>
                        <li>
                            Le tableau suivant décrit les principales stratégies comportementales de désescalade :
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th colspan="2">
                                        Principales stratégies comportementales de désescalade
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <b>Communication g&eacute;n&eacute;rale</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Utiliser un langage clair, concret et simple adapt&eacute; au niveau de d&eacute;veloppement</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Garder une voix tranquille, des mouvements lents, garder un contact oculaire au niveau du patient</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Tenir compte de son langage corporel, ne pas &ecirc;tre trop pr&egrave;s</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <b>Conduite de l&rsquo;entretien</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Se pr&eacute;senter clairement (nom et fonction)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Rappeler que l&rsquo;objectif des soins est de pr&eacute;server la sant&eacute; et la protection du patient</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Informer &agrave; l&rsquo;avance sur le d&eacute;roulement des soins et des mesures de prise en charge de l&rsquo;agitation.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Maintenir une attitude ouverte, respectueuse, sous forme d&rsquo;une &eacute;coute active en marquant des signes d&rsquo;attention (par exemple reformulation)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Faire clarifier les facteurs d&eacute;clenchants et promouvoir des approches de r&eacute;solution du probl&egrave;me</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <b>Mesures environnementales</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- R&eacute;duire les stimulations environnementales (lumi&egrave;res douces, r&eacute;duction bruits, limiter les accompagnements et les passages devant la chambre)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Mettre &agrave; distance les objets ou &eacute;quipement potentiellement dangereux</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Selon le contexte, s&eacute;parer ou bien rapprocher le patient des autres membres de la famille</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- S&rsquo;assurer des besoins fondamentaux du patient (faim, soif, confort) et y r&eacute;pondre</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Proposer des distractions sous formes de jouets ou de distracteurs sensoriels</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <b>Anticiper la suite de la prise en charge avec l&rsquo;&eacute;quipe de soins</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Anticiper la conduite &agrave; tenir si les propositions de d&eacute;sescalade &eacute;chouent (anticiper la posologie de traitement per os ou IM)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Solliciter si besoin autres professionnels (personnels suppl&eacute;mentaires, voir s&eacute;curit&eacute;)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>- Discuter de l&rsquo;indication ou la non-indication d&rsquo;une contention physique en cas d&rsquo;aggravation</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre2">Situations particulières</p>

                    <div class="select my-4">
                        <select @change="onChange($event)">
                            <option>Choisir une situation...</option>
                            <option value="1">Jeune avec trouble du spectre autistique</option>
                            <option value="2">Jeune avec trouble du comportement perturbateur</option>
                        </select>
                    </div>

<div v-if="affiche_situation == 1">
                    <p class="pt-3"><b>Jeune avec trouble du spectre autistique</b></p>
                    <ul>
                        <li>
                            Les jeunes avec un trouble du spectre de l&rsquo;autisme (TSA) sont plus &agrave; risque de pr&eacute;senter des &eacute;tats d&rsquo;agitation pour diff&eacute;rentes raisons :
                            <ul>
                                <li>
                                    Les difficult&eacute;s communication compliquent la reconnaissance et l&rsquo;expression de leur &eacute;motion, leur besoin ou encore leurs inqui&eacute;tudes vis-&agrave;-vis des situations inconnues (comme les soins d&rsquo;urgence).
                                </li>
                                <li>
                                    Les professionnels non habitu&eacute;s peuvent se sentir moins &agrave; l&rsquo;aise pour exprimer leur intention et le d&eacute;roulement des soins.
                                </li>
                                <li>
                                    La nature m&ecirc;me de soins en urgences perturbe les routines des jeunes avec un TSA.
                                </li>
                                <li>
                                    Les particularit&eacute;s d&rsquo;int&eacute;gration sensorielles peuvent susciter de nombreux stress surajout&eacute;s qui passent souvent inaper&ccedil;us (ex&nbsp;: son d&rsquo;un appareil de monitoring, sensation d&rsquo;un bracelet d&rsquo;identification, &eacute;clairage).
                                </li>
                            </ul>
                        </li>
                        <li>
                            En plus des &eacute;l&eacute;ments cliniques habituels&nbsp;l&rsquo;&eacute;valuation des jeunes avec TSA doit comprendre :
                            <ul>
                                <li>
                                    Le niveau de fonctionnement cognitif et le niveau global de fonctionnement
                                </li>
                                <li>
                                    Les moyens de communication habituels
                                </li>
                                <li>
                                    L&rsquo;existence de particularit&eacute;s sensorielles
                                </li>
                                <li>
                                    Les sympt&ocirc;mes psychiatriques et les comportements d&eacute;fis
                                </li>
                                <li>
                                    Les comorbidit&eacute;s somatiques les plus fr&eacute;quentes chez ces patients (voir la liste propos&eacute;e ici&nbsp;<a href="https://somatiek.cce.nl/somatic-conditions" target="_blank">https://somatiek.cce.nl/somatic-conditions</a>)
                                </li>
                                <li>
                                    Les strat&eacute;gies habituellement utilis&eacute;es par l&rsquo;entourage pour apaiser le patient
                                </li>
                                <li>
                                    Les m&eacute;dicaments d&eacute;j&agrave; re&ccedil;us en cas d&rsquo;anxi&eacute;t&eacute; ou d&rsquo;agitation et les &eacute;ventuels effets ind&eacute;sirables
                                </li>
                            </ul>
                        </li>
                        <li>
                            Ces informations sont recueillies aupr&egrave;s de la famille et des professionnels prenant en charge le jeune d&egrave;s que possible.
                        </li>
                        <li>
                            Ces informations doivent &ecirc;tre accessible &agrave; tous les membre de l&rsquo;&eacute;quipe prenant en charge le patient.
                        </li>
                        <li>
                            Les strat&eacute;gies comportementales de d&eacute;sescalade ont une place particuli&egrave;rement importante dans la prise en charge de l&rsquo;agitation chez les enfants avec un TSA.
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Stratégies comportementales de désescalade chez les enfants et adolescents avec un TSA
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Faire participer d&egrave;s le d&eacute;but de la prise en charge les parents aux discussions concernant les besoins du patient et les strat&eacute;gies &agrave; mettre en place pour favoriser l&rsquo;apaisement</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Proposer des outils de communication alternatifs (pictogramme&hellip;) en proposant aux parents de les ramener du domicile</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Proposer aux parents de ramener du domicile des objets familiers pour r&eacute;duire le stress (vid&eacute;o, jouet favori)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Adapter l&rsquo;environnement pour limiter les stimuli sensoriels aversifs</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Limiter le nombre de personnes impliqu&eacute;s dans la prise en charge</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Tenir compte du stress li&eacute; &agrave; l&rsquo;examen physique</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Expliquer chaque &eacute;tape en permettant &agrave; l&rsquo;enfant d&rsquo;examiner les instruments</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Lors d&rsquo;une hospitalisation</p>
                                        <ul>
                                            <li>
                                                Avis d&rsquo;un professionnel sp&eacute;cialis&eacute; (orthophoniste/psychologue/&eacute;ducateur) pour conseiller un mode de communication
                                            </li>
                                            <li>
                                                Avis d&rsquo;un psychomotricien/ergoth&eacute;rapeute peuvent proposer un profil sensoriel pour proposer des recommandations
                                            </li>
                                            <li>
                                                Promouvoir des routines, proposer un planning des soins et activit&eacute;s
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="1">
                                        Note : des équipes proposent un kit sensoriel comprenant un casque réducteur de bruit, une balle anti-stress, une toupie, une couverture lestée.
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
</div>
<div v-if="affiche_situation == 2">
                    <p class="pt-3"><b>Jeune avec trouble du comportement perturbateur</b></p>
                    <ul>
                        <li>
                            Les recommandations pour la prise en charge des comportements agressifs chroniques chez l&rsquo;enfant et l&rsquo;adolescent (comme les recommandations TRAAY) ne s&rsquo;appliquent pas &agrave; la prise en charge de l&rsquo;&eacute;tat d&rsquo;agitation.
                        </li>
                        <li>
                            Les jeunes avec un trouble du comportement perturbateur ancien peuvent pr&eacute;senter des comportements agressifs voir d&rsquo;agitation ponctuels dans des moments de stress qui viennent d&eacute;border leurs capacit&eacute;s d&rsquo;adaptation et celle de l&rsquo;entourage.
                        </li>
                        <li>
                            Le plus souvent les sympt&ocirc;mes anxieux et les enjeux relationnels sont aux premiers plans. Dans les jours qui pr&eacute;c&eacute;dent on retrouve une irritabilit&eacute; plus marqu&eacute;e ainsi que des relations marqu&eacute;es par le renforcement des conduites oppositionnelles, provocatrices ou transgressives plus ou moins aliment&eacute;es par des contre-attitudes de l&rsquo;entourage.
                        </li>
                        <li>
                            L&rsquo;examen en urgence doit rester syst&eacute;matique et ne pas &eacute;luder les autres aspects de l&rsquo;&eacute;valuation. Une comorbidit&eacute; somatique, psychiatrique, toxique ou iatrog&egrave;ne peut passer inaper&ccedil;u alors qu&rsquo;il peut s&rsquo;agir d&rsquo;un facteur &eacute;tiologique potentiellement r&eacute;versible.
                        </li>
                        <li>
                            Les strat&eacute;gies comportementales de d&eacute;sescalade doivent insister sur&nbsp;:
                            <ul>
                                <li>
                                    La s&eacute;paration avec l&rsquo;environnement imm&eacute;diat
                                </li>
                                <li>
                                    La conduite de l&rsquo;entretien fond&eacute;s sur des propositions r&eacute;flexives et de validation des &eacute;motions
                                </li>
                            </ul>
                        </li>
                        <li>
                            Des conseils sur le d&eacute;roulement de l&rsquo;entretien sont d&eacute;taill&eacute;s <a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>ici</a>.
                        </li>
                    </ul>
</div>

                    <p class="soustitre1">QUELLE EST LA PLACE DES CONTENTIONS PHYSIQUES CHEZ L’ENFANT ET L’ADOLESCENT AGITE OU A RISQUE D’AGITATION ?</p>
                    <ul>
                        <li>L’utilisation de contention physique chez l’enfant et l’adolescent reste exceptionnelle. </li>
                        <li>Cet aspect n’est pas détaillé ici.</li>
                    </ul>
                </div>
                <div v-show="showPart(3)" title="INDICATIONS">
                    <h2 class="title is-4">INDICATIONS</h2>
                    <ul>
                        <li>
                            En th&eacute;orie, un traitement m&eacute;dicamenteux peut-&ecirc;tre indiqu&eacute;&nbsp;:
                            <ul>
                                <li>
                                    Pour traiter un facteur &eacute;tiologique qui contribue &agrave; l&rsquo;agitation chez l&rsquo;enfant et l&rsquo;adolescent (ex&nbsp;: douleur, sympt&ocirc;mes anxieux)
                                </li>
                                <li>
                                    Pour obtenir un effet d&rsquo;apaisement afin de permettre la poursuite des investigations dans de bonnes conditions et pr&eacute;venir d&rsquo;&eacute;ventuelles complications. L&rsquo;objectif est que le patient soit calme mais non s&eacute;dat&eacute;, il doit pouvoir interagir, participer aux entretiens et &agrave; l&rsquo;examen physique.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Il existe une grande diversit&eacute; de pratique concernant la place des m&eacute;dicaments dans les &eacute;tats d&rsquo;agitation chez l&rsquo;enfant et l&rsquo;adolescent selon les lieux.
                        </li>
                        <li>
                            Cela est li&eacute; &agrave; l&rsquo;absence de consensus scientifiques &eacute;vident sur la place de ces traitements, &agrave; la nature multifactorielle de l&rsquo;agitation, &agrave; la diversit&eacute; des populations concern&eacute;es et des lieux de soin. Une &eacute;quipe travaillant dans un service d&rsquo;urgence ouvert o&ugrave; le jeune est plus facilement en contact avec du mat&eacute;riel dangereux sera plus enclin &agrave; prescrire un m&eacute;dicament par rapport &agrave; une &eacute;quipe form&eacute;e travaillant dans un environnement mieux adapt&eacute;.
                        </li>
                        <li>
                            L&rsquo;utilisation d&rsquo;un traitement m&eacute;dicamenteux doit toujours tenir compte des besoins sp&eacute;cifiques du patient et de son histoire.
                        </li>
                        <li>
                            Des recommandations pour la prise en charge de l&rsquo;agitation en population p&eacute;diatrique ont &eacute;t&eacute; publi&eacute;es en 2019 par l&rsquo;Association Am&eacute;ricaine de Psychiatrie d&rsquo;Urgence.
                        </li>
                        <li>
                            Seuls certains traitements antipsychotiques de 1ʳᵉ&nbsp;g&eacute;n&eacute;ration (AP1G) b&eacute;n&eacute;ficient d&rsquo;une Autorisation de Mise sur le March&eacute; (AMM) dans le traitement des troubles graves du comportement avec agitation et agressivit&eacute;. Les autres classes m&eacute;dicamenteuses n&rsquo;ont pas d&rsquo;AMM dans cette indication.
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(4)" title="REGLES DE PRESCRIPTION">
                    <h2 class="title is-4">REGLES DE PRESCRIPTION</h2>
                    <p class="soustitre2">En première intention</p>
                    <ul>
                        <li>
                            Il faut privil&eacute;gier un traitement per os.
                        </li>
                        <li>
                            Il faut privil&eacute;gier les traitements d&eacute;j&agrave; prescrits : avancer la prise d&rsquo;un traitement habituel&nbsp;et/ou administrer une demi-dose ou dose suppl&eacute;mentaire d&rsquo;un traitement prescrit en si besoin.
                        </li>
                        <li>
                            Diff&eacute;rentes classes de m&eacute;dicament peuvent &ecirc;tre prescrites :
                            <ul>
                                <li>
                                    Antihistaminique&nbsp;: L&rsquo;hydroxyzine est le traitement le plus utilis&eacute; en cas d&rsquo;agitation mod&eacute;r&eacute; avec sympt&ocirc;mes anxieux compte tenu du profil de tol&eacute;rance et sa plus grande familiarit&eacute; des patients et des m&eacute;decins. Il ne doit pas &ecirc;tre utilis&eacute; chez les patients &agrave; risque de confusion.
                                </li>
                                <li>
                                    Antipsychotiques&nbsp;: Ils sont utilis&eacute;s en cas d&rsquo;agitation plus s&eacute;v&egrave;re.
                                    <ul>
                                        <li>
                                            Les antipsychotiques de 1&egrave;re g&eacute;n&eacute;ration (AP1G) sont historiquement utilis&eacute;s, il continue &agrave; &ecirc;tre prescrit en France.
                                        </li>
                                        <li>
                                            Plusieurs revues r&eacute;centes recommandent en premi&egrave;re intention l&rsquo;utilisation d&rsquo;antipsychotique de 2nd g&eacute;n&eacute;ration (AP2G) comme l&rsquo;olanzapine, la risp&eacute;ridone, ou la qu&eacute;tiapine.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Benzodiaz&eacute;pine&nbsp;: peu utilis&eacute; compte tenu du risque d&rsquo;effet paradoxaux ou de d&eacute;sinhibition. Leur utilisation est &agrave; r&eacute;server &agrave; l&rsquo;agitation grave dans un contexte d&rsquo;intoxication ou de sevrage, ou bien si aucune &eacute;tiologie n&rsquo;est retrouv&eacute;e.
                                </li>
                                <li>
                                    Autres&nbsp;: des agonistes &alpha;-2 adr&eacute;nergique (clonidine CATAPRESSAN&reg;, guanfacine) sont mentionn&eacute;s dans la litt&eacute;rature. Ces traitements pr&eacute;sentent des risques d&rsquo;hypotension et de bradycardie (effets ind&eacute;sirables d&eacute;taill&eacute;s <router-link :to="{ name: 'trouble', params: { id: 2, start: 8 }}">ici</router-link>)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Une r&eacute;&eacute;valuation r&eacute;guli&egrave;re de l&rsquo;efficacit&eacute; et de la tol&eacute;rance est n&eacute;cessaire. Cette surveillance doit &ecirc;tre plus fr&eacute;quente en cas de deuxi&egrave;me dose.
                        </li>
                    </ul>
                    <p class="soustitre2">En cas d’inefficacité ou d’aggravation</p>
                    <ul>
                        <li>Il faut proposer une seconde dose du traitement déjà reçu plutôt que de prescrire d’emblée un nouveau traitement (sauf si le 1er traitement a été mal toléré).</li>
                        <li>La dose totale reçue par jour ne doit pas excéder les recommandations (voir Tableaux 3 et 4).</li>
                        <li>Il faut penser aux possibles effets indésirables induit par la première dose de traitement (ex : pour les neuroleptiques penser à l’akathisie, le syndrome malin des neuroleptiques, les symptômes catatoniques).</li>
                    </ul>
                    <p class="soustitre2">En cas de refus de prendre un traitement</p>
                    <ul>
                        <li>Un traitement par voie intramusculaire (IM) se discute mais n’est pas systématique.</li>
                        <li>La décision de prescrire un traitement par voie IM doit tenir compte 1) de la sévérité de l’agitation, 2) des facteurs étiologiques identifiés, 3) de l’efficacité des mesures comportementales antérieurs, 4) de l’évolution des symptômes depuis l’admission.</li>
                        <li>Si une voie parentérale est déjà en place et accessible, son utilisation est préférable à une injection IM.</li>
                        <li>Une surveillance régulière des fonctions vitales est nécessaire en cas de traitement per os ou IM, en particulier en cas de contention physique associée.</li>
                    </ul>
                </div>
                <div v-show="showPart(5)" title="TRAITEMENTS PER OS">
                    <h2 class="title is-4">TRAITEMENTS PER OS</h2>
                    <p>Le tableau suivant détaille les différents traitements utilisés en cas d’agitation chez l’enfant et l’adolescent :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>DCI</p>
                                    </th>
                                    <th>
                                        <p>Posologie / Dose habituelle (DH)</p>
                                    </th>
                                    <th>
                                        <p>D&eacute;lai avant effet maximal</p>
                                    </th>
                                    <th>
                                        <p>Dose maximale cumul&eacute;e sur journ&eacute;e</p>
                                    </th>
                                    <th>
                                        <p>Remarques</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="5" class="subth">
                                        <p>ANTIHISTAMINIQUE</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Hydroxyzine<br />
                                            (ATARAX&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 12,5 ou 25 mg</p>
                                        <p>Sirop 2 mg/ml 1</p>
                                        <p><strong>DH </strong>: 1 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>2h</p>
                                    </td>
                                    <td>
                                        <p>2 mg/kg (&le;40 kg)</p>
                                        <p>100 mg (&gt;40 kg)</p>
                                    </td>
                                    <td>
                                        <p>* &Eacute;viter en cas de syndrome confusionnel (risque de confusion/ d&eacute;sinhibition chez les plus jeunes ou patients avec troubles du d&eacute;veloppement)</p>
                                        <p>* Peut-&ecirc;tre associ&eacute; &agrave; l&rsquo;halop&eacute;ridol et la chlorpromazine en cas de crainte d&rsquo;effets ind&eacute;sirables neurologiques</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="subth">
                                        <p>ANTIPSYCHOTIQUE DE 2nd GENERATION (AP2G)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Olanzapine</p>
                                        <p>(ZYPREXA&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 2,5, 5, 7,5 ou 10 mg</p>
                                        <p><strong>DH&nbsp;</strong>: 2,5-10 mg</p>
                                    </td>
                                    <td>
                                        <p>5 h</p>
                                    </td>
                                    <td>
                                        <p>10-20 mg <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>* Pas de prescription concomitante ou dans l&rsquo;heure de benzodiaz&eacute;pine, risque de d&eacute;pression respiratoire</p>
                                        <p>* Possible effet anticholinergique</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Quetiapine</p>
                                        <p>(XEROQUEL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 25 ou 50 mg</p>
                                        <p><strong>DH </strong>: 25-50 mg</p>
                                        <p>1-1.5 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>30 min - 2h</p>
                                    </td>
                                    <td>
                                        <p>600mg (&gt;10 ans)&nbsp;<sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension</p>
                                        <p>* Risque de s&eacute;dation &agrave; faible dose</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Risp&eacute;ridone</p>
                                        <p>(RISPERDAL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 0,5, 1 ou 2 mg</p>
                                        <p><strong>DH&nbsp;</strong>: 0,005-0,01 mg/kg/dose<br />
                                            (soit 0,25-2 mg)</p>
                                    </td>
                                    <td>
                                        <p>1h</p>
                                    </td>
                                    <td>
                                        <p>1-2 mg (enfant)</p>
                                        <p>3 mg (ado) <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>* A forte dose effet ind&eacute;sirable neurologique, en particulier akathisie</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="subth">
                                        <p>ANTIPSYCHOTIQUE DE 1er GENERATION (AP1G)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Chlorpromazine (LARGACTIL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 25 mg</p>
                                        <p>Sol. Buvable 4%<br />
                                            (1 goutte= 1mg)</p>
                                        <p><strong>DH&nbsp;</strong>: 12,5-60 mg</p>
                                        <p>0,5 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>30 min - 1h</p>
                                    </td>
                                    <td>
                                        <p>40 mg (&lt;5 ans)</p>
                                        <p>75 mg (&ge;5 ans)</p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension et d&rsquo;allongement du QTc</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cyam&eacute;mazine (TERCIAN&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 25 mg</p>
                                        <p>Sol. Buvable 40 mg/ml (1 goutte = 1mg)</p>
                                        <p><strong>DH&nbsp;</strong>: 0,5 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>40 mg (&lt;5 ans) <sup>3</sup></p>
                                        <p>75 mg (&ge;5 ans) <sup>3</sup></p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension et d&rsquo;allongement du QTc</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Halop&eacute;ridol</p>
                                        <p>(HALDOL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 1 ou 5 mg</p>
                                        <p>Sol. Buvable 2 mg/ml</p>
                                        <p>(10 gouttes = 1mg)</p>
                                        <p><strong>DH </strong>: 0,025 &agrave; 0,075 mg/kg/dose<br />
                                            (soit 2 &agrave; 5 mg)</p>
                                    </td>
                                    <td>
                                        <p>2-6 h</p>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>6 mg (15-40 kg)</p>
                                        <p>15 mg (&gt;40 kg) <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension et d&rsquo;allongement du QTc</p>
                                        <p>* Effets ind&eacute;sirables extrapyramidaux tr&egrave;s fr&eacute;quents si dose &gt;3 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>L&eacute;vom&eacute;promazine (NOZINAN&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 25 mg</p>
                                        <p>Sol. Buvable 4%<br />
                                            (1 goutte = 1mg)</p>
                                        <p>DH&nbsp;: 0,5 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>1-3h</p>
                                    </td>
                                    <td>
                                        <p>40 mg (&lt;5 ans) <sup>3</sup></p>
                                        <p>75 mg (&ge;5 ans) <sup>3</sup></p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension et d&rsquo;allongement du QTc</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Loxapine (LOXAPAC&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 25 mg</p>
                                        <p>Sol. Buvable 25 mg/ml</p>
                                        <p>(1 goutte = 1mg)</p>
                                        <p><strong>DH&nbsp;</strong>: 0,5 mg/kg/dose</p>
                                    </td>
                                    <td>
                                        <p>1h&frac12;</p>
                                    </td>
                                    <td>
                                        <p>40 mg (&lt;5 ans) <sup>3</sup></p>
                                        <p>75 mg (&ge;5 ans) <sup>3</sup></p>
                                    </td>
                                    <td>
                                        <p>* Surveiller le risque d&rsquo;hypotension</p>
                                        <p>* Pas d&rsquo;effet sur le QTc</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="subth">
                                        <p>BENZODIAZEPINE</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Loraz&eacute;pam</p>
                                        <p>(TEMESTA&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Cp de 1 ou 2,5 mg</p>
                                        <p><strong>DH&nbsp;</strong>: 0,5-2 mg<br />
                                            &nbsp;</p>
                                    </td>
                                    <td>
                                        <p>1-2h</p>
                                        <p>&nbsp;</p>
                                    </td>
                                    <td>
                                        <p>4 mg (enfant) <sup>2</sup></p>
                                        <p>6-8 mg (ado) <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>* &Eacute;viter en cas de syndrome confusionnel (risque de confusion/ d&eacute;sinhibition chez les plus jeunes ou patients avec troubles du d&eacute;veloppement)</p>
                                        <p>* Peut-&ecirc;tre associ&eacute; &agrave; l&rsquo;halop&eacute;ridol et chlorpromazine en cas de crainte d&rsquo;effets ind&eacute;sirables neurologiques</p>
                                        <p>* Risque de d&eacute;pression respiratoire en cas d&rsquo;association avec l&rsquo;olanzapine</p>
                                        <p>* Indiquer dans les suspicions de syndrome catatonique</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="5">
                                        <sup>1</sup> Exemple patient de 30kg, prescription de 30mg d’Atarax (2 cp de 15 mg) ou 15ml de solution buvable d’Atarax
                                        <br><sup>2</sup> Dose maximale selon le poids de l’enfant et l’existence d’une exposition antérieure
                                        <br><sup>3</sup> En l’absence de recommandations spécifiques sur les posologies maximales de chlorpromazine, levomepromazine et loxapine en population pédiatrique nous nous sommes basés sur les recommandations pour la chlorpromazine
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(6)" title="TRAITEMENTS IM/IV">
                    <h2 class="title is-4">TRAITEMENTS IM/IV</h2>
                    <p>Le tableau suivant détaille les différentes molécules disponibles pour le traitement de l’agitation de l’enfant et de l’adolescent par voie IM ou intraveineuse (IV) :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>DCI</p>
                                    </th>
                                    <th>
                                        <p>Voie IM</p>
                                    </th>
                                    <th>
                                        <p>Biodisponibilit&eacute;/D&eacute;lai</p>
                                    </th>
                                    <th>
                                        <p>Voie IV</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Olanzapine<br />
                                            (ZYPREXA&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 10 mg</p>
                                        <p>(Dose IM = &frac12; - &frac14; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                        <p>15 &ndash; 45 min <sup>2</sup></p>
                                    </td>
                                    <td>
                                        <p>oui</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Chlorpromazine (LARGACTIL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 50 mg</p>
                                        <p>(Dose IM = &frac12; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>10 &agrave; 69%</p>
                                        <p>15 -30 min</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cyam&eacute;mazine<br />
                                            (TERCIAN&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 50 mg</p>
                                        <p>(Dose IM = &frac12; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>-/-</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Halop&eacute;ridol<br />
                                            (HALDOL&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 5 mg</p>
                                        <p>(Dose IM = &frac12; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>60 &agrave; 70%</p>
                                        <p>20 min</p>
                                    </td>
                                    <td>
                                        <p>Si pas d&rsquo;urgence&nbsp;: 0,01-0,05 mg/kg en 2 fois par jour</p>
                                        <p>Si urgence&nbsp;: 0,025-0,1 mg/kg/injection toutes les 15-20 min jusqu&rsquo;&agrave; que l&rsquo;agitation soit contr&ocirc;l&eacute;e sous surveillance scop&eacute; (TA et ECG) <sup>1</sup></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>L&eacute;vom&eacute;promazine (NOZINAN&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 50 mg</p>
                                        <p>(Dose IM = &frac12; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>50%</p>
                                        <p>15 -30 min</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Loxapine<br />
                                            (LOXAPAC&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 50 mg</p>
                                        <p>(Dose IM = &frac12; dose per os)</p>
                                    </td>
                                    <td>
                                        <p>-/-</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cloraz&eacute;pate dipotassique (TRANXENE&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 20-50 mg</p>
                                        <p>(Dose IM =dose per os)</p>
                                    </td>
                                    <td>
                                        <p>-</p>
                                        <p>30 &ndash; 60 min</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Diaz&eacute;pam<br />
                                            (VALIUM&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 10 mg</p>
                                        <p>(Dose IM =dose per os)</p>
                                    </td>
                                    <td>
                                        <p>Biodisponibilit&eacute; m&eacute;diocre</p>
                                        <p>-</p>
                                    </td>
                                    <td>
                                        <p>0,1 - 0,2 mg/kg/inj</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Loraz&eacute;pam<br />
                                            (TEMESTA&reg;)</p>
                                    </td>
                                    <td>
                                        <p>Ampoule de 4 mg (ATU)</p>
                                        <p>(Dose IM =dose per os)</p>
                                    </td>
                                    <td>
                                        <p>Biodisponibilit&eacute; identique &agrave; la voie per os</p>
                                        <p>60 &ndash; 120 min</p>
                                    </td>
                                    <td>
                                        <p>non</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4">
                                        <sup>1</sup> Les doses IV sont deux fois plus puissantes qu’en cas de prise per os
                                        <br><sup>2</sup> Une étude a montré une plus grande réduction des symptômes d’agitation dans les 30 min qui suit une injection IM d’olanzapine chez l’enfant et l’adolescent comparé à une injection IM de lorazépam ou de chlorpromazine (Saito 2020). Une revue de dossier de 145 jeunes présentant une agitation retrouve une supériorité de l’administration d’olanzapine par voie IM comparés à la chlorpromazine par voie IM sur la réduction d’un score clinique d’agitation (Snyder 2021). Ces résultats sont à nuancer car des traitements antihistaminiques étaient plus fréquemment prescrit dans le 1er groupe
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(7)" title="CAS PARTICULIERS">
                    <h2 class="title is-4">CAS PARTICULIERS</h2>
                    <p>Certaines situations cliniques influencent le choix du traitement médicamenteux pour les enfants et adolescents en situation d’agitation</p>
                    <p class="soustitre1">AGITATION LIEE A UN SYNDROME CONFUSIONNEL</p>
                    <ul>
                        <li>
                            L&rsquo;identification et le traitement des facteurs &eacute;tiologiques et de vuln&eacute;rabilit&eacute; sont au c&oelig;ur de la prise en charge.
                        </li>
                        <li>
                            Il faut d&rsquo;abord diminuer voire arr&ecirc;ter les traitements m&eacute;dicamenteux qui peuvent causer ou exacerber les sympt&ocirc;mes confusionnels
                        </li>
                        <li>
                            Un traitement m&eacute;dicamenteux peut &ecirc;tre propos&eacute; selon la situation:
                            <ul>
                                <li>
                                    Pour traiter l&rsquo;&eacute;tiologie sous-jacente
                                </li>
                                <li>
                                    Pour r&eacute;tablir les rythmes de sommeil
                                </li>
                                <li>
                                    Pour traiter des sympt&ocirc;mes physiques (comme la douleur ou les naus&eacute;es)
                                </li>
                                <li>
                                    Pour r&eacute;duire l&rsquo;agitation et pr&eacute;server la s&eacute;curit&eacute; du patient
                                </li>
                            </ul>
                        </li>
                        <li>
                            Choix d&rsquo;un traitement&nbsp;symptomatique pour r&eacute;duire l&rsquo;agitation :
                            <ul>
                                <li>
                                    Il faut &eacute;viter les traitements qui peuvent aggraver les sympt&ocirc;mes confusionnels (principalement anticholinergiques et benzodiaz&eacute;pines)
                                </li>
                                <li>
                                    Les antipsychotiques &agrave; faible dose sont &agrave; privil&eacute;gier
                                    <ul>
                                        <li>
                                            AP2G &agrave; tr&egrave;s faible dose&nbsp; (les doses propos&eacute;es sont bien les doses totales et non par kg):
                                            <ul>
                                                <li>
                                                    Risp&eacute;ridone (RISPERDAL&reg;)&nbsp;:
                                                    <ul>
                                                        <li>
                                                            0,1mg per os 1 &agrave; 2 fois par jour (&lt;5 ans)
                                                        </li>
                                                        <li>
                                                            0,2 mg per os 1 &agrave; 2 fois par jour (&ge;5 ans)
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Olanzapine (ZYPREXA&reg;)
                                                    <ul>
                                                        <li>
                                                            1 &agrave; 2,5 mg per os 1 fois par jour
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Qu&eacute;tiapine (XEROQUEL&reg;)
                                                    <ul>
                                                        <li>
                                                            12,5 ou 25 mg per os 2 fois par jour (10-17 ans)
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Historiquement les AP1G &agrave; tr&egrave;s faible dose sont aussi utilis&eacute;s
                                            <ul>
                                                <li>
                                                    Halop&eacute;ridol (HALDOL&reg;)
                                                    <ul>
                                                        <li>
                                                            0,1 mg per os 1 fois par jour
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    La m&eacute;latonine peut &ecirc;tre utilis&eacute; en cas de troubles de sommeil associ&eacute;s
                                </li>
                                <li>
                                    Les agonistes &alpha;-2 adr&eacute;nergiques peuvent &ecirc;tre utilis&eacute; en cas de contre-indication des antipsychotiques
                                </li>
                            </ul>
                        </li>
                        <li>
                            Le choix de la mol&eacute;cule doit se faire en fonction des besoins sp&eacute;cifiques du patient, du profil de tol&eacute;rance, de la maladie physique sous-jacente, de la prise de neuroleptiques associ&eacute;s, et des sympt&ocirc;mes confusionnels les plus probl&eacute;matiques
                        </li>
                    </ul>
                    <p class="soustitre2">Bénéfice et règles de prescription</p>
                    <ul>
                        <li>
                            Aucun traitement n&rsquo;a d&rsquo;Autorisation de Mise sur le March&eacute; (AMM) dans le traitement des &eacute;tats d&rsquo;agitation secondaires &agrave; un syndrome confusionnel, ni chez l&rsquo;adulte, ni chez l&rsquo;enfant.
                        </li>
                        <li>
                            Le rapport b&eacute;n&eacute;fice-risque de ces traitements sur le syndrome confusionnel (dur&eacute;e, s&eacute;v&eacute;rit&eacute;) reste mal &eacute;tablie &dagger;. Aucune &eacute;tude n&rsquo;a &eacute;t&eacute; faite pour comparer entre eux l&rsquo;efficacit&eacute; de ces traitements.
                        </li>
                        <li>
                            Leur utilisation n&rsquo;est envisageable&nbsp;:
                            <ul>
                                <li>
                                    Uniquement dans les &eacute;tats d&rsquo;agitation s&eacute;v&egrave;re associ&eacute;s &agrave; des risques imm&eacute;diats pour la s&eacute;curit&eacute; du patient,
                                </li>
                                <li>
                                    En parall&egrave;le des autres aspects de la prise en charge (identification et traitement proactif des facteurs &eacute;tiologiques, adaptation de l&rsquo;environnement),
                                </li>
                                <li>
                                    Pour une dur&eacute;e la plus courte possible &dagger;&dagger;,
                                </li>
                                <li>
                                    En r&eacute;&eacute;valuant r&eacute;guli&egrave;rement la tol&eacute;rance et donc le rapport b&eacute;n&eacute;fice/risque.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Le b&eacute;n&eacute;fice d&rsquo;un traitement m&eacute;dicamenteux dans la pr&eacute;vention (et non le traitement) de l&rsquo;agitation secondaire &agrave; un syndrome confusionnel chez l&rsquo;enfants ou l&rsquo;adolescent n&rsquo;est pas &eacute;t&eacute; &eacute;tablie.
                        </li>
                    </ul>
                    <br>
                    <p><em>&dagger; Les &eacute;tudes conduites chez les adultes n&rsquo;ont pas retrouv&eacute; d&rsquo;efficacit&eacute; de ces mol&eacute;cules sur la dur&eacute;e du syndrome confusionnel (Girard 2018, Burry 2018). Des &eacute;tudes chez la personne &acirc;g&eacute;e qui pr&eacute;sentent un syndrome confusionnel montrent que l&rsquo;utilisation d&rsquo;AP1G ou d&rsquo;AP2G peut-&ecirc;tre associ&eacute; &agrave; un risque plus &eacute;lev&eacute; de mortalit&eacute; &agrave; court ou moyen terme (Hui 2016). </em></p>
                    <p><em>&dagger;&dagger; Une &eacute;tude conduite chez des adultes montre que si ces traitements n&rsquo;ont pas montr&eacute; d&rsquo;efficacit&eacute; avant 72h il est tr&egrave;s probable que le patient n&rsquo;en tire pas de b&eacute;n&eacute;fice (Agar 2017).</em></p>
                    <br>
                    <p>La tableau suivant présente les principaux traitements médicamenteux qui peuvent causer ou exacerber des symptômes confusionnels :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>Cat&eacute;gorie</p>
                                    </th>
                                    <th>
                                        <p>Exemples</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>C</b>ortico&iuml;de</p>
                                    </td>
                                    <td>
                                        <p>Augmentation de dose, ou arr&ecirc;t brutal</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>H</b>TA antihypertenseur</p>
                                    </td>
                                    <td>
                                        <p>Antihypertenseurs centraux, b&eacute;tabloquant</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>A</b>nticholinergique</p>
                                    </td>
                                    <td>
                                        <p>Effet non sp&eacute;cifique qui peut &ecirc;tre li&eacute; &agrave; un traitement antihistaminique, anti&eacute;m&eacute;tique, antipsychotique, anticonvulsifiants</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>A</b>ntalgique</p>
                                    </td>
                                    <td>
                                        <p>Effet de sevrage ou intoxication</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>R</b>eserpine</p>
                                    </td>
                                    <td>
                                        <p>?</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>L</b>-Dopa</p>
                                    </td>
                                    <td>
                                        <p>Et tous les autres traitement psychotropes (psychostimulant, antid&eacute;presseur [syndrome s&eacute;rotoninergique], antipsychotique [syndrome malin], benzodiaz&eacute;pine, thymor&eacute;gulateur comme le lithium et les anticonvulsifiants)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>I</b>mmunologique-infectieux</p>
                                    </td>
                                    <td>
                                        <p>Isoniazide, antipalud&eacute;en (M&eacute;floquine)</p>
                                        <p>Interf&eacute;ron</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>E</b>ndocrinologique</p>
                                    </td>
                                    <td>
                                        <p>&OElig;strog&egrave;ne, thyroxine, isotretinoide</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">
                                        Note : Pensez à toujours chercher « CHAARLIE »
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <p class="soustitre2">Quels sont les approches non médicamenteuses ?</p>
                    <ul>
                        <li>
                            En plus des mesures comportementales d&eacute;j&agrave; mentionn&eacute;es pour la pr&eacute;vention de l&rsquo;agitation, des mesures environnementales permettent de r&eacute;duire la d&eacute;sorientation temporo-spatiale et les troubles du rythme nycth&eacute;m&eacute;ral chez un enfant ou adolescent confus.
                            <ul>
                                <li>
                                    R&eacute;assurance et r&eacute;orientation r&eacute;guli&egrave;re par une figure famili&egrave;re ou un soignant habituel
                                </li>
                                <li>
                                    Calendrier et horloge pour les grands enfants
                                </li>
                                <li>
                                    Photos de la famille et les jouets de la maison
                                </li>
                                <li>
                                    Maintenir une luminosit&eacute; plus &eacute;lev&eacute;e dans la journ&eacute;e et plus faible pendant la nuit
                                </li>
                                <li>
                                    Maintenir des routines r&eacute;guli&egrave;res
                                </li>
                                <li>
                                    Diminuer le niveau de bruits
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre1">AGITATION LIEE A UNE INTOXICATION OU UN SYNDROME DE SEVRAGE</p>
                    <ul>
                        <li>Le choix des traitements doit se faire en fonction de la substance suspectée et de la présentation clinique.</li>
                        <li>Le bilan de toxiques urinaires peut être négatif en cas de consommation de drogue de synthèse.</li>
                        <li>Il existe un consensus sur l’utilisation de benzodiazépine seul, par exemple lorazépam (TEMESTA®) si la substance ingérée est inconnue.</li>
                        <li>L’utilisation d’antipsychotique doit être prudente compte tenu du risque d’effet indésirable neurologiques (syndrome malin, symptômes extrapyramidaux), cardiologique (allongement du QT, trouble du rythme) et d’interaction médicamenteuse (Whelan 200).</li>
                        <li>En cas d’hallucinations ou d’agitation très sévère, l’association d’un antipsychotique à faible dose à une benzodiazépine est possible (Gerson 2019).</li>
                    </ul>
                    <p class="soustitre1">AGITATION CHEZ UN PATIENT AVEC UN TROUBLE DU SPECTRE AUTISTIQUE OU AUTRES TROUBLES DU DEVELOPPEMENT</p>
                    <ul>
                        <li>Les jeunes avec un trouble du spectre autistique (TSA) ou un autre trouble du neurodéveloppement (TND) sont plus à risque de présenter des états d’agitation.</li>
                        <li>L’existence d’un facteur environnemental (ex : évènement venant modifier la routine du patient) ne doit pas éluder les autres aspects de l’évaluation. Une comorbidité somatique (ex : céphalées, douleur dentaire, constipation, RGO), psychiatrique, toxique ou iatrogène peut passer beaucoup plus fréquemment inaperçue chez ces patients. L’existence d’antécédent d’agitation, de particularités d’intégration sensorielle, un déficit intellectuel, un trouble de l’humeur associé, une douleur à l’admission, des antécédents d’hospitalisation en psychiatrie et des antécédents de prescription de psychotropes constituent des facteurs de risque d’agitation.</li>
                        <li>Une évaluation détaillée avec les parents et une observation permet souvent d’élucider les facteurs déclenchants et oriente le traitement.</li>
                        <li>L’aggravation brutale et sévère des stéréotypies motrices et de trouble de la communication doit faire rechercher un syndrome catatonique ou confusionnel.</li>
                        <li>Les stratégies comportementales mentionnées plus haut (Tableaux 1 et 2) sont d’autant plus importantes à appliquer dans cette population.</li>
                        <li>Conseils de prescription de médicaments chez les jeunes avec TSA ou autres TND
                            <ol>
                                <li>
                                    Discuter l&rsquo;utilisation de traitement probabiliste antalgique ou contre le RGO (des protocoles existent selon les lieux de soin).
                                </li>
                                <li>
                                    Documenter aupr&egrave;s de l&rsquo;entourage la r&eacute;ponse &agrave; des traitements m&eacute;dicamenteux d&rsquo;appoint d&eacute;j&agrave; prescrit. Des effets paradoxaux ou ind&eacute;sirables sont plus fr&eacute;quents dans cette population.
                                </li>
                                <li>
                                    Proposer dans un premier temps une demi-dose ou une dose suppl&eacute;mentaire du traitement habituellement re&ccedil;u.
                                </li>
                                <li>
                                    Si un nouveau traitement est prescrit, privil&eacute;gier les traitements entrainant le moins de confusion antipsychotique &gt; anticholinergique &gt; benzodiaz&eacute;pine et utiliser une titration plus progressive.
                                </li>
                                <li>
                                    Eviter les traitements IM sauf en cas d&rsquo;absolu n&eacute;cessit&eacute;.
                                </li>
                                <li>
                                    Continuer &agrave; r&eacute;&eacute;valuer r&eacute;guli&egrave;rement le rapport b&eacute;n&eacute;fice-risque de ces traitements. Il faut donc &ecirc;tre vigilant &agrave; ne pas reconduire automatiquement des traitements prescrit pour l&rsquo;agitation hors du contexte de l&rsquo;urgence.
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <p class="soustitre1">AGITATION CHEZ UN PATIENT AVEC UN TROUBLE PSYCHIATRIQUE AIGUË</p>
                    <ul>
                        <li>Le fait de rater une prise de médicament à cause du délai d’attente aux urgences peut être en soi une cause d’agitation. L’administration du traitement habituel voir d’une demi-dose ou dose supplémentaire est utile.</li>
                        <li>Un patient avec un trouble psychiatrique connu peut être agité pour des raisons sans lien avec son trouble (ex : intoxication concomitante, douleur associée, effets iatrogènes).</li>
                        <li>Les troubles psychotiques sont rares chez les enfants prépubères et les épisodes maniaques exceptionnels à cet âge. Un enfant prépubère qui présente une agitation associée à une désorganisation de la pensée, des hallucinations, ou des idées délirantes a plus de chance de présenter un état délirant 2nd à une cause organique ou à des manifestations anxieuses associés à des difficultés de communication (ex : lié à un TSA, un TND, un trouble du langage oral ou un trouble de l’attachement).</li>
                    </ul>
                    <p class="soustitre1">AGITATION LIEE A UNE ETIOLOGIE INCONNUE</p>
                    <ul>
                        <li>
                            Parfois, la(les) cause(s) de l&rsquo;agitation reste(nt) inconnue(s) malgr&eacute; les investigations r&eacute;alis&eacute;es.
                        </li>
                        <li>
                            Le choix du traitement d&eacute;pend de la s&eacute;v&eacute;rit&eacute; de l&rsquo;agitation.
                            <ul>
                                <li>
                                    Pour les agitations l&eacute;g&egrave;res, privil&eacute;gier les strat&eacute;gies comportementales de d&eacute;sescalade
                                </li>
                                <li>
                                    Pour les agitations mod&eacute;r&eacute;es, privil&eacute;gier l&rsquo;hydroxyzine, et en 2&egrave;me intention le loraz&eacute;pam ou l&rsquo;olanzapine.
                                </li>
                                <li>
                                    Pour les agitations s&eacute;v&egrave;res, privil&eacute;gier les antipsychotiques &agrave; faible dose (ex&nbsp;: olanzapine, chlorpromazine) parfois associ&eacute;s &agrave; des benzodiaz&eacute;pines (ex&nbsp;: loraz&eacute;pam).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>
                            Des &eacute;chelles remplies par les soignants permettent d&rsquo;aider &agrave; &eacute;valuer l&rsquo;efficacit&eacute; des traitements sur les probl&egrave;mes de comportements.
                            <ul>
                                <li>
                                    Pour l&rsquo;&eacute;tat d&rsquo;agitation&nbsp;: Behavioral Activity Rating Scale (BARS)
                                </li>
                                <li>
                                    Pour le syndrome confusionnel&nbsp;: la Cornell Assessment of Pediatric Delirium (CAPD)
                                </li>
                                <li>
                                    Pour les comportements agressifs&nbsp;: la Modified-Overt Agressive Scale (R-MOAS)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Des &eacute;chelles permettant de rechercher les effets ind&eacute;sirables des traitements sont mentionn&eacute;s dans les pages correspondantes (<router-link :to="{ name: 'trouble', params: { id: 5, start: 4 }}">antipsychotiques</router-link>, <router-link :to="{ name: 'trouble', params: { id: 7, start: 4 }}">antihistaminiques</router-link>, <router-link :to="{ name: 'trouble', params: { id: 7, start: 5 }}">benzodiaz&eacute;pines</router-link>).
                        </li>
                    </ul>
                    <br>
                    <p>Le tableau ci-dessous présente des remarques sur les effets indésirables des antipsychotiques prescrits en urgences :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>Neurologique</p>
                                    </th>
                                    <th>
                                        <p>Cardiaques</p>
                                    </th>
                                    <th>
                                        <p>Endocrinologique-m&eacute;tabolique</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>&bull; Une diminution progressive des doses d&rsquo;antipsychotique doit &ecirc;tre consid&eacute;r&eacute;s quand la dur&eacute;e de traitement exc&egrave;de 10 jours afin d&rsquo;&eacute;viter les effets ind&eacute;sirables neurologiques (akathisie, dyskin&eacute;sie).</p>
                                        <p>&bull; Les antipsychotiques fortement anti dopaminergique sont plus &agrave; risque d&rsquo;effets ind&eacute;sirables neurologiques.</p>
                                        <p>&bull; Le syndrome malin des neuroleptique (SMN) est plus fr&eacute;quent en cas : i) de SMN ant&eacute;rieurs, ii) d&rsquo;agitation, iii) de d&eacute;shydratation, iv) d&rsquo;utilisation d&rsquo;un antipsychotique fortement anti dopaminergique, v) d&rsquo;augmentation rapide des doses, et iv) d&rsquo;administration par voie IM.</p>
                                    </td>
                                    <td>
                                        <p>Les traitements qui allongent le plus le QTc sont par ordre croissant :</p>
                                        <p>- AP1G : chlorpromazine (+) &gt; halop&eacute;ridol (0/+) &gt; loxapine (0)</p>
                                        <p>- AP2G : qu&eacute;tiapine (++)&gt; risp&eacute;ridone (+) &gt; olanzapine (0/+)</p>
                                    </td>
                                    <td>
                                        <p>&bull; Une surveillance du risque m&eacute;tabolique doit &ecirc;tre consid&eacute;r&eacute;e quand la dur&eacute;e de traitement exc&egrave;de 8 semaines (Capino 2020).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>

                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Place du traitement pharmacologique dans la prise en charge de l’agitation chez l’enfant et l’adolescent</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Poster agitation (algorithme)</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Poster agitation (comportements - posologie)</a></li>
                    </ul>


                    <p class="py-2 soustitre2">Liste des recommandations utilisés pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>Recommandations</p>
                                    </th>
                                    <th>
                                        <p>Age</p>
                                    </th>
                                    <th>
                                        <p>Pays</p>
                                    </th>
                                    <th>
                                        <p>Ann&eacute;e</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Best practices for evaluation and treatment of agitated children and adolescents (BETA)</p>
                                        <p>American Association for Emergency Psychiatry</p>
                                    </td>
                                    <td>
                                        <p>Enfant/ado</p>
                                    </td>
                                    <td>
                                        <p>Etats Unis</p>
                                    </td>
                                    <td>
                                        <p>2019</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="soustitre2">Autres références :</p>
                    <ul>
    <li>Becker J. E., Smith J. R., Hazen E. P. Pediatric Consultation-Liaison Psychiatry: An Update and Review. Psychosomatics 2020:61:467&ndash;480</li>
    <li>Capino A. C. et al. Antipsychotic Use in the Prevention and Treatment of Intensive Care Unit Delirium in Pediatric Patients. J Pediatr Pharmacol Ther 2020;25(2):81&ndash;95</li>
    <li>Gerson R, Malas N, Feuer V, Silver GH, Prasad R, Mroczkowski MM, et al. Best practices for evaluation and treatment of agitated children and adolescents (BETA) in the emergency department: consensus statement of the American Association for Emergency Psychiatry. West J Emerg Med. 2019;20(2):409-18. DOI: 10.5811/westjem.2019.1.41344. PubMed PMID: 30881565; PubMed Central PMCID: PMC6404720.</li>
    <li>Hazen EP, Ravichandran C, Rao Hureau A, et al. Agitation in Patients With Autism Spectrum Disorder Admitted to Inpatient Pediatric Medical Units. Pediatrics. 2020;145(s1):e20191895N</li>
    <li>Marzullo L R. Pharmacologic Management of the Agitated Child. Pediatric Emergency Care &amp; Volume 30, Number 4, April 2014</li>
    <li>McGonigle JJ, Venkat A, Beresford C, et al. Management of agitation in individuals with autism spectrum disorders in the emergency department. Child Adolesc Psychiatric Clin N Am. 2014;23(1):83&ndash;95.</li>
    <li>Sonnier L, Barzman D. Pharmacologic Management of Acutely Agitated Pediatric Patients. Pediatr Drugs 2011; 13 (1): 1-10 1174-5878/11/0001-0001/$49.95/0</li>
    <li>Sorrentino A. Chemical restraints for the agitated, violent, or psychotic pediatric patient in the emergency department: controversies and recommendations. Curr Opin Pediatr 2004; 16:201&ndash;205</li>
    <li>Thom R P, Hazen M, McDougle, Hazen E P. Providing Inpatient Medical Care to Children With Autism Spectrum Disorder. Hospital Pediatrics Volume 10, Issue 10, October 2020</li>
    <li>Wilson MP, Pepper D, Currier GW, et al. The psychopharmacology of agitation: consensus statement of the American Association of Emergency Psychiatry Project: BETA Psychopharmacology Working Group. West J Emerg Med. 2012;13(1):26&ndash;34.</li>
</ul>

                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleAgitation',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            dl_files: {},
            affiche_situation: 0,
            //showpart: 1,
            rep: 'agitation',
            maintitle: 'Agitation',
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Interventions', 'icon': 'fas fa-comments' },
                { 'id': 3, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 4, 'title': 'Prescription', 'icon': 'fas fa-capsules' },
                { 'id': 5, 'title': 'Trait. Per Os', 'icon': 'fas fa-road' },
                { 'id': 6, 'title': 'Trait. IM/IV', 'icon': 'fas fa-road' },
                { 'id': 7, 'title': 'Cas particuliers', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 8, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {},
    destroyed() {},
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {
        onChange(event) {
            this.affiche_situation = event.target.value
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>