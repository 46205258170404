// define a mixin object
export const trbleMixin = {
    props: ['startAt', 'idChapter'],
    data() {
        return {
            showpart: parseInt(this.startAt), // default chapter
            publicPath: process.env.BASE_URL,
            minmenu: [],
            total_items: 0,
            dl_files_external: {
                'cytochromes': "https://www.hug.ch/sites/interhug/files/structures/pharmacologie_et_toxicologie_cliniques/images/carte_des_cytochromes_2020.pdf",
            },
            dl_files_all: {
                'bipolaire': {
                    1: "Check list avant la prescription thymoregulateur.docx",
                    2: "Fiche suivi efficacite et tolerance thymoregulateur.pdf",
                    3: "Poster TB.pdf",
                    4: "Pwt enseignement TB.pdf",
                },
                'toc': {
                    1: "Check list avant la prescription.docx",
                    3: "Echelle CY-BOCS.pdf",
                    5: "Fiche poster TOC.pdf"
                },
                'tdah': {
                    1: "Check list avant la prescription TDAH.docx",
                    2: "Fiche information famille TDAH (livret Nantes 2018).pdf",
                    3: "Fiche information medicament TDAH (livret Montpellier).doc",
                    4: "Fiche poster resume TDAH.pdf",
                    5: "Pwt enseignement TDAH.pdf",
                },
                'psychotique': {
                    1: "Checklist avant l'instauration d'un AP.docx",
                    2: "Fiche information famille antipsychotique.docx",
                    3: "Fiche suivi efficacite et tolerance des AP.xls",
                    4: "Fiche poster resume antipsychotique.pdf",
                    5: "Pwt enseignement antipsychotique.pptx",
                },
                'depression': {
                    1: "Check list avant la prescription ISRS.docx",
                    2: "Fiche famille sur antidepresseurs.docx",
                    3: "Fiche suivi efficacite et tolerance antidepresseur.pdf",
                    4: "Fiche poster resume depression.pdf",
                    5: "Pwt enseignement depression.pdf",
                },
                'sommeil': {
                    1: "Agenda sommeil enfant adolescent.pdf",
                    2: "Fiche regles d'hygiene du sommeil.pdf",
                    3: "Fiche famille hypnotiques.pdf",
                    4: "Fiche poster resume trouble du sommeil.pdf",
                    5: "SDSC_4_16ans.pdf",
                    6: "SDSC_6mois_4ans.pdf",
                },
                'anxieux': {
                    1: "Fiche suivi efficacite et tolerance ATD trouble anxieux.pdf",
                    2: "Poster resume trouble anxieux.pdf",
                    3: "Pwt enseignement anxiete.pdf",
                },
                'tic': {
                    1: "Fiche poster resume TIC-SGT.pptx",
                    2: "Pwt enseignementTIC-SGT.pptx",
                    3: "YGTSS (french).pdf",
                },
                'agitation': {
                    1: "Pwt enseignement agitation.pptx",
                    2: "Pwt poster algo.pptx",
                    3: "Pwt poster comportement.pptx",
                },
            },
        }
    },
    metaInfo () {
      return {
        title: this.maintitle,
        meta: [
          { vmid: 'title', name: 'title', content: this.title }
        ]
      }
    },
    mounted() {
        this.setTitle()
        this.total_items = this.minmenu.length
        this.setStartAt()
    },
    watch:{
        $route (to){
            var n_st = parseInt(to.params.start)
            this.showpart = Math.min(n_st, this.total_items)
            //console.log(this.showpart)
        }
    },
    methods: {
        setTitle: function() {
            this.$emit('update', this.maintitle)
        },
        goToPart: function(part_id) {
            if(parseInt(part_id) != this.showpart){ // prevent reload same page
                this.showpart = parseInt(part_id)
                this.$router.push({ name: 'trouble', params: { id: this.idChapter, start: this.showpart } })
            }
        },
        showPart: function(part_id) {
            return part_id == this.showpart
        },
        getHref: function(filename) {
            var href = (filename != '') ? (this.publicPath + 'pathologie/' + this.rep + '/' + filename) : '#';
            return encodeURI(href);
        },
        getHrefAlt: function(rep, i) {
            var filename = this.dl_files_all[rep][i] ?? ''
            var href = (filename != '') ? (this.publicPath + 'pathologie/' + rep + '/' + filename) : '#';
            return encodeURI(href);
        },
        setStartAt: function() {
            var st = (parseInt(this.startAt)) ?? 1
            var trust_st = (st > this.total_items) ? 1 : st
            this.showpart = trust_st
        },
        scrollTop(){
            var bc = document.querySelector("#breadcrumb")
            if (bc) {
                bc.scrollIntoView({ behavior: 'smooth'})
            }
        },
        chapter_next(current_part){
          this.showpart = Math.min(parseInt(current_part) + 1, this.total_items)
          this.scrollTop()
          this.$router.push({ name: 'trouble', params: { id: this.idChapter, start: this.showpart } })
        },
        chapter_prev(current_part){
          this.showpart = Math.max(parseInt(current_part) - 1, 1)
          this.scrollTop()
          this.$router.push({ name: 'trouble', params: { id: this.idChapter, start: this.showpart } })
        }
    }
}
