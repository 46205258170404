<template>
    <div id="toc">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Prévalence</p>
                    <ul>
                        <li>La prévalence vie entière des Troubles Obsessionnels-Compulsifs (TOC) dans la population générale est de 1-3%.</li>
                        <li>On estime que 80% des patients adultes avec des TOC ont des symptômes qui ont débuté avant l’âge de 18 ans et un tiers avant la puberté.</li>
                        <li>Chez l’enfant, la prévalence en population générale est de 0,25-0,7%.</li>
                        <li>Il existe deux pics d’apparition des TOC chez le jeune :
                            <ul>
                                <li>Un 1er pic entre l’âge de 7 et 12 ans.
                                    <ul>
                                        <li>Il s’agit le plus souvent de garçons</li>
                                        <li>Les obsessions peuvent être au second plan par rapport aux compulsions. La présentation peut ressembler aux tics.</li>
                                        <li>Les TOC sont souvent plus sévères, persistent plus fréquemment à l’âge adulte, et avec une moins bonne réponse au traitement.</li>
                                    </ul>
                                </li>
                                <li>Un 2ème pic vers l’âge de 20 ans.
                                    <ul>
                                        <li>Le sexe-ratio est plus équilibré.</li>
                                        <li>Ces formes sont généralement moins sévères.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Diagnostic</p>
                    <ul>
                        <li>Les TOC sont souvent diagnostiqués longtemps après le début des troubles.
                        <li>L’âge moyen du diagnostic est de 17 ans chez l’homme et de 21 ans chez les femmes, avec une 1ère consultation généralement 8 à 10 ans après les premiers symptômes.
                        <li>Ce délai dans la prise en charge peut être expliqué par :
                            <ul>
                                <li>Le sentiment de honte ou de culpabilité des jeunes vis-à-vis de leurs symptômes qui sont longtemps masqués à leur entourage</li>
                                <li>Les difficultés pour l’entourage ou les soignants de différencier TOC de rituels normaux pendant l’enfance</li>
                                <li>Certaines formes de TOC ont une évolution marquée par des périodes de rémissions</li>
                                <li>Parfois un trouble psychiatrique associé peut-être au premier plan (par exemple, des conduites oppositionnelles)</li>
                            </ul>
                        </li>
                        <li>Les traitements antidépresseurs de type Inhibiteur Spécifique de la Recapture de la Sérotonine (ISRS) sont les traitements médicamenteux de 1ere intention dans les TOC de l’enfant et de l’adolescent.</li>
                    </ul>
                    <p class="soustitre2">Comorbidités et diagnostics différentiels</p>
                    <ul>
                        <li>Près de 60-80% des enfants et adolescents avec des TOC présentent un autre trouble psychiatrique.</li>
                        <li>Certains de ces troubles ont en commun l’existence de pensées intrusives, d’anxiété et de comportements répétitifs en particulier les tics, les dysmorphophobies, la trichotillomanie, et les trouble du contrôle des impulsions.</li>
                        <li>Ces troubles font certainement partie d’un continuum avec des facteurs étiologiques et des mécanismes physiopathologiques communs avec les TOC.</li>
                        <li>On retrouve aussi parmi les troubles psychiatriques les plus fréquemment associés au TOC, le TDAH, les troubles anxieux, les troubles de l’humeur et les troubles du comportement alimentaire.</li>
                    </ul>
                    <p>Le tableau suivant présente la liste des principaux diagnostics différentiels des TOC.</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        <p>Sympt&ocirc;me/diagnostic</p>
                                    </th>
                                    <th>
                                        <p>Pr&eacute;sentation habituelle</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Rituels normaux chez l&rsquo;enfant (&eacute;mergence vers 4-5 ans et disparition dans cette tranche d&rsquo;&acirc;ge)</p>
                                    </td>
                                    <td>
                                        <p>Habituel chez les enfants d&rsquo;&acirc;ge pr&eacute;scolaire avec des routines autour de la toilette et au moment du coucher. Le plus souvent transitoires et de moins en moins rigides avec l&rsquo;&acirc;ge. Pas de retentissement sur les activit&eacute;s du quotidien</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Tics&nbsp;et syndrome Gilles de la Tourette</p>
                                        <p>(&eacute;mergence vers 7 ans - d&eacute;but perl&eacute;)</p>
                                    </td>
                                    <td>
                                        <p>Parfois difficile de distinguer des tics moteurs complexes vs. des compulsions sans obsession mentalis&eacute;e chez les jeunes enfants (comme des rituels de toucher)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Comportements st&eacute;r&eacute;otyp&eacute;s dans le trouble du spectre de l&rsquo;autisme</p>
                                        <p>(&eacute;mergence vers 15 mois)</p>
                                    </td>
                                    <td>
                                        <p>G&eacute;n&eacute;ralement il s&rsquo;agit de conduites d&rsquo;autostimulation qui ne sont pas forc&eacute;ment en r&eacute;ponse &agrave; l&rsquo;anxi&eacute;t&eacute;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Sympt&ocirc;mes d&eacute;lirants</p>
                                    </td>
                                    <td>
                                        <p>L&rsquo;existence d&rsquo;obsessions et compulsions bizarres sans critique chez les grands adolescents doit faire rechercher des id&eacute;es d&eacute;lirantes qui peuvent se traduire par un automatisme mental, voir dans les cas les plus s&eacute;v&egrave;res par un syndrome catatonique.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Trouble moteur d&rsquo;origine immunologique, g&eacute;n&eacute;tique, vasculaire, infectieux, m&eacute;tabolique</p>
                                    </td>
                                    <td>
                                        <p>Certaines enc&eacute;phalites auto-immunes, maladie de syst&egrave;me avec une atteinte c&eacute;r&eacute;brale, maladie m&eacute;tabolique et/ou g&eacute;n&eacute;tique (maladie de Wilson, maladie de Huntington, neuroacanthocytose&hellip;) peuvent se traduire par des mouvements anormaux. Le PANDAS (<em>Paediatric Autoimmune Disorders Associated with Streptococcus infections</em>) d&eacute;signe des affections neuropsychiatriques auto-immunes associ&eacute;es &agrave; une infection &agrave; streptocoques b&ecirc;ta-h&eacute;molytiques du groupe A se manifestant par des TOC et/ou de tics.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS ET AMM</h2>
                    <p class="soustitre1">QUEL PATIENT TRAITER PAR UN ANTIDEPRESSEUR DANS LE CADRE D'UN TROUBLE OBSESSIONEL COMPULSIF&nbsp;?</p>
                    <ul>
                        <li>L’utilisation d’un traitement antidépresseur est réservée aux enfants et adolescents avec des TOC modéré à sévère en association avec une psychothérapie.</li>
                        <li>Les antidépresseurs sont à privilégier en cas de dépression associée ou de faible niveau d’insight*.</li>
                        <li>Par ailleurs, les antidépresseurs peuvent aussi être indiqués chez les jeunes qui présentent d’autres troubles psychiatriques, comme le <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 4, start: 1 }}">trouble dépressif</router-link>, le <router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 7, start: 1 }}">trouble anxieux</router-link>, et les troubles liés au stress et au trauma.</li>
                        <li>Les antidépresseurs ne sont pas indiqués dans les formes de TOC d’intensité légère.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>
                                        TOC léger<br>CY-BOCS >15 &lt;20
                                    </th>
                                    <th>
                                        TOC modéré<br>CY-BOCS &ge;20 &lt;25
                                    </th>
                                    <th>
                                        TOC sévère<br>CY-BOCS &ge;25
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        Jeune de moins de 13 ans
                                    </td>
                                    <td>
                                        <p>Psychothérapie seule</p>
                                    </td>
                                    <td>
                                        <p>Psychothérapie seule</p>
                                    </td>
                                    <td>
                                        <p>Psychothérapie + antidépresseur</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Jeune de plus de 13 ans
                                    </td>
                                    <td>
                                        <p>Psychothérapie seule</p>
                                    </td>
                                    <td>
                                        <p>Psychothérapie + antidépresseur</p>
                                    </td>
                                    <td>
                                        <p>Psychothérapie + antidépresseur</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p><small>Note : * L’insight correspond à la conscience du trouble, c’est-à-dire à la conscience du caractère excessif et déraisonnable des obsessions et des compulsions. Le niveau d’insight dépend de l’âge et de la maturité du patient et peut varier au cours de l’évolution du trouble.</small></p>
                    <p class="soustitre1">QUELLES SONT LES MOLECULES AYANT L'AUTORISATION DE MISE SUR LE MARCHE EN FRANCE ?&nbsp;</p>
                    <ul>
                        <li>Les ISRS et les antidépresseurs tricycliques sont les deux familles de traitement utilisées dans les TOC chez l’enfant et l’adolescent.</li>
                        <li>L’utilisation des antidépresseurs tricycliques n’est pas recommandée en 1er intention compte tenu des effets indésirables, en particulier sur le plan cardiologique. Son utilisation relève de la prise en charge spécialisée.</li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th colspan="4">TRAITEMENTS MENTIONNES DANS LES RECOMMANDATIONS INTERNATIONALES
                                    </th>
                                <tr>
                                    <th>
                                        Molécules
                                    </th>
                                    <th>
                                        Mécanisme d’action
                                    </th>
                                    <th>
                                        Autorisation en France
                                    </th>
                                    <th>
                                        Autorisation à l'étranger
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CLOMIPRAMINE&nbsp;(Anafranil<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        A3C
                                    </td>
                                    <td>
                                        <p>AMM &ge; 10 ans</p>
                                    </td>
                                    <td>
                                        <p>FDA &gt;5 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>FLUOXETINE&nbsp;(Prozac<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                    <td>
                                        <p>FDA &gt;8 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>SERTRALINE&nbsp;(Zoloft<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>AMM &ge; 6 ans</p>
                                    </td>
                                    <td>
                                        <p>FDA &gt;6 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>FLUVOXAMINE&nbsp;(Floxyfral<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>AMM &ge; 8 ans</p>
                                    </td>
                                    <td>
                                        <p>FDA &gt;8 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>PAROXETINE&nbsp;(Deroxat<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                    <td>
                                        <p>FDA &gt;8 ans</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CITALOPRAM (Seropram<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>ESCITALOPRAM (Seroplex<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        ISRS
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                    <td>
                                        <p>NON</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="4">AMM= Autorisation de Mise sur le Marché, FDA= Food &amp; Drug Administration, agence de régulation du médicament au Etats-Unis<br>A3C = Antidépresseur tricyclique, ISRS = Inhibiteur Spécifique de la Recapture de la Sérotonine</td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES">
                    <h2 class="title is-4">BENEFICES CLINIQUES</h2>
                    <p class="soustitre1">QUELLE EST LA REPONSE ATTENDUE AU TRAITEMENT ANTIDEPRESSEUR DANS LE TRAITEMENT DU TOC DE L'ENFANT ET L'ADOLESCENT ?</p>
                    <ul>
                        <li>On considère qu’il faut traiter en moyenne 3 jeunes avec des TOC pour avoir un patient en rémission complète c’est-à-dire une disparation complète des symptômes (contre 6 jeunes avec un trouble anxieux ou 10 avec un Episode Dépressif Majeur).</li>
                        <li>Dans les essais cliniques sur le traitement des TOC chez l’enfant et l’adolescent :
                            <ul>
                                <li>Le pourcentage de réponse aux ISRS est de 71% contre 25% pour le placebo.</li>
                                <li>La réduction observée des symptômes est en moyenne de 29-44%.</li>
                                <li>Le taux de rémission est de 16-24% pour les ISRS seuls (sans psychothérapie associée).</li>
                                <li>Les traitements combinés (médicament + psychothérapie) sont plus efficaces que les traitements médicamenteux ou les psychothérapies seuls.</li>
                            </ul>
                        </li>
                        <li>Il n’y a pas d’argument pour penser qu’un ISRS est plus efficace qu’un autre. La clomipramine a possiblement une efficacité supérieure aux ISRS mais elle est moins utilisée compte tenu du profil élevé d’effets indésirables.</li>
                        <li>Les facteurs qui influencent le choix du traitement et la réponse au traitement médicamenteux sont :
                            <ul>
                                <li>L’existence de tic associé : la sertraline seule serait moins efficace dans ce cas</li>
                                <li>Les antécédents familiaux de TOC sont associés à une moins bonne réponse au traitement psychothérapeutique</li>
                                <li>Un niveau d’insight élevé est associé à une meilleure réponse au traitement</li>
                                <li>L’existence de trouble externalisé (trouble déficit de l’attention avec/sans hyperactivité ou d’un trouble des comportements perturbateurs) est associée à une moins bonne réponse au traitement médicamenteux</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES</h2>
                    <p> Vous pouvez retrouver la liste des effets indésirables fréquents des ISRS et des antidépresseurs tricycliques <router-link :to="{ name: 'trouble', params: { id: 4, start: 4 }}">ici</router-link>.
                    </p>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS AVANT LA PRESCRIPTION</h2>
                    <p class="soustitre1">RECUEIL DE L'ADHESION DU JEUNE ET SA FAMILLE</p>
                    <p class="soustitre2">Adhésion du jeune</p>
                    <ul>
                        <li>La prise d’un médicament au quotidien n’est pas un acte anodin pour un enfant.</li>
                        <li>Il faut expliquer en fonction de l’âge l’utilité du traitement, ici « il va te permettre de te sentir mieux et de diminuer les pensées intrusives qui viennent te gêner ».</li>
                        <li>Il est important de rappeler que les parents sont également d’accord et partie prenante.</li>
                        <li>Il conviendra de discuter de ces questions avec l’enfant.</li>
                    </ul>
                    <p class="soustitre2">Adhésion des parents</p>
                    <ul>
                        <li>Il peut exister des réticences, parfois non rationnelles, parfois liées à l’expérience personnelle ou familiale au traitement.</li>
                        <li>Enfin, les parents ont désormais accès à bon nombre d’information sur internet, il faut donc répondre par avance aux questions concernant l’efficacité attendue, la tolérance, les possibles effets indésirables.</li>
                        <li>On pourra t&eacute;l&eacute;charger la <a v-bind:href='getHrefAlt("depression", 2)' class="text-blue hover:text-blue-dark no-underline" download>fiche d&rsquo;information pour les parents sur les antid&eacute;presseurs.</a></li>
                    </ul>
                    <p class="soustitre1">EVALUATION CLINIQUE</p>
                    <ul>
                        <li>L’instauration de l’antidépresseur nécessite de faire une évaluation clinique détaillée sur une période suffisamment longue qui comprend au minimum :
                            <ul>
                                <li>Age de début. Age où les symptômes ont été pour la 1ère fois notés par le patient ou sa famille</li>
                                <li>Degré de souffrance, gêne et temps passé dans la réalisation des rituels. Symptômes les plus gênants. Importance de distinguer les TOC des comportements obsessionnels ou compulsifs transitoires dans le développement normal.
                                    Utilisation possible d’échelles de cotation CY-BOCS (<a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>le questionnaire peut être téléchargé ici</a>).</li>
                                <li>Insight et acceptation du traitement</li>
                                <li>Présence de phénomènes sensoriels. Besoins prémonitoires mentaux ou physiques qui peuvent survenir à la place des obsessions.</li>
                                <li>Attitude de la famille envers la maladie. Critique excessive ou haut niveau d’adaptation des symptômes </li>
                                <li>Présence de troubles psychiatriques comorbides (p.ex. troubles anxieux, troubles de l’humeur, tics, TDAH, mésusage d’alcool ou d’autres substances). Antécédent de trouble psychiatrique familial dont TOC</li>
                                <li>Evaluation de la psychothérapie : modalités, durée, fréquence, alliance</li>
                            </ul>
                        </li>
                        <li>Une fiche <a v-bind:href='getHrefAlt("depression",1)' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l’instauration d’un antidépresseur</a> est disponible pour le clinicien pour résumer ces points.</li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <p class="soustitre1">QUEL TRAITEMENT POUR LE TOC DOIT-ETRE PRIVILEGIE ?</p>
                    <ul>
                        <li>Il n’y a pas d’argument pour penser qu’un ISRS est plus efficace qu’un autre.</li>
                        <li>La sertraline est le traitement qui a le plus fait la preuve de son efficacité dans les essais cliniques. En pratique la sertraline est souvent l’ISRS utilisé en 1er intention dans les TOC de l’enfant et de l’adolescent.</li>
                        <li>Les traitements Inhibiteurs de la Recapture de la Sérotonine et Noradrénergique n’ont pas montré leur efficacité chez l’enfant et l’adolescent. La Venlafaxine (Effexor®) a montré un risque de conduite suicidaire plus important que les ISRS.</li>
                    </ul>
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT DU TOC ?</p>
                    <ul>
                        <li>Bilan biologique pré thérapeutique : non indispensable (si nécessaire bilan hépatique et ionogramme).</li>
                        <li>La molécule doit être instaurée à faible dose et augmentée progressivement par palier en recherchant la dose minimale efficace. <b><u>Attendre 3 semaines avant d’augmenter les doses.</u></b></li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th rowspan="2">
                                        <p>Molécules</p>
                                    </th>
                                    <th colspan="2">
                                        <p><span>Dose d'introduction</span></p>
                                    </th>
                                    <th rowspan="2">
                                        <p>Augmentation palier de minimum 3 semaines</p>
                                    </th>
                                    <th rowspan="2">
                                        <p>Dose maximale</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <p><span>Enfant</span></p>
                                    </th>
                                    <th>
                                        <p><span>Adolescent</span></p>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CLOMIPRAMINE&nbsp;(Anafranil<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>6,25 &ndash; 25</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>25 mg/j</p>
                                    </td>
                                    <td>
                                        <p>25 mg</p>
                                    </td>
                                    <td>
                                        <p>300 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>FLUOXETINE&nbsp;(Prozac<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>2,5 &ndash; 10</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>10-20 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>80 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>SERTRALINE&nbsp;(Zoloft<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>12,5 &ndash; 25</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>25-50 mg/j</p>
                                    </td>
                                    <td>
                                        <p>25 mg</p>
                                    </td>
                                    <td>
                                        <p>200 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>FLUVOXAMINE&nbsp;(Floxyfral<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>12,5 &ndash; 25</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>25-50 mg/j</p>
                                    </td>
                                    <td>
                                        <p>25 mg</p>
                                    </td>
                                    <td>
                                        <p>300 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>PAROXETINE&nbsp;(Deroxat<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>2,5 &ndash; 10</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>CITALOPRAM (Seropram<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>2,5 &ndash; 10</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>10-20 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>60 mg/j</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>ESCITALOPRAM (Seroplex<sup>&reg;</sup>)</p>
                                    </td>
                                    <td>
                                        <p><span>2,5 &ndash; 10</span> mg/j</p>
                                    </td>
                                    <td>
                                        <p>10-20 mg/j</p>
                                    </td>
                                    <td>
                                        <p>10 mg</p>
                                    </td>
                                    <td>
                                        <p>30 mg/j</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                        <li>Le suivi de l’efficacité et de la tolérance du traitement nécessite des consultations régulières.</li>
                        <li>Les échelles cliniques comme <a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>la CY-BOCS</a> peuvent être utiles pour suivre l’efficacité du traitement. Des fiches de <a v-bind:href='getHrefAlt("depression",3)' class="text-blue hover:text-blue-dark no-underline" download>surveillance de la tolérance des ISRS</a> sont disponibles.</li>
                        <li>L’objectif est d’obtenir la rémission complète.</li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                    <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE</p>
                    <ul>
                        <li>La plupart des experts suggèrent que le traitement doit être continué au moins 12 mois après résolution ou stabilisation des symptômes, et être ensuite arrêté de façon très progressive (AACAP 2012).</li>
                        <li>L’arrêt doit se faire progressivement sur plusieurs mois. Deux à trois rechutes dans l’année doit faire envisager la reprise d’un traitement de fond (AACAP 2012)</li>
                    </ul>
                    <p class="soustitre1">SI PAS D'AMELIORATION CLINIQUE SIGNIFICATIVE</p>
                    <ul>
                        <li>Le médicament doit être essayé sur une durée de 12-16 semaines à dose adéquate avant d’être jugé inefficace.</li>
                        <li>Il faut s’assurer de l’observance du traitement. Certains patients peuvent de ne pas prendre leur traitement de peur de perdre des rituels qui les rassurent.</li>
                        <li>Réexaminer et soutenir le travail psychothérapeutique. Les Thérapies Cognitivo-Comportementales ont montré leur efficacité dans cette indication.</li>
                        <li>Rechercher des comorbidités psychiatriques ou des facteurs environnementaux qui contribueraient à pérenniser les symptômes. En particulier :
                            <ul>
                                <li>un niveau élevé de critiques des symptômes du jeune par la famille</li>
                                <li>une hyper-accommodation de la famille aux symptômes qui participerait aux rituels </li>
                            </ul>
                        </li>
                        <li>On peut proposer d’augmenter progressivement la dose de l’ISRS, en respectant les paliers, jusqu’à la dose maximale tolérée.</li>
                        <li>Si le traitement reste inefficace malgré l’augmentation jusqu’à la dose maximale, il faut considérer un changement de traitement.</li>
                    </ul>
                    <p class="soustitre1">SI PAS D'AMELIORATION MALGRE 2 ISRS A DOSE SUFFISANTE ET DUREE NECESSAIRE ON PEUT DISCUTER :</p>
                    <ul>
                        <li>Potentialisation par anxiolytique type benzodiazépine (clonazepame, lorazepam, alprazolam)</li>
                        <li>Potentialisation par antipsychotique à faible dose (recos Canada, AACAP 2012, Bloch 2006) : en particulier si tics, faible insight, labilité émotionnelle majeure, profil TSA-nos :
                            <ul>
                                <li>Halopéridol (Haldol®)</li>
                                <li>Rispéridone (Risperdal®)</li>
                                <li>Quétiapine (Xéroquel®)</li>
                                <li>Aripiprazole (Abilify®)</li>
                            </ul>
                        </li>
                        <li>Utilisation possible de la clomipramine (Anafranil®) (si échec d’au moins 2 ISRS) :
                            <ul>
                                <li>Prescription de clomipramine en monothérapie</li>
                                <li>Ajout de clomipramine à faible dose (25-75 mg/j) à un ISRS (AACAP 2012) (attention à la fluoxétine et paroxétine qui inhibe le CYP-350 2D6)</li>
                            </ul>
                        </li>
                        <li>Hospitalisation si TOC avec instabilité sévère de l’humeur ou idées suicidaires</li>
                    </ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Check-list avant l'instauration d'un antidépresseur</a></li>
                        <li><a v-bind:href='getHrefAlt("depression",2)' class="text-blue hover:text-blue-dark no-underline" download>Fiche d'information famille sur les antidépresseurs</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Echelle CY-BOCS</a></li>
                        <li><a v-bind:href='getHrefAlt("depression",3)' class="text-blue hover:text-blue-dark no-underline" download>Fiche de suivi de la tolérance des antidépresseurs</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique du TOC</a></li>
                    </ul>
                    <p class="soustitre2"> Associations de patients et liens utiles</p>
                    <ul>
                        <li>AF-TOC <a href="http://www.aftoc.org" target="_blank">http://www.aftoc.org/</a></li>
                        <li>AETOC <a href="https://www.aetoc.ch/" target="_blank">https://www.aetoc.ch/</a></li>
                    </ul>
                    <p class="soustitre2">Liste des recommandations et articles utilisés pour la revue</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th>Pays</th>
                                    <th>Recommandations</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Grande-Bretagne</td>
                                    <td>
                                        NICE. Obsessive-compulsive disorder: Core interventions in the treatment of obsessive-compulsive disorder and body dysmorphic disorder. London: NICE, 2005. <a href="http://www.nice.org.uk/guidance/cg31" target="_blank">http://www.nice.org.uk/guidance/cg31</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>US</td>
                                    <td>
                                        AACAP Geller DA, March J. Practice Parameter for the Assessment and Treatment of Children and Adolescents With Obsessive-Compulsive Disorder. J Am Acad Child Adolesc Psychiatry 2012;51:98–113. <a href="http://www.aacap.org/AACAP/Resources_for_Primary_Care/Practice_Parameters_and_Resource_Centers/Practice_Parameters.aspx" target="_blank">http://www.aacap.org/AACAP/Resources_for_Primary_Care/Practice_Parameters_and_Resource_Centers/Practice_Parameters.aspx</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Canada</td>
                                    <td>
                                        Katzman et al. Canadian clinical practice guidelines for the management of anxiety, posttraumatic stress and obsessive-compulsive disorders. BMC Psychiatry 2014, 14 (Suppl 1):S1 <a href="http://www.biomedcentral.com/1471-244X/14/S1/S1" target="_blank">http://www.biomedcentral.com/1471-244X/14/S1/S1</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="has-text-centered has-background-info has-text-white"><b>AUTRES REFERENCES</b></td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="is-size-6">
                                        <ul>
                                            <li>Bloch MH, Landeros-Weisenberger A, Kelmendi B, Coric V, Bracken MB, Leckman JF. A systematic review: antipsychotic augmentation with treatment refractory obsessive-compulsive disorder. Mol Psychiatry. 2006;11:622-632.</li>
                                            <li>Bolton, D., Collins, S., & Steinberg, D. (1983) The treatment of obsessive compulsive disorder in adolescence: a report of fifteen cases. British Journal of Psychiatry, 142, 456–464.</li>
                                            <li>Geller DA, Biederman J, Stewart ES, et al. Which SSRI? A meta-analysis of pharmacotherapy trials in pediatric obsessive compulsive disorder. Am J Psychiatry. 2003;160:1919-1928.</li>
                                            <li>Franklin ME, Sapyta J, Freeman JB, et al. Cognitive behavior therapy augmentation of pharmacotherapy in pediatric obsessive-compulsive disorder: the Pediatric OCD Treatment Study II (POTS II) randomized controlled trial. JAMA 2011;306:1224–32.</li>
                                            <li>March J, Franklin M, Leonard H, et al. Tics moderate treatment outcome with sertraline but not cognitive-behavior therapy in pediatric obsessive-compulsive disorder. Biol Psychiatry. 2007;61: 344-347.</li>
                                            <li>POTS. Cognitive-behavior therapy, sertraline, and their combination for children and adolescents with obsessive-compulsive disorder: the Pediatric OCD Treatment Study (POTS) randomized controlled trial. JAMA 2004;292:1969–76.</li>
                                            <li>Watson HJ, Rees CS. Meta-analysis of randomized, controlled treatment trials for pediatric obsessive-compulsive disorder. J Child Psychol Psychiatry 2008;49:489–98</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleTOC',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            rep: 'toc',
            maintitle: 'TOC',
            dl_files: {},
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>