import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import App from './App.vue'
import Buefy from 'buefy'
import Page from './components/Page.vue'
import Main from './components/Main.vue'
import NotFound from './components/NotFound.vue'
import Trouble from './components/Trouble.vue'
import Mentions from './components/Mentions.vue'
import About from './components/About.vue'
import ContactUs from './components/ContactUs.vue'
import SubscribeNewsletter from './components/SubscribeNewsletter.vue'

import './../node_modules/bulma/css/bulma.css';
import 'buefy/dist/buefy.css'

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-7RF5MZ4XFH" }
});

Vue.use(Buefy)
Vue.use(VueRouter)
Vue.use(VueMeta)

Vue.config.productionTip = false

// routes
const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function(to) {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return {selector: '#scroll_here'}
        }
        /*else {
            return { x: 0, y: 0}
        }*/
    },
    routes: [{
        path: '/',
        component: Main,
        children: [
            // Main will be rendered inside Page's <router-view>
            { path: '', component: Page, name: 'home' },
            { path: 'trouble/:id/:start', component: Trouble, name: 'trouble', props: true  },
            //{ path: 'trouble/:id?', component: Trouble, name: 'trouble', props: dynamicPropsFn  },
            { path: 'mentions', component: Mentions, name: 'mentions' },
            { path: 'a-propos', component: About, name: 'about' },
            { path: 'contact-us', component: ContactUs, name: 'contact-us' },
            { path: 'subscribe', component: SubscribeNewsletter, name: 'subscribe' },
            { path: '*', component: NotFound, name: '404' },
        ]
    }]
})

var moment = require('moment')
// filters
Vue.filter('formatTimestamp', function(value) {
    if (value) {
        return moment.unix(value).format("DD/MM/YYYY")
    }
})
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY")
    }
})
Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
})

new Vue({
    render: h => h(App),
    router: router,
    data: function() {
        return {}
    },
}).$mount('#app')