<template>
    <div id="pagecontent" class="has-background-light">
        <div class="container">
            <div class="columns">
                <div class="column is-6 is-offset-3">
                    <div class="parent has-text-centered">
                        <div v-on:click="goToTrouble(1)" class="card is-shady" data-aos="fade-right" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <figure class="image">
                                    <img src="../assets/bipolar.png" alt="bipolar">
                                </figure>
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Trouble bipolaire</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>
                        <div v-on:click="goToTrouble(2)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/adhd.png" alt="adhd">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">TDAH</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>
                        <div v-on:click="goToTrouble(3)" class="card is-shady" data-aos="fade-right" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/grid.png" alt="ocd">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">TOC</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>
                        <div v-on:click="goToTrouble(4)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/depressed.png" alt="depression">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Dépression</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div v-on:click="goToTrouble(5)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/mind.png" alt="tr. psychotiques">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Tr. Psychotiques</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div v-on:click="goToTrouble(6)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/sommeil.png" alt="tr. sommeil">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Tr. Sommeil</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div v-on:click="goToTrouble(7)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/anxiety.png" alt="tr. anxieux">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Anxiété</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div v-on:click="goToTrouble(8)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/wink.png" alt="tic">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Tic</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div v-on:click="goToTrouble(9)" class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/agitation.png" alt="agitation">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">Agitation</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>

                        <div class="card is-shady" data-aos="fade-left" data-aos-duration="1000">
                            <div class="card-image has-text-centered">
                                <img src="../assets/spinner-of-dots.png" alt="a suivre">
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <h1 class="title is-4 is-size-5-mobile">A suivre...</h1>
                                    <a href="#" class="is-hidden">Lire la fiche</a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init()

export default {
    data: function() {
        return {
            success: null,
        }
    },
    name: 'Page',
    created() {
    },
    methods: {
        goToTrouble: function(id, chapter = 1) {
            this.$router.push({ name: 'trouble', params: { id: id, start: chapter } })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#pagecontent {
    min-height: 800px;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-x: hidden;
}

.parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}


.card-image .icon {
    font-size: 5rem;
    padding-top: 5rem;
    padding-bottom: 2rem;
    color: #209cee;
}

.card-image img {
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 33%;
}

.card-content .content {
    font-size: 14px;
    margin: 1rem 1rem;
}

.card-content  {
    padding: 1.3em;
}

.card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    margin-bottom: 2rem;
}

.is-shady {
    animation: flyintoright .4s backwards;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .1) 0 1px 0;
    border-radius: 4px;
    display: inline-block;
    margin: 10px;
    position: relative;
    transition: all .2s ease-in-out;
}

.is-shady:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, .13), 0 6px 6px rgba(0, 0, 0, .19);
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
  .card-content {
padding:8px;
}


}
</style>