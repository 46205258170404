<template>
    <div id="sommeil">
        <section>
            <div class="bipogrid is-horizontal-center" data-aos="zoom-in">
                <div v-for="item in minmenu" v-on:click="goToPart(item.id)" :key="item.id" :class="{currbox:item.id == showpart}" align="center">
                    <h2 class="subtitle is-size-6-mobile">{{item.title}}</h2><i :class="item.icon"></i>
                </div>
            </div>
            <div class="boxy content_trouble">
                <div v-show="showPart(1)" title="GéNéRALITéS">
                    <h2 class="title is-4">GéNéRALITéS</h2>
                    <p class="soustitre2">Définition</p>
                    <ul>
                        <li>Les difficultés de sommeil sont des symptômes fréquents chez l’enfant et l’adolescents. Ils peuvent être associé à un retentissement significatif au quotidien chez le jeune mais également sur les interactions intra-familiales et la qualité de vie de la famille.</li>
                        <li>Les répercussions les plus fréquentes chez l’enfant et l’adolescent sont les problèmes d’attention et de concentration qui peuvent affecter les apprentissages scolaires, les comportements d’hyperactivité motrice, ainsi qu’un risque plus élevé de développer des troubles anxieux ou dépressifs.</li>
                        <li>Les difficultés de sommeil de l’enfant peuvent aggraver certains problèmes de santé comme le diabète, l’obésité, ou encore conduire à un risque plus important d’accidents et de traumatismes physiques.</li>
                        <li>Les enfants qui présentent des troubles du neurodéveloppement (trouble déficit de l’attention avec ou sans hyperactivité, trouble du spectre de l’autisme, troubles des apprentissages, trouble du développement intellectuelle, épilepsie…) et des troubles sensoriels sont plus à risque de présenter des difficultés de sommeil (1-5).</li>
                    </ul>
                    <p class="soustitre2">Diagnostic</p>
                    <ul>
                        <li>
                            Les difficult&eacute;s de sommeil de l&rsquo;enfant et de l&rsquo;adolescent peuvent &ecirc;tre li&eacute;es 1) &agrave; des conditions de sommeil perturb&eacute;es et &agrave; une mauvaise hygi&egrave;ne de sommeil, 2) &agrave; des anomalies physiologiques du sommeil (anciennement appel&eacute;es trouble du sommeil primaire), ou encore 3) &ecirc;tre secondaire &agrave; une maladie somatique, un trouble psychiatrique, des troubles relationnels ou encore &agrave; la prise d&rsquo;un m&eacute;dicament.
                        </li>
                        <li>
                            L&rsquo;insomnie est le trouble du sommeil le plus fr&eacute;quent chez l&rsquo;enfant et l&rsquo;adolescent. Cela concerne entre 12-17% des enfants et 17-39 % des adolescents (6).
                        </li>
                        <li>
                            On distingue plusieurs types d&rsquo;insomnie chez l&rsquo;enfant et l&rsquo;adolescent&nbsp;:
                            <ul>
                                <li>
                                    Les insomnies comportementales de l&rsquo;enfant
                                </li>
                                <li>
                                    Les tableaux d&rsquo;insomnie qui sont en r&eacute;alit&eacute; des troubles du rythme circadien (comme le syndrome de retard de phase)
                                </li>
                                <li>
                                    Les insomnies secondaires &agrave; des facteurs m&eacute;dicaux, psychiatriques ou iatrog&egrave;nes
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p class="soustitre2">Diagnostics différentiels</p>
                    <ul>
                        <li>Les autres troubles du sommeil (hypersomnie, parasomnie, mouvements anormaux du sommeil, troubles respiratoires au cours du sommeil…) ne sont pas abordés dans ce chapitre.</li>
                        <li>Le questionnaires SDSC (<a v-bind:href='getHref(this.dl_files[6])' class="text-blue hover:text-blue-dark no-underline" download>version 6 mois/4ans</a>, <a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>version 4 ans/16 ans</a>) peut être utilisé pour dépister ces autres troubles du sommeil nécessitant un avis spécialisé.
                        </li>
                    </ul>
                    <p class="soustitre2">Traitements non médicamenteux</p>
                    <ul>
                        <li>
                            La place des traitements m&eacute;dicamenteux dans la prise en charge des insomnies comportementales de l&rsquo;enfant est limit&eacute;e.
                        </li>
                        <li>
                            On distingue
                            <ul>
                                <li>
                                    Les approches de 1<sup>&egrave;re</sup> intention&nbsp;:
                                    <ul>
                                        <li>
                                            Les conseils psycho-&eacute;ducatifs, notamment sur la physiologie du sommeil en fonction de&nbsp;l&rsquo;&acirc;ge d&eacute;veloppemental de l&rsquo;enfant (dont l&rsquo;ad&eacute;quation entre la dur&eacute;e totale du sommeil et des besoins de sommeil en fonction de l&rsquo;&acirc;ge)
                                        </li>
                                        <li>
                                            Les approches portant sur la l&rsquo;adoption de bonnes habitudes de veille et de sommeil (hygi&egrave;ne du sommeil)
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Les approches de 2<sup>&egrave;me</sup> intention comme les interventions comportementales (d&eacute;taill&eacute; dans la <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>fiche sur les principales r&egrave;gles d&rsquo;hygi&egrave;ne et mesures comportementales pour les difficult&eacute;s de sommeil de l&rsquo;enfant et de l&rsquo;adolescent</a>)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Chez les enfants qui pr&eacute;sentent un d&eacute;veloppement typique, les approches non-pharmacologiques sont efficaces dans plus de 80% des cas (7) En revanche, chez des enfants avec troubles du neurod&eacute;veloppement et notamment des troubles du spectre de l&rsquo;autisme, seuls 25% des enfants ont une r&eacute;ponse satisfaisante aux approches non-pharmacologiques (8).
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(2)" title="INDICATIONS ET AMM">
                    <h2 class="title is-4">INDICATIONS</h2>
                    <p class="soustitre1">QUAND UTILISER DES MEDICAMENTS DANS LA PRISE EN CHARGE D’ENFANTS ET D’ADOLESCENTS AVEC DES TROUBLES DU SOMMEIL ?</p>
                    <ul>
                        <li>
                            Il n&rsquo;existe pas de consensus clair sur les indications des traitements m&eacute;dicaments dans la prise en charge de&nbsp;l&rsquo;insomnie de l&rsquo;enfant et de l&rsquo;adolescent (1,4,9), notamment parce que les &eacute;tudes cliniques pharmacologiques utilisent rarement les m&ecirc;mes cat&eacute;gories diagnostiques.
                        </li>
                        <li>
                            Un traitement m&eacute;dicamenteux chez l&rsquo;enfant ou l&rsquo;adolescent est indiqu&eacute; :
                            <ul>
                                <li>
                                    <b>En cas d&rsquo;insomnie comportementale de l&rsquo;enfant</b>&nbsp;: uniquement en deuxi&egrave;me intention, en cas d&rsquo;&eacute;chec persistant d&rsquo;une prise en charge associant des mesures d&rsquo;hygi&egrave;nes et comportementales bien conduites
                                </li>
                                <li>
                                    <b>En cas d&rsquo;insomnie d&rsquo;endormissement li&eacute; &agrave; un syndrome de retard de phase chez l&rsquo;adolescent&nbsp;:</b> en deuxi&egrave;me intention, en cas d&rsquo;&eacute;chec persistant d&rsquo;une prise en charge associant des mesures d&rsquo;hygi&egrave;nes et comportementales bien conduites
                                </li>
                                <li>
                                    <b>En cas d&rsquo;insomnie secondaire</b>, en deuxi&egrave;me intention, uniquement en cas d&rsquo;&eacute;chec persistant d&rsquo;une prise en charge associant des mesures d&rsquo;hygi&egrave;nes et comportementale bien conduites et d&rsquo;inefficacit&eacute; des mesures intervenant sur les facteurs associ&eacute;s perturbant le sommeil (ex&nbsp;: douleur, manifestations anxieuses)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Tr&egrave;s peu d&rsquo;&eacute;tudes ont &eacute;t&eacute; r&eacute;alis&eacute;es pour &eacute;valuer la dur&eacute;e de traitement optimale. La dur&eacute;e de traitement doit &ecirc;tre limit&eacute;e de quelques jours &agrave; quelques semaines dans la plupart des recommandations. En France, seule la prescription d&rsquo;hydroxyzine est associ&eacute;e &agrave; une recommandation sur la dur&eacute;e de prescription, limit&eacute;e &agrave; deux semaines (10).
                        </li>
                    </ul>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th colspan="2">
                                        Produits
                                    </th>
                                    <th colspan="2">
                                        Indications en France
                                    </th>
                                    <th rowspan="2">
                                        Remboursement
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        D&eacute;nomination Commune Internationale
                                    </th>
                                    <th>
                                        Classe
                                    </th>
                                    <th>
                                        Trouble
                                    </th>
                                    <th>
                                        Age
                                    </th>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        M&eacute;latonine pr&eacute;paration magistrale <sup>1</sup>
                                    </td>
                                    <td>
                                        Neurohormone&nbsp;<sup>2</sup>
                                    </td>
                                    <td>
                                        Pas d&rsquo;AMM
                                    </td>
                                    <td>
                                        &ge; 2 ans <sup>3</sup>
                                    </td>
                                    <td>
                                        Non rembours&eacute;e
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        M&eacute;latonine LP p&eacute;diatrique (Sl&eacute;nyto<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        Neurohormone
                                    </td>
                                    <td>
                                        AMM pour l&rsquo;enfant avec TSA ou syndrome de Smith-Magenis
                                    </td>
                                    <td>
                                        2-18 ans
                                    </td>
                                    <td>
                                        Pris en charge par l&rsquo;assurance maladie chez l&rsquo;enfant ou adolescent avec TSA ou syndrome de Smith-Magenis, entre 2 et 18 ans.
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        M&eacute;latonine LP (Circadin<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        Neurohormone
                                    </td>
                                    <td>
                                        RTU&nbsp;: Insomnie chez les enfants avec trouble du neurod&eacute;veloppement et maladies neurog&eacute;n&eacute;tiques
                                    </td>
                                    <td>
                                        6-18 ans
                                    </td>
                                    <td>
                                        Pris en charge par l&rsquo;assurance maladie uniquement dans le cadre de la RTU (avec un montant maximal 800&euro; par patient et par an)
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Alimemazine&nbsp;(Th&eacute;ral&egrave;ne<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        Anti-H1 <sup>4</sup>
                                    </td>
                                    <td>
                                        AMM&nbsp;: traitement de deuxi&egrave;me intention des insomnies d&rsquo;endormissement li&eacute;es &agrave; un &eacute;tat d&rsquo;hyper-&eacute;veil (vigilance accrue li&eacute;e &agrave; des manifestations anxieuses au coucher), apr&egrave;s &eacute;chec des mesures comportementales seules
                                    </td>
                                    <td>
                                        &ge; 3 ans (sirop)
                                        &gt;20kg (solution buvable)<br /> &ge; 6 ans (comprim&eacute;)
                                    </td>
                                    <td>
                                        Remboursable &agrave; 15% ; forme sirop non remboursable
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Hydroxyzine (Atarax<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        Anti-H1
                                    </td>
                                    <td>
                                        AMM&nbsp;: traitement de deuxi&egrave;me intention des insomnies d&rsquo;endormissement li&eacute;es &agrave; un &eacute;tat d&rsquo;hyper-&eacute;veil (vigilance accrue li&eacute;e &agrave; des manifestations anxieuses au coucher), apr&egrave;s &eacute;chec des mesures comportementales seules <sup>5</sup>
                                    </td>
                                    <td>
                                        &ge; 3 ans (sirop)<br /> &ge; 6 ans (comprim&eacute;)
                                    </td>
                                    <td>
                                        Remboursable &agrave; 65%
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="5">
                                    Notes :
                                    <ol>
                                        <li>Les compléments alimentaires qui associent de la mélatonine (strictement &lt; 2 mg) ne sont pas recommandés chez les enfants et adolescents à développement typique (avis de l’ANSES 2018). Considérés comme des produits alimentaires, et non des médicaments, les compléments alimentaires à base de mélatonine n’ont pas fait l’objet d’étude d’évaluation de leur innocuité avant leur commercialisation. Par ailleurs, ces préparations alimentaires contiennent une dose variable de mélatonine ce qui complique une éventuelle titration (11).</li>
                                        <li>La mélatonine est une neurohormone synthétisée et secrétée par la glande pinéale. Le début de sécrétion de mélatonine précède d’environ deux heures la survenue du sommeil naturel, avec un pic de sécrétion entre 3-4 heures du matin. L’hormone est impliquée dans l’induction du sommeil, son maintien notamment en deuxième partie de nuit et la synchronisation des rythmes circadiens.</li>
                                        <li>Aucune étude n’a été conduite pour évaluer l’efficacité de la mélatonine sur les troubles du sommeil d’enfants de moins de deux ans.</li>
                                        <li>L’alimémazine fait partie de la famille chimique des phénothiazines, mais son effet neuroleptique est très faible. Son effet de sédation est lié à son activité antihistaminique. A faible dose il n’y a pas d’effet sur l’architecture du sommeil.</li>
                                        <li>L’hydroxyzine a aussi une AMM pour la prémédication à l’anesthésie générale, les manifestations mineures de l’anxiété chez l’enfant et le traitement symptomatique de l’urticaire (10).</li>
                                    </ol>
                                </td>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(3)" title="BENEFICES CLINIQUES">
                    <h2 class="title is-4">BENEFICES CLINIQUES</h2>
                    <p class="soustitre1">QUELLE EST LA REPONSE ATTENDUE DANS LE TRAITEMENT MEDICAMENTEUX DES TROUBLES DU SOMMEIL DE L’ENFANT ET DE L’ADOLESCENT ?</p>
                    <ul>
                        <li>L’hydroxyzine (Atarax®) et l’alimémazine (Théralène®) sont à privilégiés chez les enfants et adolescents qui présentent des difficultés d’endormissement liés à un état d’hyper-éveil (vigilance accrue liée à des manifestations anxieuses au coucher), pour des durées de traitement limitées, en parallèle d’approches psychothérapeutiques complémentaires.</li>
                        <li>La mélatonine est devenue le traitement pharmacologique de première intention utilisé dans les insomnies de l’enfant pour lesquelles les mesures d’hygiènes et comportementales sont inefficaces.</li>
                        <li>La mélatonine à LI fonctionne surtout si un trouble du rythme circadien sous-tend des difficultés d’endormissement, spécifiquement un retard de la phase de sommeil.</li>
                        <li>La mélatonine LP pédiatrique (Slényto®) est l’unique spécialité pharmaceutique validée pour le traitement des troubles du sommeil chez l’enfant et l’adolescent âgés de 2-18 ans avec trouble du spectre de l’autisme ou avec syndrome de Smith-Magenis.</li>
                        <li>La mélatonine LP (Circadin®) possède actuellement une recommandation temporaire d’utilisation (RTU) pour les enfants de plus de 6 ans qui présentent des troubles développementaux et des maladies neurogénétiques comme le syndrome de Rett, le syndrome de Smith-Magenis, le syndrome d’Angelman, la sclérose tubéreuse de Bourneville ou des troubles du spectre de l’autisme.</li>
                        <li>Les benzodiazépines et les hypnotiques apparentés aux benzodiazépines ne sont pas recommandés pour les troubles du sommeil de l’enfant et de l’adolescent, compte tenu leur possible effets indésirables (retentissement cognitif, risque de rebond à l’arrêt, risque de dépendance). Ces traitements peuvent être utilisés dans la prise en charge de certains parasomnies, après avis auprès de spécialistes de sommeil.</li>
                    </ul>
                    <p class="soustitre1">CAS PARTICULIER DES ENFANTS ET ADOLESCENT AVEC AUTISME</p>
                    <button v-on:click="isHidden_caspart1 = !isHidden_caspart1">Voir le détail</button>
                    <div v-if="!isHidden_caspart1">
                    <p class="soustitre2">Prévalence</p>
                    <ul>
                        <li>Les difficultés de sommeil concernent entre 44 et 83% des enfants et adolescents avec un trouble du spectre de l’autisme.</li>
                        <li>Les enfants qui présentent un trouble du développement intellectuelle ou une épilepsie résistante associés sont davantage à risque (2).</li>
                    </ul>
                    <p class="soustitre2">Prise en charge non médicamenteuse</p>
                    <ul>
                        <li>Rechercher les facteurs qui contribuent à des difficultés de sommeil (ex : apnée obstructive du sommeil, épilepsie, troubles gastro-intestinaux dont reflux gastro-intestinaux, allergie, douleur physique, anxiété lors de l’endormissement) ou la participation d’une cause iatrogène (ex : akathisie lié à un neuroleptique, antiépileptique).</li>
                        <li>Les mesures d’hygiène de sommeil et comportementales sont les interventions de 1ère intention chez les enfants et adolescents avec un trouble du spectre de l’autisme qui souffrent de troubles du sommeil.</li>
                        <li>Compte tenu de leur mauvaise tolérance au changement, il faut être particulièrement attentif à la ritualisation des routines du coucher.</li>
                        <li>Des documents à destination des parents ont été développés par des associations de patients et leurs familles, et validés par un comité d’experts et sont disponibles en accès libre (Brochure : <a href="https://sommeilenfant.reseau-morphee.fr/ressources-pedagogiques" target="_blank">« Autisme : le sommeil, source d’éveil »</a>).</li>
                        <li>Les dispositifs de literie adaptés au particularités sensorielles des enfants avec un trouble du spectre de l’autisme, comme les couvertures lestées ou les matelas à mémoire de forme n’ont pas démontré leur efficacité dans des essais cliniques contrôlés. Toutefois, ces essais n’ont pas stratifié selon la présence de difficultés d’intégration sensorielle. Compte tenu de leur innocuité et du manque de donnés, ces dispositifs ne sont pas à contre-indiquer (3).</li>
                    </ul>
                    <p class="soustitre2">Prise en charge médicamenteuse</p>
                    <ul>
                        <li>
                            En deuxi&egrave;me intention de traitement, la m&eacute;latonine a montr&eacute; son efficacit&eacute; sur diff&eacute;rentes composantes du sommeil chez les enfants avec un trouble du spectre de l&rsquo;autisme : les r&eacute;sistances au moment du coucher, la latence d&rsquo;endormissement, la continuit&eacute; du sommeil, le nombre de r&eacute;veil nocturne, ainsi que le temps total de sommeil.
                        </li>
                        <li>
                            En 2015, l&rsquo;HAS a d&eacute;cid&eacute; d&rsquo;autoriser &agrave; titre d&eacute;rogatoire le Circadin<sup>&reg;</sup> dans le cadre d&rsquo;une recommandation temporaire d&rsquo;utilisation (RTU) pour les &laquo;&nbsp;<em>troubles d&eacute;veloppementaux et des maladies neurog&eacute;n&eacute;tiques comme le syndrome de Rett, le syndrome de Smith-Magenis, le syndrome d&rsquo;Angelman, la scl&eacute;rose tub&eacute;reuse de Bourneville ou des troubles autistiques</em>&nbsp;&raquo;.
                        </li>
                        <li>
                            En 2018, la formule p&eacute;diatrique de la m&eacute;latonine &agrave; lib&eacute;ration prolong&eacute;e (Sl&eacute;nyto<sup>&reg;</sup>, en mini comprim&eacute;s) a obtenu l&rsquo;AMM Europ&eacute;enne pour le traitement des troubles du sommeil chez l&rsquo;enfant et l&rsquo;adolescent avec TSA.
                            <ul>
                                <li>
                                    Le b&eacute;n&eacute;fice de ce traitement a &eacute;t&eacute; valid&eacute;e sur une &eacute;tude (12) conduite chez 125 enfants, &acirc;g&eacute;s de 2 &agrave; 17.5 ans avec un trouble du spectre de l&rsquo;autisme ou un syndrome de Smith-Magenis associ&eacute; &agrave; une insomnie &eacute;voluant depuis au moins 3 mois n&rsquo;ayant pas r&eacute;pondu &agrave; des mesures d&rsquo;hygi&egrave;nes et comportementales bien conduites.
                                </li>
                                <li>
                                    Cet essai incluait une p&eacute;riode initiale par placebo avant randomisation. Apr&egrave;s 13 semaines de traitement en double aveugle, le temps total de sommeil &eacute;tait augment&eacute; en moyenne de 57,5 minutes sous traitement contre 9,1 minutes sous placebo. La latence d&rsquo;endormissement &eacute;tait aussi am&eacute;lior&eacute;e dans le groupe trait&eacute; (&ndash; 39,6 minutes) par rapport au groupe placebo (-12,5 minutes). Les effets secondaires les plus fr&eacute;quents &eacute;taient des c&eacute;phal&eacute;es et une somnolence diurne de m&ecirc;me niveau avec MLP ou placebo.
                                </li>
                                <li>
                                    L&rsquo;efficacit&eacute; et la tol&eacute;rance de la m&eacute;latonine LP p&eacute;diatrique a &eacute;t&eacute; maintenue &agrave; moyen et long terme en phase ouverte, sur une dur&eacute;e totale d&rsquo;&eacute;tude de 2 ans (13,14).
                                </li>
                                <li>
                                    Le traitement par m&eacute;latonine LP p&eacute;diatrique a montr&eacute; une efficacit&eacute; sur la r&eacute;duction des probl&egrave;mes de comportements associ&eacute;, en particulier les sympt&ocirc;mes d&rsquo;hyperactivit&eacute; et d&rsquo;inattention, apr&egrave;s 13 semaines de traitement (15).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    </div>
                    <p class="soustitre1">CAS PARTICULIER DES ENFANTS AVEC UN TROUBLE DEFICIT DE l’ATTENTION</p>
                    <button v-on:click="isHidden_caspart2 = !isHidden_caspart2">Voir le détail</button>
                    <div v-if="!isHidden_caspart2">
                    <ul>
                        <li>
                            Les troubles du sommeil sont souvent multifactoriels chez ces jeunes&nbsp;.
                        </li>
                        <li>
                            Une r&eacute;sistance au coucher est souvent une expression de la symptomatologie du TDAH. Une hyperexcitabilit&eacute; est g&eacute;n&eacute;ralement associ&eacute;e &agrave; une survenue plus tardive du sommeil et une dur&eacute;e r&eacute;duite du sommeil.
                        </li>
                        <li>
                            Une fatigue importante dans la journ&eacute;e peut se traduire par une hyperactivit&eacute; compensatoire mimant ou renfor&ccedil;ant les comportements d&rsquo;hyperactivit&eacute;.
                        </li>
                        <li>
                            Des sympt&ocirc;mes psychiatriques associ&eacute;s&nbsp;: sympt&ocirc;mes anxieux (notamment angoisse de s&eacute;paration) ou d&eacute;pressif associ&eacute;s, difficult&eacute;s d&rsquo;int&eacute;gration sensorielle (1).
                        </li>
                        <li>
                            Les enfants avec un TDAH ont une pr&eacute;valence plus importante de syndrome de jambes sans repos, souvent associ&eacute; &agrave; une carence martiale (d&eacute;ficit en ferritine) qu&rsquo;il convient de chercher et suppl&eacute;menter au besoin (16), ou encore de syndrome de retard de phase du sommeil (17).
                        </li>
                        <li>
                            Il convient d&rsquo;&ecirc;tre attentif&nbsp;aux autres facteurs influen&ccedil;ant le sommeil sur lesquels il est possible d&rsquo;intervenir comme un effet de rebond en fin de dose du psychostimulant.
                        </li>
                    </ul>
                </div>
                </div>
                <div v-show="showPart(4)" title="EFFETS INDESIRABLES">
                    <h2 class="title is-4">EFFETS INDESIRABLES</h2>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Mol&eacute;cules
                                    </th>
                                    <th>
                                        Contre-indications
                                    </th>
                                    <th>
                                        Effets ind&eacute;sirables
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        M&eacute;latonine (Circadin<sup>&reg;</sup>, Sl&eacute;nyto<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        Mise en garde sans &ecirc;tre des contre-indications absolues&nbsp;:
                                        <ul>
                                            <li>
                                                Ant&eacute;c&eacute;dent de troubles immunitaires ou lymphoprolif&eacute;ratifs
                                            </li>
                                            <li>
                                                Traitement associ&eacute; par immunosuppresseur (du fait de propri&eacute;t&eacute;s immunomodulatrices)
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        Peu d&rsquo;effets ind&eacute;sirables rapport&eacute;s
                                        Peu fr&eacute;quemment
                                        <ul>
                                            <li>
                                                Irritabilit&eacute;, nervosit&eacute;, anxi&eacute;t&eacute;, cauchemars
                                            </li>
                                            <li>
                                                Migraine, c&eacute;phal&eacute;e
                                            </li>
                                            <li>
                                                Insomnie, somnolence
                                            </li>
                                            <li>
                                                HTA
                                            </li>
                                            <li>
                                                Douleurs abdominales, digestion difficile
                                            </li>
                                            <li>
                                                Ulc&eacute;ration buccale, bouche s&egrave;che
                                            </li>
                                            <li>
                                                Sueurs nocturnes, d&eacute;mangeassions, rougeur cutan&eacute;, s&eacute;cheresse de la peur
                                            </li>
                                            <li>
                                                Arthralgie, douleur des extr&eacute;mit&eacute;s
                                            </li>
                                            <li>
                                                Prise de poids
                                            </li>
                                            <li>
                                                Possible aggravation de l&rsquo;&eacute;pilepsie
                                            </li>
                                            <li>
                                                Possible aggravation d&rsquo;un asthme
                                            </li>
                                        </ul>
                                        Les effets ind&eacute;sirables &agrave; long-terme ne sont pas &eacute;valu&eacute;s. Compte tenu de l&rsquo;effet th&eacute;orique de suppression de l&rsquo;axe hypothalamo-hypophysaire gonadique, utilisation prudente en cas de retard pubertaire (pas d&rsquo;effet observ&eacute; &agrave; deux ans (13))
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Alimemazine&nbsp;(Th&eacute;ral&egrave;ne<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Ant&eacute;c&eacute;dent d'agranulocytose &agrave; d&rsquo;autres ph&eacute;nothiazines
                                            </li>
                                            <li>
                                                Ant&eacute;c&eacute;dent de syndrome malin des neuroleptiques
                                            </li>
                                            <li>
                                                Risque de glaucome &agrave; angle ferm&eacute;,
                                            </li>
                                            <li>
                                                Risque de blocage des urines (ad&eacute;nome de la prostate)
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <u>Communs</u>
                                        <ul>
                                            <li>
                                                Somnolence
                                            </li>
                                            <li>
                                                Effets atropiniques (bouche s&egrave;che, constipation, hypotension orthostatique, palpitation, trouble de l&rsquo;accommodation, mydriase)
                                            </li>
                                        </ul>
                                        <u>Rares</u>
                                        <ul>
                                            <li>
                                                Anomalie de la formule sanguine, agranulocytose (exceptionnelle)
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hydroxyzine (Atarax<sup>&reg;</sup>)
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Risque de glaucome &agrave; angle ferm&eacute;
                                            </li>
                                            <li>
                                                Risque de r&eacute;tention des urines
                                            </li>
                                            <li>
                                                Porphyrie
                                            </li>
                                            <li>
                                                Risque de torsades de pointes&nbsp;:
                                                <ul>
                                                    <li>
                                                        Visible sur l'ECG
                                                    </li>
                                                    <li>
                                                        Situations favorisant les torsades de pointes (maladie cardiaque, baisse importante du taux de potassium ou de magn&eacute;sium dans le sang, ant&eacute;c&eacute;dent familial de mort subite, ralentissement important du c&oelig;ur)
                                                    </li>
                                                    <li>
                                                        En association avec les m&eacute;dicaments susceptibles de provoquer des torsades de pointes)
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Des pr&eacute;cautions sont n&eacute;cessaires en cas d'insuffisance h&eacute;patique, d'insuffisance r&eacute;nale, de myasth&eacute;nie grave, de d&eacute;mence, de confusion des id&eacute;es
                                            </li>
                                            <li>
                                                A interrompre au moins 5 jours avant la r&eacute;alisation d&rsquo;un test allergique
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <u>Communs </u>
                                        <ul>
                                            <li>
                                                Somnolence
                                            </li>
                                            <li>
                                                Effets paradoxaux
                                            </li>
                                            <li>
                                                Ataxie et vertige
                                            </li>
                                            <li>
                                                Effets atropiniques (bouche s&egrave;che, constipation, hypotension orthostatique, palpitation, trouble de l&rsquo;accommodation, mydriase)
                                            </li>
                                        </ul>
                                        <u>Rares</u>
                                        <ul>
                                            <li>
                                                R&eacute;action allergique : urticaire, et plus rarement, &oelig;d&egrave;me de Quincke
                                            </li>
                                            <li>
                                                H&eacute;patite, augmentation des transaminases
                                            </li>
                                            <li>
                                                Troubles du rythme cardiaque
                                            </li>
                                        </ul>
                                        Peut aggraver une apn&eacute;e obstructive du sommeil
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="soustitre1">EFFET CARDIAQUE DE L’HYDROXYZINE</p>
                    <ul>
                        <li>
                            En mai 2014, au vu de nouvelles donn&eacute;es exp&eacute;rimentales et de pharmacovigilance sugg&eacute;rant un risque potentiel d&rsquo;alt&eacute;rations de l&rsquo;activit&eacute; &eacute;lectrique cardiaque et d&rsquo;arythmies avec l&rsquo;hydroxyzine, le PRAC (<em>Pharmacovigilance Risk Assessment Committee</em>) a recommand&eacute; les restrictions d&rsquo;utilisation de ce traitement pour&nbsp;:
                            <ul>
                                <li>
                                    les patients ayant un QT long cong&eacute;nital ou acquis connu
                                </li>
                                <li>
                                    chez les patients &agrave; risque d&rsquo;allongement du QT.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Le PRAC a aussi recommand&eacute; une r&eacute;duction de la dose maximale journali&egrave;re tol&eacute;r&eacute;e (<router-link class="text-blue hover:text-blue-dark no-underline" :to="{ name: 'trouble', params: { id: 6, start: 6 }}">voir tableau</router-link>), une utilisation &agrave; la dose efficace la plus faible et pendant la dur&eacute;e de traitement la plus courte possible (10).
                        </li>
                    </ul>
                    <p class="soustitre1">RISQUE D’ACCOUTUMANCE</p>
                    <ul>
                        <li>Aucune de ces molécules n’entraînent de dépendance.</li>
                        <li>Il est possible de les arrêter sans décroissance. L’arrêt du traitement peut entraîner une réapparition des difficultés de sommeil, si aucune modification comportementale n’a été apportée.</li>
                    </ul>
                </div>
                <div v-show="showPart(5)" title="CONSEILS AVANT LA PRESCRIPTION">
                    <h2 class="title is-4">CONSEILS</h2>
                    <p class="soustitre1">EVALUATION CLINIQUE DETAILLEE</p>
                    <ul>
                        <li>
                            L&rsquo;instauration de l&rsquo;hypnotique n&eacute;cessite de r&eacute;aliser une &eacute;valuation clinique d&eacute;taill&eacute;e sur une p&eacute;riode suffisamment longue qui comprend au minimum&nbsp;:
                            <ul>
                                <li>
                                    Les circonstances d&rsquo;apparition des difficult&eacute;s de sommeil
                                </li>
                                <li>
                                    Le mode de vie de l&rsquo;enfant/adolescent et les habitudes de sommeil (avec distinction entre les rythmes la veille de jours scolaires par rapport au week-end ou vacances, notamment chez l&rsquo;adolescent)
                                </li>
                                <li>
                                    L&rsquo;&eacute;valuation des sympt&ocirc;mes psychiatriques associ&eacute;s en particulier l&rsquo;anxi&eacute;t&eacute; et l&rsquo;humeur
                                </li>
                                <li>
                                    Les ant&eacute;c&eacute;dents personnels et familiaux de
                                    <ul>
                                        <li>
                                            troubles du sommeil
                                        </li>
                                        <li>
                                            maladies chroniques qui affectent le sommeil (troubles respiratoires, reflux gastro-&oelig;sophagien, maladie cardiaque cong&eacute;nitale, arthrite, et causes de douleurs chroniques)
                                        </li>
                                        <li>
                                            d&rsquo;anomalies morphologiques de la sph&egrave;re ORL ou des fonctions oropharyng&eacute;s qui pourrait &ecirc;tre associ&eacute;es &agrave; un syndrome d&rsquo;apn&eacute;es du sommeil obstructif
                                        </li>
                                        <li>
                                            troubles neurologiques et neurod&eacute;veloppementaux associ&eacute;s &agrave; des troubles du rythme circadien (trouble du d&eacute;veloppement intellectuel, trouble du spectre de l&rsquo;autisme, trouble d&eacute;ficit de l&rsquo;attention, trouble sensoriel)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            L&rsquo;&eacute;valuation doit permettre de d&rsquo;&eacute;liminer un trouble du sommeil secondaire &agrave; un trouble psychiatrique, une cause organique ou des effets iatrog&egrave;nes d&rsquo;un traitement associ&eacute;.
                        </li>
                        <li>
                            L&rsquo;utilisation d&rsquo;un agenda du sommeil peut aider &agrave; pr&eacute;ciser le type d&rsquo;insomnie et &agrave; suivre l&rsquo;&eacute;volution des sympt&ocirc;mes.
                        </li>
                        <li>
                            Des questionnaires valid&eacute;s en fran&ccedil;ais permettent de d&eacute;pister d&rsquo;autres troubles du sommeil n&eacute;cessitant un adressage &agrave; des sp&eacute;cialistes du sommeil (SDSC <a v-bind:href='getHref(this.dl_files[6])' class="text-blue hover:text-blue-dark no-underline" download>version 6 mois/4ans</a>, <a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>version 4 ans/16 ans</a>) (18,19)
                        </li>
                    </ul>
                    <p class="soustitre1">MESURES D’HYGIENES ET COMPORTEMENTALES</p>
                    <ul>
                        <li>
                            La mise en place de r&egrave;gles d&rsquo;hygi&egrave;nes et comportementales sont toujours en 1<sup>&egrave;re</sup> intention pour la prise en charge des troubles du sommeil de l&rsquo;enfant et de l&rsquo;adolescent. Une <a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>fiche sur les principales r&egrave;gles d&rsquo;hygi&egrave;ne et mesures comportementales pour les difficult&eacute;s de sommeil de l&rsquo;enfant et de l&rsquo;adolescent</a> r&eacute;sume ces informations.
                        </li>
                        <li>
                            Education parentale au d&eacute;veloppement du sommeil.
                            <ul>
                                <li>
                                    En particulier ad&eacute;quation de la dur&eacute;e totale du sommeil et des besoins de sommeil en fonction de l&rsquo;&acirc;ge (20) et hygi&egrave;ne du sommeil.
                                </li>
                                <li>
                                    Le niveau de preuve est fort pour cette approche.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Traitements comportementaux.
                            <ul>
                                <li>
                                    L'acad&eacute;mie am&eacute;ricaine de la m&eacute;decine du sommeil a revu 52 &eacute;tudes publi&eacute;es sur le sujet (21); 94% d&rsquo;entre elles montrent des am&eacute;liorations cliniques significatives pour les endormissements et les r&eacute;veils nocturnes.
                                </li>
                                <li>
                                    Le sommeil a &eacute;t&eacute; am&eacute;lior&eacute; chez 80% des jeunes enfants inclus. Le niveau de preuves pour ces approches est donc fort.
                                </li>
                                <li>
                                    La m&eacute;thode comportementale la mieux &eacute;valu&eacute;s est celle de l&rsquo;extinction qui consiste pour les parents &agrave; ignorer les pleurs nocturnes de l&rsquo;enfant. L&rsquo;objectif est de r&eacute;duire le renforcement du comportement de l&rsquo;enfant (cries, sanglot au couchers) et d&rsquo;aider l&rsquo;enfant &agrave; d&eacute;velopper des aptitudes &laquo; auto-apaisantes &raquo; et &agrave; s'endormir de fa&ccedil;on autonome. Le niveau de preuve est fort
                                    <ul>
                                        <li>
                                            Dans la m&eacute;thode d&rsquo;extinction compl&egrave;te les parents ignorent compl&egrave;tement les pleurs de l&rsquo;enfant jusqu&rsquo;au matin,tout en gardant une surveillance en cas de maladie ou d&rsquo;ins&eacute;curit&eacute;.
                                        </li>
                                        <li>
                                            Dans la m&eacute;thode d&rsquo;extinction graduelle les parents laissent leur enfant seul dans son lit et dans sa chambre et attendent un certain d&eacute;lai temporel avant de contr&ocirc;ler &agrave; nouveau l&rsquo;enfant. Ce d&eacute;lai peut &ecirc;tre progressif (par exemple, 5 min, puis 10 min.&hellip;) ou fixe (par exemple, toutes les 5 min.).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    L&rsquo;efficacit&eacute; des autres m&eacute;thodes comportementales (routines positives, d&eacute;calage de l&rsquo;heure du coucher, r&eacute;veils programm&eacute;s, technique de r&eacute;duction progressive de la pr&eacute;sence parentale) est moins bien &eacute;valu&eacute;e.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-show="showPart(6)" title="INSTAURATION DU MEDICAMENT">
                    <h2 class="title is-4">INSTAURATION DU MEDICAMENT</h2>
                    <p class="soustitre1">QUEL TRAITEMENT POUR LE TROUBLE DU SOMMEIL DOIT-ETRE PRIVILEGIE ?</p>
                    <ul>
                        <li>
                            L&rsquo;hydroxyzine (Atarax<sup>&reg;</sup>) et l&rsquo;alim&eacute;mazine (Th&eacute;ral&egrave;ne<sup>&reg;</sup>) sont &agrave; privil&eacute;gier chez les enfants et adolescents qui pr&eacute;sentent des difficult&eacute;s d&rsquo;endormissement li&eacute;s &agrave; un &eacute;tat d&rsquo;hyper-&eacute;veil (vigilance accrue li&eacute;e &agrave; des manifestations anxieuses au coucher), pour des dur&eacute;es de traitement limit&eacute;es, en parallèle d’autres approches psychothérapeutiques.
                        </li>
                        <li>
                            La m&eacute;latonine en pr&eacute;paration magistrale est devenue le traitement pharmacologique de premi&egrave;re intention utilis&eacute; dans les insomnies de l&rsquo;enfant pour lesquelles les mesures d&rsquo;hygi&egrave;nes et comportementales sont inefficaces.
                            <ul>
                                <li>
                                    La m&eacute;latonine &agrave; LI fonctionne surtout si un trouble du rythme circadien sous-tend des difficult&eacute;s d&rsquo;endormissement, sp&eacute;cifiquement un retard de la phase de sommeil.
                                </li>
                                <li>
                                    Son efficacit&eacute; est bien av&eacute;r&eacute;e chez les enfants qui pr&eacute;sentent des troubles du neurod&eacute;veloppement avec une diminution de la latence d&rsquo;endormissement (1,3,2) mais sans avoir toujours d&rsquo;effet sur la dur&eacute;e totale de sommeil.
                                </li>
                                <li>
                                    Son efficacit&eacute; dans les troubles du sommeil secondaire li&eacute;s &agrave; cause organique (autre que trouble du rythme circadien), psychiatrique ou iatrog&egrave;ne n&rsquo;est pas &eacute;tablie.
                                </li>
                            </ul>
                        </li>
                        <li>
                            La m&eacute;latonine LP p&eacute;diatrique (Sl&eacute;nyto<sup>&reg;</sup>) est l&rsquo;unique sp&eacute;cialit&eacute; pharmaceutique valid&eacute;e pour le traitement des troubles du sommeil chez l&rsquo;enfant et l&rsquo;adolescent &acirc;g&eacute;s de 2-18 ans avec trouble du spectre de l&rsquo;autisme ou avec syndrome de Smith-Magenis.
                        </li>
                        <li>
                            La m&eacute;latonine LP (Circadin<sup>&reg;</sup>) poss&egrave;de actuellement une recommandation temporaire d&rsquo;utilisation (RTU) pour les enfants de plus de 6 ans qui pr&eacute;sentent des troubles d&eacute;veloppementaux et des maladies neurog&eacute;n&eacute;tiques comme le syndrome de Rett, le syndrome de Smith-Magenis, le syndrome d&rsquo;Angelman, la scl&eacute;rose tub&eacute;reuse de Bourneville ou des troubles du spectre de l&rsquo;autisme.
                        </li>
                        <li>
                            Les benzodiaz&eacute;pines et les hypnotiques apparent&eacute;s aux benzodiaz&eacute;pines ne sont pas recommand&eacute;s pour les troubles du sommeil de l&rsquo;enfant et de l&rsquo;adolescent, compte tenu leur possible effets ind&eacute;sirables (retentissement cognitif, risque de rebond &agrave; l&rsquo;arr&ecirc;t, risque de d&eacute;pendance). Ces traitements peuvent &ecirc;tre utilis&eacute;s dans la prise en charge de certains parasomnies, apr&egrave;s avis aupr&egrave;s de sp&eacute;cialistes de sommeil.
                        </li>
                    </ul>
                    <p class="soustitre1">COMMENT INSTAURER LE TRAITEMENT ?</p>
                    <p class="soustitre2">Bilan pré-thérapeutique</p>
                    <ul>
                        <li>Aucun bilan biologique pré-thérapeutique n’est nécessaire. Toutefois un bilan biologique peut être nécessaire dans la démarche diagnostique globale face à un jeune avec un trouble du sommeil</li>
                        <li>Un ECG doit être réalisé avant l’instauration de l’hydroxyzine (Atarax®), avec mesure du QTc.</li>
                    </ul>
                    <p class="soustitre2">Moment de la prise</p>
                    <ul>
                        <li>La prise d’hydroxyzine (Atarax®) et d’alimémazine (Théralène®) se fait 15-30 min avant le coucher.</li>
                        <li>La prise de la mélatonine LI se fait habituellement au coucher.</li>
                        <li>La prise de la mélatonine à LP (Slényto® ou Circadin®) se fait 30-60 min avant le coucher.</li>
                    </ul>
                    <p class="soustitre2">Titration</p>
                    <ul>
                        <li>La molécule est instaurée à une posologie adaptée au poids du patient pour l’hydroxyzine et l’alimémazine.</li>
                        <li>La posologie de la mélatonine, que ce soit en LI ou en LP, est indépendante du poids ou de l’âge de l’enfant – elle doit être individuellement titrée.</li>
                    </ul>

                    <div class="table-container">
                        <table class="table-auto">

<tbody>
<tr>
<th>
DCI
</th>
<th>
Dose habituelle
</th>
<th>
Dose maximale
</th>
</tr>

<tr>
<td>
M&eacute;latonine&nbsp;LI (pr&eacute;paration magistrale
</td>
<td>
0,5 &agrave; 3 mg <sup>1</sup>
</td>
<td>
Incertain
</td>
</tr>

<tr>
<td>
M&eacute;latonine LP (Sl&eacute;nyto<sup>&reg;</sup>, Circadin<sup>&reg;</sup>)
</td>
<td>
2 &agrave; 10 mg
</td>
<td>
10 mg
</td>
</tr>

<tr>
<td rowspan="2">
Hydroxyzine (Atarax<sup>&reg;</sup>)
</td>
<td>
Sirop 2 mg/ml (si &acirc;g&eacute; de plus de 30 mois)
<ul>
<li>
0,5 mL/kg /j
</li>
<li>
30 mois- 6 ans (10 &agrave; 20 kg) : 5 &agrave; 10 ml/j
</li>
<li>
6 - 10 ans (20 &agrave; 30 kg) : 10 &agrave; 15 ml ml/j
</li>
<li>
10 - 15 ans (30 &agrave; 40 kg) : 15 &agrave; 20 ml/j
</li>
</ul>
</td>
<td  rowspan="2">
2 mg/kg chez l'enfant &le; 40 kg
100 mg chez l'enfant &gt; 40 kg
</td>
</tr>
<tr>
<td>
Comprim&eacute;s de 25mg <sup>2</sup>
</td>
</tr>

<tr>
<td rowspan="3">
Alimemazine (Th&eacute;ral&egrave;ne<sup>&reg;</sup>)
</td>
<td>
Sirop 0,5 mg/ml (si enfant &acirc;g&eacute; de plus &ge; 3 ans)
<ul>
<li>
0,5 &agrave; 1 mL/kg
</li>
</ul>
</td>
<td rowspan="3">
Enfant&nbsp;: 10 mg
Adolescent&nbsp;: 20 mg
</td>
</tr>
<tr>
<td>
Gouttes 4 % (si enfant p&egrave;se &ge; 20 kg)
<ul>
<li>
0,25 &agrave; 0,5 mg/kg
</li>
</ul>
</td>
</tr>
<tr>
<td>
Comprim&eacute;s de 5 mg (si enfant &acirc;g&eacute; de &ge; 6 ans)
<ul>
<li>
20 &agrave; 40 kg : 1 comprim&eacute;
</li>
<li>
40 &agrave; 50 kg : 1 &agrave; 2 comprim&eacute;s
</li>
</ul>
</td>
</tr>
</tbody>
<tfoot><tr><td colspan="3">Note :
<ol><li>Il n’existe pas de consensus clair pour la posologie. La dose physiologique de mélatonine est de 500 μg. Il n’existe pas de relation dose-effet évidente. La posologie est indépendante de l’âge et du poids.</li>
<li>Pour l’hydroxyzine 1 mg = 0.5 mL.</li></ol></td></tr></tfoot>
                        </table>
                    </div>
                </div>
                <div v-show="showPart(7)" title="SURVEILLANCE">
                    <h2 class="title is-4">SURVEILLANCE</h2>
                    <ul>
                            <li>Il est recommandé d’évaluer le jeune 1 fois par semaine initialement puis d’espacer les consultations en fonction de l’amélioration clinique.</li>
    <li>L’objectif est d’utiliser le traitement durant la période la plus courte possible (10), sauf en cas d’insomnie chronique associée à un trouble du neurodéveloppement.</li>
    <li>L’utilisation d’agenda du sommeil peut aider à préciser les modifications du sommeil.</li>
                    </ul>
                </div>
                <div v-show="showPart(8)" title="CONDUITE A TENIR SELON L'EVOLUTION">
                    <h2 class="title is-4">CONDUITE A TENIR SELON L'EVOLUTION</h2>
                        <p class="soustitre1">SI UNE AMELIORATION CLINIQUE SIGNIFICATIVE EST OBSERVEE</p>
<ul>
    <li>Il est recommandé d’évaluer le jeune 1 fois par semaine initialement puis espacer les consultations en fonction de l’amélioration clinique.</li>
    <li>L’objectif est d’utiliser le traitement durant la période la plus courte possible (10), sauf en cas d’insomnie chronique associée à un trouble du neurodéveloppement.</li>
</ul>
    <p class="soustitre1">SI PAS D’AMELIORATION CLINIQUE</p>
<ul>
    <li>Il faut s’assurer de l’observance du traitement.</li>
    <li>Réexaminer les règles d’hygiène du sommeil et les mesures comportementales.</li>
    <li>Rechercher des comorbidités psychiatriques ou des facteurs environnementaux qui contribueraient à pérenniser les difficultés de sommeil.</li>
    <li>Requestionner les diagnostics différentiels de troubles du sommeil, éventuellement à l’aide des questionnaires (<a v-bind:href='getHref(this.dl_files[6])' class="text-blue hover:text-blue-dark no-underline" download>SDSC version 6 mois/4 ans</a> et <a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>SDSC version 4 ans/16 ans</a>)</li>
    <li>On peut proposer d’augmenter la dose du traitement progressivement, jusqu’à la dose maximum tolérée.</li>
    <li>Des investigations complémentaires doivent être demandés en cas de troubles du sommeil persistent. </li>
</ul>
                </div>
                <div v-show="showPart(9)" title="DOCUMENTS UTILES ET REFERENCES">
                    <h2 class="title is-4">DOCUMENTS UTILES ET REFERENCES</h2>
                    <p class="soustitre2">Ressources à télécharger</p>
                    <ul class="docs_utiles">
                        <li><a href="https://sommeilenfant.reseau-morphee.fr/wp-content/uploads/sites/5/2020/10/Re%CC%81seau-Morphe%CC%81e-Agenda-du-Sommeil-de-3-a%CC%80-10-ans.pdf">Agenda du sommeil du réseau Morphée</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[1])' class="text-blue hover:text-blue-dark no-underline" download>Autre agenda du sommeil</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[6])' class="text-blue hover:text-blue-dark no-underline" download>Questionnaire SDSC version 6 mois/4 ans</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[5])' class="text-blue hover:text-blue-dark no-underline" download>Questionnaire SDSC version 4 ans/16 ans</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[3])' class="text-blue hover:text-blue-dark no-underline" download>Fiche d’information famille sur le traitement médicamenteux du trouble du sommeil</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[2])' class="text-blue hover:text-blue-dark no-underline" download>Fiche règles d'hygiène du sommeil</a></li>
                        <li><a v-bind:href='getHref(this.dl_files[4])' class="text-blue hover:text-blue-dark no-underline" download>Poster résumé du traitement pharmacologique de l’insomnie</a></li>
                    </ul>
                    <p class="soustitre2">Association de patients et liens utiles :</p>
                    <ul>
                        <li>Réseau Morphée : <a href="https://sommeilenfant.reseau-morphee.fr" target="_blank">https://sommeilenfant.reseau-morphee.fr/</a></li>
                    </ul>
                    <p class="py-2 soustitre2">Liste des recommandations utilisées pour la revue :</p>
                    <div class="table-container">
                        <table class="table-auto">
                            <tbody>
                                <tr>
                                    <th>
                                        Pays
                                    </th>
                                    <th>
                                        Recommandations les plus r&eacute;centes
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        France
                                    </td>
                                    <td>
                                        HAS 2014
                                        <br><a href="https://www.has-sante.fr/jcms/c_967446/fr/circadin-dans-le-traitement-des-troubles-du-rythme-veille-sommeil-de-l-enfant" target="_blank">https://www.has-sante.fr/jcms/c_967446/fr/circadin-dans-le-traitement-des-troubles-du-rythme-veille-sommeil-de-l-enfant</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Grande-Bretagne
                                    </td>
                                    <td>
                                        NICE 2013 Sleep disorder in children and young people with ADHD: melatonin
                                        <br><a href="https://www.nice.org.uk/advice/esuom2/chapter/Key-points-from-the-evidence" target="_blank">https://www.nice.org.uk/advice/esuom2/chapter/Key-points-from-the-evidence</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        US
                                    </td>
                                    <td>
                                        AAN 2020 (Buckley et al. 2020)
                                        <br><a href="https://www.aan.com/Guidelines/home/GuidelineDetail/988" target="_blank">https://www.aan.com/Guidelines/home/GuidelineDetail/988</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Canada
                                    </td>
                                    <td>
                                        CPS 2018 (Cunnings et al. 2018)
                                        <br><a href="https://www.cps.ca/documents/position/melatonin-sleep-disorders-children-adolescents" target="_blank">https://www.cps.ca/documents/position/melatonin-sleep-disorders-children-adolescents</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Australie
                                    </td>
                                    <td>
                                        RACGP 2015
                                        <br><a href="https://www.racgp.org.au/afp/2015/december/sleep-problems-in-children/" target="_blank">https://www.racgp.org.au/afp/2015/december/sleep-problems-in-children/</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">AAP: American Academy of Neurology, CPS: Canadian Paediatric Society, HAS: Haute Autorité de Santé, NICE: National Institute for Health and Care Excellence, RACGP: The Royal Australian College of General Practitioners</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <p class="soustitre2">Autres références :</p>
                    <ul>
                        <li>1. NICE (2013) National Institute for Health and Care Excellence: Guidance In: Sleep disorders in children and young people with attention defificit hyperactivity disorder: melatonin. The British Psychological Society &amp; The Royal College of Psychiatrists., Leicester (UK)</li>
                        <li>2. Cohen S, Conduit R, Lockley SW, Rajaratnam SM, Cornish KM (2014) The relationship between sleep and behavior in autism spectrumdisorder (ASD): a review. J Neurodev Disord (6):44</li>
                        <li>3. Buckley AW, Hirtz D, Oskoui M, Armstrong MJ, Batra A, Bridgemohan C, Coury D (2020) Practice guideline: Treatment for insomnia and disrupted sleep behavior in children and adolescents with autism spectrum disorder. Neurology 94:1-13. doi:10.1212/WNL.0000000000009033</li>
                        <li>4. Hannan K, Hiscock H (2015) Sleep problems in children. The Royal Australian College of General practitioners 44 (12):880-884</li>
                        <li>5. Schr&ouml;der CM, Kilic-Huck U, Ruppert E, Bourgin P (2020) Circadian rhythm sleep disorders &ndash; comorbidities. In: European Sleep Medicine Textbook. D Skene &amp; C Gronfier,</li>
                        <li>6. American Academy of Sleep Medicine (2014) The International Classification of Sleep Disorders - Third Edition (ICSD-3). American Academy of Sleep Medicine, Darien, IL</li>
                        <li>7. Mindell JA, Kuhn B, Lewin DS, Meltzer LJ, Sadeh A (2006) Behavioral treatment of bedtime problems and night wakings in infants and young children. Sleep 29 (10):1263-1276</li>
                        <li>8. Malow BA, Byars K, Johnson K, Weiss S, Bernal P, Goldman SE, Panzer R, Coury DL, Glaze DG (2012) A practice pathway for the identification, evaluation, and management of insomnia in children and adolescents with autism spectrum disorders. Pediatrics 130 Suppl 2:S106-124. doi:10.1542/peds.2012-0900I</li>
                        <li>9. Bhargava S (2011) Diagnosis and Management of Common Sleep Problems in Children. Pediatrics in Review 32 (3):91. doi:10.1542/pir.32-3-91</li>
                        <li>10. Haute Autorit&eacute; de Sant&eacute; H (2017) Avis de la Commission de la Transparence : hydroxyzine (chlorhydrate).</li>
                        <li>11. Claustrat B, Bourgin P, Franco P, Geoffroy PA, Kilic-Huck U, Lecendreux M, Lopez R, Micoulaud-Franchi JA, Vecchierini MF, Quera-Salva T, Schr&ouml;der CM (2018) A propos de l&rsquo;avis de l&rsquo;Anses sur les risques li&eacute;s &agrave; la consommation de m&eacute;latonine. M&eacute;decine du Sommeil (15):92-94</li>
                        <li>12. Gringras P, Nir T, Breddy J, Frydman-Marom A, Findling RL (2017) Efficacy and Safety of Pediatric Prolonged-Release Melatonin for Insomnia in Children With Autism Spectrum Disorder. J Am Acad Child Adolesc Psychiatry 56 (11):948-957.e944. doi:10.1016/j.jaac.2017.09.414</li>
                        <li>13. Malow BA, Findling RL, Schroder CM, Maras A, Breddy J, Nir T, Zisapel N, Gringras P (2020) Sleep, Growth, and Puberty After 2 Years of Prolonged-Release Melatonin in Children With Autism Spectrum Disorder. J Am Acad Child Adolesc Psychiatry. doi:10.1016/j.jaac.2019.12.007</li>
                        <li>14. Maras A, Schroder CM, Malow BA, Findling RL, Breddy J, Nir T, Shahmoon S, Zisapel N, Gringras P (2018) Long-Term Efficacy and Safety of Pediatric Prolonged-Release Melatonin for Insomnia in Children with Autism Spectrum Disorder. J Child Adolesc Psychopharmacol 28 (10):699-710. doi:10.1089/cap.2018.0020</li>
                        <li>15. Schroder CM, Malow BA, Maras A, Melmed RD, Findling RL, Breddy J, Nir T, Shahmoon S, Zisapel N, Gringras P (2019) Pediatric Prolonged-Release Melatonin for Sleep in Children with Autism Spectrum Disorder: Impact on Child Behavior and Caregiver's Quality of Life. Journal of autism and developmental disorders 49 (8):3218-3230. doi:10.1007/s10803-019-04046-5</li>
                        <li>16. Konofal E, Lecendreux M, Deron J, Marchand M, Cortese S, Za&iuml;m M, Mouren MC, Arnulf I (2008) Effects of iron supplementation on attention deficit hyperactivity disorder in children. Pediatric neurology 38 (1):20-26. doi:10.1016/j.pediatrneurol.2007.08.014</li>
                        <li>17. Van der Heijden KB, Smits MG, Van Someren EJ, Gunning WB (2005) Idiopathic chronic sleep onset insomnia in attention-deficit/hyperactivity disorder: a circadian rhythm sleep disorder. Chronobiol Int 22 (3):559-570. doi:10.1081/cbi-200062410</li>
                        <li>18. Bruni O, Ottaviano S, Guidetti V, Romoli M, Innocenzi M, Cortesi F, Giannotti F (1996) The Sleep Disturbance Scale for Children (SDSC). Construction and validation of an instrument to evaluate sleep disturbances in childhood and adolescence. J Sleep Res 5 (4):251-261. doi:10.1111/j.1365-2869.1996.00251.x</li>
                        <li>19. Putois B, Leslie W, Gustin MP, Challamel MJ, Raoux A, Guignard-Perret A, Weick D, Sauzeau JB, Herbillon V, Zourou F, Spruyt K, Franco P (2017) The French Sleep Disturbance Scale for Children. Sleep medicine 32:56-65. doi:10.1016/j.sleep.2016.12.008</li>
                        <li>20. Paruthi S, Brooks LJ, D'Ambrosio C, Hall WA, Kotagal S, Lloyd RM, Malow BA, Maski K, Nichols C, Quan SF, Rosen CL, Troester MM, Wise MS (2016) Recommended Amount of Sleep for Pediatric Populations: A Consensus Statement of the American Academy of Sleep Medicine. J Clin Sleep Med 12 (6):785-786. doi:10.5664/jcsm.5866</li>
                        <li>21. Mindell JA, Telofski LS, Wiegand B, Kurtz ES (2009) A nightly bedtime routine: impact on sleep in young children and maternal mood. Sleep 32 (5):599-606. doi:10.1093/sleep/32.5.599</li>
                    </ul>
                </div>
            </div>
            <div id="paginate" class="has-text-centered">
                <i @click.prevent="chapter_prev(showpart)" class="fas fa-chevron-circle-left"></i> {{showpart}}/{{total_items}} <i @click.prevent="chapter_next(showpart)" class="fas fa-chevron-circle-right"></i>
            </div>
        </section>
    </div>
</template>
<script>
import { trbleMixin } from '../../mixins/trbleMixin'

export default {
    name: 'TroubleSommeil',
    mixins: [trbleMixin], // helpers to show/hide chapters
    data() {
        return {
            dl_files: {},
            //showpart: 1,
            isHidden_caspart1: true,
            isHidden_caspart2: true,
            rep: 'sommeil',
            maintitle: 'Troubles du sommeil',
            minmenu: [
                { 'id': 1, 'title': 'Généralités', 'icon': 'fas fa-star' },
                { 'id': 2, 'title': 'Indications', 'icon': 'fas fa-tasks' },
                { 'id': 3, 'title': 'Bénéfices', 'icon': 'fas fa-balance-scale' },
                { 'id': 4, 'title': 'Effets indés.', 'icon': 'fas fa-exclamation-circle' },
                { 'id': 5, 'title': 'Conseils', 'icon': 'fas fa-comments' },
                { 'id': 6, 'title': 'Instauration', 'icon': 'fas fa-capsules' },
                { 'id': 7, 'title': 'Surveillance', 'icon': 'fas fa-eye' },
                { 'id': 8, 'title': 'Conduite', 'icon': 'fas fa-road' },
                { 'id': 9, 'title': 'Ressources', 'icon': 'fas fa-file-download' },
            ]
        }
    },
    created() {},
    destroyed() {},
    mounted() {
        this.dl_files = this.dl_files_all[this.rep]
    },
    methods: {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>